import React, { createContext, useState, useContext } from "react";
import api, { API_URL } from "../API";
import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
import { CaseContext } from "./caseContext";
import { toast } from "react-toastify";
// Create the initial context state
const initialContextState = {
  reportId: undefined,
  sarCaseId: undefined,
  reportName: "",
  reportDescription: "",
  reportDate: "",
};

// Create the context
export const ReportContext = createContext(initialContextState);

// Create a context provider component
export const ReportProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const { sarCase } = useContext(CaseContext);
  const [reportCtx, setReportCtx] = useState(initialContextState);
  const [reports, setReports] = React.useState(null);

  const updateReportCtx = async (ctx) => {
    setReportCtx(ctx);
  };

  const getReportByID = async (reportID) => {
    const requestBody = JSON.stringify({
      action: "get-report-by-id",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
      rid: reportID,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(
        "Report view Failure getting report: " +
          sarCase.sarCaseId +
          "::" +
          response.status +
          " for: get-report-by-id with issue: " +
          response.data
      );
    } else {
      setReportCtx(response.data[0]);
      return response.data[0];
    }
  };

  const getReportsByCase = async (case_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-reports-by-case",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          cid: case_id,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setReports(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const createReport = async (case_id, name, desc) => {
    console.log("createReport");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-report",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          cid: case_id,
          name,
          desc,
        });
        // const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          console.log("createReport: ", JSON.stringify(response.data));
          // reportCtx.reportId = response.data[0]["id"];
          resolve(response.data[0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // update-report
  const updateReportData = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          action: "update-report",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const runReport = async (reportIdList) => {
    const requestBody = JSON.stringify({
      action: "run-reports",
      reportIdList,
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      reportCtx.reportId = response.data["id"];
      return response.data;
    }
  };

  const deleteReport = async (reportID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "delete-report",
          rid: reportID,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });

        const { data: response } = await api.post("", requestBody);
        if (response.status != "failure") {
          resolve(true);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to update the caseID in the store
  const updateReportId = async (id) => {
    // Make your API call here to update the id
    // For demo purposes, we will simply update the state directly
    setReportId(id);
  };

  // Create the context value
  const contextValue = {
    reports,
    getReportsByCase,
    reportCtx,
    updateReportCtx,
    getReportByID,
    createReport,
    setReports,
    runReport,
    updateReportData,
    deleteReport,
  };

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};
