import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../lib/contextLib";
import { systemContext, userContext, caseContext, analysisContext } from "../index";
import Dropzone from 'react-dropzone'
import {
    Box,
    Grid,
    Stack,
    Button,
    ListItemText,
    ListSubheader,
    Typography,
} from "@mui/material";
import useFileUpload from "../Hooks/PresignedS3"

export default function FootageUpload() {
    const { authHeaders } = useAppContext();
    const sysCtx = useContext(systemContext);
    const usrCtx = useContext(userContext);
    const caseCtx = useContext(caseContext);

    const [files, setFiles, uploadFiles] = useFileUpload([]);

    const handleDrop = (acceptedFiles) => {
        setFiles(acceptedFiles)
        console.log(acceptedFiles)
    }   

    return (
    <Box align='center' margin={5}>
        <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <Box width={500} height={300} sx={{border: '3px dashed grey'}} {...getRootProps()}>
                        {/* <input {...getInputProps()} /> */}
                        <Typography>Drop Files Here</Typography>
                    </Box>
                )}
        </Dropzone>
        <Box>
            <Typography>Files: ({files.length})</Typography>
            {files.map((file, i) => (
                <Typography key={i}>{file.path}</Typography>
            ))}
        </Box>
        <Button variant="contained" type='submit' onClick={uploadFiles}>Upload</Button>
    </Box>
    );
}