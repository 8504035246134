import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import { Box, Typography, Modal, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  //   right: "30%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { ReportContext } from "../Context/reportContext";

export default function CreateReportModal(props) {
  const navigate = useNavigate();
  const { createReportOpen, handleCreateReportModalClose } =
    useContext(UiContext);
  const { createReport } = useContext(ReportContext);

  const [isLoading, setIsLoading] = useState(false);
  const nameInput = useRef();
  const descriptionInput = useRef();
  const formRef = React.useRef();
  const { case_id } = useParams();

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    handleCreateReportModalClose();
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      console.log(nameInput.current.value);
      console.log(descriptionInput.current.value);
      await createReport(
        case_id,
        nameInput.current.value,
        descriptionInput.current.value
      );
    } catch (error) {
      setIsLoading(false);
      handleCreateReportModalClose();
      toast.error(error.message);
    }

    setIsLoading(false);

    handleCreateReportModalClose();
  };

  //   Hook that uploads files when files are set
  React.useEffect(() => {
    return () => {
      handleCreateReportModalClose();
    };
  }, []);

  return (
    <Modal
      open={createReportOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <div>
        <Box sx={{ ...style }}>
          <form
            id="enroll"
            onSubmit={() => onSubmit()}
            ref={formRef}
            // style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            <TextField
              id="name"
              name="name"
              label="Name"
              placeholder="Name"
              inputRef={nameInput}
              required={true}
            ></TextField>

            <TextField
              id="description"
              name="description"
              label="Description"
              placeholder="Description"
              inputRef={descriptionInput}
              required={true}
            ></TextField>

            <br></br>
            <br></br>
            <LoadingButton
              variant="outlined"
              loading={isLoading}
              //   disabled={nameInput.current.value.length === 0}
              style={{ marginLeft: 20 }}
              onClick={() => {
                formRef.current.reportValidity() && onSubmit();
              }}
            >
              Submit
            </LoadingButton>
            <LoadingButton
              onClick={onClose}
              disabled={isLoading}
              variant="outlined"
            >
              Close
            </LoadingButton>
          </form>
        </Box>
      </div>
    </Modal>
  );
}
