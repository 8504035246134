import React, { useState, useRef, useCallback } from "react";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Typography,
  Input,
  Box,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { MainLayout } from "../Layouts/MainLayout";
import { toast } from "react-toastify";

export default function MessagingOpt() {
  React.useEffect(() => {}, []);

  return (
    <MainLayout title="Message Opt-in">
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Paper elevation={16} sx={{ padding: 5, height: "82vh !Imporant" }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h2">Messaging Opt-In</Typography>

            <Typography variant="p">
              {<br></br>}
              Search and Rescue (SAR) is a dynamic environment, unplanned, and
              short-lived.
              {<br></br>}
              {<br></br>}
              When volunteers or outside law enforcement agencies arrive onsite
              to aid in a search and rescue with the home agency, minimum
              requirements must be met. First the agency and all members or
              volunteer groups must check in at the mobile headquarters logging
              name, phone numbers, emergency contacts and equipment.
              The agency or volunteer members are then assigned any additional
              equipment needed and search areas. 
              {<br></br>}
              {<br></br>}
              As a participant in a SAR
              effort you agree, for the duration of the specific episode, to be
              contacted about the search, to receive text and multimedia
              messages which may include satellite, drone, or other images,
              coordinates, and other data regarding the search. Your responses,
              if any, may be included with other evidence collected during the
              search.
            </Typography>
          </Stack>
        </Paper>
      </Box>
    </MainLayout>
  );
}
