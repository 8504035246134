import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import {
  systemContext,
  userContext,
  caseContext,
  reportContext,
} from "../index";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "../table/table";

export default function ReportTable(props) {
  const reports = props.reports;
  const { case_id } = useParams();
  const [columns, setColumns] = useState([
    { title: "Report ID", field: "reportId", editable: "never" },
    { title: "Case ID", field: "sarCaseId", editable: "never" },
    { title: "Name", field: "reportName", editable: "never" },
    {
      title: "Description",
      field: "description",
      editable: "never",
    },
    {
      title: "File path",
      field: "filepath",
      editable: "never",
    },
    {
      title: "Report Date",
      field: "reportDate",
      editable: "never",
    },
  ]);

  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    // navigate(`Case/${case_id}/Reports/${rowData.reportId}`);
  };

  return (
    <>
      <Table
        data={reports ? reports : []}
        columns={columns}
        loading={props?.loading}
        // onRowUpdate={props?.editable ? onUpdate : null}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: false,
          selection: false,
          maxBodyHeight: "500px",
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
        onRowView={handleViewClick}
      />
    </>
  );
}
