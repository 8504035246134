import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Modal,
  Stack,
  TextField,
  Grid,
  Switch,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
const style = {
  position: "absolute",
  transform: "translateX(6vw)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "32px",
};
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { FlightContext } from "../Context/flightContext";
import { AnalysisContext } from "../Context/analysisContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import SearchConfigsTable from "../SearchConfigs/SearchConfigsTable";
import Table from "../table/table";
import { MultiSelect } from "react-multi-select-component";
import "../CustomDropdownStyles.css"; // Import the CSS file
import zIndex from "@mui/material/styles/zIndex";
export default function SearchConfigModal(props) {
  const flightId = props?.flightId;
  const analyse = props?.analyse;
  const { searchConfigModalOpen, handleSearchConfigModalClose } =
    useContext(UiContext);

  const { sarCase, getCaseByID, getTagsByCase } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { createAnalysis, runAnalysis } = useContext(AnalysisContext);
  const { flightCtx, getTagsByFlight } = useContext(FlightContext);
  const { searchConfigs, getSearchConfigsByCase, setSearchConfigs } =
    useContext(SearchConfigContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState(false);

  const { case_id } = useParams();

  const navigate = useNavigate();

  const nameInput = useRef();
  const descriptionInput = useRef();
  const matchTagInput = useRef();
  const formRef = React.useRef();

  const onConfirm = async () => {
    setIsLoading(true);
    try {
      await getSearchConfigsByCase(case_id);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }

    // if (Object.values(val).includes(true)) {

    //   //   setInput(false);

    // }
  };

  const onSubmit = async () => {
    // console.log("checked", checked);
    // console.log("Object.values(configIDs).flat()", Object.values(configIDs).flat());
    if (Object.values(configIDs).flat().length > 0) {
      if (Object.values(configIDs).flat().length > 1) {
        toast.warning("Only one search config can be selected");
      } else {
        try {
          setIsLoading(true);
          const analysis = await createAnalysis(
            sarCase.sarCaseOrgId,
            case_id,
            flightCtx.flightId,
            Object.values(configIDs).flat(),
            nameInput.current.value,
            descriptionInput.current.value,
            matchTagInput.current.value,
            props?.selectedFootageIds,
            props?.selectedVideoIds,
            selected.map((el) => el.label),
            checked // isFullCase
          );
          await runAnalysis(sarCase.sarCaseOrgId, case_id, flightCtx.flightId, [
            analysis.id,
          ]);
          navigate(`/cases/${case_id}/analysis/${analysis.id}`);
          toast.info(`Execution Request Sent for Analysis: ${analysis.id}`);
          setIsLoading(false);
          handleSearchConfigModalClose();
        } catch (error) {
          setIsLoading(false);
          handleSearchConfigModalClose();
          toast.error(error.message);
        }
      }
    } else {
      toast.warning("No search config selected");
      return;
    }
  };

  const getFlightTags = async () => {
    if (flightCtx?.flightId) {
      try {
        setIsListLoading(true);
        let response = await getTagsByFlight(flightCtx?.flightId);
        // console.log("RESPONSE: ", response);
        setIsListLoading(false);
        setTags(response);
      } catch (error) {
        setIsListLoading(false);
        toast.error(error.message);
      }
    }
  };

  const getCaseTags = async () => {
    if (case_id) {
      try {
        setIsListLoading(true);
        let response = await getTagsByCase(case_id);
        // console.log("getCaseTags response: ", response);
        setIsListLoading(false);
        setTags(response);
      } catch (error) {
        setIsListLoading(false);
        toast.error(error.message);
      }
    }
  };

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    // setInput(true);
    // setVal({
    //   color_rgb: false,
    //   object_detect: false,
    // });
    handleSearchConfigModalClose();
  };
  const onSwitchChange = (event) => {
    setChecked(!checked);
  };

  React.useEffect(() => {
    if (!props?.readOnly) onConfirm();
  }, []);

  React.useEffect(() => {
    getFlightTags();
    return () => {
      setSelected([]);
      setConfigIds([]);
      setChecked(false);
    };
  }, [searchConfigModalOpen]);

  React.useEffect(() => {
    if (checked) {
      getCaseTags();
    } else {
      getFlightTags();
    }
  }, [checked]);

  const [configIDs, setConfigIds] = useState({});

  const onSelectionChange = (rows, rowData) => {
    // console.log("onSelectionChange, rowData:", rowData);
    // console.log("onSelectionChange, rows:", rows);
    if (rows.length > 0) {
      let duplicateState = { ...configIDs };
      duplicateState[`${rows[0]["searchTypeName"]}`] = rows.map(
        (el) => el.searchConfigId
      );
      setConfigIds({ ...duplicateState });
    } else {
      let duplicateState = { ...configIDs };
      duplicateState[`${rowData["searchTypeName"]}`] = duplicateState[
        `${rowData["searchTypeName"]}`
      ].filter((el) => el !== rowData.searchConfigId);
      setConfigIds({ ...duplicateState });
    }

    // console.log("onSelectionChange, configIDs:", configIDs);
  };

  // Custom styles for the dropdown content
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 2, // Add the desired z-index value
    }),
  };

  return (
    <Modal
      open={searchConfigModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <div>
        <Box sx={{ ...style }}>
          {analyse && (
            <div style={{ justifyContent: "right", display: "flex" }}>
              <FormControlLabel
                control={
                  <Switch defaultChecked={checked} onChange={onSwitchChange} />
                }
                label="Get tags by case"
              />
            </div>
          )}
          {analyse && tags.length > 0 && (
            <div>
              <Typography variant="h5" align="center" sx={{ marginBottom: 5 }}>
                Please select which filters to apply
              </Typography>

              <MultiSelect
                options={tags.map((el) => {
                  return {
                    label: el,
                    value: el,
                  };
                })}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                isLoading={isListLoading}
              />
              <br />
              <br />
            </div>
          )}
          {analyse && (
            <Typography variant="h5" align="center" sx={{ marginBottom: 5 }}>
              Please select which search configurations to apply
            </Typography>
          )}
          <SearchConfigsTable
            searchConfigs={searchConfigs}
            tableOptions={{
              toolbar: false,
              sorting: false,
              draggable: false,
              grouping: false,
              search: false,
              paging: false,
              selection: props?.readOnly ? false : true,
              showSelectAllCheckbox: false,
            }}
            onSelectionChange={onSelectionChange}
            modalView={true}
            editable={false}
            deleteable={false}
            readOnly={props?.readOnly}
          />

          <br></br>
          <br></br>

          {analyse && (
            <Grid container spacing={2} xs={12}>
              <Grid item spacing={2} xs={6}>
                {props?.selectedFootageIds?.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ marginBottom: 5 }}
                    >
                      Selected Frame IDs
                    </Typography>
                    <Table
                      data={props?.selectedFootageIds.map((el) => {
                        return {
                          id: el,
                        };
                      })}
                      columns={[
                        { title: "ID", field: "id", editable: "never" },
                      ]}
                    />
                  </>
                )}
              </Grid>
              <Grid item spacing={2} xs={6}>
                {props?.selectedVideoIds?.length > 0 && (
                  <>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ marginBottom: 5 }}
                    >
                      Selected Video IDs
                    </Typography>
                    <Table
                      data={props?.selectedVideoIds.map((el) => {
                        return {
                          id: el,
                        };
                      })}
                      columns={[
                        { title: "ID", field: "id", editable: "never" },
                      ]}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          )}

          <br></br>
          <br></br>

          <form
            id="enroll"
            onSubmit={() => onSubmit()}
            ref={formRef}
            // style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            {analyse && (
              <>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    inputRef={nameInput}
                    required={true}
                  ></TextField>

                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    placeholder="Description"
                    inputRef={descriptionInput}
                    required={true}
                  ></TextField>

                  <TextField
                    id="matchTag"
                    name="matchTag"
                    label="Tag on Match"
                    placeholder="Tag on Match"
                    inputRef={matchTagInput}
                    required={true}
                  ></TextField>
                </Stack>

                <br></br>
              </>
            )}

            {props?.readOnly ? (
              <></>
            ) : (
              <>
                <LoadingButton
                  onClick={onClose}
                  disabled={isLoading}
                  variant="outlined"
                >
                  Close
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isLoading}
                  disabled={configIDs.length === 0}
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    formRef.current.reportValidity() && onSubmit();
                  }}
                >
                  Submit
                </LoadingButton>
              </>
            )}
          </form>
        </Box>
      </div>
    </Modal>
  );
}
