import React from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { VideoContext } from "../Context/videoContext";
import { FootageContext } from "../Context/footageContext";
import { UiContext } from "../Context/uiContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightVideoTable from "../Flights/FlightVideoTable";
import FlightFootageTable from "../Flights/FlightFootageTable";
import FileUploadModal from "../Modals/FileUploadModal";
import Table from "../table/table";
import SearchConfigModal from "../Modals/searchConfigModal";
import CustumAccordianSummaryLayout from "../Layouts/CustumAccordianSummaryLayout";
import { MainLayout } from "../Layouts/MainLayout";
import TaskStatusModal from "../Modals/TaskStatusModal";

const testFlights = [
  {
    caseId: 1,
    flightId: 1,
    frames: [],
  },
];

export default function FlightSummaryPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { flightCtx, getFlightByID, flights, setFlights, updateFlight } =
    useContext(FlightContext);
  const { videos, setVideos } = useContext(VideoContext);
  const { footage, getFootageByFlight, setFootage, syncFlightFootageExif } =
    useContext(FootageContext);
  const {
    handlefileUploadModalOpen,
    fileUploadModalopen,
    handleSearchConfigModalOpen,
    searchConfigModalOpen,
    openInNewTab,
    handleStatusModalOpen,
    statusModalOpen,
  } = useContext(UiContext);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [flightFootageTableLoading, setFlightFootageTableLoading] =
    useState(false);
  const [flightVideoTableLoading, setFlightVideoTableLoading] = useState(false);
  const [uploadFlightVideo, setUploadFlightVideo] = useState(false);
  const [uploadFlightFootage, setUploadFlightFootage] = useState(false);
  const [uploadFlightGeoData, setUploadFlightGeoData] = useState(false);
  const [selectedFootageIds, setSelectedFootageIds] = useState([]);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const [responsiveDialogConfirm, setResponsiveDialogConfirm] =
    React.useState(false);
  const [openResponsiveDialog, setOpenResponsiveDialog] = React.useState(false);
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickConfirm = () => {
    setResponsiveDialogConfirm(true);
    setOpenResponsiveDialog(false);
  };

  const handleCancel = () => {
    setResponsiveDialogConfirm(false);
    setOpenResponsiveDialog(false);
  };

  const handleClose = () => {
    setOpenResponsiveDialog(false);
  };

  const ResponsiveDialog = () => (
    <React.Fragment>
      <Dialog
        open={openResponsiveDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to proceed without uploading geolocation file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  const { case_id, flight_id } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      await getCaseByID(case_id);
      await getFlightByID(flight_id);
      setLoading(false);

      setData([flightCtx]);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };
  React.useEffect(() => {
    if (1 == 1) {
      setColumns([
        { title: "Flight Id", field: "flightId", editable: "never" },
        { title: "Name", field: "flightName", editable: "onUpdate" },
        { title: "Pilot", field: "pilotName", editable: "onUpdate" },
        { title: "Date", field: "flightDate", editable: "never" },
        {
          title: "Description",
          field: "flightDescription",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.flightDescription}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ]);
      if (!sarCase?.sarCaseId && !flightCtx?.flightId) {
        fetchData();
      } else {
        setData([flightCtx]);
      }
    } else {
      setFlights(testFlights);
    }
  }, [sarCase?.sarCaseId, flightCtx?.flightId]);

  React.useEffect(() => {

    console.log("flightCtx:::", flightCtx);
    
  }, []);

  React.useEffect(() => {
    console.log("videos: ", videos);
    if (videos?.[0]?.refresh) {
      onFlightVideoTableExpand({}, true);
    }
  }, [videos]);

  React.useEffect(() => {
    console.log("footage: ", footage);
    if (footage?.[0]?.refresh) {
      onFlightFootageTableExpand({}, true);
    }
  }, [footage]);

  const handleSplitClick = () => {
    console.log("The split link was clicked.");
  };

  const handleSyncClick = async () => {
    setOpenResponsiveDialog(true);
  };

  const callSyncAction = async () => {
    try {
      toast.info("Sending Execution Request");
      setSyncLoading(true);
      let response = await syncFlightFootageExif(flight_id);
      toast.info(`Sync Request: ${response}`);
      await getFlightByID(flight_id);
      setSyncLoading(false);
    } catch (error) {
      setSyncLoading(false);
      toast.error(error.message);
    }
  };

  const handleStatusModal = async () => {
    await getFlightByID(flight_id);
    handleStatusModalOpen(true);
  };
  React.useEffect(() => {
    console.log("responsiveDialogConfirm: ", responsiveDialogConfirm);
    if (responsiveDialogConfirm) {
      callSyncAction();
    }
  }, [responsiveDialogConfirm]);

  const onFlightFootageTableExpand = async (event, expanded) => {
    if (expanded) {
      setFlightFootageTableLoading(true);
    } else {
      setFlightFootageTableLoading(false);
    }
  };

  const onFlightVideoTableExpand = async (event, expanded) => {
    if (expanded) {
      setFlightVideoTableLoading(true);
    } else {
      setFlightVideoTableLoading(false);
    }
  };

  const onSummaryUpdate = (newData, oldData, tableData, setNewTableData) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("newData", newData);

        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.flightId === oldData.flightId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);

        // const dataUpdate = [...data];
        // const index = oldData.tableData.id;
        // dataUpdate[index] = newData;
        // setData([...dataUpdate]);
        await updateFlight({
          action: "update-flight",
          fid: oldData.flightId,
          name: newData.flightName,
          pilot: newData.pilotName,
          description: newData.description,
        });
        resolve();
      } catch (error) {
        toast.error(error.message);
        resolve();
      }

      // setTimeout(() => {

      //   resolve();
      // }, 1000);
    });
  };

  // const handleFlightVideoUpload = () => {
  //   setUploadFlightFootage(false);
  //   setUploadFlightVideo(true);
  //   handlefileUploadModalOpen();
  // };

  // const handleFlightFootageUpload = () => {
  //   setUploadFlightVideo(false);
  //   setUploadFlightFootage(true);
  //   handlefileUploadModalOpen();
  // };

  const handleFlightGeoDataUpload = () => {
    setUploadFlightVideo(false);
    setUploadFlightFootage(false);
    setUploadFlightGeoData(true);
    handlefileUploadModalOpen();
  };

  const handleAnalyze = () => {
    handleSearchConfigModalOpen();
  };

  return (
    <MainLayout title={`Flight ${sarCase.sarCaseName}`}>
      <ResponsiveDialog />
      {statusModalOpen && (
        <TaskStatusModal
          todo_count={flightCtx?.geolocation_todo_count}
          done_count={flightCtx?.geolocation_done_count}
          percent={flightCtx?.geolocation_percent}
          estRemaining={0}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        {/* <Typography variant="h3" align="left" sx={{ marginBottom: 5 }}>
            Flight Summary - {flightCtx.flightName}
          </Typography> */}
        <Button
          variant="contained"
          size="large"
          type="submit"
          onClick={handleAnalyze}
          sx={{ marginLeft: "auto" }}
        >
          Analyze
        </Button>

        {searchConfigModalOpen && (
          <SearchConfigModal
            flightId={flight_id}
            analyse={true}
            selectedVideoIds={selectedVideoIds}
            selectedFootageIds={selectedFootageIds}
          />
        )}
      </Box>

      <Box marginBottom={5} maxHeight={750}>
        <br />
        <Table
          data={data}
          columns={columns}
          onRowUpdate={onSummaryUpdate}
          loading={loading}
          editable={true}
        />
      </Box>
      <Box>
        <Accordion onChange={onFlightVideoTableExpand}>
          <CustumAccordianSummaryLayout
            title="Flight Videos"
            button1Text="Upload Flight Video"
            button1HandleClick={() => {
              openInNewTab(
                `Cases/${case_id}/Flights/${flight_id}/upload/video`
              );
              // handleFlightVideoUpload()
            }}
            button2Text="Sync EXIF"
            button2HandleClick={() => handleSyncClick()}
          />

          <AccordionDetails>
            {flightVideoTableLoading && (
              <FlightVideoTable
                editable={false}
                // loading={}
                options={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: false,
                  paging: true,
                  selection: true,
                  maxBodyHeight: "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
                videoIds={selectedVideoIds}
                setVideoIds={setSelectedVideoIds}
              ></FlightVideoTable>
            )}
          </AccordionDetails>
          {uploadFlightVideo && (
            <FileUploadModal
              isVideoSelect={true}
              title="Kind of Video Files:"
            />
          )}
        </Accordion>

        <Accordion onChange={onFlightFootageTableExpand}>
          <CustumAccordianSummaryLayout
            title="Flight Footage (Images)"
            button1Text="Upload Flight Images"
            button1HandleClick={() => {
              openInNewTab(
                `Cases/${case_id}/Flights/${flight_id}/upload/footage`
              );
              // handleFlightFootageUpload()
            }}
            button2Text={
              flightCtx?.geolocation_todo_count > 0 &&
              flightCtx?.geolocation_done_count <
                flightCtx?.geolocation_todo_count
                ? "Sync Status"
                : "Sync EXIF"
            }
            button2HandleClick={() => {
              if (syncLoading) {
                toast.info("Sync request in progress");
              }
              if (
                flightCtx?.geolocation_todo_count > 0 &&
                flightCtx?.geolocation_done_count <
                  flightCtx?.geolocation_todo_count
              ) {
                handleStatusModal();
              } else {
                handleSyncClick();
              }
            }}
            button3Text="Upload Geo Data"
            button3HandleClick={() => handleFlightGeoDataUpload()}
            button2Disabled={false}
          />

          <AccordionDetails>
            {flightFootageTableLoading && (
              <FlightFootageTable
                // footage={footage}
                editable={false}
                options={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: false,
                  paging: true,
                  selection: true,
                  maxBodyHeight: "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
                footageIds={selectedFootageIds}
                setFootageIds={setSelectedFootageIds}
                // loading={flightFootageTableLoading}
                deleteable={true}
              ></FlightFootageTable>
            )}
          </AccordionDetails>
          {uploadFlightFootage && (
            <FileUploadModal
              isFootageSelect={true}
              title="Kind of Image/Footage Files:"
            />
          )}

          {uploadFlightGeoData && (
            <FileUploadModal
              isGeoDataSelect={true}
              title="Upload Geo Data File:"
            />
          )}
        </Accordion>
      </Box>
    </MainLayout>
  );
}
