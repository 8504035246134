import * as React from "react";
import { useParams, useLocation } from "react-router-dom"; // Import useLocation
import { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
  Modal,
  Tooltip,
  Button,
  FormGroup,
  Switch,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useFileUpload2 from "../Hooks/PresignedS3_2";
import LoadingButton from "@mui/lab/LoadingButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { FlightContext } from "../Context/flightContext";
import { toast } from "react-toastify";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";
import { VideoContext } from "../Context/videoContext";
import { FootageContext } from "../Context/footageContext";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "../table/table";
import { MainLayout } from "../Layouts/MainLayout";
import { Stack } from "@mui/system";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default function Upload() {
  const { setVideos } = useContext(VideoContext);
  // const { progress, setProgress } = useContext(UiContext);
  const { updateFileContext, fileContext } = useContext(ImageContext);
  const radiometricCoverters = useRadiometericConverters();
  const { sarCase, addAwsGetAccess, copyAwsFiles } = useContext(CaseContext);
  const { images } = useContext(ImageContext);
  const { userCtx, sendWorkerNotification } = useContext(UserContext);
  const [
    files,
    setFiles,
    uploadBulkFiles,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
  ] = useFileUpload2([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bucketAccess, setBucketAccess] = useState(false);

  const [responsiveDialogConfirm, setResponsiveDialogConfirm] =
    React.useState(false);
  const [openResponsiveDialog, setOpenResponsiveDialog] = React.useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const handleClickConfirm = () => {
    setResponsiveDialogConfirm(true);
    setOpenResponsiveDialog(false);
  };

  const handleCancel = () => {
    setResponsiveDialogConfirm(false);
    setOpenResponsiveDialog(false);
  };

  const handleClose = () => {
    setOpenResponsiveDialog(false);
  };

  const ResponsiveDialog = () => (
    <React.Fragment>
      <Dialog
        open={openResponsiveDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want the copy process to be started?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  React.useEffect(() => {
    console.log("responsiveDialogConfirm: ", responsiveDialogConfirm);
    if (responsiveDialogConfirm) {
      // call copy action
      copyAwsFiles({
        gid: userCtx.workerOrgId,
        cid: case_id,
        fid: flight_id,
        accountId: accountID.current.value,
        bucket: bucketName.current.value,
        prefix: bucketPrefix.current.value,
      });
      toast.info("Copy request sent");
    }
  }, [responsiveDialogConfirm]);

  //form submit fields
  const accountID = React.useRef();
  const bucketName = React.useRef();
  const bucketPrefix = React.useRef();

  const columns = [
    {
      title: "File Name",
      field: "path",
    },

    {
      title: "State",
      field: "uploadStatus",
      render: (rowData) => uploadStatus[rowData.path] || "Not Uploaded",
    },
  ];
  const { case_id, flight_id, type } = useParams();

  const location = useLocation(); // Access the location object

  // Extract imageID from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const imageID = queryParams.get("imageID") || "";
  const frameID = queryParams.get("frameID") || "";
  const imagePurpose = queryParams.get("imagePurpose") || "";
  const imageViewType = queryParams.get("imageViewType") || "";
  const footageType = queryParams.get("footageType") || "";
  const converterType = queryParams.get("converterType") || "";
  const imageName = queryParams.get("imageName") || "";

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleFileContextChange = (key, value) => {
    updateFileContext({
      ...fileContext,
      [key]: value,
    });
  };

  const onConfirm = async () => {
    if (files.length === 0) {
      toast.warning("Upload atleast one file");
      return;
    }
    if (imageID && files.length > 1) {
      toast.warning("Only 1 file can be uploaded");
      return;
    }
    if (frameID && files.length > 1) {
      toast.warning("Only 1 file can be uploaded");
      return;
    }
    if (files) {
      if (type === "images" && fileContext.img_purpose === -1) {
        toast.warning("Select image purpose");
        return;
      }
      if (type === "images" && fileContext.img_view_type === -1) {
        toast.warning("Select image type");
        return;
      }

      if (
        (type === "videos" || type === "footage") &&
        fileContext.footage_type === -1
      ) {
        toast.warning("Select footage type");
        return;
      }
      try {
        setIsLoading(true);
        let response = await uploadBulkFiles(fileContext);
        console.log("response", response);
        // setProgress(0);
        setIsLoading(false);
        // setFiles([]);
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  const onSubmit = React.useCallback(
    () => async (event) => {
      event.preventDefault();
      if (accountID.current.value === "") {
        toast.warn("Account ID can not be empty");
        return;
      }
      if (bucketName.current.value === "") {
        toast.warn("Bucket name can not be empty");
        return;
      }
      if (bucketPrefix.current.value === "") {
        toast.warn("Bucket Prefix can not be empty");
        return;
      }
      setLoading(true);

      const data = {
        action: "add-aws-get-access",
        accountId: accountID.current.value,
        bucket: bucketName.current.value,
        prefix: bucketPrefix.current.value,
      };
      try {
        let response = await addAwsGetAccess(data);
        console.log("RESPONSE addAwsGetAccess:", response);
        if (response[0] === true) {
          toast.info("Information successfully stored");
          setOpenResponsiveDialog(true);
        } else {
          toast.error(response[0]);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    },
    [userCtx, sarCase]
  );

  // Hook that uploads files when files are set
  React.useEffect(() => {
    setState();
  }, [type, userCtx]);

  React.useEffect(() => {
    console.log("img_purpose", fileContext.img_purpose);
    console.log("img_view_type", fileContext.img_view_type);
    console.log("footage_type", fileContext.footage_type);
    console.log("iid", fileContext.iid);
  }, [fileContext]);

  const setState = async () => {
    console.log("IDS:", userCtx.workerId, userCtx.workerOrgId);
    if (userCtx.workerOrgId && userCtx.workerId) {
      setFileState();
    }
  };

  const setFileState = () => {
    if (type === "images") {
      let fileContext = {
        action: "get-s3-presigned-url-list",
        action_purpose: type, // todo: evolve to "case" (generic)
        presigned_ttl: 600,
        isImageSelect: 1,
        gid: userCtx.workerOrgId,
        wid: userCtx.workerId,
        cid: case_id,
        img_purpose: -1, // images: 0 - source, 1 - evidence, 2 - source subimages (not used here)
        img_view_type: -1, // 0 - color rgb, 1 - thermal rgb, 2 - radiometric
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      };
      if (imageID) {
        setIsOptionSelected(true);
        fileContext = {
          ...fileContext,
          iid: imageID,
          img_purpose: Number(imagePurpose),
          img_view_type: Number(imageViewType),
        };
      }
      updateFileContext({
        ...fileContext,
      });
    }

    if (type === "video") {
      updateFileContext({
        action: "get-s3-presigned-url-list",
        action_purpose: type,
        presigned_ttl: 600,
        isVideoSelect: 1,
        gid: sarCase.sarCaseOrgId,
        wid: userCtx.workerId,
        cid: case_id,
        fid: flight_id, // want this: flightCtx.flightId,, but is not working !!!!!!!!!!
        img_purpose: 3, // images: 0 - source, 1 - evidence, 2 - source subimages (not used here)
        // footage: 3 - flight video, 4 - flight footage
        footage_type: -1, // 0 - RGB Color, 1 - Thermal Color, 2 - Radiometric
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      });
    }

    if (type === "footage") {
      let fileContext = {
        action: "get-s3-presigned-url-list",
        action_purpose: type,
        presigned_ttl: 600,
        isFootageSelect: 1,
        gid: sarCase.sarCaseOrgId,
        wid: userCtx.workerId,
        cid: case_id,
        fid: flight_id, // want this: flightCtx.flightId,, but is not working !!!!!!!!!!
        img_purpose: 4, // images: 0 - source, 1 - evidence, 2 - source subimages (not used here)
        // footage: 3 - flight video, 4 - flight footage
        footage_type: -1, // 0 - RGB Color, 1 - Thermal Color, 2 - Radiometric
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      };
      if (frameID) {
        setIsOptionSelected(true);
        fileContext = {
          ...fileContext,
          frid: frameID,
          footage_type: Number(footageType),
          radiometric_converter: Number(converterType),
        };
      }
      updateFileContext({
        ...fileContext,
      });
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Allow only 12-digit numeric values (AWS account ID format)
    const numericInput = inputValue.replace(/\D/g, "").slice(0, 12);
    accountID.current.value = numericInput;
  };

  return (
    <MainLayout title={`Upload`}>
      <ResponsiveDialog />
      {flight_id ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <FormControlLabel
            disabled={loading}
            control={
              <Switch
                checked={bucketAccess}
                onChange={(e) => setBucketAccess(e.target.checked)}
              />
            }
            label="Bucket Access"
          />
        </Box>
      ) : (
        <></>
      )}

      {bucketAccess ? (
        <form
          id="enroll"
          onSubmit={onSubmit()}
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <Paper elevation={16} sx={{ padding: 5 }}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h2">S3 Bucket Details</Typography>
              <Typography variant="p">
                * Please follow these steps before submitting these details{" "}
                <a
                  target="_blank"
                  href="https://docs.cloud303.io/s/d2e44aa0-a8a0-4fd4-b14d-d3bd32e90ab9"
                >
                  Document Link
                </a>
              </Typography>
              <TextField
                label="Account ID"
                onChange={handleInputChange}
                placeholder="Account ID"
                inputProps={{
                  inputMode: "numeric",
                }}
                inputRef={accountID}
                id="accountId"
              ></TextField>

              <TextField
                id="bucketName"
                name="bucketName"
                label="Bucket Name"
                placeholder="Bucket Name"
                inputRef={bucketName}
              ></TextField>

              <TextField
                // multiline={true}
                // rows={4}
                id="bucketPrefix"
                name="bucketPrefix"
                label="Bucket Prefix"
                placeholder="Bucket Prefix"
                inputRef={bucketPrefix}
              ></TextField>

              <Button disabled={loading} variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </Paper>
        </form>
      ) : (
        <>
          {!isObjEmpty(uploadStatus) && (
            <div>
              {!imageID && !frameID && (
                <Button
                  sx={{
                    marginBottom: "2vh",
                  }}
                  onClick={() => {
                    setUploadStatus({});
                    setFiles([]);
                  }}
                  disabled={isLoading}
                >
                  Upload more files
                </Button>
              )}
              <Table
                data={files}
                columns={columns}
                options={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: true,
                  paging: false,
                  selection: false,
                  maxBodyHeight: "700px",
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
              />
            </div>
          )}

          {isObjEmpty(uploadStatus) && (
            <Box margin={5} sx={style}>
              {type === "images" && (
                <Box>
                  <Typography
                    align="center"
                    variant="h5"
                    sx={{ display: "flex", paddingBottom: 1 }}
                  >
                    {!imageName
                      ? "Purpose and Kind of Files:"
                      : `Re-upload file ${imageName}`}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      name="img_purpose"
                      value={fileContext["img_purpose"]}
                      onChange={(event) => {
                        handleFileContextChange(
                          event.target.name,
                          event.target.value
                        );
                        setIsOptionSelected(true); // Set the flag when an option is selected
                      }}
                    >
                      <FormLabel
                        style={{
                          textAlign: "left",
                          paddingRight: "10",
                        }}
                      >
                        required <text style={{ color: "red" }}>*</text>
                      </FormLabel>
                      <FormControlLabel
                        value="0"
                        disabled={
                          imageID && imagePurpose !== "0" ? true : false
                        }
                        control={<Radio />}
                        label={"Source Images"}
                        defaultChecked
                      ></FormControlLabel>
                      <FormControlLabel
                        value="1"
                        disabled={
                          imageID && imagePurpose !== "1" ? true : false
                        }
                        control={<Radio />}
                        label={"Evidence Images"}
                      ></FormControlLabel>
                    </RadioGroup>

                    <FormControl>
                      <RadioGroup
                        row
                        name="img_view_type"
                        value={fileContext["img_view_type"]}
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true); // Set the flag when an option is selected
                        }}
                      >
                        <FormLabel
                          style={{
                            textAlign: "left",
                            paddingRight: "10",
                          }}
                        >
                          required <text style={{ color: "red" }}>*</text>
                        </FormLabel>
                        <FormControlLabel
                          value="0"
                          disabled={
                            imageID && imageViewType !== "0" ? true : false
                          }
                          control={<Radio />}
                          label={"RGB Color"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="1"
                          disabled={
                            imageID && imageViewType !== "1" ? true : false
                          }
                          control={<Radio />}
                          label={"Thermal Color"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="2"
                          disabled={
                            imageID && imageViewType !== "2" ? true : false
                          }
                          control={<Radio />}
                          label={"Radiometric"}
                        ></FormControlLabel>
                      </RadioGroup>
                    </FormControl>

                    {fileContext.img_view_type == 2 && (
                      <FormControl>
                        <FormLabel
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Converters
                        </FormLabel>
                        <RadioGroup
                          name="radiometric_converter"
                          onChange={(event) => {
                            handleFileContextChange(
                              event.target.name,
                              event.target.value
                            );
                            setIsOptionSelected(true); // Set the flag when an option is selected
                          }}
                        >
                          {radiometricCoverters?.map((el) => {
                            return (
                              <FormControlLabel
                                control={<Radio />}
                                value={el.objId}
                                label={el.dispName}
                                key={el.objId}
                                disabled={
                                  imageID && imageViewType ? true : false
                                }
                              ></FormControlLabel>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </FormControl>
                </Box>
              )}

              {type === "video" && (
                <Box>
                  <Typography
                    align="center"
                    variant="h5"
                    sx={{ display: "flex", paddingBottom: 1 }}
                  >
                    Kind of Video Files:
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      name="footage_type"
                      onChange={(event) => {
                        handleFileContextChange(
                          event.target.name,
                          event.target.value
                        );
                        setIsOptionSelected(true); // Set the flag when an option is selected
                      }}
                    >
                      <FormLabel
                        style={{
                          textAlign: "left",
                          paddingRight: "10",
                        }}
                      >
                        required <text style={{ color: "red" }}>*</text>
                      </FormLabel>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={"RGB Color"}
                      ></FormControlLabel>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={"Thermal Color"}
                      ></FormControlLabel>
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={"Radiometric"}
                      ></FormControlLabel>
                    </RadioGroup>
                  </FormControl>

                  {fileContext.footage_type == 2 && (
                    <FormControl>
                      <FormLabel
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Converters
                      </FormLabel>
                      <RadioGroup
                        name="radiometric_converter"
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true); // Set the flag when an option is selected
                        }}
                      >
                        {radiometricCoverters?.map((el) => {
                          return (
                            <FormControlLabel
                              control={<Radio />}
                              value={el.objId}
                              label={el.dispName}
                              key={el.objId}
                            ></FormControlLabel>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Box>
              )}

              {type === "footage" && (
                <Box>
                  <Typography
                    align="center"
                    variant="h5"
                    sx={{ display: "flex", paddingBottom: 1 }}
                  >
                    {!imageName
                      ? "Kind of Image/Footage Files:"
                      : `Re-upload file ${imageName}`}
                  </Typography>

                  <FormControl>
                    <RadioGroup
                      row
                      name="footage_type"
                      value={
                        frameID && footageType
                          ? footageType
                          : fileContext["footage_type"]
                      }
                      onChange={(event) => {
                        handleFileContextChange(
                          event.target.name,
                          event.target.value
                        );
                        setIsOptionSelected(true); // Set the flag when an option is selected
                      }}
                    >
                      <FormLabel
                        style={{
                          textAlign: "left",
                          paddingRight: "10",
                        }}
                      >
                        required <text style={{ color: "red" }}>*</text>
                      </FormLabel>
                      <FormControlLabel
                        value="0"
                        disabled={frameID && footageType !== "0" ? true : false}
                        control={<Radio />}
                        label={"RGB Color"}
                      ></FormControlLabel>
                      <FormControlLabel
                        value="1"
                        disabled={frameID && footageType !== "1" ? true : false}
                        control={<Radio />}
                        label={"Thermal Color"}
                      ></FormControlLabel>
                      <FormControlLabel
                        value="2"
                        disabled={frameID && footageType !== "2" ? true : false}
                        control={<Radio />}
                        label={"Radiometric"}
                      ></FormControlLabel>
                    </RadioGroup>
                  </FormControl>

                  {fileContext.footage_type == 2 && (
                    <FormControl>
                      <FormLabel
                        style={{
                          textAlign: "left",
                        }}
                      >
                        Converters required{" "}
                        <text style={{ color: "red" }}>*</text>
                      </FormLabel>
                      <RadioGroup
                        name="radiometric_converter"
                        value={
                          frameID && converterType
                            ? converterType
                            : fileContext["radiometric_converter"]
                        }
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true); // Set the flag when an option is selected
                        }}
                      >
                        {radiometricCoverters?.map((el) => {
                          return (
                            <FormControlLabel
                              control={<Radio />}
                              value={el.objId}
                              label={el.dispName}
                              key={el.objId}
                              disabled={
                                frameID && converterType !== el.objId
                                  ? true
                                  : false
                              }
                            ></FormControlLabel>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  )}
                </Box>
              )}

              <Box
                sx={{
                  maxHeight: "100px",
                  overflow: "scroll",
                }}
              >
                <Typography>{files.length} Files To Upload:</Typography>
                {files.map((file, i) => (
                  <Typography key={i}>
                    {i + 1}. {file.path}
                  </Typography>
                ))}
              </Box>
              <Dropzone
                multiple={imageID || frameID ? false : true}
                onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    height={200}
                    sx={{ border: "3px dashed grey" }}
                    {...getRootProps()}
                  >
                    <Typography>Drop Files Here</Typography>
                  </Box>
                )}
              </Dropzone>
              <Tooltip title={"Closing will cause background upload to stop"}>
                <LoadingButton
                  onClick={() => {
                    window.open("about:blank", "_self");
                    window.close();
                  }}
                  disabled={isLoading}
                  variant="outlined"
                  sx={{ marginRight: "2vh", marginTop: "2vh" }}
                >
                  Close
                </LoadingButton>
              </Tooltip>
              <LoadingButton
                onClick={onConfirm}
                variant="outlined"
                loading={isLoading}
                sx={{ marginTop: "2vh" }}
                disabled={!isOptionSelected} // Disable the button if no option is selected
              >
                Confirm
              </LoadingButton>
            </Box>
          )}
        </>
      )}
    </MainLayout>
  );
}
