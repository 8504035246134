import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";

import { CaseContext } from "../Context/caseContext";
import { VideoContext } from "../Context/videoContext";

import { UiContext } from "../Context/uiContext";

import { Box, Button } from "@mui/material";

import FileUploadModal from "../Modals/FileUploadModal";
import FlightVideoTable from "../Flights/FlightVideoTable";
import { MainLayout } from "../Layouts/MainLayout";

export default function FlightVideoSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { videos, getVideosByFlight } = useContext(VideoContext);

  const { handlefileUploadModalOpen, openInNewTab } = useContext(UiContext);
  const { case_id, flight_id } = useParams();
  const [flightVideoTableLoading, setFlightVideoTableLoading] = useState(false);
  const [uploadFlightVideo, setUploadFlightVideo] = useState(false);

  const handleFlightFootageUpload = () => {
    setUploadFlightVideo(true);
    handlefileUploadModalOpen();
  };

  const fetchData = async () => {
    try {
      // setFlightVideoTableLoading(true);
      await getCaseByID(case_id);
      // await getVideosByFlight(flight_id);
      // setFlightVideoTableLoading(false);
    } catch (error) {
      // setFlightVideoTableLoading(false);
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId && !videos.videoId) {
        fetchData();
      }
      console.log("videos?.[0]?.refresh", videos?.[0]?.refresh);
      if (videos?.[0]?.refresh) {
        fetchData();
      }
    }
  }, [videos, sarCase]);

  return (
    <MainLayout title={`Videos`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          onClick={()=> {
            openInNewTab(
              `Cases/${case_id}/Flights/${flight_id}/Videos/upload/video`
            );
            // handleFlightFootageUpload
          }}
          sx={{ marginLeft: "auto" }}
        >
          Upload Flight Videos
        </Button>
      </Box>
      <FlightVideoTable
        // videos={videos}
        editable={false}
        // loading={flightVideoTableLoading}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: true,
          selection: false,
          maxBodyHeight: "500px",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
      ></FlightVideoTable>
      {uploadFlightVideo && (
        <FileUploadModal isVideoSelect={true} title="Kind of Video Files:" />
      )}
    </MainLayout>
  );
}
