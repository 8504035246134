import React, { createContext, useState } from "react";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
// Create the initial context state
const initialContextState = {
  user: {
    cognitoId: "",
    cognitoWorkerName: "",
    cognitoWorkerOrgName: "",
  },
  authHeaders: {},
  isAuthenticated: false,
};

// Create the context
export const AppContext = createContext(initialContextState);

// Create a context provider component
export const AppProvider = ({ children }) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [authHeaders, setAuthHeaders] = useState(null);
  const [user, setUser] = useState(null);

  const updateAuthHeaders = async () => {
    try {
      const res = await Auth.currentSession();
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      setAuthHeaders({
        Authorization: `Bearer ${jwt}`,
        "Content-type": "application/json",
      });
      userHasAuthenticated(true);
    } catch (error) {
      console.log("AUTH ERROR:", error);
      toast.error(error.message);
    }
  };

  const login = async (email, password) => {
    return new Promise(async (resolve, rejcet) => {
      try {
        await Auth.signIn(email, password);
        let user = await Auth.currentAuthenticatedUser();
        setUser({
          email: user.attributes.email,
          cognitoId: user.attributes.sub,
          cognitoWorkerName: user.attributes.name,
          cognitoWorkerOrgName: user.attributes["custom:org_name"],
        });
        userHasAuthenticated(true);
        resolve(true);
      } catch (error) {
        console.log("AUTH ERROR:", error);
        toast.error(error.message);
        rejcet(error);
      }
    })
    
  };

  const loadSession = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      setUser({
        email: user.attributes.email,
        cognitoId: user.attributes.sub,
        cognitoWorkerName: user.attributes.name,
        cognitoWorkerOrgName: user.attributes["custom:org_name"],
      });
      userHasAuthenticated(true);
    } catch (error) {
      console.log("AUTH ERROR:", error);
      toast.error(error.message);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      userHasAuthenticated(false);
    } catch (error) {
      console.log("AUTH ERROR:", error);
      toast.error(error.message);
    }
  };

  // Create the context value
  const contextValue = {
    isAuthenticated,
    login,
    logout,
    authHeaders,
    updateAuthHeaders,
    user,
    loadSession,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
