import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

import AssessmentIcon from "@mui/icons-material/Assessment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import MovieIcon from "@mui/icons-material/Movie";
import RuleIcon from "@mui/icons-material/Rule";

import { Button, Collapse, Drawer, List, ListSubheader } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { UiContext } from "../Context/uiContext";

export default function CustomDrawer({ isClosed, closeDrawer }) {
  const {
    createReportOpen,
    handleCreateReportModalClose,
    handleCreateReportModalOpen,
  } = React.useContext(UiContext);
  const [caseID, setCaseID] = React.useState("");
  const [flightID, setFightID] = React.useState("");
  const [menuOptions, setMenuOption] = React.useState("default");
  const [open, setOpen] = React.useState(true);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(!open);
  };

  const defaultOption = [
    {
      Name: "Messaging Opt-In",
      Icon: ListAltIcon,
      Route: "/messaging-opt-in",
      Nested: [],
    },
    { Name: "Cases", Icon: ListAltIcon, Route: "/Cases", Nested: [] },
    caseID
      ? {
          Name: "1. Reference Images/Evidence",
          Icon: MovieIcon,
          Route: `/Cases/${caseID}/Images`,
          Nested: [],
        }
      : null,
    caseID
      ? {
          Name: "2. Search Configs",
          Icon: AssessmentIcon,
          Route: `/Cases/${caseID}/SearchConfigs`,
          Nested: [],
        }
      : null,

    caseID
      ? {
          Name: "3. Surveillance Sources",
          Icon: FlightTakeoffIcon,
          Route: `/Cases/${caseID}/Flights`,
          Nested: [],
        }
      : null,
    caseID
      ? {
          Name: "4. Analyses",
          Icon: RuleIcon,
          Route: `/Cases/${caseID}/Analysis`,
          Nested: [],
        }
      : null,

    caseID
      ? {
          Name: "5. Reports",
          Icon: RuleIcon,
          Route: `/Cases/${caseID}/Reports`,
          Nested: [],
        }
      : null,

    // { Name: "Create case", Icon: ListAltIcon, Route: "/Cases/create" },
    // caseID
    //   ? {
    //       Name: "Create Flight",
    //       Icon: MovieIcon,
    //       Route: `/Cases/${caseID}/Flights/Create`,
    //     }
    //   : null,
  ];
  const menuList = {
    default: [...defaultOption],
    Cases: [...defaultOption],
    Flights: [...defaultOption],
    Nested: [
      flightID
        ? {
            Name: "Videos",
            Icon: MovieIcon,
            Route: `/Cases/${caseID}/Flights/${flightID}/Videos`,
          }
        : null,
      flightID
        ? {
            Name: "Footage",
            Icon: MovieIcon,
            Route: `/Cases/${caseID}/Flights/${flightID}/Footage`,
          }
        : null,
    ],
  };

  let location = useLocation();
  const getPathName = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    if (pathnames[0] === "Cases") {
      setMenuOption(pathnames[0]);
      if (pathnames[1]) {
        setCaseID(pathnames[1]);
      } else {
        setCaseID("");
      }
    }

    if (pathnames[2] === "Flights") {
      setMenuOption(pathnames[2]);
      if (pathnames[3]) {
        setFightID(pathnames[3]);
      } else {
        setFightID("");
      }
    }

    if (pathnames[2] === "Analysis") {
      setMenuOption("default");
    }
    if (pathnames[2] === "SearchConfigs") {
      setMenuOption("default");
    }
    if (pathnames[2] === "Images") {
      setMenuOption("default");
    }

    // Reset config
    if (pathnames.length === 0) {
      setMenuOption("default");
      setFightID("");
      setCaseID("");
    }
  };

  React.useEffect(() => {
    getPathName();
  }, [location]);

  return (
    <Drawer open={isClosed} onClose={closeDrawer}>
      <Box sx={{ width: "auto", auto: 250 }} role="presentation">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Menu
            </ListSubheader>
          }
          key={"menu"}
        >
          {menuList[`${menuOptions}`] &&
            menuList[`${menuOptions}`]
              .filter((el) => el !== null)
              .map(({ Name, Icon, Route, Nested }, i) => (
                <>
                  <ListItem
                    disablePadding
                    onClick={closeDrawer}
                    component={Link}
                    to={Route}
                    key={i + "menu"}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        Name === "3. Surveillance Sources" && flightID
                          ? handleClick(e)
                          : null;
                      }}
                    >
                      <ListItemIcon>{<Icon />}</ListItemIcon>
                      <ListItemText primary={Name} />
                      {open
                        ? Name === "3. Surveillance Sources" &&
                          flightID && <ExpandLess />
                        : Name === "3. Surveillance Sources" &&
                          flightID && <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                  {Name === "3. Surveillance Sources" && flightID && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {menuList["Nested"].map(({ Name, Icon, Route }, i) => {
                          return (
                            <ListItem
                              disablePadding
                              component={Link}
                              to={Route}
                              key={i + "nested"}
                              onClick={closeDrawer}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>{<Icon />}</ListItemIcon>
                                <ListItemText primary={Name} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </>
              ))}
        </List>
      </Box>
    </Drawer>
  );
}
