const config = {
    // Backend config
    // s3: {
    //   REGION: process.env.REACT_APP_REGION,
    //   BUCKET: process.env.REACT_APP_BUCKET,
    // },
    // apiGateway: {
    //   REGION: process.env.REACT_APP_REGION,
    //   URL: process.env.REACT_APP_API_URL,
    // },
    cognito: {
      REGION: "us-east-1", //process.env.REACT_APP_REGION,
      USER_POOL_ID: "us-east-1_au4Gi37Ue", // process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: "2ch8s7a5k843pfs6lrnnas56om" // process.env.REACT_APP_USER_POOL_CLIENT_ID,
    //   IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  };
  
  export default config;