import React, { useState, useEffect, useContext, forwardRef } from "react";
import { flushSync } from "react-dom";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { AnalysisContext } from "../../Context/analysisContext";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

import { UiContext } from "../../Context/uiContext";
import { toast } from "react-toastify";
export default function HitsTable(props) {
  const {
    handleImageViewModalOpen,
    handleHitsViewModalOpen,
    handleNotificationModalOpen,
  } = useContext(UiContext);
  const {
    currFrame,
    currframeDataImage,
    framesDataImagesQuery,
    setCurrFrameDataImage,
    frameDataImagesCount,
    slidishRef,
    currImage,
    setCurrImage,
    updateFrameDataImage,
  } = useContext(AnalysisContext);

  function viewFrameDataImage(data) {
    handleImageViewModalOpen();
    setCurrFrameDataImage(data);
    setCurrImage(data);
  }

  function sendNotificationFrameDataImage(data) {
    setCurrFrameDataImage(data);
    handleNotificationModalOpen();
  }

  const openHitsViewModal = async (rowData) => {
    setCurrFrameDataImage(rowData);
    handleHitsViewModalOpen();
  };

  const [columns, setColumns] = useState([
    // {
    //   title: "View Image",
    //   field: "frameDataImageId",
    //   editable: "never",
    //   render: (rowData) => (
    //     <>
    //       <Button
    //         disabled={currFrame?.frameId === rowData.frameId}
    //         onClick={() => viewFrameDataImage(rowData)}
    //       >
    //         View
    //       </Button>
    //     </>
    //   ),
    //   editComponent: (tableData) => (
    //     <>
    //       <Button
    //         disabled={true}
    //         onClick={() => viewFrameDataImage(tableData.rowData)}
    //       >
    //         View
    //       </Button>
    //     </>
    //   ),
    // },
    {
      title: "FrameData Image ID",
      field: "frameDataImageId",
      editable: "never",
      // width: 50,
    },
    {
      title: "Image",
      field: "filepath",
      editable: "never",
      // width: 100,
      render: (rowData) => (
        <Button
          onClick={() => slidishRef.current.goto(rowData.tableData.index)}
        >
          <img
            style={{
              maxWidth: "120px",
              maxHeight: "120px",
            }}
            src={rowData.filepath.replace(/full/g, "thumb")}
          ></img>
        </Button>
      ),
    },
    {
      title: "Name",
      field: "name",
      editable: "never",
      // width: 50,
    },
    {
      title: "Hits",
      field: "number_of_hits",
      editable: "never",
      // width: 50,
    },
    {
      title: "Display Sequence",
      field: "display_sequence",
      editable: "never",
      // width: 50,
    },
    {
      title: "Report Sequence",
      field: "report_sequence",
      editable: "onUpdate",
      // width: 50,
    },
    {
      title: "Use in report",
      field: "use_in_report",
      editable: "onUpdate",
      width: 10,
      render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">use_in_report</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.use_in_report}
            label="use_in_report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },

    {
      title: "Description",
      field: "description",
      editable: "onUpdate",
      width: 100,
      // render: (rowData) => (
      //   <TextField
      //     value={rowData.flightDescription}
      //     onChange={(e) => rowData.onChange(e.target.value)}
      //     multiline={true}
      //     maxRows={5}
      //     style={{
      //       width:"100%"
      //     }}
      //   />
      // ),
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.description}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
          style={{
            width: "100%",
          }}
        />
      ),
    },
  ]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = React.createRef();

  const pageSize = props?.options?.pageSize || 10;
  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / pageSize);
  const [pageInputError, setPageInputError] = useState(null);

  const wrappedDataQuery = (query) =>
    framesDataImagesQuery(query).then((result) => {
      console.log("result:::", result);
      setTotalCount(result.totalCount); // Save totalCount from backend
      return result;
    });

  const handlePageJump = (e) => {
    e.preventDefault();
    const pageValue = parseInt(e.target.elements.page.value, 10);

    if (isNaN(pageValue) || pageValue < 1 || pageValue > totalPages) {
      setPageInputError(
        `Please enter a page number between 1 and ${totalPages}`
      );
      return;
    }

    setPageInputError(null); // clear errors
    const pageNumber = pageValue - 1;

    if (tableRef.current?.onPageChange) {
      tableRef.current.onPageChange(null, pageNumber);
    }
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.frameDataImageId === oldData.frameDataImageId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);
        await updateFrameDataImage(oldData.frameDataImageId, {
          report_sequence: newData?.report_sequence,
          // name: newData?.name,
          description: newData?.description,
        });
        resolve();
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });

  const [newTableData, setNewTableData] = useState([]);

  return (
    <>
      <MaterialTable
        data={wrappedDataQuery}
        tableRef={tableRef}
        columns={columns}
        loading={props?.loading}
        onRowUpdate={props?.editable ? onUpdate : null}
        editable={{
          onRowUpdate: props?.editable
            ? (newData, oldData) =>
                onUpdate(newData, oldData, newTableData, setNewTableData)
            : null,
        }}
        options={{
          ...props?.options,
          headerStyle: {
            position: "sticky",
            top: 0,
            zIndex: "1",
            backgroundColor: "white",
          },
          emptyRowsWhenPaging: false,
        }}
        title={props?.title}
        icons={tableIcons}
        actions={[
          {
            icon: InfoIcon,
            tooltip: "Get Hits",
            onClick: (event, rowData) => {
              rowData?.number_of_hits > 0
                ? openHitsViewModal(rowData)
                : toast.warning("No hits on this image");
            },
          },
          {
            icon: VisibilityIcon,
            tooltip: "View Image",
            onClick: (event, rowData) => {
              viewFrameDataImage(rowData);
            },
          },
          {
            icon: CircleNotificationsIcon,
            tooltip: "Send notification",
            onClick: (event, rowData) => {
              sendNotificationFrameDataImage(rowData);
            },
          },
        ]}
      />

      {totalPages > 5 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "8px",
            padding: "0.5rem 1rem",
          }}
        >
          <form
            onSubmit={handlePageJump}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <label htmlFor="page-input" style={{ fontSize: "0.9rem" }}>
              Go to Page:
            </label>
            <input
              id="page-input"
              name="page"
              type="number"
              min="1"
              max={totalPages}
              style={{
                width: "70px",
                padding: "6px 10px",
                fontSize: "0.9rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
            <button
              type="submit"
              style={{
                backgroundColor: "#1976d2",
                color: "#fff",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              Go
            </button>
            {pageInputError && (
              <div style={{ color: "red", fontSize: "0.85rem", width: "100%" }}>
                {pageInputError}
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
}
