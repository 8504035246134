import React from 'react';
import cn from 'classnames';
import './SquareButton.scss';

export const SquareButton = ({ children, className, ...props }) => {
	return (
		<button className={cn('square-button', className)} {...props}>
			{children}
		</button>
	);
};
