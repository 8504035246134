import React from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";

import Dropzone from "react-dropzone";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import FlightTable from "../Flights/FlightTable";
import useFileUpload2 from "../Hooks/PresignedS3_2";
import { API_URL } from "../API";
import { MainLayout } from "../Layouts/MainLayout";

const testFlights = [
  {
    flightId: 1,
    sarCaseId: 1,
    flightName: "Lost Boys",
    pilot: "Bill Dohse",
    filepath: "867-5309",
    flightDate: new Date(),
  },
  {
    flightId: 2,
    sarCaseId: 1,
    flightName: "Lost Boys",
    pilot: "Bill Dohse",
    filepath: "867-5309",
    flightDate: new Date(),
  },
];

export default function FlightSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { setFlights } = useContext(FlightContext);
  const { case_id } = useParams();

  // const [files, setFiles, uploadFiles] = useFileUpload2([]);
  // const [fileContext, setFileContext] = React.useState({
  //   action: "get-s3-presigned-url",
  //   action_purpose: "flight",
  //   presigned_ttl: 600,
  //   gid: sarCase.sarCaseOrgId,
  //   wid: userCtx.workerId,
  //   cid: case_id,
  //   img_purpose: 0, // 0 - source, 1 - evidence, 2 - source subimages (not used here)
  //   // 3 - flight video, 4 - flight footage
  //   for_report: 0,
  //   filename: "",
  //   content_type: "",
  // });

  // const handleDrop = (acceptedFiles) => {
  //   setFiles(acceptedFiles);
  // };

  // const handleFileContextChange = (key, value) => {
  //   console.log(key, value);
  //   setFileContext({
  //     ...fileContext,
  //     [key]: value,
  //   });
  // };

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId) {
        getCaseByID(case_id);
      } 
    } else {
      setFlights(testImages);
    }
  }, [sarCase.sarCaseId]);

  return (
    <MainLayout title="Flights">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={`/Cases/${case_id}/Flights/Create`}
          sx={{ visibility: "hidden", marginRight: "auto" }}
        >
          This is a hidden button
        </Button>

        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={`/Cases/${case_id}/Flights/Create`}
          sx={{ marginLeft: "auto" }}
        >
          Create Flight
        </Button>
      </Box>
      <FlightTable analyse={true}></FlightTable>
    </MainLayout>
  );
}
