import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { Helmet } from "react-helmet";

export const MainLayout = ({ children, title}) => {
  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "82vh !Imporant",
          margin: "10vh",
        }}
      >
        <CssBaseline />
        {children}
      </Box>
      ;
    </>
  );
};
