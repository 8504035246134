import React, { createContext, useState, useContext } from "react";
import { AppContext } from "./appContext";

import api, { API_URL } from "../API";

const testCriteria = [
  {
    criteriaId: 1,
    criteriaName: "criteria 1",
    numConfigs: 2,
    numCriteria: 4,
    numFrames: 6,
    numProcessed: 8,
    numFlagged: 10,
    numArchived: 12,
    criteriaDate: new Date(),
  },
  {
    criteriaId: 2,
    criteriaName: "criteria 2",
    numConfigs: 1,
    numCriteria: 2,
    numFrames: 3,
    numProcessed: 4,
    numFlagged: 5,
    numArchived: 6,
    criteriaDate: new Date(),
  },
];

// Create the initial context state
const initialContextState = {
  criteriaId: undefined,
};

// Create the context
export const CriteriaContext = createContext(initialContextState);

// Create a context provider component
export const CriteriaProvider = ({ children }) => {
  const { authHeaders } = useContext(AppContext);
  const [criteriaCtx, setCriteriaCtx] = useState(initialContextState);
  const [criteria, setCriteria] = React.useState([]);

  // Function to update the caseID in the store
  const updateCriteriaCtx = async (ctx) => {
    setCriteriaCtx(ctx);
  };

  const getCriteriaByCase = async (case_id) => {
    const requestBody = JSON.stringify({
      action: "get-criteria-by-case",
      cid: case_id,
      ip: "0.0.0.0",
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      setCriteria(response.data);
    }
  };

  const getCriteriaById = async () => {
    const requestBody = JSON.stringify({
      action: "get-criteria-by-id",
      critId: criteriaCtx.criteriaId,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      setCriteriaCtx(response.data[0]);
    }
  };

  // Create the context value
  const contextValue = {
    criteria,
    criteriaCtx,
    getCriteriaByCase,
    getCriteriaById,
    setCriteria,
    setCriteriaCtx,
    updateCriteriaCtx,
  };

  return (
    <CriteriaContext.Provider value={contextValue}>
      {children}
    </CriteriaContext.Provider>
  );
};
