import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Modal,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AnalysisContext } from "../Context/analysisContext";
import { UiContext } from "../Context/uiContext";
import FAQs from "../Static/FAQs";
import FAQ from "../Components/FAQ";
import { toast } from "react-toastify";
import { CaseContext } from "../Context/caseContext";

export default function AnalysisPreview({ handleCloseFramesSlider }) {
  const {
    frames: frameData,
    runAnalysisPreview,
    runAnalysis,
  } = useContext(AnalysisContext);
  const { sarCase } = useContext(CaseContext);
  const { analysisPreviewOpen, handleAnalysisPreviewClose } =
    useContext(UiContext);

  const { case_id } = useParams();
  const navigate = useNavigate();

  const [timer, setTimer] = useState(60);
  const [executeLoading, setExecuteLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:900px)"); // Detect mobile devices

  useEffect(() => {
    if (analysisPreviewOpen) {
      const countdown = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [analysisPreviewOpen]);

  useEffect(() => {
    if (timer === 0) {
      handleConfirm();
    }
  }, [timer]);

  const handleConfirm = async () => {
    try {
      console.log("Confirmed automatically after 60 seconds");
      setExecuteLoading(true);
      await runAnalysis(
        sarCase.sarCaseOrgId,
        case_id,
        -1,
        [runAnalysisPreview[0]?.analysisId],
        false
      );
      setExecuteLoading(false);
      toast.info(
        `Execution Request Sent for Analysis: ${runAnalysisPreview[0]?.analysisId}`
      );
      navigate(
        `/cases/${case_id}/analysis/${runAnalysisPreview[0]?.analysisId}`
      );
      handleClose();
    } catch (error) {
      toast.error(error.message);
      setExecuteLoading(false);
    }
  };

  const handleCancel = () => {
    setTimer(60); // Reset timer
    handleClose();
  };

  const handleClose = async () => {
    handleAnalysisPreviewClose();
  };

  // Filter framesTasks based on invalid, nomatch, or tasked > 0
  const filteredFramesTasks = runAnalysisPreview[0]?.framesTasks
    .filter((task) => task.invalid > 0 || task.nomatch > 0 || task.tasked > 0)
    .map((task) => ({
      ...task,
      // Filter configs based on nomatch or tasked > 0
      configs: task.configs.filter(
        (config) => config.nomatch > 0 || config.tasked > 0
      ),
    }));

  if (analysisPreviewOpen) {
    return (
      <>
        <Modal
          open={analysisPreviewOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "95%" : "90%",
              height: isMobile ? "95%" : "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Confirmation and Cancel Buttons */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                // onClick={handleConfirm}
                disabled={executeLoading}
                onClick={() => {
                  if (executeLoading) {
                    toast.warn("Execution Request in progress");
                  } else {
                    handleConfirm();
                  }
                }}
                sx={{ mr: 2 }}
              >
                Confirm ({timer}s)
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                disabled={executeLoading}
              >
                Cancel
              </Button>
            </Box>

            {/* Table to display filtered framesTasks and configs */}
            <Box sx={{ flex: 1, overflow: "auto", mt: 2 }}>
              <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Frame Type</TableCell>
                      <TableCell>Invalid</TableCell>
                      <TableCell>No Match</TableCell>
                      <TableCell>Tasked</TableCell>
                      <TableCell>Configs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFramesTasks?.map((task, index) => (
                      <TableRow key={index}>
                        <TableCell>{task.frameType}</TableCell>
                        <TableCell>{task.invalid}</TableCell>
                        <TableCell>{task.nomatch}</TableCell>
                        <TableCell>{task.tasked}</TableCell>
                        <TableCell>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Config Type</TableCell>
                                <TableCell>No Match</TableCell>
                                <TableCell>Tasked</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {task.configs.map((config, configIndex) => (
                                <TableRow key={configIndex}>
                                  <TableCell>{config.cfgType}</TableCell>
                                  <TableCell>{config.nomatch}</TableCell>
                                  <TableCell>{config.tasked}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* Collapsible FAQs Section */}
            <Box sx={{ mt: 2 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="faq-content"
                  id="faq-header"
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Frequently Asked Questions
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FAQ faqs={FAQs.analysis_results_preview_FAQ} />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Modal>
      </>
    );
  } else {
    return null;
  }
}
