import React, { useEffect, useRef, useState } from "react";
import chroma from "chroma-js";

const ColorScale = ({ checkedElements }) => {
  const colorScaleRef = useRef(null);
  const prevReduceArray = useRef(null);

  function getMinMaxRGB(inputColors) {
    let minValues = [255, 255, 255];
    let maxValues = [0, 0, 0];

    for (let i = 0; i < inputColors.length; i++) {
      const color = inputColors[i].match(/\d+/g).map(Number);

      for (let j = 0; j < color.length; j++) {
        minValues[j] = Math.min(minValues[j], color[j]);
        maxValues[j] = Math.max(maxValues[j], color[j]);
      }
    }

    const minRGB = "rgb(" + minValues.join(", ") + ")";
    const maxRGB = "rgb(" + maxValues.join(", ") + ")";

    return [minRGB, maxRGB];
  }

  useEffect(() => {
    const finalScale = checkedElements.map((item) => item.rgb);

    const reduceArray = getMinMaxRGB(finalScale);

    // Only update if the reduceArray has changed
    if (
      JSON.stringify(reduceArray) !== JSON.stringify(prevReduceArray.current)
    ) {
      prevReduceArray.current = reduceArray;
    }

    // Get a Chroma.js scale
    const scale = chroma.scale(reduceArray).mode("rgb");

    // Set the number of color samples
    const numSamples = 200;

    // Generate color scale samples
    const colors = scale.colors(numSamples);

    // Clear previous content
    colorScaleRef.current.innerHTML = "";

    // Render color samples
    colors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      colorSample.style.transition = "background-color 0.3s ease-in-out";
      colorScaleRef.current.appendChild(colorSample);
    });
  }, [checkedElements]);

  return (
    <div>
      <div
        ref={colorScaleRef}
        style={{
          display: "flex",
          height: "20px",
        }}
      ></div>
    </div>
  );
};

export default ColorScale;
