import React, { useState, useEffect, useRef } from "react";

//MUI components
import { Box, Paper, Stack, Typography, TextField } from "@mui/material";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

//NPM imports
import ColorPicker from "react-best-gradient-color-picker";
import { toast } from "react-toastify";

export default function CustomColorModal({
  mapIndex,
  isEditingTemp,
  onClose,
  onChangeTemp,
  currIndexT1,
  currIndexT2,
}) {
  const t1Input = useRef();
  const t2Input = useRef();

  // State for validation errors and helper text
  const [errors, setErrors] = useState({ t1: false, t2: false });
  const [helperTexts, setHelperTexts] = useState({ t1: "", t2: "" });

  useEffect(() => {
    console.log("currIndexT1", currIndexT1);
    console.log("currenIndexT2", currIndexT2);
    //  = currIndexT1;
    if (t2Input?.current) {
      t2Input.current.value = currIndexT2;
    }
    if (t1Input?.current) {
      t1Input.current.value = currIndexT1;
    }

    // setColor(currIndexRgb);
  }, [isEditingTemp]);

  //   useEffect(() => {
  //     console.log("currIndexT1", currIndexT1);
  //     console.log("currenIndexT2", currIndexT2);
  //     //  = currIndexT1;
  //     if(t2Input?.current){
  //         t2Input.current.value = currIndexT2;
  //     }
  //     if(t1Input?.current){
  //         t1Input.current.value = currIndexT1;
  //     }

  //     // setColor(currIndexRgb);
  //   }, [currIndexT1, currIndexT2]);

  const validateTemperature = (value) => /^-?[0-9]*(\.[0-9]+)?$/.test(value);

  const setParentTempAndClose = () => {
    let t1 = t1Input.current.value.trim();
    let t2 = t2Input.current.value.trim();

    let newErrors = { t1: false, t2: false };
    let newHelperTexts = { t1: "", t2: "" };

    // Validate T1
    if (!validateTemperature(t1)) {
      newErrors.t1 = true;
      newHelperTexts.t1 = "Invalid format. Use numbers like 25.00 or -3.14";
    } else {
      t1 = parseFloat(t1).toFixed(2); // Convert to 2 decimal places
    }

    // Validate T2 (only if not empty)
    if (t2 !== "" && !validateTemperature(t2)) {
      newErrors.t2 = true;
      newHelperTexts.t2 = "Invalid format. Use numbers like 25.00 or -3.14";
    } else if (t2 !== "") {
      t2 = parseFloat(t2).toFixed(2);
    }

    // Ensure T1 ≤ T2 if T2 exists
    if (t2 !== "" && parseFloat(t1) > parseFloat(t2)) {
      newErrors.t1 = true;
      newErrors.t2 = true;
      newHelperTexts.t1 = "Lower bound (T1) must be ≤ Upper bound (T2)";
      newHelperTexts.t2 = "Upper bound (T2) must be ≥ Lower bound (T1)";
      toast.error("T1 must be less than or equal to T2.");
    }

    // If there are validation errors, update state and stop execution
    if (newErrors.t1 || newErrors.t2) {
      setErrors(newErrors);
      setHelperTexts(newHelperTexts);
      return;
    }

    // If valid, update temperature and close modal
    onChangeTemp(mapIndex, t1, t2 !== "" ? t2 : null);
    onClose();
  };

  if (isEditingTemp) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // maxWidth: "90vw",
            // maxHeight: "90vh",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.75rem",
            zIndex: 9999,
            display: "flex",
          }}
        >
          <Box>
            <Paper
              elevation={16}
              sx={{ padding: 5, bgcolor: "rgb(255,255,255, 0.7)" }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="h2">Temperatures</Typography>
                <TextField
                  id="t1"
                  name="Temperature °C"
                  label="Temperature °C"
                  // value={currIndexT1}
                  inputRef={t1Input}
                  error={errors.t1}
                  helperText={helperTexts.t1}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "-?[0-9]*(.[0-9]+)?",
                    title: "Enter a valid temperature (e.g., 25.00 or -3.14)",
                  }}
                ></TextField>
                {/* <Typography variant="h5">
                  {}
                </Typography> */}
                <TextField
                  id="t2"
                  name="Temperature °C"
                  label="Temperature °C"
                  placeholder=""
                  // value={currIndexT2}
                  inputRef={t2Input}
                  error={errors.t2}
                  helperText={helperTexts.t2}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "-?[0-9]*(.[0-9]+)?",
                    title: "Enter a valid temperature (e.g., 25.00 or -3.14)",
                  }}
                ></TextField>
                {/* <Typography variant="h5">
                  {(t2Input.current ? t2Input.current.value : 0 * 9) / 5 + 32}
                </Typography> */}
              </Stack>
              <Box
                sx={{
                  marginLeft: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CloseIcon
                  color={"info"}
                  fontSize={"large"}
                  sx={{ marginTop: 5 }}
                  onClick={() => onClose()}
                />
                <CheckIcon
                  color={"info"}
                  fontSize={"large"}
                  sx={{ marginLeft: 3, marginTop: 5 }}
                  onClick={() => setParentTempAndClose()}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
