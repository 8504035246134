import * as React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Modal,
  Tooltip,
  Button,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Switch,
} from "@mui/material";
import useFileUpload2 from "../Hooks/PresignedS3_2";
import LoadingButton from "@mui/lab/LoadingButton";
import { ImageContext } from "../Context/imageContext";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { toast } from "react-toastify";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";
import { VideoContext } from "../Context/videoContext";
import { FootageContext } from "../Context/footageContext";
import Table from "../table/table";
import { MainLayout } from "../Layouts/MainLayout";
import { Stack } from "@mui/system";
import { AppContext } from "../Context/appContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default function Upload() {
  const { setVideos } = useContext(VideoContext);
  const { updateFileContext, fileContext } = useContext(ImageContext);
  const radiometricCoverters = useRadiometericConverters();
  const { sarCase, addAwsGetAccess, copyAwsFiles } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { user } = useContext(AppContext);
  const [
    files,
    setFiles,
    uploadBulkFiles,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    uploadWithRetry,
    getPresignedURL
  ] = useFileUpload2([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bucketAccess, setBucketAccess] = useState(false);

  const [responsiveDialogConfirm, setResponsiveDialogConfirm] =
    React.useState(false);
  const [openResponsiveDialog, setOpenResponsiveDialog] = React.useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const handleClickConfirm = () => {
    setResponsiveDialogConfirm(true);
    setOpenResponsiveDialog(false);
  };

  const handleCancel = () => {
    setResponsiveDialogConfirm(false);
    setOpenResponsiveDialog(false);
  };

  const handleClose = () => {
    setOpenResponsiveDialog(false);
  };

  const ResponsiveDialog = () => (
    <React.Fragment>
      <Dialog
        open={openResponsiveDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want the copy process to be started?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleClickConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (responsiveDialogConfirm) {
      copyAwsFiles({
        gid: userCtx.workerOrgId,
        cid: case_id,
        fid: flight_id,
        wid: user.cognitoId,
        accountId: accountID.current.value,
        bucket: bucketName.current.value,
        prefix: bucketPrefix.current.value,
      });
      toast.info("Copy request sent");
    }
  }, [responsiveDialogConfirm]);

  const accountID = React.useRef();
  const bucketName = React.useRef();
  const bucketPrefix = React.useRef();

  const columns = [
    {
      title: "File Name",
      field: "path",
    },
    {
      title: "State",
      field: "uploadStatus",
      render: (rowData) => uploadStatus[rowData.path] || "Not Uploaded",
    },
    // {
    //   title: "Actions",
    //   field: "actions",
    //   render: (rowData) =>
    //     uploadStatus[rowData.path] === "Upload Failed: Failed to fetch" && (
    //       <Button
    //         variant="contained"
    //         color="secondary"
    //         onClick={() => handleRetry(rowData)}
    //       >
    //         Retry
    //       </Button>
    //     ),
    // },
  ];

  // const handleRetry = async (file) => {
  //   try {
  //     // Update the upload status to "Retrying..."
  //     setUploadStatus((prevStatus) => ({
  //       ...prevStatus,
  //       [file.path]: "Retrying...",
  //     }));

  //     // Get a new presigned URL for the file
  //     const uploadContext = {
  //       ...fileContext,
  //       filename: file.name,
  //       content_type: file.type,
  //     };
  //     const uploadUrl = await getPresignedURL(uploadContext);

  //     // Retry the upload
  //     const result = await uploadWithRetry(file, uploadUrl);

  //     // Update the upload status based on the result
  //     setUploadStatus((prevStatus) => ({
  //       ...prevStatus,
  //       [file.path]: result.status,
  //     }));

  //     if (result.status === "Upload Successful") {
  //       toast.success(`File ${file.name} uploaded successfully!`);
  //     } else {
  //       toast.error(`File ${file.name} failed to upload.`);
  //     }
  //   } catch (error) {
  //     setUploadStatus((prevStatus) => ({
  //       ...prevStatus,
  //       [file.path]: "Upload Failed: Failed to fetch",
  //     }));
  //     toast.error(`Retry failed for file ${file.name}: ${error.message}`);
  //   }
  // };

  const { case_id, flight_id, type } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageID = queryParams.get("imageID") || "";
  const frameID = queryParams.get("frameID") || "";
  const imagePurpose = queryParams.get("imagePurpose") || "";
  const imageViewType = queryParams.get("imageViewType") || "";
  const footageType = queryParams.get("footageType") || "";
  const converterType = queryParams.get("converterType") || "";
  const imageName = queryParams.get("imageName") || "";

  const returnAllowedTypes = (type) => {
    let allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (type === "images") {
      allowedTypes.push("image/tiff");
      allowedTypes.push("image/tif");
    }

    if (type === "assets") {
      allowedTypes.push("application/pdf");
    }

    if (type === "video") {
      allowedTypes = ["video/mp4"];
    }

    if (type === "footage") {
      allowedTypes.push("image/tiff");
      allowedTypes.push("image/tif");
    }

    return allowedTypes;
  };

  const handleDrop = (acceptedFiles) => {
    const allowedTypes = returnAllowedTypes(type);
    const validFiles = acceptedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validFiles.length !== acceptedFiles.length) {
      toast.warning(`Only ${allowedTypes.join(", ")} files are allowed`);
    }

    setFiles([...validFiles, ...files]);
  };

  const handleFileContextChange = (key, value) => {
    updateFileContext({
      ...fileContext,
      [key]: value,
    });
  };

  const onConfirm = async () => {
    if (files.length === 0) {
      toast.warning("Upload at least one file");
      return;
    }
    if (imageID && files.length > 1) {
      toast.warning("Only 1 file can be uploaded");
      return;
    }
    if (frameID && files.length > 1) {
      toast.warning("Only 1 file can be uploaded");
      return;
    }
    if (files) {
      if (type === "images" && fileContext.img_purpose === -1) {
        toast.warning("Select image purpose");
        return;
      }
      if (type === "images" && fileContext.img_view_type === -1) {
        toast.warning("Select image type");
        return;
      }

      if (
        (type === "videos" || type === "footage") &&
        fileContext.footage_type === -1
      ) {
        toast.warning("Select footage type");
        return;
      }
      try {
        setIsLoading(true);
        setProgress(0); // Reset progress before starting upload
        let response = await uploadBulkFiles(fileContext);
        console.log("response", response);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  const onSubmit = React.useCallback(
    () => async (event) => {
      event.preventDefault();
      if (accountID.current.value === "") {
        toast.warn("Account ID can not be empty");
        return;
      }
      if (bucketName.current.value === "") {
        toast.warn("Bucket name can not be empty");
        return;
      }
      if (bucketPrefix.current.value === "") {
        toast.warn("Bucket Prefix can not be empty");
        return;
      }
      setLoading(true);

      const data = {
        action: "add-aws-get-access",
        accountId: accountID.current.value,
        bucket: bucketName.current.value,
        prefix: bucketPrefix.current.value,
      };
      try {
        let response = await addAwsGetAccess(data);
        if (response[0] === true) {
          toast.info("Information successfully stored");
          setOpenResponsiveDialog(true);
        } else {
          toast.error(response[0]);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    },
    [userCtx, sarCase]
  );

  React.useEffect(() => {
    setState();
  }, [type, userCtx]);

  const setState = async () => {
    if (userCtx.workerOrgId && userCtx.workerId) {
      setFileState();
    }
  };

  const setFileState = () => {
    if (type === "images") {
      let fileContext = {
        action: "get-s3-presigned-url-list",
        action_purpose: type,
        presigned_ttl: 3600,
        isImageSelect: 1,
        gid: userCtx.workerOrgId,
        wid: user.cognitoId,
        cid: case_id,
        img_purpose: -1,
        img_view_type: -1,
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      };
      if (imageID) {
        setIsOptionSelected(true);
        fileContext = {
          ...fileContext,
          iid: imageID,
          img_purpose: Number(imagePurpose),
          img_view_type: Number(imageViewType),
        };
      }
      updateFileContext({
        ...fileContext,
      });
    }

    if (type === "assets") {
      let fileContext = {
        action: "get-s3-presigned-url-list",
        action_purpose: "images",
        presigned_ttl: 3600,
        isImageSelect: 1,
        gid: userCtx.workerOrgId,
        wid: user.cognitoId,
        cid: case_id,
        img_purpose: 5,
        img_view_type: -1,
        for_report: 1,
        filename: "",
        content_type: "",
        filenameList: [],
      };
      setIsOptionSelected(true);
      updateFileContext({
        ...fileContext,
      });
    }

    if (type === "video") {
      updateFileContext({
        action: "get-s3-presigned-url-list",
        action_purpose: type,
        presigned_ttl: 3600,
        isVideoSelect: 1,
        gid: sarCase.sarCaseOrgId,
        wid: user.cognitoId,
        cid: case_id,
        fid: flight_id,
        img_purpose: 3,
        footage_type: -1,
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      });
    }

    if (type === "footage") {
      let fileContext = {
        action: "get-s3-presigned-url-list",
        action_purpose: type,
        presigned_ttl: 3600,
        isFootageSelect: 1,
        gid: sarCase.sarCaseOrgId,
        wid: user.cognitoId,
        cid: case_id,
        fid: flight_id,
        img_purpose: 4,
        footage_type: -1,
        for_report: 0,
        filename: "",
        content_type: "",
        filenameList: [],
      };
      if (frameID) {
        setIsOptionSelected(true);
        fileContext = {
          ...fileContext,
          frid: frameID,
          footage_type: Number(footageType),
          radiometric_converter: Number(converterType),
        };
      }
      updateFileContext({
        ...fileContext,
      });
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numericInput = inputValue.replace(/\D/g, "").slice(0, 12);
    accountID.current.value = numericInput;
  };

  return (
    <>
      <MainLayout title={`Upload`}>
        <ResponsiveDialog />
        {flight_id ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControlLabel
              disabled={loading}
              control={
                <Switch
                  checked={bucketAccess}
                  onChange={(e) => setBucketAccess(e.target.checked)}
                />
              }
              label="Bucket Access"
            />
          </Box>
        ) : (
          <></>
        )}

        {bucketAccess ? (
          <form
            id="enroll"
            onSubmit={onSubmit()}
            style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
          >
            <Paper elevation={16} sx={{ padding: 5 }}>
              <Stack direction="column" spacing={2}>
                <Typography variant="h2">S3 Bucket Details</Typography>
                <Typography variant="p">
                  * Please follow these steps before submitting these details{" "}
                  <a
                    target="_blank"
                    href="https://docs.cloud303.io/s/d2e44aa0-a8a0-4fd4-b14d-d3bd32e90ab9"
                  >
                    Document Link
                  </a>
                </Typography>
                <TextField
                  label="Account ID"
                  onChange={handleInputChange}
                  placeholder="Account ID"
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  inputRef={accountID}
                  id="accountId"
                ></TextField>

                <TextField
                  id="bucketName"
                  name="bucketName"
                  label="Bucket Name"
                  placeholder="Bucket Name"
                  inputRef={bucketName}
                ></TextField>

                <TextField
                  id="bucketPrefix"
                  name="bucketPrefix"
                  label="Bucket Prefix"
                  placeholder="Bucket Prefix"
                  inputRef={bucketPrefix}
                ></TextField>

                <Button disabled={loading} variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Paper>
          </form>
        ) : (
          <>
            {!isObjEmpty(uploadStatus) && (
              <div>
                {!imageID && !frameID && (
                  <Button
                    sx={{
                      marginBottom: "2vh",
                    }}
                    onClick={() => {
                      setUploadStatus({});
                      setFiles([]);
                    }}
                    disabled={isLoading}
                  >
                    Upload more files
                  </Button>
                )}
                <Box sx={{ marginTop: 2 }}>
                  <LinearProgressWithLabel value={progress} />
                </Box>
                <Table
                  data={files}
                  columns={columns}
                  options={{
                    toolbar: false,
                    sorting: false,
                    draggable: false,
                    grouping: false,
                    search: true,
                    paging: false,
                    selection: false,
                    maxBodyHeight: "700px",
                    actionsCellStyle: { zIndex: "0", position: "relative" },
                    headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                  }}
                />
  
              </div>
            )}

            {isObjEmpty(uploadStatus) && (
              <Box margin={5} sx={style}>
                {type === "images" && (
                  <Box>
                    <Typography
                      align="center"
                      variant="h5"
                      sx={{ display: "flex", paddingBottom: 1 }}
                    >
                      {!imageName
                        ? "Purpose and Kind of Files:"
                        : `Re-upload file ${imageName}`}
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        row
                        name="img_purpose"
                        value={fileContext["img_purpose"]}
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true);
                        }}
                      >
                        <FormLabel
                          style={{
                            textAlign: "left",
                            paddingRight: "10",
                          }}
                        >
                          required <text style={{ color: "red" }}>*</text>
                        </FormLabel>
                        <FormControlLabel
                          value="0"
                          disabled={
                            imageID && imagePurpose !== "0" ? true : false
                          }
                          control={<Radio />}
                          label={"Source Images"}
                          defaultChecked
                        ></FormControlLabel>
                        <FormControlLabel
                          value="1"
                          disabled={
                            imageID && imagePurpose !== "1" ? true : false
                          }
                          control={<Radio />}
                          label={"Evidence Images"}
                        ></FormControlLabel>
                      </RadioGroup>

                      <FormControl>
                        <RadioGroup
                          row
                          name="img_view_type"
                          value={fileContext["img_view_type"]}
                          onChange={(event) => {
                            handleFileContextChange(
                              event.target.name,
                              event.target.value
                            );
                            setIsOptionSelected(true);
                          }}
                        >
                          <FormLabel
                            style={{
                              textAlign: "left",
                              paddingRight: "10",
                            }}
                          >
                            required <text style={{ color: "red" }}>*</text>
                          </FormLabel>
                          <FormControlLabel
                            value="0"
                            disabled={
                              imageID && imageViewType !== "0" ? true : false
                            }
                            control={<Radio />}
                            label={"RGB Color"}
                          ></FormControlLabel>
                          <FormControlLabel
                            value="1"
                            disabled={
                              imageID && imageViewType !== "1" ? true : false
                            }
                            control={<Radio />}
                            label={"Thermal Grayscale"}
                          ></FormControlLabel>
                          <FormControlLabel
                            value="2"
                            disabled={
                              imageID && imageViewType !== "2" ? true : false
                            }
                            control={<Radio />}
                            label={"Radiometric"}
                          ></FormControlLabel>
                        </RadioGroup>
                      </FormControl>

                      {fileContext.img_view_type == 2 && (
                        <FormControl>
                          <FormLabel
                            style={{
                              textAlign: "left",
                            }}
                          >
                            Converters
                          </FormLabel>
                          <RadioGroup
                            name="radiometric_converter"
                            onChange={(event) => {
                              handleFileContextChange(
                                event.target.name,
                                event.target.value
                              );
                              setIsOptionSelected(true);
                            }}
                          >
                            {radiometricCoverters?.map((el) => {
                              return (
                                <FormControlLabel
                                  control={<Radio />}
                                  value={el.objId}
                                  label={el.dispName}
                                  key={el.objId}
                                  disabled={
                                    imageID && imageViewType ? true : false
                                  }
                                ></FormControlLabel>
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      )}
                    </FormControl>
                  </Box>
                )}

                {type === "video" && (
                  <Box>
                    <Typography
                      align="center"
                      variant="h5"
                      sx={{ display: "flex", paddingBottom: 1 }}
                    >
                      Kind of Video Files:
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        row
                        name="footage_type"
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true);
                        }}
                      >
                        <FormLabel
                          style={{
                            textAlign: "left",
                            paddingRight: "10",
                          }}
                        >
                          required <text style={{ color: "red" }}>*</text>
                        </FormLabel>
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label={"RGB Color"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={"Thermal Grayscale"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={"Radiometric"}
                        ></FormControlLabel>
                      </RadioGroup>
                    </FormControl>

                    {fileContext.footage_type == 2 && (
                      <FormControl>
                        <FormLabel
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Converters
                        </FormLabel>
                        <RadioGroup
                          name="radiometric_converter"
                          onChange={(event) => {
                            handleFileContextChange(
                              event.target.name,
                              event.target.value
                            );
                            setIsOptionSelected(true);
                          }}
                        >
                          {radiometricCoverters?.map((el) => {
                            return (
                              <FormControlLabel
                                control={<Radio />}
                                value={el.objId}
                                label={el.dispName}
                                key={el.objId}
                              ></FormControlLabel>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                )}

                {type === "footage" && (
                  <Box>
                    <Typography
                      align="center"
                      variant="h5"
                      sx={{ display: "flex", paddingBottom: 1 }}
                    >
                      {!imageName
                        ? "Kind of Image/Footage Files:"
                        : `Re-upload file ${imageName}`}
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        row
                        name="footage_type"
                        value={
                          frameID && footageType
                            ? footageType
                            : fileContext["footage_type"]
                        }
                        onChange={(event) => {
                          handleFileContextChange(
                            event.target.name,
                            event.target.value
                          );
                          setIsOptionSelected(true);
                        }}
                      >
                        <FormLabel
                          style={{
                            textAlign: "left",
                            paddingRight: "10",
                          }}
                        >
                          required <text style={{ color: "red" }}>*</text>
                        </FormLabel>
                        <FormControlLabel
                          value="0"
                          disabled={
                            frameID && footageType !== "0" ? true : false
                          }
                          control={<Radio />}
                          label={"RGB Color"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="1"
                          disabled={
                            frameID && footageType !== "1" ? true : false
                          }
                          control={<Radio />}
                          label={"Thermal Grayscale"}
                        ></FormControlLabel>
                        <FormControlLabel
                          value="2"
                          disabled={
                            frameID && footageType !== "2" ? true : false
                          }
                          control={<Radio />}
                          label={"Radiometric"}
                        ></FormControlLabel>
                      </RadioGroup>
                    </FormControl>

                    {fileContext.footage_type == 2 && (
                      <FormControl>
                        <FormLabel
                          style={{
                            textAlign: "left",
                          }}
                        >
                          Converters required{" "}
                          <text style={{ color: "red" }}>*</text>
                        </FormLabel>
                        <RadioGroup
                          name="radiometric_converter"
                          value={
                            frameID && converterType
                              ? converterType
                              : fileContext["radiometric_converter"]
                          }
                          onChange={(event) => {
                            handleFileContextChange(
                              event.target.name,
                              event.target.value
                            );
                            setIsOptionSelected(true);
                          }}
                        >
                          {radiometricCoverters?.map((el) => {
                            return (
                              <FormControlLabel
                                control={<Radio />}
                                value={el.objId}
                                label={el.dispName}
                                key={el.objId}
                                disabled={
                                  frameID && converterType !== el.objId
                                    ? true
                                    : false
                                }
                              ></FormControlLabel>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Box>
                )}

                <Box
                  sx={{
                    maxHeight: "100px",
                    overflow: "scroll",
                  }}
                >
                  <Typography>{files.length} Files To Upload:</Typography>
                  {files.map((file, i) => (
                    <Typography key={i}>
                      {i + 1}. {file.path}
                    </Typography>
                  ))}
                </Box>
                <Dropzone
                  multiple={imageID || frameID ? false : true}
                  onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}
                  // accept={returnAllowedTypes(type)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      height={200}
                      sx={{ border: "3px dashed grey" }}
                      {...getRootProps()}
                    >
                      <Typography>Drop Files Here</Typography>
                    </Box>
                  )}
                </Dropzone>
                <Tooltip title={"Closing will cause background upload to stop"}>
                  <LoadingButton
                    onClick={() => {
                      window.open("about:blank", "_self");
                      window.close();
                    }}
                    disabled={isLoading}
                    variant="outlined"
                    sx={{ marginRight: "2vh", marginTop: "2vh" }}
                  >
                    Close
                  </LoadingButton>
                </Tooltip>
                <LoadingButton
                  onClick={onConfirm}
                  variant="outlined"
                  loading={isLoading}
                  sx={{ marginTop: "2vh" }}
                  disabled={!isOptionSelected}
                >
                  Confirm
                </LoadingButton>
              </Box>
            )}
          </>
        )}
      </MainLayout>
    </>
  );
}
