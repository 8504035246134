import React, { createContext, useState, useContext } from "react";
import api, { API_URL } from "../API";
import { toast } from "react-toastify";

import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
// Create the initial context state
const initialContextState = {
  sarCaseId: undefined, // Current case, provides context for flights, footage, configs, etc.
  sarCaseName: "",
  sarCaseOrgId: undefined, // Useful in reference to a case
  leadName: "",
  leadPhone: "",
  leadEmail: "",
  beneficiaryName: "",
  beneficiaryPhone: "",
  beneficiaryEmail: "",
  sarCaseDescription: "",
  sarCaseDate: "",
  sarCaseFilename: "",
  filepath: "",
};

const testCases = [
  {
    sarCaseId: 1,
    sarCaseName: "Lost Boys",
    leadName: "Bill Dohse",
    leadPhone: "867-5309",
    leadEmail: "bill@find911.com",
    beneficiaryName: "Peter Pan",
    beneficiaryPhone: "555-1111",
    beneficiaryEmail: "peter@neverland.com",
    sarCaseDescription: "simple description",
    sarCaseDate: new Date(),
  },
  {
    sarCaseId: 2,
    sarCaseName: "Wild Boy",
    leadName: "Bill Dohse",
    leadPhone: "867-5309",
    leadEmail: "bill@find911.com",
    beneficiaryName: "Duran Duran",
    beneficiaryPhone: "555-2222",
    beneficiaryEmail: "duran@duran.com",
    sarCaseDescription: "still a minimal length description",
    sarCaseDate: new Date(),
  },
];

// Create the context
export const CaseContext = createContext(initialContextState);

// Create a context provider component
export const CaseProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [sarCase, setSarCase] = useState(initialContextState);
  const [cases, setCases] = React.useState([]);

  const getCaseByID = async (caseID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-cases-by-id",
          cid: caseID,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          if (!response.data[0]?.sarCaseId) {
            reject(new Error("Case not found"));
          }
          setSarCase(response.data[0]);
          resolve(response.data[0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getCasesByWorker = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-cases-by-worker",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ip: "0.0.0.0",
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setCases(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const createCase = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          sarCase.sarCaseId = response.data["id"];
          sarCase.sarCaseOrgId = userCtx.workerOrgId;
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateCase = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // get-tags-by-case
  const getTagsByCase = async (caseID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-tags-by-case",
          cid: caseID,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // add-aws-get-access
  const addAwsGetAccess = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          // flightCtx.flightId = response.data["id"];
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // copy-aws-files
  const copyAwsFiles = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "copy-aws-files",
          wid: user.cognitoId,
          ...data,
        });
        // const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    cases,
    getCasesByWorker,
    sarCase,
    getCaseByID,
    createCase,
    updateCase,
    setSarCase,
    getTagsByCase,
    addAwsGetAccess,
    copyAwsFiles,
  };

  return (
    <CaseContext.Provider value={contextValue}>{children}</CaseContext.Provider>
  );
};
