import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

import AssessmentIcon from "@mui/icons-material/Assessment";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import MovieIcon from "@mui/icons-material/Movie";
import RuleIcon from "@mui/icons-material/Rule";

import {
  Button,
  Collapse,
  Drawer,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { UiContext } from "../Context/uiContext";

export default function CustomDrawer({ isClosed, closeDrawer }) {
  const {
    createReportOpen,
    handleCreateReportModalClose,
    handleCreateReportModalOpen,
  } = React.useContext(UiContext);
  const [caseID, setCaseID] = React.useState("");
  const [flightID, setFightID] = React.useState("");
  const [menuOptions, setMenuOption] = React.useState("default");
  const [open, setOpen] = React.useState(true);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(!open);
  };

  const defaultOption = [
    {
      Name: "Messaging Opt-In",
      Icon: ListAltIcon,
      Route: "/messaging-opt-in",
      Nested: [],
    },
    { Name: "Cases", Icon: ListAltIcon, Route: "/cases", Nested: [] },
    caseID
      ? {
          Name: "1. Reference Images / Evidence",
          Description: "Photos for color samples or evidence",
          Icon: MovieIcon,
          Route: `/cases/${caseID}/images`,
        }
      : null,
    caseID
      ? {
          Name: "2. Search Configs",
          Description: "View / create search criteria",
          Icon: AssessmentIcon,
          Route: `/cases/${caseID}/search-configs`,
        }
      : null,

    caseID
      ? {
          Name: "3. Flight Images",
          Description: "Load images or START analysis",
          Icon: FlightTakeoffIcon,
          Route: `/cases/${caseID}/flights`,
        }
      : null,
    caseID
      ? {
          Name: "4. Analysis Results",
          Description: "Check status or review results",
          Icon: RuleIcon,
          Route: `/cases/${caseID}/analysis`,
        }
      : null,

    caseID
      ? {
          Name: "5. Report Assets",
          Description: "Extra images or pdfs for report",
          Icon: RuleIcon,
          Route: `/cases/${caseID}/assets`,
        }
      : null,

    caseID
      ? {
          Name: "6. Reports",
          Description: "View / create case reports",
          Icon: RuleIcon,
          Route: `/cases/${caseID}/reports`,
        }
      : null,
  ];
  const menuList = {
    default: [...defaultOption],
    cases: [...defaultOption],
    flights: [...defaultOption],
    Nested: [
      flightID
        ? {
            Name: "Videos",
            Description: "Videos list",
            Icon: MovieIcon,
            Route: `/cases/${caseID}/flights/${flightID}/videos`,
          }
        : null,
      flightID
        ? {
            Name: "Footage",
            Icon: MovieIcon,
            Description: "Footage list",
            Route: `/cases/${caseID}/flights/${flightID}/footage`,
          }
        : null,
    ],
  };

  let location = useLocation();
  const getPathName = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    console.log("pathnames", pathnames);

    if (pathnames[0] === "cases") {
      setMenuOption(pathnames[0]);
      if (pathnames[1]) {
        setCaseID(pathnames[1]);
      } else {
        setCaseID("");
      }
    }

    if (pathnames[2] === "flights") {
      setMenuOption(pathnames[2]);
      if (pathnames[3]) {
        setFightID(pathnames[3]);
      } else {
        setFightID("");
      }
    }

    if (pathnames[2] === "analysis") {
      setMenuOption("default");
    }
    if (pathnames[2] === "search-configs") {
      setMenuOption("default");
    }
    if (pathnames[2] === "images") {
      setMenuOption("default");
    }
    if (pathnames[2] === "reports") {
      setMenuOption("default");
    }
    if (pathnames[2] === "assets") {
      setMenuOption("default");
    }

    // Reset config
    if (pathnames.length === 0) {
      setMenuOption("default");
      setFightID("");
      setCaseID("");
    }
  };

  React.useEffect(() => {
    getPathName();
  }, [location]);

  return (
    <Drawer open={isClosed} onClose={closeDrawer}>
      <Box sx={{ width: "auto", auto: 250 }} role="presentation">
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Menu
            </ListSubheader>
          }
          key={"menu"}
        >
          {menuList[`${menuOptions}`] &&
            menuList[`${menuOptions}`]
              .filter((el) => el !== null)
              .map(({ Name, Description, Icon, Route, Nested }, i) => (
                <>
                  <ListItem
                    disablePadding
                    onClick={closeDrawer}
                    component={Link}
                    to={Route}
                    key={i + "menu"}
                  >
                    <ListItemButton
                      onClick={(e) => {
                        Name === "3. Surveillance Sources" && flightID
                          ? handleClick(e)
                          : null;
                      }}
                    >
                      <ListItemIcon>{<Icon />}</ListItemIcon>
                      <ListItemText
                        primary={Name}
                        secondary={
                          <Typography variant="body2" color="text.secondary">
                            {Description}
                          </Typography>
                        }
                      />
                      {open
                        ? Name === "3. Flight Images" &&
                          flightID && <ExpandLess />
                        : Name === "3. Flight Images" &&
                          flightID && <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                  {Name === "3. Flight Images" && flightID && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {menuList["Nested"].map(({ Name, Description, Icon, Route }, i) => {
                          return (
                            <ListItem
                              disablePadding
                              component={Link}
                              to={Route}
                              key={i + "nested"}
                              onClick={closeDrawer}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>{<Icon />}</ListItemIcon>
                                <ListItemText
                                  primary={Name}
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {Description}
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  )}
                </>
              ))}
        </List>
      </Box>
    </Drawer>
  );
}
