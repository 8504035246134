import React from "react";
import { Link } from "react-router-dom";

export default function TableOfContents() {
  return (
    <>
      <div>
        <div style={{ textAlign: "left", padding: 20 }}>
          <a>Find911</a>
        </div>
        <br />
        <img src="images/find911-logo.jpg" />
        <div style={{ textAlign: "left" }}>
          <Link to="/login">LOGIN</Link>
          <br></br>
          <Link to="/Analysis/Create">Analysis Create</Link>
          <br></br>
          <Link to="/Analysis/Execute">Analysis Execute</Link>
          <br></br>
          <Link to="/Analysis/Select">Analysis Select</Link>
          <br></br>
          <Link to="/Analysis/Results">Analysis Results</Link>
          <br></br>
          <Link to="/Cases/SelectCase">Case Select</Link>
          <br></br>
          <Link to="/CreateSelectCase">Create or Select Case</Link>
          <br></br>
          <Link to="/Footage/Select">Footage Select</Link>
          <br></br>
          <Link to="/buildColorPalette">Build Color Palette</Link>
          <br></br>
          <Link to="/ObjectDetect/DetectObjectsPalette">Detect Objects Palette</Link>
          <p>
            Case id: <span id="caseId">ID is TBD</span>: &nbsp; Name:{" "}
            <span id="caseName">Name is TBD</span>
            <br />
            Lead id: <span id="orgId">ID is TBD</span>: &nbsp; Name:{" "}
            <span id="orgName">Org is TBD</span>
            <br />
            Case Desc:: <span id="caseDesc">Description is TBD</span>
            <br />
          </p>
        </div>
        <div style={{ textAlign: "left" }}>
          <Link to="/login">LOGIN</Link>
          <br></br>
          <Link to="/Cases/CreateCase">Create Case</Link>
          <br></br>
          <Link to="/Cases/SelectCase">Case Select</Link>
          <br></br>
          //<Link to="/Analysis/Create">Analysis Create</Link>
          <br></br>
          <Link to="/Analysis/Select">Analysis Select</Link>
          <br></br>
          <Link to="/Analysis/Execute">Analysis Execute</Link>
          <br></br>
          <Link to="/Analysis/Results">Analysis Results</Link>
          <br></br>
          <Link to="/Footage/Select">Footage Select</Link>
          <br></br>
          <Link to="/buildColorPalette">Build Color Palette</Link>
          //{" "}
          <p>
            Case id: <span id="caseId">ID is TBD</span>: &nbsp; Name:{" "}
            <span id="caseName">Name is TBD</span>
            <br />
            Lead id: <span id="orgId">ID is TBD</span>: &nbsp; Name:{" "}
            <span id="orgName">Org is TBD</span>
            <br />
            Case Desc:: <span id="caseDesc">Description is TBD</span>
            <br />{" "}
          </p>{" "}
        </div>
        <hr />
        <ol id="caseNeeded" style={{ display: "block", textAlign: "left" }}>
          <ol style={{ textAlign: "left" }}>
            Create / Select Color Palette & Configuration
            <li>
              <a href=""> Upload Image for Color Palette</a>
              <br />
            </li>
            <li>
              <a href="javascript:goLink( 'selImageForColorPalette.html', '_blank' )">
                {" "}
                Select Image for Color Palette
              </a>
              <br />
            </li>
            <li>
              <a href="javascript:goLink( 'selColorPalette.html', '_blank' )">
                {" "}
                Configure Color Palette for Analysis
              </a>
              <br />
            </li>
            <li>
              <a href="javascript:goLink( 'createAnalysisConfiguration.html', '_blank' )">
                {" "}
                Create Analysis Configuration
              </a>
              <br />
            </li>
          </ol>
          <br />
          <ol style={{ textAlign: "left" }}>
            Analyze
            <li>
              <a href="javascript:goLink( 'uploadImage.html', '_blank' )">
                {" "}
                Upload Images
              </a>
              <br />
            </li>
            <li>
              <a href="javascript:goLink( 'uploadVideo.html', '_blank' )">
                {" "}
                Upload Videos
              </a>
              <br />
            </li>
          </ol>
          <br />
          <ol style={{ textAlign: "left" }}>
            Analyze
            <li>
              <a href="javascript:goLink( 'performAnalysis.html', '_blank' )">
                {" "}
                Perform Analysis
              </a>
              <br />
            </li>
          </ol>
        </ol>
        <hr />
        <ol style={{ textAlign: "left" }}>
          Testing:
          <li>
            <a href="javascript:goLink( 'invokeN8N.html', '_blank' )">
              Invoke N8N Test
            </a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'getModels.html', '_blank' )">
              Get Models
            </a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'getImages.html', '_blank' )">
              Gety RGB/Thermal Images
            </a>
            <br />
          </li>
        </ol>
      </div>

      {/* <div style={{ textAlign: "left" }}>
        <Link to="/login">LOGIN</Link>
        <br></br>
        <Link to="/Cases/CreateCase">Create Case</Link>
        <br></br>
        <Link to="/Cases/SelectCase">Case Select</Link>
        <br></br>
        <Link to="/Analysis/Create">Analysis Create</Link>
        <br></br>
        <Link to="/Analysis/Select">Analysis Select</Link>
        <br></br>
        <Link to="/Analysis/Execute">Analysis Execute</Link>
        <br></br>
        <Link to="/Analysis/Results">Analysis Results</Link>
        <br></br>
        <Link to="/Footage/Select">Footage Select</Link>
        <br></br>
        <Link to="/buildColorPalette">Build Color Palette</Link>
        <p>
          Case id: <span id="caseId">ID is TBD</span>: &nbsp; Name:{" "}
          <span id="caseName">Name is TBD</span>
          <br />
          Lead id: <span id="orgId">ID is TBD</span>: &nbsp; Name:{" "}
          <span id="orgName">Org is TBD</span>
          <br />
          Case Desc:: <span id="caseDesc">Description is TBD</span>
          <br />
        </p>
      </div>

      <hr />
      <ol id="caseNeeded" style={{ display: "block", textAlign: "left" }}>
        <ol style={{ textAlign: "left" }}>
          Create / Select Color Palette & Configuration
          <li>
            <a href=""> Upload Image for Color Palette</a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'selImageForColorPalette.html', '_blank' )">
              {" "}
              Select Image for Color Palette
            </a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'selColorPalette.html', '_blank' )">
              {" "}
              Configure Color Palette for Analysis
            </a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'createAnalysisConfiguration.html', '_blank' )">
              {" "}
              Create Analysis Configuration
            </a>
            <br />
          </li>
        </ol>
        <br />
        <ol style={{ textAlign: "left" }}>
          Analyze
          <li>
            <a href="javascript:goLink( 'uploadImage.html', '_blank' )">
              {" "}
              Upload Images
            </a>
            <br />
          </li>
          <li>
            <a href="javascript:goLink( 'uploadVideo.html', '_blank' )">
              {" "}
              Upload Videos
            </a>
            <br />
          </li>
        </ol>
        <br />
        <ol style={{ textAlign: "left" }}>
          Analyze
          <li>
            <a href="javascript:goLink( 'performAnalysis.html', '_blank' )">
              {" "}
              Perform Analysis
            </a>
            <br />
          </li>
        </ol>
      </ol>

      <hr />
      <ol style={{ textAlign: "left" }}>
        Testing:
        <li>
          <a href="javascript:goLink( 'invokeN8N.html', '_blank' )">
            Invoke N8N Test
          </a>
          <br />
        </li>
        <li>
          <a href="javascript:goLink( 'getModels.html', '_blank' )">
            Get Models
          </a>
          <br />
        </li>
        <li>
          <a href="javascript:goLink( 'getImages.html', '_blank' )">
            Gety RGB/Thermal Images
          </a>
          <br />
        </li>
      </ol> */}
      {/* </div> */}
    </>
  );
}
