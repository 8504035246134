import React, { useState, useRef } from "react";

//MUI components
import { Box, Paper, Stack, Typography, TextField } from "@mui/material";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

export default function CustomTempLite({ onClose, addTempToPalette }) {
  const t1Input = useRef();
  const t2Input = useRef();
  const addNewTemperatureAndClose = () => {
    const t1 = t1Input.current.value;
    const t2 = t2Input.current.value;
    addTempToPalette({t1,t2});
    onClose();
  };

  {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // maxWidth: "90vw",
            // maxHeight: "90vh",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.75rem",
            zIndex: 9999,
            display: "flex",
          }}
        >
          <Box>
            <Paper
              elevation={16}
              sx={{ padding: 5, bgcolor: "rgb(255,255,255, 0.7)" }}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="h2">Temperatures</Typography>
                <TextField
                  id="t1"
                  name="Temperature °C"
                  label="Temperature °C"
                  placeholder=""
                  inputRef={t1Input}
                ></TextField>

                <TextField
                  id="t2"
                  name="Temperature °C"
                  label="Temperature °C"
                  placeholder=""
                  inputRef={t2Input}
                ></TextField>
              </Stack>
              <Box sx={{ marginLeft: 3, display: "flex", flexDirection: "row", justifyContent:"center" }}>
                <CloseIcon
                  color={"info"}
                  fontSize={"large"}
                  sx={{ marginTop: 5}}
                  onClick={() => onClose()}
                />
                <CheckIcon
                  color={"info"}
                  fontSize={"large"}
                  sx={{ marginLeft: 3, marginTop: 5 }}
                  onClick={() => addNewTemperatureAndClose()}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    );
  }
}
