import React, { createContext, useState, useContext } from "react";
import { AppContext } from "./appContext";

import api, { API_URL } from "../API";
import { UserContext } from "./userContext";

const initialContextState = {
  sarCaseId: undefined,
  searchConfigId: undefined,
  searchConfigName: undefined,
  searchConfigType: undefined,
};

export const SearchConfigContext = createContext(initialContextState);

// Create a context provider component
export const SearchConfigProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [searchConfigCtx, setSearchConfigCtx] = useState(initialContextState);
  const [searchConfigs, setSearchConfigs] = React.useState([]);

  // Function to update the ID in the store
  const updateSearchConfigCtx = async (ctx) => {
    setSearchConfigCtx(ctx);
  };

  const getSearchConfigsByCase = async (case_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-search-configs-by-case",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          cid: case_id,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setSearchConfigs(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getSearchConfigData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-search-configs-by-id",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          scId: searchConfigCtx.searchConfigId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setSearchConfigCtx(response.data[0]);
          resolve(response.data[0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteSearchConfigData = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "delete-search-configs-by-id",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          scid: data.searchConfigId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          let tempConfigs = { ...searchConfigs };
          let typeName = `${data["searchTypeName"]}`;
          tempConfigs[typeName] = tempConfigs[`${typeName}`].filter(
            (el) => el.searchConfigId !== data.searchConfigId
          );
          setSearchConfigs({ ...tempConfigs });
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  //get-detectable-objects
  const getDetectableObjects = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-detectable-objects",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // get-anomaly-detectors
  const getAnomalyDetectors = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-anomaly-detectors",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-face-recognition-search-config
  const createFaceRecognitionSearchConfig = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-face-recognition-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-color-palette-search-config
  const createColorPaletteSearchConfigRGB = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-color-palette-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const createColorPaletteSearchConfigHSL = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-color-palette-hsl-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-detect-objects-search-config
  const createDetectObjectsSearchConfig = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-detect-objects-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-detect-anomaly-search-config
  const createDetectAnomalySearchConfig = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-detect-anomaly-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-radiometric-search-config
  const createRadiometricSearchConfig = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-radiometric-palette-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          ...data,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // update-search-config
  // scid
  // name
  // description
  const updateSearchConfig = async ({ name, description, scid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-search-config",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          scid,
          name,
          description,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    searchConfigs,
    searchConfigCtx,
    getSearchConfigData,
    deleteSearchConfigData,
    updateSearchConfigCtx,
    getSearchConfigsByCase,
    setSearchConfigs,
    setSearchConfigCtx,
    createFaceRecognitionSearchConfig,
    createColorPaletteSearchConfigRGB,
    createColorPaletteSearchConfigHSL,
    createDetectObjectsSearchConfig,
    createDetectAnomalySearchConfig,
    getDetectableObjects,
    getAnomalyDetectors,
    createRadiometricSearchConfig,
    updateSearchConfig,
  };

  return (
    <SearchConfigContext.Provider value={contextValue}>
      {children}
    </SearchConfigContext.Provider>
  );
};
