import React, { useState, useRef, useContext } from "react";
import { CropperRef, Cropper, Coordinates } from "react-advanced-cropper";

import { CaseContext } from "../../Context/caseContext";
import { UserContext } from "../../Context/userContext";
import { ImageContext } from "../../Context/imageContext";

import { ZoomInIcon } from "./icons/ZoomInIcon";
import { ZoomOutIcon } from "./icons/ZoomOutIcon";
import { ArrowTopIcon } from "./icons/ArrowTopIcon";
import { ArrowLeftIcon } from "./icons/ArrowLeftIcon";
import { ArrowRightIcon } from "./icons/ArrowRightIcon";
import { ArrowBottomIcon } from "./icons/ArrowBottomIcon";

import { PreviewResults } from "./components/PreviewResults";
import { VerticalButtons } from "./components/VerticalButtons";
import { SquareButton } from "./components/SquareButton";
import "./CustomCropper.scss";
import { toast } from "react-toastify";

export const CustomCropper = ({
  imageUrl,
  coordinates,
  setPreview,
  setCoordinates,
  setPaletteArray,
  paletteArray,
}) => {
  const { userCtx } = useContext(UserContext);
  const [sarCase] = useState(CaseContext);

  const { uploadImageForRadiometric, uploadImageForColorPalette } =
    useContext(ImageContext);
  const cropperRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const zoom = (factor) => () => {
    const cropper = cropperRef.current;
    if (cropper) {
      cropper.zoomImage(factor);
    }
  };

  const move = (direction) => () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const coordinates = cropper.getCoordinates();
      if (coordinates) {
        const { width, height } = coordinates;
        if (direction === "left") {
          cropper.moveImage(-width / 4);
        } else if (direction === "right") {
          cropper.moveImage(width / 4);
        } else if (direction === "top") {
          cropper.moveImage(0, -height / 4);
        } else if (direction === "bottom") {
          cropper.moveImage(0, height / 4);
        }
      }
    }
  };

  const onCrop = async () => {
    if (cropperRef.current) {
      if (setCoordinates) {
        setCoordinates(cropperRef.current.getCoordinates());
        setPreview(cropperRef.current.getCanvas()?.toDataURL());
        submitCoordinates(cropperRef.current.getCoordinates());
      } else {
        console.log("here");
        setPreview(cropperRef.current.getCanvas()?.toDataURL());
        fetchKeyColors(cropperRef.current.getCanvas()?.toDataURL());
      }
    }
  };

  const submitCoordinates = async (coordinates) => {
    const { top, left, width, height } = coordinates;
    // Calculate coordinates
    const originalTop = left;
    const originalLeft = top;
    const originalBottom = originalTop + height;
    const originalRight = originalLeft + width;

    await fetchKeyTemps({
      top: originalTop,
      left: originalLeft,
      bottom: originalBottom,
      right: originalRight,
    });
  };

  const fetchKeyTemps = async (coordinates) => {
    try {
      setIsLoading(true);
      const response = await uploadImageForRadiometric({
        top: coordinates.top,
        left: coordinates.left,
        bottom: coordinates.bottom,
        right: coordinates.right,
      });
      setPaletteArray(
        paletteArray.concat([
          ...response.map((el) => {
            return {
              t1: `${el.low}`,
              t2: `${el.high}`,
              isRange: el.high ? true : false,
            };
          }),
        ])
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR:", error);
      toast.error(error.message);
    }
  };

  const fetchKeyColors = async (paramData) => {
    try {
      setIsLoading(true);
      const response = await uploadImageForColorPalette({
        image: paramData,
        topNumColors: 5,
        wid: userCtx.workerId,
        gid: sarCase.sarCaseOrgId,
        cid: sarCase.sarCaseId,
      });
      console.log("uploadImageForColorPalette response:", response);
      // let lowHighRange = convertToRGBFormat(response);
      setPaletteArray(paletteArray.concat(convertToRGBFormat(response)));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const convertToRGBFormat = (objects) => {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      if (obj.low && typeof obj.low === "string") {
        const rgbArray = obj.low.match(/\d+(\.\d+)?/g);
        if (rgbArray && rgbArray.length === 3) {
          const roundedArray = rgbArray.map(Number).map(Math.round);
          const rgbString = `rgb(${roundedArray.join(",")})`;
          obj.rgb = rgbString;
        }
      }

      if (obj.high && typeof obj.high === "string") {
        const rgbArray = obj.high.match(/\d+(\.\d+)?/g);
        if (rgbArray && rgbArray.length === 3) {
          const roundedArray = rgbArray.map(Number).map(Math.round);
          const rgbString = `rgb(${roundedArray.join(",")})`;
          obj.rgb2 = rgbString;
        }
      }
      obj.isRange = true;

      return obj;
    });
  };

  return (
    <>
      <div className={"getting-result-manual-example"}>
        <Cropper
          ref={cropperRef}
          className={"getting-result-manual-example__cropper"}
          // stencilProps={{ aspectRatio: 1 }}
          src={imageUrl}
        />
        <div
          className={"getting-result-manual-example__crop-button"}
          onClick={onCrop}
        >
          {!isLoading ? "Crop Image" : "Loading..."}
        </div>
        <VerticalButtons>
          <SquareButton title="Zoom In" onClick={zoom(2)}>
            <ZoomInIcon />
          </SquareButton>
          <SquareButton title="Zoom Out" onClick={zoom(0.5)}>
            <ZoomOutIcon />
          </SquareButton>
          <SquareButton title="Move Top" onClick={move("top")}>
            <ArrowTopIcon />
          </SquareButton>
          <SquareButton title="Move Left" onClick={move("left")}>
            <ArrowLeftIcon />
          </SquareButton>
          <SquareButton title="Move Right" onClick={move("right")}>
            <ArrowRightIcon />
          </SquareButton>
          <SquareButton title="Move Bottom" onClick={move("bottom")}>
            <ArrowBottomIcon />
          </SquareButton>
        </VerticalButtons>
      </div>
    </>
  );
};
