import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { Box, Typography, Modal } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  //   right: "30%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";

export default function CreateSearchConfigModal(props) {
  const navigate = useNavigate();
  const { createSearchConfigModalOpen, handleCreateSearchConfigModalClose } =
    useContext(UiContext);
  const [newCritValue, setNewCritValue] = React.useState("select");
  const handleNewCritValueChange = (event) => {
    setNewCritValue(event.target.value);

    if (event.target.value != "select")
      if (event.target.value == "object-detect")
        navigate(`/Cases/${case_id}/SearchConfigs/DetectObjectsPalette`);
      else if (event.target.value == "anomaly-detect")
        navigate(`/Cases/${case_id}/SearchConfigs/DetectAnomalyPalette`);
      else if (event.target.value == "radiometric")
        navigate(`/Cases/${case_id}/SearchConfigs/radiometric`);
      else navigate(`/Cases/${case_id}/Images`);
  };

  const [isLoading, setIsLoading] = useState(false);
  const { case_id } = useParams();

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    handleCreateSearchConfigModalClose();
  };

  //   Hook that uploads files when files are set
  React.useEffect(() => {
    return () => {
      handleCreateSearchConfigModalClose();
    };
  }, []);

  return (
    <Modal
      open={createSearchConfigModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <div>
        <Box sx={{ ...style }}>
          <label sx={{ marginLeft: "auto" }}>
            Create New &nbsp;
            <select value={newCritValue} onChange={handleNewCritValueChange}>
              <option value="select"> - Select - </option>
              <option value="rgb-color">Color Palette</option>
              <option value="thermal-color">Thermal Color</option>
              <option value="radiometric">Radiometric</option>
              <option value="facial-recog">Facial Recognition</option>
              <option value="object-detect">Object Detect</option>
              <option value="anomaly-detect">Anomaly Detect</option>
            </select>
          </label>
          <br></br>
          <br></br>
          <LoadingButton
            onClick={onClose}
            disabled={isLoading}
            variant="outlined"
          >
            Close
          </LoadingButton>
        </Box>
      </div>
    </Modal>
  );
}
