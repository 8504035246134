import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    // email: "eali@cloud303.io",
    // name: "Ehtasham",
    // companyName: "Cloud303",
    // password: "testing12345",
    // confirmPassword: "testing12345",

    email: "",
    name: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    // confirmationCode: "",
  });

  const nav = useNavigate();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.name.length > 0 &&
      fields.companyName.length > 0 &&
      fields.password.length >= 8 &&
      fields.password === fields.confirmPassword
    );
  }

  // function validateConfirmationForm() {
  //   return fields.confirmationCode.length > 0;
  // }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      let user = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          name: fields.name,
          phone_number: "+15555555555",
          "custom:org_name": fields.companyName,
        },
      });
      setIsLoading(false);
      setNewUser(user);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingX: 10,
            paddingTop: 5,
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              You'll be confirmed by Admin soon
            </Typography>
            {/* <Box
              component="form"
              onSubmit={handleConfirmationSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmationCode"
                label="Confirmation Code"
                name="confirmationCode"
                autoComplete="confirmationCode"
                autoFocus
                value={fields.confirmationCode}
                onChange={handleFieldChange}
              />

              <LoadingButton
                loading={isLoading}
                loadingIndicator="Loading..."
                variant="outlined"
                type="submit"
                disabled={!validateConfirmationForm()}
              >
                Verify
              </LoadingButton>
            </Box> */}
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            paddingX: 10,
            paddingTop: 5,
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={fields.email}
                onChange={handleFieldChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                value={fields.password}
                onChange={handleFieldChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="Confirm Password"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="confirmPassword"
                value={fields.confirmPassword}
                onChange={handleFieldChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                name="Name"
                autoComplete="name"
                autoFocus
                value={fields.name}
                onChange={handleFieldChange}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="companyName"
                label="Company Name"
                name="Company"
                autoComplete="companyName"
                autoFocus
                value={fields.companyName}
                onChange={handleFieldChange}
              />

              <LoadingButton
                loading={isLoading}
                loadingIndicator="Loading..."
                variant="outlined"
                type="submit"
                disabled={!validateForm()}
              >
                Sign Up
              </LoadingButton>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
      </>
    );
  }

  return (
    <div style={{ padding: "60px 0" }}>
      <Helmet>
        <title>Signup</title>
      </Helmet>
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
