import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import {
  Box,
  Typography,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { ReportContext } from "../Context/reportContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Default width for mobile
  maxWidth: 600, // Max width for desktop
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Enable scrolling for small screens
  maxHeight: "90vh", // Limit height to 90% of viewport height
};

export default function CreateReportModal({ createReportModalClose }) {
  const navigate = useNavigate();
  const { createReportOpen, handleCreateReportModalClose } =
    useContext(UiContext);
  const { createReport } = useContext(ReportContext);

  const [isLoading, setIsLoading] = useState(false);
  const nameInput = useRef();
  const descriptionInput = useRef();
  const formRef = React.useRef();
  const { case_id } = useParams();

  // Check if the screen is mobile-sized
  const isMobile = useMediaQuery("(max-width:600px)");

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    handleCreateReportModalClose();
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      console.log(nameInput.current.value);
      console.log(descriptionInput.current.value);
      await createReport(
        case_id,
        nameInput.current.value,
        descriptionInput.current.value
      );
    } catch (error) {
      setIsLoading(false);
      handleCreateReportModalClose();
      toast.error(error.message);
    }

    setIsLoading(false);
    createReportModalClose();
  };

  React.useEffect(() => {
    return () => {
      handleCreateReportModalClose();
    };
  }, []);

  return (
    <Modal
      open={createReportOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form
          id="enroll"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          ref={formRef}
        >
          <TextField
            id="name"
            name="name"
            label="Name"
            placeholder="Name"
            inputRef={nameInput}
            required={true}
            fullWidth // Make the input full width
            margin="normal" // Add spacing
          />

          <TextField
            id="description"
            name="description"
            label="Description"
            placeholder="Description"
            inputRef={descriptionInput}
            required={true}
            fullWidth // Make the input full width
            margin="normal" // Add spacing
            multiline // Allow multiple lines for description
            rows={isMobile ? 3 : 5} // Adjust rows based on screen size
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2, // Add spacing between buttons
              mt: 3, // Add margin top
            }}
          >
            <LoadingButton
              variant="outlined"
              loading={isLoading}
              onClick={() => {
                formRef.current.reportValidity() && onSubmit();
              }}
            >
              Submit
            </LoadingButton>
            <LoadingButton
              onClick={onClose}
              disabled={isLoading}
              variant="outlined"
            >
              Close
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
