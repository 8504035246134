import React from "react";
import Image from "mui-image";
import { Box } from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";

export default function ZoomImageModal({ zoomed, filePath, onClose }) {
  console.log(filePath);
  if (zoomed && filePath) {
    // Add a check for the filePath prop
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9998,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // maxWidth: "90vw",
            // maxHeight: "90vh",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.75rem",
            zIndex: 9999,
          }}
        >
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={100}
            defaultPositionY={200}
          >
            <TransformComponent>
              <Image src={filePath} width={1200} />
            </TransformComponent>
          </TransformWrapper>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0.5rem",
            right: 0,
            zIndex: 2,
          }}
        >
          <ZoomInMapIcon onClick={onClose} />
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
