import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import { systemContext, userContext, caseContext, criteriaContext } from "../index";
import {
  Box,
  Button,
  Stack,
  TextField,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import FootageTable from "../Footage/FootageTable";
import ColorPaletteTable from "../ColorPalette/ColorPaletteTable";
import UploadButtons from "../UploadFootage";

const testFootage = [
  {
    id: 1,
    flight: "flight1",
    access: "All",
    footageType: "Color",
    dateCreated: new Date(),
  },
  {
    id: 2,
    flight: "flight2",
    access: "Bill",
    footageType: "Thermal",
    dateCreated: new Date(),
  },
  {
    id: 3,
    flight: "LONG FLIGHT NAME TEST",
    access: "All",
    footageType: "Color",
    dateCreated: new Date(),
  },
];

const testCPs = [
  {
    id: 1,
    name: "Dog Hair Palette",
    access: "Case",
    owner: "Bill",
    dateCreated: new Date(),
  },
  {
    id: 2,
    name: "Bone",
    access: "All",
    owner: "Find911",
    dateCreated: new Date(),
  },
];

const ACTION = "create-select-case";
const textValues = {
  criteriaName: "",
};

export default function CreateCriteriaPage() {
  const { authHeaders } = useAppContext();

  const [footage, setFootage] = React.useState([]);
  const [CPs, setCPs] = React.useState([]);

  React.useEffect(() => {
    if (0 == 1) {
      Promise.all([
        fetch( sysCtx.apiUrl, {
            method: "POST",
            headers: {
              ...authHeaders
            },
            body: JSON.stringify({
              action: "get-flights", // UPDATE
              gid: "1",
              wid: "1",
              ip: "0.0.0.0",
            }),
          }
        ),
        fetch( sysCtx.apiUrl, {
            method: "POST",
            headers: {
              ...authHeaders
            },
            body: JSON.stringify({
              action: "get-colorpalettes", // UPDATE
              gid: "1",
              wid: "1",
              ip: "0.0.0.0",
            }),
          }
        ),
      ])
        .then(([resFlights, resCPs]) =>
          Promise.all([resFlights.json(), resCPs.json()])
        )
        .then(([flights, CPs]) => {
          setFootage(flights);
          setCPs(CPs);
        });
    } else {
      // MANUALLY SET FOR NOW
      setFootage(testFootage);
      setCPs(testCPs);
    }
  }, []);

  const [selectedFootage, setSelectedFootage] = React.useState([]);
  function handleFootageChange(footage) {
    console.log("In CreateCriteriaPage: ");
    setSelectedFootage(footage);
    console.log(footage);
    // console.log('Selected Footage IDs:', selectedFootage)
  }

  const [selectedCPs, setSelectedCPs] = React.useState([]);
  function handleCPChange(IDs) {
    // console.log('In CreateCriteriaPage: ')
    setSelectedCPs(IDs);
    // console.log(IDs)
    // console.log('Selected CP IDs:', selectedCPs)
  }

  const [formValues, setFormValues] = React.useState(textValues);
  function handleInputChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({
      ...formValues,
      [e.target.id]: value,
    });
    console.log(formValues);
  }

  const saveCriteriaConfig = (event) => {
    // event.preventDefault();

    const form = {
      action: "create-criteria",
      gid: "1",
      wid: "1",
      ip: "0.0.0.0",
      flight_ids: selectedFootage,
      cp_ids: selectedCPs,
      Criteria_name: formValues["CriteriaName"], // could generically pass all form values, but choose not to, for now
    };

    console.log(selectedFootage);
    console.log("Flight IDs: ", selectedFootage.toString());
    console.log("Color Palette IDs: ", selectedCPs.toString());
    console.log(form);
    alert(
      "Submitted to Backend:\n" +
        "Flight IDs: " +
        selectedFootage.toString() +
        "\nColor Palette IDs: " +
        selectedCPs.toString() +
        "\nCriteria Name: " +
        formValues["criteriaName"].toString()
    );
    alert(JSON.stringify(form));

    fetch( sysCtx.apiUrl, {
        method: "POST",
        headers: {
          ...authHeaders
        },
        body: JSON.stringify(form),
      }
    ).then((response) => {
      console.log(response);
      alert(response);
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingX: 10,
          paddingTop: 5,
        }}
      >
        <Box>
          <FootageTable
            onChange={(ids) => handleFootageChange(ids)}
            footage={footage}
          ></FootageTable>
        </Box>
        <br></br>
        <br></br>
        <Box>
          <ColorPaletteTable
            onChange={(ids) => handleCPChange(ids)}
            rows={CPs}
          />
        </Box>
        <br></br>
        <br></br>
        <Box>
          <Stack
            direction="row"
            spacing={2}
            useFlexGap
            justifyContent="space-evenly"
          >
            <TextField
              id="criteriaName"
              label="Enter Criteria Name"
              placeholder="Criteria Name"
              value={formValues.criteriaName}
              onChange={handleInputChange}
            ></TextField>
            <Button
              variant="contained"
              type="submit"
              onClick={saveCriteriaConfig}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
