import React, { useEffect, useRef } from "react";
import chroma from "chroma-js";
import { Box, Typography, useMediaQuery } from "@mui/material";

const ColorScaleRGB = ({
  checkedElements,
  rangeBuffer = 10, // Single rangeBuffer for all RGB channels
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
}) => {
  const colorScaleRef = useRef(null);
  const redScaleRef = useRef(null);
  const greenScaleRef = useRef(null);
  const blueScaleRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  // Validate and parse RGB values
  const parseRGB = (color) => {
    try {
      const [r, g, b] = chroma(color).rgb();
      return {
        r: isNaN(r) ? 0 : Math.round(r), // Round to nearest integer
        g: isNaN(g) ? 0 : Math.round(g), // Round to nearest integer
        b: isNaN(b) ? 0 : Math.round(b), // Round to nearest integer
      };
    } catch (error) {
      console.error("Invalid color format:", color);
      return { r: 0, g: 0, b: 0 }; // Fallback to default values
    }
  };

  // Get min and max RGB values based on rangeBuffer
  const getMinMaxRGB = (inputColors, rangeBuffer) => {
    let minRed = 255,
      minGreen = 255,
      minBlue = 255;
    let maxRed = 0,
      maxGreen = 0,
      maxBlue = 0;

    for (let i = 0; i < inputColors.length; i++) {
      const { r, g, b } = parseRGB(inputColors[i]);

      // Apply rangeBuffer to each component and clamp within 0-255
      const minR = Math.max(0, r - rangeBuffer);
      const maxR = Math.min(255, r + rangeBuffer);

      const minG = Math.max(0, g - rangeBuffer);
      const maxG = Math.min(255, g + rangeBuffer);

      const minB = Math.max(0, b - rangeBuffer);
      const maxB = Math.min(255, b + rangeBuffer);

      // Update min and max values
      minRed = Math.min(minRed, minR);
      minGreen = Math.min(minGreen, minG);
      minBlue = Math.min(minBlue, minB);

      maxRed = Math.max(maxRed, maxR);
      maxGreen = Math.max(maxGreen, maxG);
      maxBlue = Math.max(maxBlue, maxB);
    }

    const minRGB = `rgb(${Math.round(minRed)}, ${Math.round(
      minGreen
    )}, ${Math.round(minBlue)})`;
    const maxRGB = `rgb(${Math.round(maxRed)}, ${Math.round(
      maxGreen
    )}, ${Math.round(maxBlue)})`;

    return {
      minRGB,
      maxRGB,
      minRed,
      maxRed,
      minGreen,
      maxGreen,
      minBlue,
      maxBlue,
    };
  };

  // Update color scale and min/max values
  useEffect(() => {
    const finalScale = checkedElements.map((item) => item.rgb);

    if (finalScale.length === 0 || finalScale.some((color) => !color)) {
      console.error("❌ Error: No valid colors found in checkedElements.");
      return;
    }

    const {
      minRGB,
      maxRGB,
      minRed,
      maxRed,
      minGreen,
      maxGreen,
      minBlue,
      maxBlue,
    } = getMinMaxRGB(finalScale, rangeBuffer);

    // Main color scale
    const scale = chroma.scale([minRGB, maxRGB]).mode("rgb");
    const numSamples = 200;
    const colors = scale.colors(numSamples);

    colorScaleRef.current.innerHTML = "";
    colors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      colorSample.style.transition = "background-color 0.3s ease-in-out";
      colorScaleRef.current.appendChild(colorSample);
    });

    setMinValue(minRGB);
    setMaxValue(maxRGB);

    // Create separate scales for Red, Green, and Blue
    const midGreen = Math.round((minGreen + maxGreen) / 2); // Midpoint green
    const midBlue = Math.round((minBlue + maxBlue) / 2); // Midpoint blue

    // Red Scale
    const redScale = chroma
      .scale([
        `rgb(${minRed}, ${0}, ${0})`, // Min Red
        `rgb(${maxRed}, ${0}, ${0})`, // Max Red
      ])
      .mode("rgb");
    const redColors = redScale.colors(numSamples);
    redScaleRef.current.innerHTML = "";
    redColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      redScaleRef.current.appendChild(colorSample);
    });

    // Green Scale
    const greenScale = chroma
      .scale([
        `rgb(${0}, ${minGreen}, ${0})`, // Min Green
        `rgb(${0}, ${maxGreen}, ${0})`, // Max Green
      ])
      .mode("rgb");
    const greenColors = greenScale.colors(numSamples);
    greenScaleRef.current.innerHTML = "";
    greenColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      greenScaleRef.current.appendChild(colorSample);
    });

    // Blue Scale
    const blueScale = chroma
      .scale([
        `rgb(${0}, ${0}, ${minBlue})`, // Min Blue
        `rgb(${0}, ${0}, ${maxBlue})`, // Max Blue
      ])
      .mode("rgb");
    const blueColors = blueScale.colors(numSamples);
    blueScaleRef.current.innerHTML = "";
    blueColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      blueScaleRef.current.appendChild(colorSample);
    });
  }, [checkedElements, rangeBuffer]);

  return (
    <div>
      {/* Main Color Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Main Color Scale</strong>
        </Typography>
        <div
          ref={colorScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Red Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Red Scale</strong>
        </Typography>
        <div
          ref={redScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Green Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Green Scale</strong>
        </Typography>
        <div
          ref={greenScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Blue Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Blue Scale</strong>
        </Typography>
        <div
          ref={blueScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Min and Max RGB Values */}
      <Box>
        <Typography variant={isMobile ? "body2" : "body1"}>
          <strong>Min RGB:</strong> {minValue}
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          <strong>Max RGB:</strong> {maxValue}
        </Typography>
      </Box>
    </div>
  );
};

export default ColorScaleRGB;