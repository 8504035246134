import React, { createContext, useState, useContext } from "react";
import api, { API_URL } from "../API";
import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
import { CaseContext } from "./caseContext";
import { toast } from "react-toastify";

// Create the initial context state
const initialContextState = {
  flightId: undefined,
  sarCaseId: undefined,
  flightName: "",
  pilotName: "",
  filepath: "",
  flightDescription: "",
  flightDate: "",
};

// Create the context
export const FlightContext = createContext(initialContextState);

// Create a context provider component
export const FlightProvider = ({ children }) => {
  const { authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const { sarCase } = useContext(CaseContext);
  const [flightCtx, setFlightCtx] = useState(initialContextState);
  const [flights, setFlights] = React.useState([]);

  const updateFlightCtx = async (ctx) => {
    setFlightCtx(ctx);
  };

  const getFlightByID = async (flightID, start = 0, qty = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-flights-by-id",
          fid: flightID,
          start,
          qty,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          if (!response.data[0]["records"][0]) 
            toast.warn("Flight record not found");
          setFlightCtx(response.data[0]["records"][0]);
          resolve(response.data[0]["records"][0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const flightsByCaseQuery = (case_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getFlightsByCase(
          case_id,
          query.page * query.pageSize,
          query.pageSize
        );
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Flights found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getFlightsByCase = async (case_id, start = 0, qty = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-flights-by-case",
          cid: case_id,
          start,
          qty,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setFlights(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const createFlight = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          flightCtx.flightId = response.data["id"];
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateFlight = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // get-tags-by-flight
  const getTagsByFlight = async (flightID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-tags-by-flight",
          fid: flightID,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    flights,
    flightsByCaseQuery,
    getFlightsByCase,
    flightCtx,
    updateFlightCtx,
    getFlightByID,
    createFlight,
    setFlights,
    updateFlight,
    getTagsByFlight,
  };

  return (
    <FlightContext.Provider value={contextValue}>
      {children}
    </FlightContext.Provider>
  );
};
