import React, { useState, useEffect, useContext, forwardRef } from "react";
import { flushSync } from "react-dom";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";

import { AnalysisContext } from "../../Context/analysisContext";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";

export default function HitsTable(props) {
  const {
    currFrameData,
    framesDataQuery,
    setCurrFrameData,
    setFrameDataImages,
    frameDataCount,
    setFrameDataImagesCount,
    frameDataImagesCount,
  } = useContext(AnalysisContext);

  const [columns, setColumns] = useState([
    // {
    //   title: "Activate",
    //   field: "frameDataId",
    //   editable: "onUpdate",
    //   render: (rowData) => (
    //     <Tooltip
    //       title={
    //         rowData?.frameDataImagesCount === 0
    //           ? "No Frame Data Images available for this frame"
    //           : ""
    //       }
    //     >
    //       <div>
    //         <Button
    //           disabled={
    //             currFrameData?.frameDataId === rowData.frameDataId ||
    //             rowData?.frameDataImagesCount === 0
    //           }
    //           onClick={() => updateFrameDataImages(rowData)}
    //         >
    //           Activate
    //         </Button>
    //       </div>
    //     </Tooltip>
    //   ),
    //   editComponent: (tableData) => (
    //     <>
    //       <Button
    //         disabled={true}
    //         onClick={() => updateFrameDataImages(tableData.rowData)}
    //       >
    //         Activate
    //       </Button>
    //     </>
    //   ),
    // },
    { title: "FrameData ID", field: "frameDataId", editable: "never" },

    { title: "Config ID List", field: "config_id_list", editable: "never" },
    { title: "Number Of Hits", field: "number_of_hits", editable: "never" },
    { title: "Priority", field: "priority", editable: "onUpdate" },
    {
      title: "Adjustments",
      editable: "never",
      field: "rgb",
      render: (rowData) => {
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {rowData.adjustments?.colorRanges
              ? rowData.adjustments?.colorRanges.map(function (object, i) {
                  // console.log("OBJECT", object);
                  // const r = object[0][0];
                  // const g = object[0][1];
                  // const b = object[0][2];
                  // const r2 = object[1][0];
                  // const g2 = object[1][1];
                  // const b2 = object[1][2];

                  const h = object[0][0]; // should internally be stored as the desired degree 0..360
                  const s = object[0][1]; // Should internally be stored as the percent already 0..100
                  const l = object[0][2]; // Should internally be stored as the percent already 0..100
                  const h2 = object[1][0];
                  const s2 = object[1][1];
                  const l2 = object[1][2];

                  var lpcnt = l; // / 2.55;
                  if (lpcnt > 100) lpcnt = 100;
                  var spcnt = s; // / 2.55;
                  if (spcnt > 100) spcnt = 100;
                  var l2pcnt = l2; // / 2.55;
                  if (l2pcnt > 100) l2pcnt = 100;
                  var s2pcnt = s2; // / 2.55;
                  if (s2pcnt > 100) s2pcnt = 100;

                  const isRange =
                    !(h2 == 0 && l2 == 0 && s2 == 0) &&
                    !(h2 == h && l2 == l && s2 == s);

                  // const isRange =
                  //   !(r2 == 0 && g2 == 0 && b2 == 0) &&
                  //   !(r2 == r && g2 == g && b2 == b);

                  // if (isRange)
                  //   return (
                  //     <div
                  //       key={i}
                  //       style={{
                  //         flexDirection: "row",
                  //         display: "flex",
                  //         flexWrap: "wrap",
                  //       }}
                  //     >
                  //       &nbsp; &nbsp;
                  //       <Tooltip
                  //         title={`rgb(${r},${g},${b})  -  rgb(${r2},${g2},${b2})`}
                  //       >
                  //         <div>
                  //           <div
                  //             style={{
                  //               width: "40px",
                  //               height: "40px",
                  //               marginLeft: "auto",
                  //               marginRight: "auto",
                  //               backgroundColor: `rgb(${r},${g},${b})`,
                  //               border: "2px solid #000",
                  //             }}
                  //           ></div>
                  //           <div
                  //             style={{
                  //               width: "40px",
                  //               height: "40px",
                  //               marginLeft: "auto",
                  //               marginRight: "auto",
                  //               backgroundColor: `rgb(${r2},${g2},${b2})`,
                  //               border: "2px solid #000",
                  //             }}
                  //           ></div>
                  //         </div>
                  //       </Tooltip>
                  //     </div>
                  //   );
                  // else
                  //   return (
                  //     <div
                  //       key={i}
                  //       style={{
                  //         display: "flex",
                  //         flexDirection: "row",
                  //       }}
                  //     >
                  //       &nbsp; &nbsp;
                  //       <Tooltip title={`rgb(${r},${g},${b})`}>
                  //         <div
                  //           style={{
                  //             display: "flex",
                  //             flexDirection: "row",
                  //             width: "40px",
                  //             height: "40px",
                  //             marginLeft: "auto",
                  //             marginRight: "auto",
                  //             backgroundColor: `rgb(${r},${g},${b})`,
                  //             border: "2px solid #000",
                  //           }}
                  //         />
                  //       </Tooltip>
                  //     </div>
                  //   );

                  if (isRange)
                    return (
                      <div
                        key={i}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip
                          title={`hsl(${h},${s},${l}) - hsl(${h2},${s2},${l2})`}
                        >
                          <div>
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                                border: "2px solid #000",
                              }}
                            />

                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `hsl(${h2}, ${s2pcnt}%, ${l2pcnt}%)`,
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  else
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip title={`hsl(${h},${s},${l})`}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "40px",
                              height: "40px",
                              marginLeft: "auto",
                              marginRight: "auto",
                              backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                              border: "2px solid #000",
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                })
              : "-"}
          </div>
        );
      },
    },
    {
      title: "Is Archived",
      field: "is_archived",
      editable: "onUpdate",
      render: (rowData) => (rowData.is_archived ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_archived</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_archived}
            label="report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Is Flagged",
      field: "is_flagged",
      editable: "onUpdate",
      render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_flagged}
            label="report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Is Processed",
      field: "is_processed",
      editable: "onUpdate",
      render: (rowData) => (rowData.is_processed ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_processed</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_processed}
            label="report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    { title: "Time", field: "reftime", editable: "never" },
    { title: "Search Type ID", field: "search_type_id", editable: "never" },

    // {
    //   title: "Notes",
    //   field: "notes",
    //   editable: "onUpdate",
    //   editComponent: (tableData) => (
    //     <TextField
    //       value={tableData.rowData.flightDescription}
    //       onChange={(e) => tableData.onChange(e.target.value)}
    //       multiline={true}
    //       maxRows={5}
    //     />
    //   ),
    // },
  ]);
  const [actions, setActions] = useState([
    (rowData) => {
      return {
        icon: ExpandCircleDownIcon,
        tooltip:
          currFrameData?.frameDataId === rowData.frameDataId
            ? "Expanded"
            : "Expand",
        disabled: currFrameData?.frameDataId === rowData.frameDataId,
        onClick: (event, rowData) => {
          if (rowData?.frameDataImagesCount === 0) {
            toast.warn("No Frame Data Images available for this frame");
          } else if (currFrameData?.frameDataId === rowData.frameDataId) {
            toast.warn("Frame data is already expanded");
          } else {
            updateFrameDataImages(rowData);
          }
        },
      };
    },
  ]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = React.createRef();

  function updateFrameDataImages(data) {
    console.log("updateFrameDataImages", data);
    setCurrFrameData(data);
    flushSync(() => {
      setFrameDataImagesCount(0);
      setFrameDataImages([]);
    });
    if (data?.frameDataImagesCount > 0) {
      setFrameDataImagesCount(data.frameDataImagesCount);
    } else {
      toast.warning("No Frame Data images found for first frame data.");
    }
  }

  React.useEffect(() => {
    if (
      frameDataImagesCount === false &&
      currFrameData &&
      currFrameData.frameDataImagesCount > 0
    ) {
      setFrameDataImagesCount(currFrameData.frameDataImagesCount);
    }
    setActions([
      (rowData) => {
        return {
          icon: ExpandCircleDownIcon,
          tooltip:
            currFrameData?.frameDataId === rowData.frameDataId
              ? "Expanded"
              : "Expand",
          disabled: currFrameData?.frameDataId === rowData.frameDataId,
          onClick: (event, rowData) => {
            if (rowData?.frameDataImagesCount === 0) {
              toast.warn("No Frame Data Images available for this frame");
            } else if (currFrameData?.frameDataId === rowData.frameDataId) {
              toast.warn("Frame data is already expanded");
            } else {
              updateFrameDataImages(rowData);
            }
          },
        };
      },
    ]);
    setColumns([
      // {
      //   title: "Activate",
      //   field: "frameDataId",
      //   editable: "onUpdate",
      //   render: (rowData) => (
      //     <Tooltip
      //       title={
      //         rowData?.frameDataImagesCount === 0
      //           ? "No Frame Data Images available for this frame"
      //           : ""
      //       }
      //     >
      //       <div>
      //         <Button
      //           disabled={
      //             currFrameData?.frameDataId === rowData.frameDataId ||
      //             rowData?.frameDataImagesCount === 0
      //           }
      //           onClick={() => updateFrameDataImages(rowData)}
      //         >
      //           Activate
      //         </Button>
      //       </div>
      //     </Tooltip>
      //   ),
      //   editComponent: (tableData) => (
      //     <>
      //       <Button
      //         disabled={true}
      //         onClick={() => updateFrameDataImages(tableData.rowData)}
      //       >
      //         Activate
      //       </Button>
      //     </>
      //   ),
      // },
      { title: "FrameData ID", field: "frameDataId", editable: "never" },
  
      { title: "Config ID List", field: "config_id_list", editable: "never" },
      { title: "Number Of Hits", field: "number_of_hits", editable: "never" },
      { title: "Priority", field: "priority", editable: "onUpdate" },
      {
        title: "Adjustments",
        editable: "never",
        field: "rgb",
        render: (rowData) => {
          return (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {rowData.adjustments?.colorRanges
                ? rowData.adjustments?.colorRanges.map(function (object, i) {
                    // console.log("OBJECT", object);
                    // const r = object[0][0];
                    // const g = object[0][1];
                    // const b = object[0][2];
                    // const r2 = object[1][0];
                    // const g2 = object[1][1];
                    // const b2 = object[1][2];
  
                    const h = object[0][0]; // should internally be stored as the desired degree 0..360
                    const s = object[0][1]; // Should internally be stored as the percent already 0..100
                    const l = object[0][2]; // Should internally be stored as the percent already 0..100
                    const h2 = object[1][0];
                    const s2 = object[1][1];
                    const l2 = object[1][2];
  
                    var lpcnt = l; // / 2.55;
                    if (lpcnt > 100) lpcnt = 100;
                    var spcnt = s; // / 2.55;
                    if (spcnt > 100) spcnt = 100;
                    var l2pcnt = l2; // / 2.55;
                    if (l2pcnt > 100) l2pcnt = 100;
                    var s2pcnt = s2; // / 2.55;
                    if (s2pcnt > 100) s2pcnt = 100;
  
                    const isRange =
                      !(h2 == 0 && l2 == 0 && s2 == 0) &&
                      !(h2 == h && l2 == l && s2 == s);
  
                    // const isRange =
                    //   !(r2 == 0 && g2 == 0 && b2 == 0) &&
                    //   !(r2 == r && g2 == g && b2 == b);
  
                    // if (isRange)
                    //   return (
                    //     <div
                    //       key={i}
                    //       style={{
                    //         flexDirection: "row",
                    //         display: "flex",
                    //         flexWrap: "wrap",
                    //       }}
                    //     >
                    //       &nbsp; &nbsp;
                    //       <Tooltip
                    //         title={`rgb(${r},${g},${b})  -  rgb(${r2},${g2},${b2})`}
                    //       >
                    //         <div>
                    //           <div
                    //             style={{
                    //               width: "40px",
                    //               height: "40px",
                    //               marginLeft: "auto",
                    //               marginRight: "auto",
                    //               backgroundColor: `rgb(${r},${g},${b})`,
                    //               border: "2px solid #000",
                    //             }}
                    //           ></div>
                    //           <div
                    //             style={{
                    //               width: "40px",
                    //               height: "40px",
                    //               marginLeft: "auto",
                    //               marginRight: "auto",
                    //               backgroundColor: `rgb(${r2},${g2},${b2})`,
                    //               border: "2px solid #000",
                    //             }}
                    //           ></div>
                    //         </div>
                    //       </Tooltip>
                    //     </div>
                    //   );
                    // else
                    //   return (
                    //     <div
                    //       key={i}
                    //       style={{
                    //         display: "flex",
                    //         flexDirection: "row",
                    //       }}
                    //     >
                    //       &nbsp; &nbsp;
                    //       <Tooltip title={`rgb(${r},${g},${b})`}>
                    //         <div
                    //           style={{
                    //             display: "flex",
                    //             flexDirection: "row",
                    //             width: "40px",
                    //             height: "40px",
                    //             marginLeft: "auto",
                    //             marginRight: "auto",
                    //             backgroundColor: `rgb(${r},${g},${b})`,
                    //             border: "2px solid #000",
                    //           }}
                    //         />
                    //       </Tooltip>
                    //     </div>
                    //   );
  
                    if (isRange)
                      return (
                        <div
                          key={i}
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          &nbsp; &nbsp;
                          <Tooltip
                            title={`hsl(${h},${s},${l}) - hsl(${h2},${s2},${l2})`}
                          >
                            <div>
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                                  border: "2px solid #000",
                                }}
                              />
  
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  backgroundColor: `hsl(${h2}, ${s2pcnt}%, ${l2pcnt}%)`,
                                  border: "2px solid #000",
                                }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      );
                    else
                      return (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          &nbsp; &nbsp;
                          <Tooltip title={`hsl(${h},${s},${l})`}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                                border: "2px solid #000",
                              }}
                            />
                          </Tooltip>
                        </div>
                      );
                  })
                : "-"}
            </div>
          );
        },
      },
      {
        title: "Is Archived",
        field: "is_archived",
        editable: "onUpdate",
        render: (rowData) => (rowData.is_archived ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_archived</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_archived}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Is Flagged",
        field: "is_flagged",
        editable: "onUpdate",
        render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_flagged}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Is Processed",
        field: "is_processed",
        editable: "onUpdate",
        render: (rowData) => (rowData.is_processed ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_processed</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_processed}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      { title: "Time", field: "reftime", editable: "never" },
      { title: "Search Type ID", field: "search_type_id", editable: "never" },
  
      // {
      //   title: "Notes",
      //   field: "notes",
      //   editable: "onUpdate",
      //   editComponent: (tableData) => (
      //     <TextField
      //       value={tableData.rowData.flightDescription}
      //       onChange={(e) => tableData.onChange(e.target.value)}
      //       multiline={true}
      //       maxRows={5}
      //     />
      //   ),
      // },
    ]);
  }, [currFrameData, frameDataCount]);

  return (
    <>
      <MaterialTable
        data={framesDataQuery}
        tableRef={tableRef}
        columns={columns}
        loading={props?.loading}
        options={{...props?.options, headerStyle: { position: "sticky", top: 0, zIndex: "1", backgroundColor: "white"  }}}
        title={props?.title}
        icons={tableIcons}
        actions={actions}
      />
    </>
  );
}
