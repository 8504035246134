import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DOMPurify from "dompurify"; // For sanitizing HTML

const FAQ = ({ faqs }) => {
  // Function to sanitize and render HTML content
  const renderHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };
  return (
    <Box sx={{ width: "100%", margin: "0 auto", padding: 2 }}>
      <Typography
        variant="h4"
        sx={{ textAlign: "left", marginBottom: 4, fontWeight: "bold" }}
      >
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ marginBottom: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ textAlign: "left" }}>
            {Array.isArray(faq.answer) ? (
                <Typography
                  dangerouslySetInnerHTML={renderHTML(faq.answer.join(" "))}
                  sx={{ marginBottom: 1 }}
                />
            ) : (
              // Render single answer as HTML
              <Typography dangerouslySetInnerHTML={renderHTML(faq.answer)} />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
