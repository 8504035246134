import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import { Box, Typography, Modal, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import validator from "validator";
import { UiContext } from "../Context/uiContext";
import { AnalysisContext } from "../Context/analysisContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function NotificationModal(props) {
  const activeFrameDataImage = props.activeFrameDataImage;
  const { notificationModalOpen, handleNotificationModalClose } =
    useContext(UiContext);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [comment, setComment] = useState("");
  const formRef = React.useRef();
  const { case_id } = useParams();
  const { createSendFrameDataImage } = useContext(AnalysisContext);

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    handleNotificationModalClose();
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      if (!comment.trim()) {
        throw new Error("Comment is mandatory.");
      }
      if (!emails.trim() && !phoneNumbers.trim()) {
        throw new Error("At least one email or phone number is required.");
      }
      if (emails.trim() && !validateEmails(emails)) {
        throw new Error("Invalid email format.");
      }
      if (phoneNumbers.trim() && !validatePhoneNumbers(phoneNumbers)) {
        throw new Error("Invalid phone number format.");
      }
      //   console.log("Emails:", emails);
      //   console.log("Phone Numbers:", phoneNumbers);
      //   console.log("Comment:", comment);
      //   console.log(
      //     "activeFrameDataImage.frameDataImageId:",
      //     activeFrameDataImage.frameDataImageId
      //   );
      //   console.log(
      //     "activeFrameDataImage.frameDataId:",
      //     activeFrameDataImage.frameDataId
      //   );
      //   console.log(
      //     "activeFrameDataImage.frameId:",
      //     activeFrameDataImage.frameId
      //   );

      await createSendFrameDataImage(
        activeFrameDataImage.frameId,
        activeFrameDataImage.frameDataId,
        activeFrameDataImage.frameDataImageId,
        phoneNumbers,
        emails,
        comment,
        "23123"
      );
      setIsLoading(false);
      handleNotificationModalClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
      return;
    }
  };

  const validateEmails = (emails) => {
    const emailArray = emails.split(",");
    return emailArray.every((email) => validator.isEmail(email.trim()));
  };

  const validatePhoneNumbers = (phoneNumbers) => {
    const phoneArray = phoneNumbers.split(",");
    return phoneArray.every((phone) => validator.isMobilePhone(phone.trim()));
  };

  const handleAddPhoneNumber = () => {
    if (validator.isMobilePhone(phoneNumber)) {
      setPhoneNumbers((prev) =>
        prev ? `${prev},${phoneNumber}` : phoneNumber
      );
      setPhoneNumber("");
    } else {
      toast.error("Invalid phone number.");
    }
  };

  React.useEffect(() => {
    return () => {
      handleNotificationModalClose();
    };
  }, []);

  return (
    <Modal
      open={notificationModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <div>
        <Box sx={{ ...style }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Notification Details
          </Typography>
          <form
            id="enroll"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            ref={formRef}
          >
            <TextField
              id="emails"
              name="emails"
              label="Emails (comma separated)"
              placeholder="email1@example.com, email2@example.com"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              fullWidth
              margin="normal"
            ></TextField>

            <Box display="flex" alignItems="center">
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                containerStyle={{ marginRight: 10, flex: 1 }}
              />
              <LoadingButton
                variant="outlined"
                onClick={handleAddPhoneNumber}
                style={{ flexShrink: 0 }}
              >
                Add
              </LoadingButton>
            </Box>
            <TextField
              id="phoneNumbers"
              name="phoneNumbers"
              label="Phone Numbers (comma separated)"
              placeholder="Enter phone numbers"
              value={phoneNumbers}
              onChange={(e) => setPhoneNumbers(e.target.value)}
              fullWidth
              margin="normal"
              multiline
            ></TextField>

            <TextField
              id="comment"
              name="comment"
              label="Comment"
              placeholder="Enter your comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required={true}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            ></TextField>

            <br />
            <br />
            <LoadingButton
              variant="outlined"
              loading={isLoading}
              style={{ marginLeft: 20 }}
              onClick={() => {
                formRef.current.reportValidity() && onSubmit();
              }}
            >
              Send Notification
            </LoadingButton>
            <LoadingButton
              onClick={onClose}
              disabled={isLoading}
              variant="outlined"
              style={{ marginLeft: 20 }}
            >
              Close
            </LoadingButton>
          </form>
        </Box>
      </div>
    </Modal>
  );
}
