import React, { createContext, useState, useContext } from "react";
import { AppContext } from "./appContext";

import api, { API_URL } from "../API";
import { toast } from "react-toastify";
import { UserContext } from "./userContext";
// Create the initial context state
const initialContextState = {
  imageId: undefined,
  filepath: "",
};

// Create the context
export const ReportsAssetsContext = createContext(initialContextState);

// Create a context provider component
export const ReportsAssetsProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const {userCtx} = useContext(UserContext);
  const [reportAssetsCtx, setReportAssetsCtx] = useState(initialContextState);
  const [reportAssets, setReportAssets] = React.useState([]);

  // Function to update the ID in the store
  const updateReportAssetsCtx = async (ctx) => {
    setReportAssetsCtx(ctx);
  };

  const getFieldName = (fieldName) => {
    if (fieldName === "imageId") return "id";
    if (fieldName === "uploadDate") return "reftime";
    else return fieldName;
  };

  const reportAssetsByCaseQuery = (case_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getReportAssetsByCase(
          case_id,
          query.page * query.pageSize,
          query.pageSize,
          query?.orderBy ? getFieldName(query?.orderBy?.field) : "id",
          query.orderDirection
        );
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Images found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getReportAssetsByCase = (
    case_id,
    start,
    qty,
    orderByField = "id",
    orderDirection = "ASC"
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-report-assets-by-case",
          cid: case_id,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          start,
          qty,
          orderByField,
          orderDirection,
          ip: "0.0.0.0",
        });
        const { data: response } = await api.post("", requestBody);

        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setReportAssets(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    reportAssetsByCaseQuery,
    reportAssets,
    setReportAssets,
    reportAssetsCtx,
    updateReportAssetsCtx,
  };

  return (
    <ReportsAssetsContext.Provider value={contextValue}>
      {children}
    </ReportsAssetsContext.Provider>
  );
};
