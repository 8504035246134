import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";

import { CaseContext } from "../Context/caseContext";

import { FootageContext } from "../Context/footageContext";
import { UiContext } from "../Context/uiContext";

import { Box, Button } from "@mui/material";

import FlightFootageTable from "../Flights/FlightFootageTable";
import FileUploadModal from "../Modals/FileUploadModal";
import { MainLayout } from "../Layouts/MainLayout";
import { FlightContext } from "../Context/flightContext";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";

export default function FlightSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { getFootageByFlight, footage } = useContext(FootageContext);
  const { handlefileUploadModalOpen, openInNewTab } = useContext(UiContext);
  const { flightCtx, getFlightByID, flights, setFlights, updateFlight } =
    useContext(FlightContext);
  const { case_id, flight_id } = useParams();
  const [flightFootageTableLoading, setFlightFootageTableLoading] =
    useState(false);
  const [uploadFlightVideo, setUploadFlightVideo] = useState(false);
  const [uploadFlightFootage, setUploadFlightFootage] = useState(false);

  const handleFlightFootageUpload = () => {
    setUploadFlightVideo(false);
    setUploadFlightFootage(true);
    handlefileUploadModalOpen();
  };

  const fetchData = async () => {
    try {
      setFlightFootageTableLoading(true);
      await getCaseByID(case_id);
      await getFlightByID(flight_id);
      await getFootageByFlight(flight_id);
      setFlightFootageTableLoading(false);
    } catch (error) {
      setFlightFootageTableLoading(false);
      toast.error(error.message);
    }
  };

  const fetchFootageByFlight = async () => {
    try {
      setFlightFootageTableLoading(true);
      await getFootageByFlight(flight_id);
      setFlightFootageTableLoading(false);
    } catch (error) {
      setFlightFootageTableLoading(false);
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId && !footage.frameId) {
        fetchData();
      } else {
        fetchFootageByFlight();
      }
    }
  }, []);

  return (
    <MainLayout title={`footage`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            openInNewTab(
              `Cases/${case_id}/Flights/${flight_id}/Footage/upload/footage`
            );
          }}
          sx={{ marginLeft: "auto" }}
        >
          Upload Flight Images
        </Button>
      </Box>
      <FlightFootageTable
        // footage={footage}
        editable={false}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: true,
          selection: false,
          maxBodyHeight: "500px",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
        loading={flightFootageTableLoading}
      ></FlightFootageTable>
      {uploadFlightFootage && (
        <FileUploadModal
          isFootageSelect={true}
          title="Kind of Image/Footage Files:"
        />
      )}
      <Box
        sx={{
          width: "100%",
          padding: "2rem", // Adjust padding for mobile
          marginTop: "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.footage_select_FAQ} />
      </Box>
    </MainLayout>
  );
}
