export default {
    build_color_palette_CASES_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_CASES_FAQ</P>"],
      },
    ],
  
    build_color_palette_CASE_NUMBER_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_CASE_NUMBER_FAQ</P>"],
      },
    ],
  
    build_color_palette_REFERENCE_IMAGES_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_REFERENCE_IMAGES_FAQ</P>"],
      },
    ],
  
    build_color_palette_REFERENCE_IMAGES_UPLOAD_FILE_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_REFERENCE_IMAGES_UPLOAD_FILE_FAQ</P>"],
      },
    ],
  
    build_color_palette_REFERENCE_IMAGE_NUMBER_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_REFERENCE_IMAGE_NUMBER_FAQ</P>"],
      },
    ],
  
    build_color_palette_SEARCH_CRITERIA_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_SEARCH_CRITERIA_FAQ</P>"],
      },
    ],
  
    build_color_palette_FLIGHT_IMAGES_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_FLIGHT_IMAGES_FAQ</P>"],
      },
    ],
  
    build_color_palette_ANALYSES_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_ANALYSES_FAQ</P>"],
      },
    ],
  
    build_color_palette_REPORTS_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_REPORTS_FAQ</P>"],
      },
    ],
  
    build_color_palette_FLIGHTS_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_FLIGHTS_FAQ</P>"],
      },
    ],
  
    build_color_palette_FLIGHT_NUMBER_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["<P>TODO build_color_palette_FLIGHT_NUMBER_FAQ</P>"],
      },
    ],
  
    build_color_palette_RGB_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Create a <b>search criteria</b> based on the red-green-blue (RGB) pixel color palette.  (FYI - we advise using the HSL option.)</P>",
          "You will first prepare your color selections, check the keepers, then when ready, you will 'Submit' them.<br/>",
          "If you have selected the <b>'Color-RGB' palette icon</b> from your reference images, you can now select a snippet from that image,",
          "then 'Crop Image' to grab the most frequent color ranges that you likely want in your search.<br/>",
          "Select a small area and know it will take a few seconds to compute.  Check only the colors or color ranges you want to keep.<br/>",
          "No matter how you got here, you can also:<ul>",
          "<li>'Pick Color' to pick an individual color from anywhere on the screen.</li>",
          "<li>'Add to Palette' makes your picked color part of the list of choices to save.  Again, you must then mark the checkbox for saving as part of your search criteria.</li>",
          "<li>'Add Custom Color' lets you easily specify an individual color</li>",
          "<li>'Add Color Range' forces you through one extra 'Add Custom Color' button,</li></ul>",
          "but then lets you select a specific color, by RGB, and identify a range, e.g., +/- 10, around that color.",
        ],
      },
      {
        question: "How do I get colors from my image?",
        answer: [
          "<P>Move or resize the 'crop box' over your image.  Then click the 'Crop Image' button at the top center of your image to grab the most frequent color ranges that you likely want in your search.</P>",
          "Select a small area and know it will take a few seconds to compute.  Check only the colors you want to keep.<br/>",
        ]
      },
      {
        question: "Why is the crop box so small (or large)?",
        answer: [
          "<P>The crop box upper limit size represents about 500 x 500 = 250,000 pixels.<br/><ul>",
          "<li>This prevents excessive data to the API call which exceeds data limits and causes network errors.</li>",
          "<li>This prevents heavy backend processing from exceeding API network time limits.</li></ul></P>",
          "<P>The crop box lower limit size represents about 3 x 3 = 9 pixels.<br/><ul>",
          "<li>This prevents backend processing edge cases from too few pixels.</li></ul>",
          "There are other mechanisms to pick single pixel colors.</P>",
        ],
      },
      {
        question: "Why am I getting an error on crop image?",
        answer:
          "When you zoom in too far and make the cropping area too small, then you send too few cells (think 'pixels') to be processed.",
      },
      {
        question: "How do I pick a single color?",
        answer: [
          "Click on the 'Pick Color' button.  Then hover over anywhere on the screen and click for the pixel of interest.",
          "You will be shown the RGB pixel value, the hex version of the RGB pixel value, and the HSL value.",
          "Please note: there is a shadow over most of the upper left image; selecting a pixel there will be far darker than your actual object.",
          "Each time you pick and click, the sample color will show up.  When you have decided you have the color of interest, then click on 'Add To Palette'.",
          "Repeat this process as often as desired."
        ]
      },
      {
        question: "How do I add a custom color?",
        answer:
          "You can add a custom color by clicking the 'Add Custom Color' button and selecting your desired color.",
      },
      {
        question: "How do I add a custom color range?",
        answer: [
          "You can add a custom color range by clicking the 'Add Color Range' button, then the 'Add Custom Color'",
          "button on the next screen and then selecting your desired color as the 'center' of your range and click the checkmark.<br/>",
          "Then select a range, e.g., +/- 10, around that color. and hit 'Submit'"
        ]
      },
      {
        question: "Can I edit or delete colors?",
        answer:
          "Yes, you can edit or delete colors by clicking the respective icons, pencil or trash can, next to each color (range) in the palette."
      },
    ],
  
    build_color_palette_HSL_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Create a <b>search criteria</b> based on the hue-saturation-lightness (HSL) color palette.  (FYI - we advise using this option.)</P>",
          "You will first prepare your color selections, check the keepers, then when ready, you will 'Submit' them.<br/>",
          "If you have selected the <b>'Color-HSL' palette icon</b> from your reference images, you can now select a snippet from that image,",
          "then 'Crop Image' to grab the most frequent color ranges that you likely want in your search.<br/>",
          "Select a small area and know it will take a few seconds to compute.  Check only the colors you want to keep.<br/>",
          "No matter how you got here, you can also:<ul>",
          "<li>'Pick Color' to pick an individual color from anywhere on the screen.  This let's you explore the colors in the image.</li>",
          "<li>'Add to Palette' makes your picked color part of the list of choices to save.  Again, you must then mark the checkbox for saving as part of your search criteria.</li>",
          "<li>'Add Custom Color' lets you easily specify an individual color</li>",
          "<li>'Add Color Range' forces you through one extra 'Add Custom Color' button,</li></ul>",
          "but then lets you select a specific color, by HSL, and identify a range, e.g., +/- 10, around that color.",
        ],
      },
      {
        question: "How do I get colors from my image?",
        answer: [
          "<P>Move or resize the 'crop box' over your image.  Then click the 'Crop Image' button at the top center of your image to grab the most frequent color ranges that you likely want in your search.</P>",
          "Select a small area and know it will take a few seconds to compute.  Check only the colors you want to keep.<br/>",
        ]
      },
      {
        question: "Why is the crop box so small (or large)?",
        answer: [
          "<P>The crop box upper limit size represents about 500 x 500 = 250,000 pixels.<br/><ul>",
          "<li>This prevents excessive data to the API call which exceeds data limits and causes network errors.</li>",
          "<li>This prevents heavy backend processing from exceeding API network time limits.</li></ul></P>",
          "<P>The crop box lower limit size represents about 3 x 3 = 9 pixels.<br/><ul>",
          "<li>This prevents backend processing edge cases from too few pixels.</li></ul>",
          "There are other mechanisms to pick single pixel colors.</P>",
        ],
      },
      {
        question: "Why am I getting an error on crop image?",
        answer:
          "When you zoom in too far and make the cropping area too small, then you send too few cells (think 'pixels') to be processed.",
      },
      {
        question: "Why do my color ranges appear to go from black to white?",
        answer: [
          "Don't worry, your hue is still the 'core driver' for the search.  The apparent black to white is affected by the 'L' part of HSL, the lightness scale",
          "Imagine your hue is, green.  Then essentially, what is happening is that the search will be for a very dark green (maybe hidden in the shadows) to a",
          "very brightly light green (maybe in bright sunlight).  The 'S' part of HSL, the saturation scale will also play a part when searching."
        ],
      },
      {
        question: "What is the hue-saturation-lightness (HSL) Color Model?",
        answer: [ "<P>The HSL color model is one of the most common cylindrical-coordinate representations of points.  It rearranges the geometry of RGB in an",
          " attempt to be more intuitive and perceptually relevant than the cartesian cube.  The HSL color model is closer to how human vision is conceptualized.</P>",
          "<img src='https://find911-data.s3.amazonaws.com/assets/HSLColorWheel.png' title='HSL Color Wheel' />"
        ],
      },
      {
        question: "Why do I need color ranges?",
        answer: [
          "<P>The color of an object appears different under different lighting conditions because of the way light is reflected and absorbed by the object. This phenomenon is called metamerism.</P>",
          "Factors that affect 'color perception' of an object are: light source, object properties, brightness, light color, and reflected colors of adjacent objects.",
          "<P>We suggest starting with a google search for 'color under different lighting conditions' to get started understanding the challenges and complexity of the issue.</P>"
        ]
      },
      {
        question: "Are there any prebuilt search criteria HSL color ranges?",
        answer: ["Yes.  They are organized as the <b>center color</b> and wedge angle on the color wheel.  The below shows the start and end degrees on the HSL color wheel:<ul>",
          "<li>Reds: (Hue: 330-30) and WIDE Reds: (Hue: 300-60)</li>",
          "<li>Oranges: (Hue: 0-60)</li>",
          "<li>Yellows: (Hue: 30-90) and WIDE Yellows: (Hue: 0-120)</li>",
          "<li>Yellow-Greens: (Hue: 60-120)</li>",
          "<li>Greens: (Hue: 90-150) and WIDE Greens: (Hue: 60-180)</li>",
          "<li>Green-Cyans: (Hue: 120-180)</li>",
          "<li>Cyans: (Hue: 150-210) and WIDE Cyans: (Hue: 120-240)</li>",
          "<li>Cyan-Blues: (Hue: 180-240)</li>",
          "<li>Blues: (Hue: 210-270) and WIDE Blues: (Hue: 180-300)</li>",
          "<li>Blue-Magentas: (Hue: 240-300)</li>",
          "<li>Megentas: (Hue: 270-330) and WIDE Megentas: (Hue: 240-360)</li>",
          "<li>Magenta-Reds: (Hue: 300-360)</li>",
          "</ul>For the moment, these are grouped as anomaly detectors... and really need to be moved to a more logical place.",
          "<img src='https://find911-data.s3.amazonaws.com/assets/HSLColorWheel.png' title='HSL Color Wheel' />"
        ],
      },
      {
        question: "How do I add a custom color?",
        answer:
          "You can add a custom color by clicking the 'Add Custom Color' button and selecting your desired color."
      },
      {
        question: "How do I add a custom color range?",
        answer: [
          "You can add a custom color range by clicking the 'Add Color Range' button, then the 'Add Custom Color'",
          "button on the next screen and then selecting your desired color as the 'center' of your range and click the checkmark.<br/>",
          "Then select a range, e.g., +/- 10, around that color. and hit 'Submit'",
          "<P>You may notice one or more non-editable color bases in your list.  These come from the low value of the checked items on the prior page.",
          "If you want an entirely new color range, then click the 'Add Custom Color' button.</P>"
        ],
      },
      {
        question: "Can I edit or delete colors?",
        answer:
          "Yes, you can edit or delete colors by clicking the respective icons, pencil or trash can, next to each color (range) in the palette."
      },
    ],
  
    anomaly_palette_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Anomaly Palette lets you select a basic type of anomaly detector and then tailor it to your needs</P>",
          "There are the following anomaly types:<ul>",
          "<li>Hot & Cold Spot Detectors for Radiometric Data</li>",
          "<li>Separate Hue, Saturation, and Lightness anomaly detectors</li>",
          "<li>Smoke detector - which needs a final review</li></ul>",
          "After selecting a detector, there may be additional parameters that can be controlled (edited) on the right.",
          "The most common parameters are:<ul>",
          "<li>sigmaCoeff - how abnormal of a value, compared to the image as a whole, are you expecting.  Review the 'normal distribution curve' for more information.  We have found it surprising that sigma > 5 and even 10 is often appropriate, especially when refining searches.</li>",
          "<li>diffSize and sumSize refer to the box size around a pixel when looking for anomalies.  Such anomaly detectors compare the group of pixels relative to those around them.  The values  for these are normally pretty small with upper limits.</li>",
          "",
        ],
      },
      {
        question: "Can I edit the parameters?",
        answer:
          "Yes, you can edit the parameters by clicking the pencil icon next to the parameter set.  Later, click the 'check' to save or 'X' to revert."
      },
      {
        question: "Can I add more parameters?",
        answer:
          "No.  But if you have any great ideas, please email them to rboyer@cloud303.io"
      },
    ],
  
    detect_faces_palette_FAQ: [
      {
        question: "What is the purpose / overview for this page? ",
        answer: [
          "<P>Detect Faces allows you to highlight a region of a picture and 'Find Faces' in that region.  Then you can check the ones you want to keep.</P>",
        ],
      },
    ],
  
    detect_objects_palette_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Detect Object Palette lets you select from a list of objects that Amazon Rekognition has been trained to find.</P>",
        ],
      },
    ],
  
    detect_radio_palette_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Create a <b>search criteria</b> based on the radiometric data</P>",
          "You will first prepare your radiometric value selections, check the keepers, then when ready, you will 'Submit' them.<br/>",
          "If you have selected the <b>'Radiometric' palette icon</b> from your reference images, you can now select a snippet from that image,",
          "then 'Crop Image' to grab the most frequent value ranges that you likely want in your search.<br/>",
          "Select a small area and know it will take a few seconds to compute.  Then check only the value ranges you want to keep.<br/>",
          "No matter how you got here, you can also:<ul>",
          "<li>'Add Custom Temperature' which provides a range</li></ul>",
        ],
      },
      {
        question: "How do I get temperature ranges from my image?",
        answer: [
          "<P>Move or resize the 'crop box' over your image.  Then click the 'Crop Image' button at the top center of your image to grab the most ",
          "frequent temperature ranges that you likely want in your search.</P>",
          "Select a small area and know it will take a few seconds to compute.  Check only the colors you want to keep.<br/>",
          "<P>When you upload your image, you select the temperature conversion function.  The most common are from FLIR as follows:<br/>",
          "<img src='https://find911-data.s3.amazonaws.com/assets/FLIR Low vs High Resolution.png' title='FLIR Low/High Resolution Info' />",
        ],
      },
      {
        question: "Why is the crop box so small (or large)?",
        answer: [
          "<P>The crop box upper limit size represents about 500 x 500 = 250,000 pixels.<br/><ul>",
          "<li>This prevents excessive data to the API call which exceeds data limits and causes network errors.</li>",
          "<li>This prevents heavy backend processing from exceeding API network time limits.</li></ul></P>",
          "<P>The crop box lower limit size represents about 3 x 3 = 9 pixels.<br/><ul>",
          "<li>This prevents backend processing edge cases from too few pixels.</li></ul></P>",
        ],
      },
      {
        question: "How do I pick a single temperature?",
        answer: [
          "While that seems inadvisable, you could set the temperature range upper and lower values to be the same.",
        ]
      },
      {
        question: "How do I add a custom temperature range?",
        answer: [
          "You can add a custom color range by clicking the 'Add Custom Temperature' button then enter low and high values",
        ]
      },
      {
        question: "Can I edit or delete temperature ranges?",
        answer:
          "Yes, you can edit or delete temperatures by clicking the respective icons, pencil or trash can, next to each temperature range in the palette."
      },
      {
        question: "Why am I getting an error on crop image?",
        answer:
          "When you zoom in too far and make the cropping area too small, then you send too few cells (think 'pixels') to be processed.",
      },
    ],
  
    case_view_FAQ: [
      {
        question: "How do I use this system?",
        answer: [
          "<P>The system attempts to mirror the search and rescue process, going through the 5 stages as shown.  Usually, you complete work in a prior step",
          "to be ready for the next step.<br/>Example 1. From reference images, you click an icon to get key colors from those images to create a search config.<br/>",
          "Example 2. From flight images you select the images you want to analyze (all by default) and the associated search criteria to initiate the analysis,",
          "but then go to 'Analyses' to see the results.</P>",
          "<P>Each of the steps is represented in an <b>accordion</b>view.  Click on the 'row' or title of interest to expand the section or else click on",
          "the 'down arrow' on the right edge of any given row.  You will, in many cases and as you are first learning the system, want to click on the 'Go To Page' button for greater detail / work.</P>",
          "<P>Throughout the system, the accordion view style is used.  They will expand or contract just as you observe on this page.  Think of the expansion",
          "as a preview or quick view of the data without having to first go to the full page.</P>",
          "<P>Associated with most accordions are <b>pagination</b> controls, on the bottom right of the associated table.  You can view 10, 25, 50, or 100 items at a time.",
          "The controls show you which items you are looking at with the abilty to go forward and backward pages or to the ends.</P>"
        ]
      },
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "Search is broken down into 4 stages, plus 2 for reporting.  <b>Each stage leads to the next.</b><ol>",
          "<li>[PREP] Reference Images / Evidence: Load reference images.  Upload last known photos, clothing, etc.  These are then used to create 'color palettes' for searching.",
          "Currently additional evidence, such as manual photos of evidence found in the field are uploaded here.</li>",
          "<li>[PREP] Search Configurations: View / create additional search criteria.  This is nominally 'prep' work in that it can occur prior to active searching in the field.",
          "Image based color palettes are launched from the images in step 1.  Additional search criteria for colors-from-scratch, faces, and anomalies",
          "can be created here.  Already created on your behalf are common search critera for:<ul><li>Key color ranges in the HSL color wheel</li>",
          "<li>Hot Spot / Cold Spot anomalies (for thermal or radiometric)</li>",
          "<li>Anomalies based on differences in Hue, Saturation, or Lightness</li></ul>",
          "Nominally, both of these first two 'prep' steps could be skipped.</li>",
          "<li>Flight Images: Upload surveillance images, e.g., drone images, and then initiate analyses on all or some of the images.",
          "The <b>analysis starts on this page</b> because the system has to know what you are analyzing, and that starts with the surveillance images.</li>",
          "<li>Analyses Results: Review the results of analyses initiated from flight images. The 'quick view' lets your rapidly review images, mark images for reporting,",
          "send notifications to field support, and review detailed analysis breakdown of individual images.</li>",
          "<li>[PREP]Report Assets: Upload logos and supplementary PDF files for inclusion into the final report.</li>",
          "<li>Reports: View / create reports.  These are queued for processing and creating time depends on how much info will go into the report.",
          "After clicking the create report, you will see a new line for the report, but no PDF icon (N/A instead).  The case owner will receive an email when completed.",
          "If you are not the case owner, check back in a few minutes, refresh the page, and look for the PDF icon.  Click on the icon to view the report.</li>",
        ],
      },
      {
        question: "What analyses can be performed?",
        answer: [
          "<P>The analyses that can be performed are dependent upon the image type as shown in the table below:</P>",
          "<img src='https://find911-data.s3.amazonaws.com/assets/Anomaly Detection vs Image Type Matrix.png' title='Anomaly Detection vs Image Type Matrix' />",
        ],
      },
    ],

    case_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["Cases created by you show up in this list.",
          "In a future update to the system, all cases of which you are a part will be shown here."
        ],
      },
      {
        question: "How do I create a new case?",
        answer: ["Toward the top right of the screen, there is a button to 'Create New Case', click it and fill in the appropriate details." ],
      },
      {
        question: "Why am I not seeing my cases?",
        answer: ["Every now and again, usually when you've been away from the computer for awhile, you will try to refresh a page before the system recognizes you.",
          "In these situations, your cases or other information may not show.  Take a beat.  Refresh the window, e.g., <ctrl>R, or hit return in the address",
          "bar, then wait for your user to be recognized again.  You will see a notice slide in from the top right with your name.  Then everything should",
          "be back to normal."
        ],
      },
      {
        question: "Why does my URL say .../cases/undefined/...?",
        answer: ["Please see the above question of 'Why am I not seeing my cases?'" ],
      },
    ],
  
    case_create_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["A case contains all information, images, analyses, etc. for a search effort.  Thus, naming and associated details should follow",
          "a pattern or protocol for your agency.  Please complete all fields (description is optional) to start up a new case.<br/>",
          "When analyses are completed the case owner / creator will receive messages.<br/>",
          "Click 'Submit' to save."
        ],
      },
    ],
  
    images_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>Images Select serves two purposes.  First, use it to upload <b>reference images</b> of objects or people to be used for creating search criteria.",
          "Second, a placeholder for the future, upload <b>evidence images</b> that might go into a final report.  Still a work in progress.</P>",
          "In addition to viewing or editing details about the image, there are three other actions:<ul>",
          "<li>Click the left palette icon (mouse over shows it is <b>Color-RGB</b>) to use parts of that image as the color basis for searching.</li>",
          "<li>Click the right palette icon (mouse over shows it is <b>Color-HSL</b>) to use parts of that image as the color basis for searching.</li>",
          "<li>Click the sun &amp; mountains icon (mouse over shows it is <b>Radiometric</b>) to use parts of that image as the radiometric basis for searching.</li>",
          "<li>Click on the person icon when planning on searching using facial recognition.</li>",
          "<li>Click on the ?? icon when planning on searching using radiometric data.</li>"
        ]
      },
      {
        question: "How do I upload images to this page?",
        answer: [
          "<P>Click on the blue button in the upper right portion of the screen, labeled 'Upload Files'.</P>You will then be presented with a modal dialog where you:<ul>",
          "<li>Identify the purpose of the image, either as a source (aka reference) image or as an image to be used in a report as evidence.</li>",
          "<li>Identify the kind of image.  The image suffix, e.g., .jpg, can be ambiguous as to what type of processing is appropriate for the image.</li>",
          "<li>Drag and drop files into the center box.  Multiple files may be provided.  A list of all files is shown in a list, immediately above the box.</li></ul>",
          "Upon clicking confirm, another display will show the files being uploaded and provide a status of the upload.  Please note, sometimes internet glitches occur and the",
          "file may show 'uploading' when in fact it has already uploaded into the system.  Check your images before trying to upload it again, as it is likely already there."
        ]
      },
      {
        question: "Should I use the RGB or HSL color model?",
        answer: [
          "<P>We recommend the <b>HSL</b> for Hue-Saturation-Lightness.  Color is a really tricky thing, especially due to lighting conditions and reflected colors.</P>",
          "The HSL color wheel <a href='https://find911-data.s3.amazonaws.com/assets/HSLColorWheel.png'>HSL Color Wheel</a> makes it easier to",
          "focus on the core color (hue) while also accounting for overcast or bright sunlight.  This cannot account for reflected light, i.e., due to surrounding materials",
          "<br/>Note: when editing custom colors in HSL, the maximum hue may be smaller than the minimum.  This occurs when your range is wrapping around the circle.",
          "Read this as: starting at the minimum value, go around the circle counter-clockwise until you reach the maximum value.  That wedge of hues is what is to be searched.",
          "<br/><img src='https://find911-data.s3.amazonaws.com/assets/HSLColorWheel.png' title='HSL Color Wheel' />",
        ],
      },
      {
        question: "What is the difference between Thermal (Grayscale) and Radiometric images?",
        answer: [
          "<P>Thermal images are basic grayscale or sometimes color (RGB) whereas radiometric images store effective temperature values</P>",
          "<P>Thermal images, typically .jpg, may come in many color palettes, intended to highlight certain features or contrasts. However, for processing purposes, PLEASE use the",
          "'white hot' color palette (in grayscale).  Please do not use other color palettes as camera specific software is required to return them to a form we can calculate against.</P>",
          "<P>Radiometric images, typically in .tif (or.tiff) hold a digital 'temperature' value, where the temperature is actually a composition of reflectivity and emissivity.</P>",
          "The hot spot and cold spot anomaly detectors will work on either white-hot thermal grayscale or radiometric images."
        ],
      },
    ],
  
    images_view_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "The images view allows you to view a reference or evidence image in greater detail.  You may also click the 'pencil icon' edit action to change its descrption or other report data."
        ]
      },
    ],
  
    report_assets_view_FAQ: [
      {
        question: "TODO report_assets_view_FAQ",
        asnwer: ["TODO report_assets_view_FAQ"],
      }
    ],
  
    report_assets_select_FAQ: [
      {
        question: "What are report assets and how do I use them?",
        asnwer: ["<P>Report Assets are items that will go into your generated report.  There are two types:<ul>",
          "<li>Logos or images of your collaborators (if any) that will be placed on the title page of the report, under the heading, 'Collaborators'</li>",
          "<li>PDF documents to append to your report, such as human reporting of the search or 'About Us' documentation to go into the report</li></ul>",
          "These get uploaded Like the reference images.  And, like the reference images, these can be marked as going in the report and the ordering of",
          "these items can also be specified.  Naturally, the logo ordering is isolated from the PDF file ordering.</P>",
        ],
      }
    ],
  
    searchconfig_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>This Search Configs page shows the various configurations that you have prepared (or that the system has autogenerated) for your searches.</P>",
          "You may edit or delete any search configuration using the pencil or trash can icon, respectively.<br/>",
          "In the upper right area of the screen, you can use the 'Create New' dropdown to create additional",
          "search configurations.  These include:<ul>",
          "<li>Jumping back to the reference images page and creating a color palette, radiometric, or face from one of your reference images.</li>",
          "<li>Creating either an RGB or HSL color palette from scratch - where you specify the custom color(s) and buffer ranges.</li>",
          "<li>Creating a radiometric palette from scratch - where you specify the custom ranges.</li>",
          "<li>Creating list of objects (from a preset list) of items you might hope to find.</li>",
          "<li>Creating an anomaly detector.  Many of these were already autogenerated for you due to high usage.</li></ul>",
          "<br/>P.S. Robert played a little loose with the terms.  If you see 'color_hsv' it is really 'color_hsl'.",
        ]
      },
      {
        question: "What are these default color detectors?",
        answer: ["Yes.  They are organized as the <b>center color</b> and wedge angle on the color wheel.<br/>",
          "The more specific you can be with a color, e.g., Cyan-Blue vs a simple Blue, the better.",
          "However, due to our language choices and the lighting effects, sometimes you may just need the 'WIDE <color>' choice<br/>",
          "The below shows the start and end degrees on the HSL color wheel:<ul>",
          "<li>Reds: (Hue: 330-30) and WIDE Reds: (Hue: 300-60)</li>",
          "<li>Oranges: (Hue: 0-60)</li>",
          "<li>Yellows: (Hue: 30-90) and WIDE Yellows: (Hue: 0-120)</li>",
          "<li>Yellow-Greens: (Hue: 60-120)</li>",
          "<li>Greens: (Hue: 90-150) and WIDE Greens: (Hue: 60-180)</li>",
          "<li>Green-Cyans: (Hue: 120-180)</li>",
          "<li>Cyans: (Hue: 150-210) and WIDE Cyans: (Hue: 120-240)</li>",
          "<li>Cyan-Blues: (Hue: 180-240)</li>",
          "<li>Blues: (Hue: 210-270) and WIDE Blues: (Hue: 180-300)</li>",
          "<li>Blue-Magentas: (Hue: 240-300)</li>",
          "<li>Megentas: (Hue: 270-330) and WIDE Megentas: (Hue: 240-360)</li>",
          "<li>Magenta-Reds: (Hue: 300-360)</li>",
          "</ul>For the moment, these are grouped as anomaly detectors... and really need to be moved to a more logical place.",
          "<img src='https://find911-data.s3.amazonaws.com/assets/HSLColorWheel.png' title='HSL Color Wheel' />"
        ],
      },
      {
        question: "What are these other anomaly detectors?",
        answer: [
          "<P>The anomaly detectors that are prebuilt are:<ul>",
          "<li>Color Based: hue, lightness, saturation differences are computed relative to the adjacent pixels and those whose differences are the",
          "greatest (exceed the specified sigma) will be identified.</li>",
          "<li>Hot Spot and Cold Spot Detectors work on are designed for thermal grayscale (aka white-hot) or radiometric thermal images.</li></ul>",
          "If you get too many hits, increase the sigma parameter.  If there are too few hits, then decrease sigma.  See the chart below.<br/>",
          "<img src='https://find911-data.s3.amazonaws.com/assets/BellCurve.jpg' title='Bell Curve' />"
        ]
      },
    ],
  
    flights_view_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>For each separate surveillance sortie, aerial or ground drone, you will have created a <b>flight</b> in the system.  This page provides a gateway to each.</P><ul>",
          "<li>The 'eye icon' lets you go look at the flight in detail, which will then let you upload footage and examine or analyse the entire flight or individual images.</li>",
          "<li>The 'bar chart icon' lets you launch an analysis on the entire flight from this page, without having to drill down to the next level.</li></ul>",
        ]
      },
      {
        question: "How do I upload my flight images?",
        answer: [
          "Click the button to 'Upload Flight Images'.  Then identify the kind of images taken, because the extension alone does not provide enough information to know what processing to perform.",
          "And use the file system to drag-and-drop your flight images into the waiting box",
        ],
      },
      {
        question: "How do I upload my flight videos?",
        answer: [
          "Click the button to 'Upload Flight Videos'.  Then identify the kind of images taken, because the extension alone does not provide enough information to know what processing to perform.",
          "And use the file system to drag-and-drop your flight videos into the waiting box.  Please note that for distributed processing purposes, all videos are broken down into their constituent frames.",
          "<P>Videos are generally a bad choice today, primarily because there is not enough metadata to know the location of the drone for the image... and the drone metadata is often not available.",
          "If you have such data, please email: rboyer@cloud303.io to work through this workflow with you.",
          "Currently, to optimize processing, all video frames are extracted to become 'footage' to optimize data processing.",
          "</P>"
        ],
      },
      {
        question: "How do I re-upload an image?",
        answer: [
          "Click the icon that says 'Image Not Available' where you should have seen your image.  An upload area will be presented and the name of the missing file will be show.",
          "Then identify the kind of images taken, as before.  Use the file system to drag-and-drop your specific image to re-upload into the waiting box.  Please make sure it is 'the same' image.",
        ],
      },
      {
        question: "What are the 'Sync EXIF' buttons?",
        answer:
          "Click the 'Sync EXIF' button, to extract the image information, such as latitude and longitude that live in the metadata of the images and have those values automatically entered into the system to accompany each image."
      },
      {
        question: "What is the 'Upload Geo Data' button?",
        answer:
          "Click the 'Upload Geo Data' button, to extract the drone's flight information, such as heading, roll, and pitch to have those values automatically entered into the system to accompany each image.  Not all drone formats are supported."
      },
      {
        question: "How do I analyze a full flight or individual frames?",
        answer: [
          "By default, clicking on the 'Analyze' button will cause all frames in the flight footage (images) to be analyzed.<br/>",
          "If you need to analyze a single or a few frames, you can check the box for the ones of interest before clicking on the 'Analyze' button.",
          "<P>After clicking the 'Analyze' button, you will then select the search criteria (created earlier) for how to process the frames (images).</P>"
        ]
      },
      {
        question: "What is the analyze input 'tag on match' purpose?",
        answer: [
          "When running an analysis, enter a term representative of the search in the <b>tag on match</b> field.  Later, use the <b>filters</b> dropdown at the top to identify only the frames with had hits on a prior search analysis,",
          "Where that search is identified by the 'tag on match'.  Multiple different analyses <b>are allowed</b> to use the same 'tag on match'."
        ]
      },
      {
        question: "What is the analyze search filter?",
        answer: [
          "The search <b>filters</b> dropdown at the top allows narrowing an analysis based on hits occurring in prior search analyses.  Selecting no prior tag is equivalent to no filter.  Selecting multiple tags will cause an",
          "analysis to only search frames which had hits for all of the selected tags."
        ]
      },
      {
        question: "Can I edit flight details?",
        answer:
          "Yes, you can edit flight details by clicking on the 'pencil icon' to edit."
      },
    ],
  
    flights_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["A 'Flight', within a 'Case, contains all footage taken from a given surveillance episode, whether drone or ground robot.  The list",
          "presented on this page allow you to create or select a flight of interest and, once there, upload flight images or initiate an analysis of the entire flight or select images."
        ],
      },
      {
        question: "I just completed a physical flight, now what?",
        answer: [
          "Click the 'Create Flight' button in the upper right of the screen;  Fill in the details according to the pattern or protocol for your agency.<br/>",
          "After creating the flight in the system:<ol>",
          "<li>Click the 'eye icon' to go into the newly created flight.</li>",
          "<li>Upload your images to the flight.  You will be required to indicate the type of images so the system will know which type of processing is appropriate.</li>",
          "<li>Click the 'Sync EXIF' data button, to extract the image information, such as latitude and longitude, and enter those into the system to accompany each image.</li>",
          "<li>If you also pulled the drone flight path (in a form the system understands), you can also sync that, so that the drone heading, pitch, and roll for each image are known</li>",
        ]
      },
    ],
  
    flights_create_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["After you complete a physical flight or other ground surveillance, you will create a 'flight' as a place to store the related images and initiate later analysis.",
          "Always fill in the details according to the pattern or protocol for your agency.<ul>",
          "<li>Flight Name: might include abbreviated info on drone type, typical altitude AGL, camera or just flight # for the case,</li>",
          "<li>Pilot Name: typically the full name of the person,</li>",
          "<li>Description: might include general area description, details of equipment used, etc.</li></ul>",
          "The information you save here is 'only' to assist you in remembering your actions and later generating reports."
        ],
      },
    ],
  
    view_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["The primary purpose of this page is to view and edit an image taken during a surveillance flight.  If you want to edit the image, then click on the 'eye icon'."
        ],
      },
    ],

    footage_view_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["This pages provides a close up view of original footage and the specifications.  A mouse scroll-wheel will allow you to zoom-in/out."
        ],
      },
    ],
  
    footage_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["The primary purpose of this page is to upload and view images taken during a surveillance flight.  If you want to edit or zoom in on the image,",
          "then click on the 'eye icon'."
        ],
      },
    ],
  
    report_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: ["Click on the 'Create Report' button on the upper right side of the screen and give the report a name and basic description.",
          "Upon clicking 'submit' this is queued for processing and creation time depends on how much info will go into the report.",
          "You will see a new line for the report, but no PDF icon (N/A instead).  The case owner will receive an email when the report is completed.",
          "If you are not the case owner, check back in a few minutes, refresh the page, and look for the PDF icon.  Click on the icon to view the report."
        ],
      },
    ],
  
    analysis_results_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [ "<P>This Analysis result page shows you the details of an analyses.  There are potentially many things being shown on this page, so look to additional FAQ questions for answers.</P>",
          "At the top is a reminder about which analysis is being reviewed.  Your agency should have a pattern or protocol for naming to make it easy to remember critical overview / analysis purpose.<ul>",
          "<li>The <b>Frames</b> table is the first table which displays each of the images that was selected for the analysis.  See below for FAQ about the 'Frames Table'</li>",
          "<li>The <b>Frame Data</b> table follows to show details about analyses of the frame selected in the frame table.  See below for FAQ about the 'Frame Data Table'</li>",
          "<li>The <b>Frame Data Images</b> filmstrip (image slider) follows to show images for a given frame's analysis data.  See below for FAQ about the 'Frame Data Images filmstrip'</li>",
          "<li>The <b>Compare Images</b> link button provides a way to simultaneously see the primary image and any markup of that image, thus allowing you to see the raw data below the markups.</li>",
          "<li>The <b>Frame Data Images</b> table then provides image details for the given frame's analysis data.  See below for FAQ about the 'Frame Data Images table'</li></ul>"
        ]
      },
      {
        question: "How do I use the Frames table?",
        answer: [ "<P>The Frames table shows each frame of an analysis.  Like all tables, it may be paginated and can be sorted by columns.  It also contains a few extra actions and icons.</P><ul>",
          "<li>Clicking on the 'i icon' adjacent to the <b>Frames</b> table title, will provide a reminder of which configurations were used in the analysis</li>",
          "<li>Clicking on the 'i icon' on the upper right edge of the table, will provide a status of the analysis processing.  When you <b>'start'</b> an analysis, what you have",
          "actually done is to <b>'queue up'</b> the analysis.  Clicking the 'i icon' will retrieve processing status, any errors experienced, and an estimated time remaining.",
          "Should you find #Attempted < #Done, it means an early processing error occured.  Please report this error, indicating Case # and Analysis#</li>",
          "<li>Clicking on the 'looping arrow icon' near the upper right edge of the table will refresh the page.  This is usually used after discovering analysis processing is complete.</li>",
          "<li>Clicking on either image, (thumbnail of the original frame image or thumbnail of the result markup will bring up the Frame Quick View overlay.  See below for FAQ about the 'Frame Quick View overlay</li>",
          "<li>Clicking on the row, or the 'down arrow icon' refreshes the frame data table with the analysis information of the given frame.</li></ul>",
        ]
      },
      {
        question: "How do I use the Frames Quick View?",
        answer: [ "<P>The Frames Quick View shows you the details of an analyses.  There are potentially many things being shown on this page, so look to additional FAQ questions for answers.</P>",
        ]
      },
      {
        question: "How do I use the Frame Data table?",
        answer: [ "<P>The Frame Data table shows you the details of the analyses applied to a given frame.</P>",
          "<P>Perhaps most important is that when many hits (pixels matching the criteria) occur, the system will automatically narrow the search in an attempt to get you better results.  There is a downside",
          "to this automation in that, say in RGB, each of the colors are narrowed.  In doing so, your object of interest may now be overlooked as it is not toward the center of the search criteria.  It",
          "is for this reason that we recommend reasonably tight constraints on your searches, while simultaneously recognizing that when the details of what is sought are sketchy, or environmental factors",
          "intrude, you must sometimes cast that wider net.  Proof that you are smarter than the computer.</P>",
          "<P>Two important columns in the table are the 'Number of Hits' (hits = pixels) found matching the search criteria and the 'Adjustments' column.  Hovering over the adjustments shows the constraints",
          "being made to the search criteria, for which you will see the range of colors being reduced on each iteration.",
          "<P>When you click on any row (or click the 'down-arrow icon'), then the supporting images are displayed in the Frame Data Images filmstrip and also shown in the Frame Data Images table.",
        ]
      },
      {
        question: "How do I use the Frame Data Images filmstrip (image slider)?",
        answer: [ "<P>The Frames Data Images filmstrip (image slider) shows the supporting images associated with the currently selected frame data.  These always start with the original image, and may be",
          "followed by the point (aka 'red-dots') image or hit concentration (aka 'yellow boxes') image.</P>",
          "At the top is a reminder about which analysis is being reviewed.  Your agency should have a pattern or protocol for naming to make it easy to remember critical overview / analysis purpose.<ul>",
          "<li>The <b>Frames</b> table is the first table which displays each of the images that was selected for the analysis.  See below for FAQ about the 'Frames Table'</li>",
          "<li>The <b>Frame Data</b> table follows to show details about analyses of the frame selected in the frame table.  See below for FAQ about the 'Frames Table'</li>",
          "<li>The <b>Frame Data Images</b> filmstrip (image slider) follows to show images for a given frame's analysis data.  See below for FAQ about the 'Frame Data Images filmstrip'</li>",
          "<li>The <b>Frame Data Images</b> table then provides image details for the given frame's analysis data.  See below for FAQ about the 'Frame Data Images table'</li></ul>"
        ]
      },
      {
        question: "How do I Compare Images?",
        answer: [ "<P>The 'Compare Images' link button starts the image comparison.  The primary image is always the original.  You may then select a secondary image to compare.</P>",
          "<P>The scroll bars on the left control both images, as do the 'plus' / 'minus' zoom controls</P>"
        ]
      },
      {
        question: "How do I use the Frame Data Images Info table?",
        answer: [ "<P>The Frame Data Images Info table works in conjunction with the Frame Data Images filmstrip.  Clicking on an image highlights the associated row and vice versa.</P>",
          "There are three action icons:<ul>",
          "<li>The 'i icon' displays a number of hits for the current analysis associate with that image.  This corresponds to the 'Hits' column.  For internal reasons, only the",
          "point (aka 'red-dots') image will report the number of hits, which coincidentally equals the number of red pixels shown on the marked up image.</li>",
          "<li>The 'eye icon' will bring up a zoomable, panable image to review.</li>",
          "<li>The 'bell icon' allows you to send a notification to any email or phone number using the given image plus the original image and the associated coordinates</li></ul>",
        ]
      },
    ],

    analysis_results_preview_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [ "This page provides a breakdown of what analysis components will be run or not.  The 'or not' can be important!",
          "Each frame 'image view type' (RGB, Thermal Grayscale, or Radiometric) are available to different analyses and sometimes",
          "a non-matching analysis may be attempted where it is not viable and as a user, you wonder why nothing worked.  This is",
          "here to help!<br/>Also, if files cannot be found, they will be flagged as 'invalid' and also show in this report.",
          "If you are confident with the numbers of images to be tasked, you can 'Confirm' or allow the timer to expire."
        ]
      },
      {
        question: "<b>Frame Types aka Image view types</b>: RGB, Thermal Grayscale, or Radiometric",
        answer: [ "Each frame is marked according to its 'view type'.  It is like the file extension that indicates a .jpg or .png",
          "image file, but takes it one step beyond.  An image could be color for RGB or black/white for Thermal Grayscale, but still",
          "both be .jpg files.  The distinction is needed so that the correct analysis processing can occur.<br/>",
          "Each different view type is shown as a separate row and relevant summary data is shown for each row."
        ]
      },
      {
        question: "Columns Invalid, No Match, Tasked, Configs",
        answer: [ "Invalid shows a count of bad images.  E.g., the image could not be found on the server, usually due to a failed upload<br/>",
          "No Match shows a summary count of search configurations that cannot be run against that image type.  This is technically not an",
          "error as there may be other images that are a match for that search configuration type.",
          "Tasked represents the count of images that will be separately executed as a queued task.<br/>",
          "The breakdown of which types of configurations will be tasked or do not match are detailed in the configs column."
        ]
      },
    ],
  
    analysis_select_FAQ: [
      {
        question: "What is the purpose / overview for this page?",
        answer: [
          "<P>This Analysis Select page shows you the analyses available for this case.</P>The initiation of an analysis starts on the <b>'Flight Images'</b> page because you must",
          "identify which of several flights to analyze or even individual or multiple frames of a flight to be analysed.  There are multiple actions available here:<ul>",
          "<li>Creating a new analysis (button top right) - which takes you back to the '<b>Flights</b> page for the aforementioned reason.</li>",
          "<li>'Right arrow' to re-execute the analysis - thb - not sure when this is useful to a normal user.</li>",
          "<li>'i icon' to get status information about the execution of the analysis.  Analyses get queued up to run in the background.</li>",
          "<li>'eye icon' to go to the specific analysis results.</li>",
          "<li>'pencil icon' to edit high level descriptions of the analysis.</li>",
          "<li>'trashcan icon' to delete the analysis.</li>"
        ]
      },
    ],
  };