export default function useMinMax(inputColors) {
  let minValues = [255, 255, 255];
  let maxValues = [0, 0, 0];

  for (let i = 0; i < inputColors.length; i++) {
    const color = inputColors[i].rgb
      .replace(/[^\d,]/g, "")
      .split(",")
      .map(Number);

    for (let j = 0; j < color.length; j++) {
      minValues[j] = Math.min(minValues[j], color[j]);
      maxValues[j] = Math.max(maxValues[j], color[j]);
    }
  }

  const minRGB = minValues;
  const maxRGB = maxValues;

  return [minRGB, maxRGB];
}
