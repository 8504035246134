import * as React from "react";
import { Helmet } from "react-helmet";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ToDo from "./ToDo";
import { Link } from "react-router-dom";
import DashButton from "./DashButton";
import BackgroundVideo from "../backgroundVideo";

function Copyright(props) {
  return (
    <Typography variant="body1" color="#ffff" align="center" {...props}>
      {"Copyright © "}
      <a
        style={{ color: "white" }}
        target="_blank"
        href="https://find-911.com/"
      >
        Find-911
      </a>
      {" 2022-"}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Dashboard() {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "93vh",
          overflow: "hidden",
          position: "absolute",
        }}
      >
        <BackgroundVideo />
      </div>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "90vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/*
              <DashButton
                buttonText={"Analysis Select"}
                link={"/Analysis/Select"}
              />
              <DashButton
                buttonText={"Analysis results"}
                link={"/Analysis/Results/:1"}
              />
              <DashButton
                buttonText={"Analysis Create"}
                link={"/Analysis/Create"}
              />
              <DashButton
                buttonText={"Analysis Execute"}
                link={"/Analysis/Execute"}
              />
              */}

              {/* <DashButton buttonText={"Case Select"} link={"/Cases"} /> */}

              {/*
              <DashButton buttonText={"Create Case"} link={"/Case/Create"} />

              <DashButton buttonText={"Flight Select"} link={"/Flight/Select"} />
              <DashButton buttonText={"Create Flight"} link={"/Flight/Create"} />

              <DashButton buttonText={"Footage Select"} link={"/Footage/Select"} />
              <DashButton buttonText={"Footage Upload"} link={"/Footage/Upload"} />

              <DashButton
                buttonText={"Build Color Palette"}
                link={"/buildColorPalette"}
              />

              <DashButton
                buttonText={"Detect Objects Palette"}
                link={"/ObjectDetect/DetectObjectsPalette"}
              />

              <DashButton
                buttonText={"Detect Faces Palette"}
                link={"/FaceDetect/DetectFacesPalette"}
              />
              */}

              {/* <DashButton buttonText={"Image Select"} link={"/Images/Select"} /> */}

              {/*
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <ToDo />
                </Paper>
              </Grid>
              */}
            </Grid>
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                width: "100vw",
                // backgroundColor: "#1976d2",
                bottom: 0,
                left: 0,
              }}
            >
              <Copyright sx={{}} />
            </div>
          </Container>
        </Box>
      </Box>
    </>
  );
}
