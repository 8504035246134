import React, { useState, useEffect, useContext, forwardRef } from "react";
import { flushSync } from "react-dom";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import { toast } from "react-toastify";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";
import InfoIcon from "@mui/icons-material/Info";

import { AnalysisContext } from "../../Context/analysisContext";
import { UiContext } from "../../Context/uiContext";

export default function FramesTable(props) {
  const {
    currFrame,
    setCurrFrame,
    framesQuery,
    setFrameData,
    setCurrFrameData,
    currFrameData,
    getFrameDataByFrameID,
    setFrameDataCount,
    frameDataCount,
    setFrameDataImagesCount,
    setFrameDataImages,
    currImage,
    setCurrImage,
    updateFrame,
    sliderCurrFrame,
    setSliderCurrFrame,
  } = useContext(AnalysisContext);

  const {
    handleImageViewModalOpen,
    framesSliderOpen,
    setFramesSliderOpen,
    handleFramesSliderClose,
    handleFramesSliderOpen,
  } = useContext(UiContext);
  // const [imageStatus, setImageStatus] = useState({});
  const [columns, setColumns] = useState([
    { title: "Frame", field: "frameId", editable: "never", width: 10 },
    {
      title: "image",
      field: "display_filepath",
      editable: "never",
      width: 100,
      render: (rowData) => (
        <Tooltip title="Click to view image">
          <div>
            <img
              style={{
                maxWidth: "120px",
                maxHeight: "120px",
              }}
              src={rowData.display_filepath}
              onClick={() => {
                // viewFrameDataImage(rowData, false);
                setSliderCurrFrame({ ...rowData, click_original: true });
                handleFramesSliderOpen();
              }}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Marked Image",
      field: "markedup_display_filepath",
      editable: "never",
      width: 100,
      render: (rowData) => (
        <Tooltip
          title={
            rowData.markedup_display_filepath.includes("NoData.jpg")
              ? "No Marked Image"
              : "Click to view image"
          }
        >
          <div>
            <img
              style={{
                maxWidth: "120px",
                maxHeight: "120px",
              }}
              src={rowData.markedup_display_filepath}
              onClick={() => {
                if (!rowData.markedup_display_filepath.includes("NoData.jpg")) {
                  setSliderCurrFrame({ ...rowData, click_original: false });
                  handleFramesSliderOpen();
                }
                // rowData.markedup_display_filepath.includes("NoData.jpg")
                //   ? null
                //   : viewFrameDataImage(rowData, true);
              }}
            />
          </div>
        </Tooltip>
      ),
    },
    {
      title: "#Hits",
      field: "frameDataHitCount",
      editable: "never",
      width: 10,
    },

    { title: "Latitude", field: "latitude", editable: "never", width: 10 },
    {
      title: "Longitude",
      field: "longitude",
      editable: "never",
      width: 10,
    },
    { title: "Altitude (AGL m)", field: "alt_agl", editable: "never" },

    // {
    //   title: "Altitude (m)",
    //   field: "altitude",
    //   editable: "never",
    //   width: 10,
    // },
    // {
    //   title: "Alt AGL (m)",
    //   field: "alt_agl",
    //   editable: "never",
    //   width: 10,
    // },
    {
      title: "Heading (yaw)",
      field: "heading",
      editable: "never",
      width: 10,
    },
    { title: "Pitch", field: "pitch", editable: "never", width: 10 },
    { title: "Roll", field: "roll", editable: "never", width: 10 },
    { title: "Video", field: "video_id", editable: "never", width: 10 },
    {
      title: "Vid Time",
      field: "video_timestamp",
      editable: "never",
      width: 10,
    },
    {
      title: "Img View Type",
      field: "img_view_type",
      editable: "onUpdate",
      width: 10,
      render: (rowData) =>
        rowData.img_view_type == 0
          ? `Color`
          : rowData.img_view_type == 1
          ? `Thermal Grayscale`
          : rowData.img_view_type == 2
          ? `Radiometric`
          : `Other - ${rowData.img_view_type}`,
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">view</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.img_view_type}
            label="view"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={0}>Color</MenuItem>
            <MenuItem value={1}>Thermal Grayscale</MenuItem>
            <MenuItem value={2}>Radiometric</MenuItem>
            <MenuItem value={3}>{`Other`}</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Use in report",
      field: "use_in_report",
      editable: "onUpdate",
      width: 10,
      render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">use_in_report</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.use_in_report}
            label="use_in_report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Is Flagged",
      field: "is_flagged",
      editable: "onUpdate",
      width: 10,
      render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_flagged}
            label="is_flagged"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Description",
      field: "description",
      editable: "onUpdate",
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.description}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
    // {
    //   title: "Hits",
    //   field: "frameDataHitCount",
    //   editable: "never",
    //   width: 10,
    // },
  ]);
  const [actions, setActions] = useState([
    (rowData) => {
      return {
        icon: ExpandCircleDownIcon,
        tooltip: rowData.frameId === currFrame?.frameId ? "Expanded" : "Expand",
        disabled: rowData.frameId === currFrame?.frameId,
        onClick: (event, rowData) => {
          expandAction(rowData);
        },
      };
    },

    // {
    //   icon: VisibilityIcon,
    //   tooltip: "View Image",
    //   onClick: (event, rowData) => {
    //     viewFrameDataImage(rowData);
    //   },
    // },

    {
      icon: ReplayIcon,
      tooltip: "Reload data",
      isFreeAction: true,
      onClick: (event) => {
        event?.stopPropagation();
        props?.reloadData();
      },
    },
    {
      icon: InfoIcon,
      tooltip: "Analysis status",
      isFreeAction: true,
      onClick: (event) => {
        event?.stopPropagation();
        props?.handleInfoClick();
      },
    },
  ]);

  async function UpdateFrameData(data) {
    flushSync(() => {
      setCurrFrameData([]);
      setFrameDataCount(false);
      setFrameDataImagesCount(false);
      setFrameDataImages([]);
    });
    if (data?.frameDataCount > 0) {
      setCurrFrame(data);
      setFrameDataCount(data.frameDataCount);
    } else {
      setCurrFrame(data);
      toast.warning(
        "No Frame Data info found for first frame, Please check processing status."
      );
    }
  }

  useEffect(() => {
    if (frameDataCount === false && currFrame && currFrame.frameDataCount > 0) {
      setFrameDataCount(currFrame.frameDataCount);
    }
    setColumns([
      { title: "Frame", field: "frameId", editable: "never", width: 10 },
      {
        title: "image",
        field: "display_filepath",
        editable: "never",
        width: 100,
        render: (rowData) => (
          <Tooltip title="Click to view image">
            <div>
              <img
                style={{
                  maxWidth: "120px",
                  maxHeight: "120px",
                }}
                src={rowData.display_filepath}
                onClick={() => {
                  // viewFrameDataImage(rowData, false);
                  setSliderCurrFrame({ ...rowData, click_original: true });
                  handleFramesSliderOpen();
                }}
              />
            </div>
          </Tooltip>
        ),
      },
      {
        title: "Marked Image",
        field: "markedup_display_filepath",
        editable: "never",
        width: 100,
        render: (rowData) => (
          <Tooltip
            title={
              rowData.markedup_display_filepath.includes("NoData.jpg")
                ? "No Marked Image"
                : "Click to view image"
            }
          >
            <div>
              <img
                style={{
                  maxWidth: "120px",
                  maxHeight: "120px",
                }}
                src={rowData.markedup_display_filepath}
                onClick={() => {
                  if (
                    !rowData.markedup_display_filepath.includes("NoData.jpg")
                  ) {
                    setSliderCurrFrame({ ...rowData, click_original: false });
                    handleFramesSliderOpen();
                  }
                  // rowData.markedup_display_filepath.includes("NoData.jpg")
                  //   ? null
                  //   : viewFrameDataImage(rowData, true);
                }}
              />
            </div>
          </Tooltip>
        ),
      },
      {
        title: "#Hits",
        field: "frameDataHitCount",
        editable: "never",
        width: 10,
      },

      { title: "Latitude", field: "latitude", editable: "never", width: 10 },
      {
        title: "Longitude",
        field: "longitude",
        editable: "never",
        width: 10,
      },
      { title: "Altitude (AGL m)", field: "alt_agl", editable: "never" },

      // {
      //   title: "Altitude (m)",
      //   field: "altitude",
      //   editable: "never",
      //   width: 10,
      // },
      // {
      //   title: "Alt AGL (m)",
      //   field: "alt_agl",
      //   editable: "never",
      //   width: 10,
      // },
      {
        title: "Heading (yaw)",
        field: "heading",
        editable: "never",
        width: 10,
      },
      { title: "Pitch", field: "pitch", editable: "never", width: 10 },
      { title: "Roll", field: "roll", editable: "never", width: 10 },
      { title: "Video", field: "video_id", editable: "never", width: 10 },
      {
        title: "Vid Time",
        field: "video_timestamp",
        editable: "never",
        width: 10,
      },
      {
        title: "Img View Type",
        field: "img_view_type",
        editable: "never",
        width: 10,
        render: (rowData) =>
          rowData.img_view_type == 0
            ? `Color`
            : rowData.img_view_type == 1
            ? `Thermal Grayscale`
            : rowData.img_view_type == 2
            ? `Radiometric`
            : `Other - ${rowData.img_view_type}`,
      },
      {
        title: "Use in report",
        field: "use_in_report",
        editable: "onUpdate",
        width: 10,
        render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">use_in_report</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.use_in_report}
              label="use_in_report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Is Flagged",
        field: "is_flagged",
        editable: "onUpdate",
        width: 10,
        render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_flagged}
              label="is_flagged"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Description",
        field: "description",
        editable: "onUpdate",
        editComponent: (tableData) => (
          <TextField
            value={tableData.rowData.description}
            onChange={(e) => tableData.onChange(e.target.value)}
            multiline={true}
            maxRows={5}
          />
        ),
      },
      // {
      //   title: "Is Processed",
      //   field: "is_processed",
      //   editable: "onUpdate",
      //   width: 10,
      //   render: (rowData) => (rowData.is_processed ? `Yes` : `No`),
      //   editComponent: (tableData) => (
      //     <FormControl fullWidth>
      //       <InputLabel id="demo-simple-select-label">is_processed</InputLabel>
      //       <Select
      //         labelId="demo-simple-select-label"
      //         id="demo-simple-select"
      //         value={tableData.rowData.is_processed}
      //         label="is_processed"
      //         onChange={(e) => tableData.onChange(e.target.value)}
      //       >
      //         <MenuItem value={false}>No</MenuItem>
      //         <MenuItem value={true}>Yes</MenuItem>
      //       </Select>
      //     </FormControl>
      //   ),
      // },
      // {
      //   title: "Is Representative",
      //   field: "is_representative",
      //   editable: "onUpdate",
      //   width: 10,
      //   render: (rowData) => (rowData.is_representative ? `Yes` : `No`),
      //   editComponent: (tableData) => (
      //     <FormControl fullWidth>
      //       <InputLabel id="demo-simple-select-label">is_representative</InputLabel>
      //       <Select
      //         labelId="demo-simple-select-label"
      //         id="demo-simple-select"
      //         value={tableData.rowData.is_representative}
      //         label="is_representative"
      //         onChange={(e) => tableData.onChange(e.target.value)}
      //       >
      //         <MenuItem value={false}>No</MenuItem>
      //         <MenuItem value={true}>Yes</MenuItem>
      //       </Select>
      //     </FormControl>
      //   ),
      // },
    ]);
    setActions([
      (rowData) => {
        return {
          icon: ExpandCircleDownIcon,
          tooltip:
            rowData.frameId === currFrame?.frameId ? "Expanded" : "Expand",
          disabled: rowData.frameId === currFrame?.frameId,
          onClick: (event, rowData) => {
            expandAction(rowData);
          },
        };
      },

      // {
      //   icon: VisibilityIcon,
      //   tooltip: "View Image",
      //   onClick: (event, rowData) => {
      //     viewFrameDataImage(rowData);
      //   },
      // },

      {
        icon: ReplayIcon,
        tooltip: "Reload data",
        isFreeAction: true,
        onClick: (event) => {
          event?.stopPropagation();
          props?.reloadData();
        },
      },
      {
        icon: InfoIcon,
        tooltip: "Analysis status",
        isFreeAction: true,
        onClick: (event) => {
          event?.stopPropagation();
          props?.handleInfoClick();
        },
      },
    ]);
  }, [currFrame]);

  //  useEffect(() => {
  //     const preloadImages = async (data) => {
  //       const promises = data.map(
  //         (row) =>
  //           new Promise((resolve) => {
  //             const img = new Image();
  //             img.src = row.display_filepath || placeholderImage;
  //             img.onload = () => resolve({ id: row.imageId, status: "loaded" });
  //             img.onerror = () => resolve({ id: row.imageId, status: "error" });
  //           })
  //       );

  //       const results = await Promise.all(promises);
  //       const statusMap = results.reduce((acc, { id, status }) => {
  //         acc[id] = status;
  //         return acc;
  //       }, {});

  //       setImageStatus(statusMap);
  //     };

  //     preloadImages(images);
  //   }, [images]);

  const expandAction = (rowData) => {
    if (rowData?.frameDataCount === 0) {
      toast.warn("No Frame Data available for this frame");
    } else if (currFrame?.frameId === rowData.frameId) {
      toast.warn("Frame is already expanded");
    } else {
      UpdateFrameData(rowData);
    }
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.frameId === oldData.frameId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);
        await updateFrame(oldData.frameId, {
          description: newData?.description,
          is_flagged: newData?.is_flagged,
          use_in_report: newData?.use_in_report,
        });
        resolve();
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = React.createRef();
  const pageSize = props?.options?.pageSize || 10;
  const [totalCount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(totalCount / pageSize);
  const [pageInputError, setPageInputError] = useState(null);

  const wrappedDataQuery = (query) =>
    framesQuery(query).then((result) => {
      console.log("result:::", result);
      setTotalCount(result.totalCount); // Save totalCount from backend
      return result;
    });

  const handlePageJump = (e) => {
    e.preventDefault();
    const pageValue = parseInt(e.target.elements.page.value, 10);

    if (isNaN(pageValue) || pageValue < 1 || pageValue > totalPages) {
      setPageInputError(
        `Please enter a page number between 1 and ${totalPages}`
      );
      return;
    }

    setPageInputError(null); // clear errors
    const pageNumber = pageValue - 1;

    if (tableRef.current?.onPageChange) {
      tableRef.current.onPageChange(null, pageNumber);
    }
  };

  function viewFrameDataImage(data, markedup) {
    if (markedup) {
      console.log("markedup_filepath:::", data.markedup_filepath);
      console.log("markedup_filepath:::", data);
      data = { ...data, filepath: data.markedup_filepath };
    }
    handleImageViewModalOpen();
    setCurrImage(data);
  }

  const [newTableData, setNewTableData] = useState([]);

  return (
    <>
      {
        <MaterialTable
          data={wrappedDataQuery}
          tableRef={tableRef}
          columns={columns}
          loading={props?.loading}
          // onRowUpdate={props?.editable ? onUpdate : null}
          // editable={{
          //   onRowUpdate: props?.editable
          //     ? (newData, oldData) =>
          //         onUpdate(newData, oldData, newTableData, setNewTableData)
          //     : null,
          // }}
          options={{
            ...props?.options,
            headerStyle: {
              position: "sticky",
              top: 0,
              zIndex: "1",
              backgroundColor: "white",
            },
            emptyRowsWhenPaging: false,
            remote: false, // Disable remote data fetching
          }}
          title={""}
          icons={tableIcons}
          actions={actions}
        />
      }

      {totalPages > 5 && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "8px",
            padding: "0.5rem 1rem",
          }}
        >
          <form
            onSubmit={handlePageJump}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              flexWrap: "wrap",
            }}
          >
            <label htmlFor="page-input" style={{ fontSize: "0.9rem" }}>
              Go to Page:
            </label>
            <input
              id="page-input"
              name="page"
              type="number"
              min="1"
              max={totalPages}
              style={{
                width: "70px",
                padding: "6px 10px",
                fontSize: "0.9rem",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
            <button
              type="submit"
              style={{
                backgroundColor: "#1976d2",
                color: "#fff",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "0.9rem",
              }}
            >
              Go
            </button>
            {pageInputError && (
              <div style={{ color: "red", fontSize: "0.85rem", width: "100%" }}>
                {pageInputError}
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
}
