import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../Context/appContext";
import api, { API_URL } from "../API";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { UserContext } from "../Context/userContext";
import { useParams } from "react-router-dom";

export default function useFileUpload2() {
  // const { progress, setProgress } = useContext(UiContext);
  const { userCtx, sendWorkerNotification } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const { case_id } = useParams();

  useEffect(() => {
    setFiles(files);
  }, [files]);

  // Convert the array into an object for efficient lookups
  const fileNameToUrlMap = (array) => {
    return array.reduce((acc, item) => {
      acc[item.filename] = item.presignedUrl;
      return acc;
    }, {});
  };

  const getBulkPresignedURL = async (uploadContext) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(uploadContext);
        const { data: response } = await api.post("", requestBody);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // In a near future revision, we will want a bulk get presigns, to eliminate 100's of lambda calls
  const uploadBulkFile = async (file, totalFileSum, uploadURL) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = {
          status: "something went wrong",
        };
        if (uploadURL !== "") {
          const data = {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          };
          response = await fetch(uploadURL, data);
        }
        // setProgress((prevProgress) =>
        //   prevProgress >= 100 ? 0 : prevProgress + 100 / totalFileSum
        // );
        resolve({
          filename: file.name,
          status: response?.status,
        });
      } catch (error) {
        resolve({
          filename: file.name,
          status: error.message,
        });
      }
    });
  };

  const uploadBulkFiles = async (uploadContext) => {
    try {
      const updatedFiles = [];
      uploadContext["filenameList"] = files.map((file) => {
        return {
          filename: file.name,
          content_type: file.type,
        };
      });

      let uploadUrls = await getBulkPresignedURL(uploadContext);
      uploadUrls = fileNameToUrlMap(uploadUrls);

      let promises = files.map(async (file) => {
        updatedFiles.push(file);
        // Initialize the upload status for this file
        setUploadStatus((prevStatus) => ({
          ...prevStatus,
          [file.name]: "Uploading...",
        }));

        try {
          let promise = await uploadBulkFile(
            file,
            files.length * 2,
            uploadUrls[file.name]
          );
          // Update the upload status when the Promise resolves
          setUploadStatus((prevStatus_1) => ({
            ...prevStatus_1,
            [file.name]: "Upload Successful",
          }));
          return promise;
        } catch (error) {
          // Handle upload error

          try {
            await sendWorkerNotification(
              case_id,
              userCtx.workerId,
              0,
              error.message,
              `Upload Successful for ${file.name}, CASE ID: ${case_id}`,
              true,
              true
            );
          } catch (error) {
            setUploadStatus((prevStatus_2) => ({
              ...prevStatus_2,
              [file.name]: "Upload/Notification Failed",
            }));
            return;
          }
          setUploadStatus((prevStatus_2) => ({
            ...prevStatus_2,
            [file.name]: "Notification sent for this failed Upload",
          }));
        }
      });
      let response = await Promise.all(promises);
      setFiles(updatedFiles);
      return response;
    } catch (error) {
      return error;
    }
  };

  const getPresignedURL = async (uploadContext) => {
    return new Promise(async (resolve, reject) => {
      console.log("in get presigned 2");
      try {
        const requestBody = JSON.stringify(uploadContext);
        const { data: response } = await api.post("", requestBody);
        // let resp = await fetch(API_URL, {
        //   method: "POST",
        //   headers: {
        //     ...authHeaders,
        //   },
        //   body: JSON.stringify(uploadContext),
        // });
        // const t = await resp.json();
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  // In a near future revision, we will want a bulk get presigns, to eliminate 100's of lambda calls
  const uploadFile = async (uploadContext, file) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("in uploadFile 2: " + file.path + "," + file.type);
        const data = {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        };
        uploadContext["filename"] = file.name;
        uploadContext["content_type"] = file.type;
        let uploadURL = await getPresignedURL(uploadContext);
        let response = await fetch(uploadURL, data);

        resolve({
          filename: file.name,
          status: response.status,
        });
      } catch (error) {
        try {
          await sendWorkerNotification(
            case_id,
            userCtx.workerId,
            0,
            error.message,
            `Upload failed for ${file.name}, CASE ID: ${case_id}`,
            true,
            true
          );
        } catch (error) {
          toast.error(error.message);
        }
        resolve({
          filename: file.name,
          status: error.message,
        });
      }
    });
  };

  const uploadFiles = async (uploadContext) => {
    console.log(files);
    console.log(uploadContext);
    return new Promise(async (resolve, reject) => {
      try {
        let promises = files.map(async (file) =>
          uploadFile(uploadContext, file)
        );
        let response = await Promise.all(promises);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  return [
    files,
    setFiles,
    uploadBulkFiles,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
  ];
}
