import React, { useEffect, useRef } from "react";
import chroma from "chroma-js";
import { Box, Typography, useMediaQuery } from "@mui/material";

const ColorScaleHSL = ({
  checkedElements,
  rangeBufferH = 10,
  rangeBufferS = 10,
  rangeBufferL = 10,
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
}) => {
  const colorScaleRef = useRef(null);
  const hueScaleRef = useRef(null);
  const saturationScaleRef = useRef(null);
  const lightnessScaleRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");

  // Validate and parse HSL values
  const parseHSL = (color) => {
    try {
      const [h, s, l] = chroma(color).hsl();
      return {
        h: isNaN(h) ? 0 : h, // Default to 0 if hue is NaN
        s: isNaN(s) ? 0 : s,
        l: isNaN(l) ? 0 : l,
      };
    } catch (error) {
      console.error("Invalid color format:", color);
      return { h: 0, s: 0, l: 0 }; // Fallback to default values
    }
  };

  // Get min and max HSL values based on rangeBuffer
  const getMinMaxHSL = (inputColors, rangeBufferH, rangeBufferS, rangeBufferL) => {
    let minHue = 360,
      minSat = 100,
      minLight = 100;
    let maxHue = 0,
      maxSat = 0,
      maxLight = 0;

    for (let i = 0; i < inputColors.length; i++) {
      const { h, s, l } = parseHSL(inputColors[i]);

      // Apply rangeBuffer to each component and ensure values stay within limits
      const minH = (h - rangeBufferH + 360) % 360; // Ensure hue wraps around
      const maxH = (h + rangeBufferH) % 360;

      const minS = Math.max(0, s * 100 - rangeBufferS);
      const maxS = Math.min(100, s * 100 + rangeBufferS);

      const minL = Math.max(0, l * 100 - rangeBufferL);
      const maxL = Math.min(100, l * 100 + rangeBufferL);

      minHue = Math.min(minHue, minH);
      minSat = Math.min(minSat, minS);
      minLight = Math.min(minLight, minL);

      maxHue = Math.max(maxHue, maxH);
      maxSat = Math.max(maxSat, maxS);
      maxLight = Math.max(maxLight, maxL);
    }

    const minHSL = `hsl(${Math.round(minHue)}, ${Math.round(minSat)}%, ${Math.round(minLight)}%)`;
    const maxHSL = `hsl(${Math.round(maxHue)}, ${Math.round(maxSat)}%, ${Math.round(maxLight)}%)`;

    return {
      minHSL,
      maxHSL,
      minHue,
      maxHue,
      minSat,
      maxSat,
      minLight,
      maxLight,
    };
  };

  // Update color scale and min/max values
  useEffect(() => {
    const finalScale = checkedElements.map((item) => item.hsl);

    if (finalScale.length === 0 || finalScale.some((color) => !color)) {
      console.error("❌ Error: No valid colors found in checkedElements.");
      return;
    }

    const {
      minHSL,
      maxHSL,
      minHue,
      maxHue,
      minSat,
      maxSat,
      minLight,
      maxLight,
    } = getMinMaxHSL(finalScale, rangeBufferH, rangeBufferS, rangeBufferL);

    console.log("Min HSL:", minHSL);
    console.log("Max HSL:", maxHSL);

    // Main color scale
    const scale = chroma.scale([minHSL, maxHSL]).mode("hsl");
    const numSamples = 200;
    const colors = scale.colors(numSamples);

    colorScaleRef.current.innerHTML = "";
    colors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      colorSample.style.transition = "background-color 0.3s ease-in-out";
      colorScaleRef.current.appendChild(colorSample);
    });

    setMinValue(minHSL);
    setMaxValue(maxHSL);

    // Create separate scales for Hue, Saturation, and Lightness
    const midSat = (minSat + maxSat) / 2; // Midpoint saturation
    const midLight = (minLight + maxLight) / 2; // Midpoint lightness

    // Hue Scale
    const hueScale = chroma
      .scale([
        `hsl(${minHue}, ${midSat}%, ${midLight}%)`, // Min Hue
        `hsl(${maxHue}, ${midSat}%, ${midLight}%)`, // Max Hue
      ])
      .mode("hsl");
    const hueColors = hueScale.colors(numSamples);
    hueScaleRef.current.innerHTML = "";
    hueColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      hueScaleRef.current.appendChild(colorSample);
    });

    // Saturation Scale
    const saturationScale = chroma
      .scale([
        `hsl(${minHue}, ${minSat}%, ${midLight}%)`, // Min Saturation
        `hsl(${minHue}, ${maxSat}%, ${midLight}%)`, // Max Saturation
      ])
      .mode("hsl");
    const saturationColors = saturationScale.colors(numSamples);
    saturationScaleRef.current.innerHTML = "";
    saturationColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      saturationScaleRef.current.appendChild(colorSample);
    });

    // Lightness Scale
    const lightnessScale = chroma
      .scale([
        `hsl(${minHue}, ${midSat}%, ${minLight}%)`, // Min Lightness
        `hsl(${minHue}, ${midSat}%, ${maxLight}%)`, // Max Lightness
      ])
      .mode("hsl");
    const lightnessColors = lightnessScale.colors(numSamples);
    lightnessScaleRef.current.innerHTML = "";
    lightnessColors.forEach((color) => {
      const colorSample = document.createElement("div");
      colorSample.style.backgroundColor = color;
      colorSample.style.width = `${100 / numSamples}%`;
      colorSample.style.height = "100%";
      lightnessScaleRef.current.appendChild(colorSample);
    });
  }, [checkedElements, rangeBufferH, rangeBufferS, rangeBufferL]);

  return (
    <div>
      {/* Main Color Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Main Color Scale</strong>
        </Typography>
        <div
          ref={colorScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Hue Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Hue Scale</strong>
        </Typography>
        <div
          ref={hueScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Saturation Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Saturation Scale</strong>
        </Typography>
        <div
          ref={saturationScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Lightness Scale */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <strong>Lightness Scale</strong>
        </Typography>
        <div
          ref={lightnessScaleRef}
          style={{
            display: "flex",
            height: "20px",
          }}
        ></div>
      </Box>

      {/* Min and Max HSL Values */}
      <Box>
        <Typography variant={isMobile ? "body2" : "body1"}>
          <strong>Min HSL:</strong> {minValue}
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"}>
          <strong>Max HSL:</strong> {maxValue}
        </Typography>
      </Box>
    </div>
  );
};

export default ColorScaleHSL;