import React, { useState, useEffect, useContext, forwardRef } from "react";
import { flushSync } from "react-dom";

import { Button, IconButton, TextField, Tooltip } from "@mui/material";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import { toast } from "react-toastify";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReplayIcon from "@mui/icons-material/Replay";
import InfoIcon from "@mui/icons-material/Info";

import { AnalysisContext } from "../../Context/analysisContext";
import { UiContext } from "../../Context/uiContext";

export default function FramesTable(props) {
  const {
    currFrame,
    setCurrFrame,
    framesQuery,
    setFrameData,
    setCurrFrameData,
    currFrameData,
    getFrameDataByFrameID,
    setFrameDataCount,
    frameDataCount,
    setFrameDataImagesCount,
    setFrameDataImages,
    currImage,
    setCurrImage,
  } = useContext(AnalysisContext);

  const { handleImageViewModalOpen } = useContext(UiContext);

  const [columns, setColumns] = useState([
    // {
    //   title: "Actions",
    //   field: "frameDataId",
    //   editable: "never",
    //   render: (rowData) => (
    //     <Tooltip
    //       title={
    //         rowData?.frameDataCount === 0
    //           ? "No Frame Data available for this frame"
    //           : ""
    //       }
    //     >
    //       <div>
    //         <Tooltip title="Expand">
    //           <ExpandCircleDownIcon
    //             // fontSize="large"
    //             disabled={
    //               currFrame?.frameId === rowData.frameId ||
    //               rowData?.frameDataCount === 0
    //             }
    //             onClick={() => expandAction(rowData)}
    //           />
    //         </Tooltip>

    //         <Tooltip title="View Image">
    //           <VisibilityIcon
    //             // fontSize="large"
    //             disabled={
    //               currFrame?.frameId === rowData.frameId ||
    //               rowData?.frameDataCount === 0
    //             }
    //             onClick={() => viewFrameDataImage(rowData)}
    //           />
    //         </Tooltip>

    //         {/* <Button
    //           style={{
    //             padding: "0px",
    //           }}
    //           disabled={
    //             currFrame?.frameId === rowData.frameId ||
    //             rowData?.frameDataCount === 0
    //           }
    //           onClick={() => updateFrameData(rowData)}
    //         >
    //           Activate
    //         </Button> */}
    //       </div>
    //     </Tooltip>
    //   ),
    //   // editComponent: (tableData) => (
    //   //   <>
    //   //     <Button
    //   //       disabled={true}
    //   //       onClick={() => updateFrameData(tableData.rowData)}
    //   //     >
    //   //       Activate
    //   //     </Button>
    //   //   </>
    //   // ),
    // },
    // { title: "Index", field: "index", editable: "never", width: 10 },
    { title: "Frame", field: "frameId", editable: "never", width: 10 },
    {
      title: "Image",
      field: "display_filepath",
      editable: "never",
      width: 100,
      render: (rowData) => (
        <Button
          disabled={currFrame?.frameId === rowData.frameId}
          onClick={() => {
            console.log("currFrame", currFrame);
            if (currFrame?.frameId === rowData.frameId) {
              console.log("HELLOOO");
            }
            updateFrameData(rowData);
          }}
        >
          <img
            style={{
              maxWidth: "120px",
              maxHeight: "120px",
            }}
            src={rowData.filepath}
          ></img>
        </Button>
      ),
    },
    {
      title: "#Hits",
      field: "frameDataHitCount",
      editable: "never",
      width: 10,
    },

    { title: "Latitude", field: "latitude", editable: "never", width: 10 },
    {
      title: "Longitude",
      field: "longitude",
      editable: "never",
      width: 10,
    },
    { title: "Altitude (AGL m)", field: "alt_agl", editable: "never" },

    // {
    //   title: "Altitude (m)",
    //   field: "altitude",
    //   editable: "never",
    //   width: 10,
    // },
    // {
    //   title: "Alt AGL (m)",
    //   field: "alt_agl",
    //   editable: "never",
    //   width: 10,
    // },
    {
      title: "Heading (yaw)",
      field: "heading",
      editable: "never",
      width: 10,
    },
    { title: "Pitch", field: "pitch", editable: "never", width: 10 },
    { title: "Roll", field: "roll", editable: "never", width: 10 },
    { title: "Video", field: "video_id", editable: "never", width: 10 },
    {
      title: "Vid Time",
      field: "video_timestamp",
      editable: "never",
      width: 10,
    },
    {
      title: "Image View Type",
      field: "img_view_type",
      editable: "onUpdate",
      width: 10,
      render: (rowData) =>
        rowData.img_view_type == 0
          ? `Color`
          : rowData.img_view_type == 1
          ? `Thermal RGB`
          : rowData.img_view_type == 2
          ? `Radiometric`
          : `Other {rowData.img_view_type}`,
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">view</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.img_view_type}
            label="view"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={0}>Color</MenuItem>
            <MenuItem value={1}>Thermal RGB</MenuItem>
            <MenuItem value={2}>Radiometric</MenuItem>
            <MenuItem value={3}>{`Other`}</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Is Archived",
      field: "is_archived",
      editable: "onUpdate",
      width: 10,
      render: (rowData) => (rowData.is_archived ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_archived</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_archived}
            label="report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Is Flagged",
      field: "is_flagged",
      editable: "onUpdate",
      width: 10,
      render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.is_flagged}
            label="report"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      title: "Hits",
      field: "frameDataHitCount",
      editable: "never",
      width: 10,
    },
  ]);
  const [actions, setActions] = useState([
    (rowData) => {
      return {
        icon: ExpandCircleDownIcon,
        tooltip: rowData.frameId === currFrame?.frameId ? "Expanded" : "Expand",
        disabled: rowData.frameId === currFrame?.frameId,
        onClick: (event, rowData) => {
          expandAction(rowData, currFrame);
        },
      };
    },

    {
      icon: VisibilityIcon,
      tooltip: "View Image",
      onClick: (event, rowData) => {
        viewFrameDataImage(rowData);
      },
    },

    {
      icon: ReplayIcon,
      tooltip: "Reload data",
      isFreeAction: true,
      onClick: (event) => {
        event?.stopPropagation();
        props?.reloadData();
      },
    },
    {
      icon: InfoIcon,
      tooltip: "Analysis status",
      isFreeAction: true,
      onClick: (event) => {
        event?.stopPropagation();
        props?.handleInfoClick();
      },
    },
  ]);

  async function updateFrameData(data) {
    flushSync(() => {
      setFrameDataCount(false);
      setFrameDataImagesCount(false);
      setFrameDataImages([]);
    });
    if (data?.frameDataCount > 0) {
      setCurrFrame(data);
      setFrameDataCount(data.frameDataCount);
    } else {
      setCurrFrame(data);
      toast.warning(
        "No Frame Data info found for first frame, Please check processing status."
      );
    }
  }

  useEffect(() => {
    if (frameDataCount === false && currFrame && currFrame.frameDataCount > 0) {
      setFrameDataCount(currFrame.frameDataCount);
    }
    setColumns([
      // {
      //   title: "Actions",
      //   field: "frameDataId",
      //   editable: "never",
      //   render: (rowData) => (
      //     <Tooltip
      //       title={
      //         rowData?.frameDataCount === 0
      //           ? "No Frame Data available for this frame"
      //           : ""
      //       }
      //     >
      //       <div>
      //         <Tooltip title="Expand">
      //           <ExpandCircleDownIcon
      //             // fontSize="large"
      //             disabled={
      //               currFrame?.frameId === rowData.frameId ||
      //               rowData?.frameDataCount === 0
      //             }
      //             onClick={() => expandAction(rowData)}
      //           />
      //         </Tooltip>

      //         <Tooltip title="View Image">
      //           <VisibilityIcon
      //             // fontSize="large"
      //             disabled={
      //               currFrame?.frameId === rowData.frameId ||
      //               rowData?.frameDataCount === 0
      //             }
      //             onClick={() => viewFrameDataImage(rowData)}
      //           />
      //         </Tooltip>

      //         {/* <Button
      //           style={{
      //             padding: "0px",
      //           }}
      //           disabled={
      //             currFrame?.frameId === rowData.frameId ||
      //             rowData?.frameDataCount === 0
      //           }
      //           onClick={() => updateFrameData(rowData)}
      //         >
      //           Activate
      //         </Button> */}
      //       </div>
      //     </Tooltip>
      //   ),
      //   // editComponent: (tableData) => (
      //   //   <>
      //   //     <Button
      //   //       disabled={true}
      //   //       onClick={() => updateFrameData(tableData.rowData)}
      //   //     >
      //   //       Activate
      //   //     </Button>
      //   //   </>
      //   // ),
      // },
      // { title: "Index", field: "index", editable: "never", width: 10 },
      { title: "Frame", field: "frameId", editable: "never", width: 10 },
      {
        title: "image",
        field: "display_filepath",
        editable: "never",
        width: 100,
        render: (rowData) => (
          <Button
            disabled={currFrame?.frameId === rowData.frameId}
            onClick={() => {
              console.log("currFrame", currFrame);
              if (currFrame?.frameId === rowData.frameId) {
                console.log("HELLOOO");
              }
              updateFrameData(rowData);
            }}
          >
            <img
              style={{
                maxWidth: "120px",
                maxHeight: "120px",
              }}
              src={rowData.display_filepath}
            ></img>
          </Button>
        ),
      },
      {
        title: "#Hits",
        field: "frameDataHitCount",
        editable: "never",
        width: 10,
      },

      { title: "Latitude", field: "latitude", editable: "never", width: 10 },
      {
        title: "Longitude",
        field: "longitude",
        editable: "never",
        width: 10,
      },
      { title: "Altitude (AGL m)", field: "alt_agl", editable: "never" },

      // {
      //   title: "Altitude (m)",
      //   field: "altitude",
      //   editable: "never",
      //   width: 10,
      // },
      // {
      //   title: "Alt AGL (m)",
      //   field: "alt_agl",
      //   editable: "never",
      //   width: 10,
      // },
      {
        title: "Heading (yaw)",
        field: "heading",
        editable: "never",
        width: 10,
      },
      { title: "Pitch", field: "pitch", editable: "never", width: 10 },
      { title: "Roll", field: "roll", editable: "never", width: 10 },
      { title: "Video", field: "video_id", editable: "never", width: 10 },
      {
        title: "Vid Time",
        field: "video_timestamp",
        editable: "never",
        width: 10,
      },
      {
        title: "Img View Type",
        field: "img_view_type",
        editable: "onUpdate",
        width: 10,
        render: (rowData) =>
          rowData.img_view_type == 0
            ? `Color`
            : rowData.img_view_type == 1
            ? `Thermal RGB`
            : rowData.img_view_type == 2
            ? `Radiometric`
            : `Other - ${rowData.img_view_type}`,
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">view</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.img_view_type}
              label="view"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={0}>Color</MenuItem>
              <MenuItem value={1}>Thermal RGB</MenuItem>
              <MenuItem value={2}>Radiometric</MenuItem>
              <MenuItem value={3}>{`Other`}</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "is_archived",
        field: "is_archived",
        editable: "onUpdate",
        width: 10,
        render: (rowData) => (rowData.is_archived ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_archived</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_archived}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "is_flagged",
        field: "is_flagged",
        editable: "onUpdate",
        width: 10,
        render: (rowData) => (rowData.is_flagged ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is_flagged</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.is_flagged}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Hits",
        field: "frameDataHitCount",
        editable: "never",
        width: 10,
      },
    ]);
    setActions([
      (rowData) => {
        return {
          icon: ExpandCircleDownIcon,
          tooltip: rowData.frameId === currFrame?.frameId ? "Expanded" : "Expand",
          disabled: rowData.frameId === currFrame?.frameId,
          onClick: (event, rowData) => {
            expandAction(rowData, currFrame);
          },
        };
      },

      {
        icon: VisibilityIcon,
        tooltip: "View Image",
        onClick: (event, rowData) => {
          viewFrameDataImage(rowData);
        },
      },

      {
        icon: ReplayIcon,
        tooltip: "Reload data",
        isFreeAction: true,
        onClick: (event) => {
          event?.stopPropagation();
          props?.reloadData();
        },
      },
      {
        icon: InfoIcon,
        tooltip: "Analysis status",
        isFreeAction: true,
        onClick: (event) => {
          event?.stopPropagation();
          props?.handleInfoClick();
        },
      },
    ])
  }, [currFrame]);

  const expandAction = (rowData) => {
    if (rowData?.frameDataCount === 0) {
      toast.warn("No Frame Data available for this frame");
    } else if (currFrame?.frameId === rowData.frameId) {
      toast.warn("Frame is already expanded");
    } else {
      updateFrameData(rowData);
    }
  };
 

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = React.createRef();

  function viewFrameDataImage(data) {
    handleImageViewModalOpen();
    setCurrImage(data);
  }

  return (
    <>
      {
        <MaterialTable
          data={framesQuery}
          tableRef={tableRef}
          columns={columns}
          loading={props?.loading}
          options={{...props?.options, headerStyle: { position: "sticky", top: 0, zIndex: "1", backgroundColor: "white"  }}}
          title={""}
          icons={tableIcons}
          actions={actions}
        />
      }
    </>
  );
}
