import React from "react";
import { Helmet } from "react-helmet";

import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";

import { Box, Grid, Typography } from "@mui/material";
import Table from "../table/table";
import { toast } from "react-toastify";
import { MainLayout } from "../Layouts/MainLayout";

export default function ProfileViewPage() {
  const { userCtx, updateWorkerProfile } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await getCaseByID(case_id);
    await getFootageByID(footage_id);
    setLoading(false);
    setData([footageCtx]);
  };

  React.useEffect(() => {
    console.log("userCtx", userCtx);
  }, [userCtx]);

  const style = {
    position: "absolute",
    // transform: "translateX(6vw)",
    width: "90%",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    marginTop: "10vh",
    padding: "32px",
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.sarCaseId === oldData.sarCaseId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);
        await updateWorkerProfile(newData.workerName, newData.contact);

        resolve();
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });

  return (
    <MainLayout title={`Profile`}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" align="left" sx={{ marginBottom: 5 }}>
            User profile
          </Typography>
          <Table
            data={[userCtx]}
            columns={[
              { title: "cognito ID", field: "cognitoId", editable: "never" },
              { title: "User ID", field: "workerId", editable: "never" },
              {
                title: "Org ID",
                field: "workerOrgId",
                editable: "never",
              },
              {
                title: "Org name",
                field: "workerOrgName",
                editable: "never",
              },
              {
                title: "Cognito username",
                field: "cognitoWorkerName",
                editable: "never",
              },

              {
                title: "Name",
                field: "workerName",
                editable: "onUpdate",
              },

              {
                title: "Contact",
                field: "contact",
                editable: "onUpdate",
              },
            ]}
            editable={true}
            onRowUpdate={onUpdate}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
}
