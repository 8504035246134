import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Checkbox,
  Modal,
} from "@mui/material";
import useFileUpload2 from "../Hooks/PresignedS3_2";
import LoadingButton from "@mui/lab/LoadingButton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { FlightContext } from "../Context/flightContext";
import { toast } from "react-toastify";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";
import { VideoContext } from "../Context/videoContext";
import { FootageContext } from "../Context/footageContext";
import { AppContext } from "../Context/appContext";

export default function FileUploadModal({
  isImageSelect,
  isVideoSelect,
  isFootageSelect,
  isGeoDataSelect,
  title,
}) {
  const { setVideos } = useContext(VideoContext);
  const { fileUploadModalopen, handlefileUploadModalClose } =
    useContext(UiContext);
  const { updateFileContext, fileContext } = useContext(ImageContext);
  const radiometricCoverters = useRadiometericConverters();
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { images } = useContext(ImageContext);
  const { userCtx } = useContext(UserContext);
  const { user } = useContext(AppContext);
  const { flightCtx, getFlightByID } = useContext(FlightContext);
  const { setFootage } = useContext(FootageContext);
  const [
    files,
    setFiles,
    uploadBulkFiles,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
  ] = useFileUpload2([]);
  const [isLoading, setIsLoading] = useState(false);

  const { case_id, flight_id } = useParams();

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const handleFileContextChange = (key, value) => {
    console.log(key, value);
    updateFileContext({
      ...fileContext,
      [key]: value,
    });
  };

  const onConfirm = async () => {
    if (files.length === 0) {
      toast.warning("Upload atleast one file");
      return;
    }
    if (files) {
      if (isGeoDataSelect && files.length > 1) {
        toast.warning("Only 1 file can be uploaded");
        return;
      }

      if (
        isGeoDataSelect &&
        files.length === 1 &&
        files[0]["type"] !== "text/csv"
      ) {
        toast.warning("Only CSV file can be uploaded");
        return;
      }

      try {
        setIsLoading(true);
        let response = await uploadBulkFiles(fileContext);
        console.log("response", response);
        if (response.length > 0) {
          response.map((el) => {
            console.log("response el:", el);
            if (el.status == 200) {
              toast.info(`${el.filename} successfully uploaded!`);
            } else {
              toast.warning(`${el.filename} upload failed! ${el.status}`);
            }
          });
        }
        getFlightByID(flight_id);

        setIsLoading(false);
        setFiles([]);
        handlefileUploadModalClose();
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
        handlefileUploadModalClose();
      }
    }
  };

  const onClose = async (event, reason) => {
    if ((reason === "backdropClick" || reason === "escapeKeyDown") && isLoading)
      return;
    setIsLoading(false);
    setFiles([]);
    // updateFileContext({});
    handlefileUploadModalClose();
    setState();
  };

  // Hook that uploads files when files are set
  React.useEffect(() => {
    setState();
  }, [isImageSelect, isVideoSelect, isFootageSelect]);

  React.useEffect(() => {
    console.log("img_purpose", fileContext.img_purpose);
  }, [fileContext]);

  const setState = () => {
    if (isGeoDataSelect) {
      updateFileContext({
        action: "get-s3-presigned-url-list",
        action_purpose: "geolocation",
        presigned_ttl: 3600,
        // isFootageSelect: 1,
        gid: sarCase.sarCaseOrgId,
        wid: user.cognitoId,
        cid: sarCase.sarCaseId,
        fid: flight_id,
        filename: "",
        content_type: "",
        filenameList: [],
      });
    }
  };

  return (
    <Modal
      open={fileUploadModalopen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box margin={5} sx={style}>
          {isGeoDataSelect && (
            <Box>
              <Typography
                align="center"
                variant="h5"
                sx={{ display: "flex", paddingBottom: 1 }}
              >
                {title}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography>{files.length} Files To Upload:</Typography>
            {files.map((file, i) => (
              <Typography key={i}>
                {i + 1}. {file.path}
              </Typography>
            ))}
          </Box>
          <Dropzone onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <Box
                height={200}
                sx={{ border: "3px dashed grey" }}
                {...getRootProps()}
              >
                <Typography>Drop Files Here</Typography>
              </Box>
            )}
          </Dropzone>
          <LoadingButton
            onClick={onClose}
            disabled={isLoading}
            variant="outlined"
          >
            Close
          </LoadingButton>
          <LoadingButton
            onClick={onConfirm}
            variant="outlined"
            loading={isLoading}
          >
            Confirm
          </LoadingButton>
        </Box>
      </div>
    </Modal>
  );
}
