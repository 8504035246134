import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "../table/table";
import { toast } from "react-toastify";
import { MainLayout } from "../Layouts/MainLayout";

export default function CaseSelectPage() {
  const { userCtx } = useContext(UserContext);
  const { setSarCase, cases, getCasesByWorker } = useContext(CaseContext);
  const [columns, setColumns] = useState([
    // {
    //   title: "Action",
    //   field: "sarCaseId",
    //   render: (rowData) => (
    //     <IconButton onClick={(e) => handleViewClick(rowData)} aria-label="view">
    //       <VisibilityIcon />
    //     </IconButton>
    //   ),
    // },
    { title: "Case Id", field: "sarCaseId" },
    { title: "Date", field: "sarCaseDate" },
    { title: "Lead Agency Name", field: "sarCaseAgencyName" },
    { title: "Case Name/#", field: "sarCaseName" },
    { title: "Lead Name", field: "leadName" },
    { title: "Lead Phone", field: "leadPhone" },
    { title: "Lead Email", field: "leadEmail" },
    { title: "Search Area Name", field: "searchAreaName" },
    {
      title: "Description",
      field: "sarCaseDescription",

      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.sarCaseDescription}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    setSarCase({
      ...rowData,
    });
    navigate(`/Cases/${rowData.sarCaseId}`);
  };

  const fetchCaseData = async () => {
    setLoading(true);
    try {
      await getCasesByWorker();
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  React.useEffect(
    () => {
      if (userCtx.workerId !== undefined) {
        fetchCaseData();
      }
    },
    [userCtx.workerId],
    cases
  );

  return (
    <MainLayout title="cases">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={"/Cases/Create"}
          sx={{ visibility: "hidden", marginRight: "auto" }}
        >
          This is a hidden button
        </Button>

        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={"/Cases/Create"}
          sx={{ marginLeft: "auto" }}
        >
          Create New Case
        </Button>
      </Box>
      <Table
        data={cases}
        columns={columns}
        title={"Cases"}
        loading={loading}
        options={{
          toolbar: true,
          sorting: true,
          draggable: true,
          search: true,
          paging: false,
          maxBodyHeight: "500px",
          actionsCellStyle: { zIndex: "0", position: "relative"},
          headerStyle: { position: 'sticky', top: 0, zIndex: 1} ,
          rowStyle: {
            backgroundColor: "#EEE",
          },
        }}
        onRowView={handleViewClick}
        recordType="case"
      />
    </MainLayout>
  );
}
