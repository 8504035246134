import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import { systemContext, userContext, caseContext, criteriaContext } from "../index";
import { Box, Button, Checkbox, Collapse, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import { useNavigate } from "react-router-dom";


const headCells = ['Criteria', '#Flights', '#Configs', '#Criteria', '#Frames', '#Processed', '#Flagged', '#Archived' ]

function CriteriaTableRow(props) {
    const caseCtx = useContext(caseContext);
    const criteriaCtx = useContext(criteriaContext);

    const { case_id } = useParams();

    const {
        criteriaId,
        criteriaName,
        numConfigs,
        numCriteria,
        numFrames,
        numProcessed,
        numFlagged,
        numArchived,
        criteriaDate,
    } = props;
    
    const navigate = useNavigate();

    const handleExecuteClick = (event) => {
        criteriaCtx.caseId = criteriaId;

        // TODO: implement fetch() to invoke lambda to invoke fargate to do the real work
    };

    const handleViewClick = (event) => {
        criteriaCtx.caseId = criteriaId;
        criteriaCtx.criteriaName = "To get from button - TBD";
        // navigate(`/Criteria/Summary`);
        navigate(`/Cases/${case_id}/Criteria/${criteriaId}`);

        
    };
        

    return (
        <TableRow>
            <TableCell>{criteriaName}</TableCell>
            <TableCell>{numConfigs}</TableCell>
            <TableCell>{numCriteria}</TableCell>
            <TableCell>{numFrames}</TableCell>
            <TableCell>{numProcessed}</TableCell>
            <TableCell>{numFlagged}</TableCell>
            <TableCell>{numArchived}</TableCell>
            <TableCell>{criteriaDate.toLocaleString()}</TableCell>
            {/* @Matt, want these conditional so only one is active.  Think we need the 2 slots.  Let's discuss. */}
            <TableCell>
                <Button onClick={() => handleExecuteClick()} key={criteriaId}>
                Execute
                </Button>
            </TableCell>
            <TableCell>
                <Button onClick={() => handleViewClick()} key={criteriaId}>
                View
                </Button>
            </TableCell>
        </TableRow>
    );
}


export default function CriteriaTable(props) {

    const criteria = props.criteria;

    return (
        <>
          <Box>
            <TableContainer>
                <Table>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => <TableCell key={headCell}>{headCell}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {criteria.map((row) => 
                        <CriteriaTableRow 
                        criteriaId={row.criteriaId}
                        criteriaName={row.criteriaName}
                        numConfigs={row.numConfigs}
                        numCriteria={row.numCriteria}
                        numFrames={row.numFrames}
                        numProcessed={row.numProcessed}
                        numFlagged={row.numFlagged}
                        numArchived={row.numArchived}
                        criteriaDate={row.criteriaDate}
                        key={row.criteriaName}
                        />
                    )}
                </TableBody>
            </Table>
          </TableContainer>
        </Box>
    </>
  );
}
