import React, { useState, useEffect } from "react";

//MUI components
import { Box, Paper } from "@mui/material";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

//NPM imports
import ColorPicker from "react-best-gradient-color-picker";

export default function CustomColorModal({
  mapIndex,
  isEditingColor,
  onClose,
  onChangeColor,
  currIndexRgb,
  currenIndexHex,
}) {
  const [color, setColor] = useState(currIndexRgb);

  useEffect(() => {
    setColor(currIndexRgb);
  }, [currIndexRgb]);

  const setParentColorAndClose = (mapIndex, color) => {
    onChangeColor(mapIndex, color);
    onClose();
  };

  if (isEditingColor) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9998,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // maxWidth: "90vw",
            // maxHeight: "90vh",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.75rem",
            zIndex: 9999,
            display: "flex",
          }}
        >
          <Box>
            <Paper
              elevation={16}
              sx={{ padding: 5, bgcolor: "rgb(255,255,255, 0.7)" }}
            >
              <ColorPicker
                value={color}
                onChange={setColor}
                hideOpacity={true}
              />
            </Paper>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CloseIcon
              color={"info"}
              fontSize={"large"}
              sx={{ marginLeft: 2 }}
              onClick={onClose}
            />
            <CheckIcon
              color={"info"}
              fontSize={"large"}
              sx={{ marginLeft: 2, marginTop: 3 }}
              onClick={() => setParentColorAndClose(mapIndex, color)}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
