import React from "react";
import { Link, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { SearchConfigContext } from "../Context/searchConfigContext";

import Table from "../table/table";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import SearchConfigsTable from "../SearchConfigs/SearchConfigsTable";

import { useNavigate } from "react-router-dom";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";

const testSearchConfigs = [
  {
    searchConfigId: 1,
    searchConfigName: "Santa Cruz Sweatshirt",
    filepath:
      "https://img1.wsimg.com/isteam/ip/bcef1094-bcc3-4cf6-a34f-e052ad88e450/IMG-6681%20(1).JPG/:/rs=w:133,h:75,cg:true,m/cr=w:133,h:75/qt=q:95",
    sequence: 1,
    img_purpose: 0,
    use_in_report: "Yes",
    description: "simple description",
    uploadDate: new Date(),
  },
  {
    searchConfigId: 2,
    searchConfigName: "Bullet Casing",
    filepath:
      "https://img1.wsimg.com/isteam/ip/bcef1094-bcc3-4cf6-a34f-e052ad88e450/IMG-6881.PNG/:/cr=t:29.6%25,l:17.24%25,w:31.65%25,h:14.62%25/rs=w:365,h:365,cg:true,m",
    sequence: 1,
    img_purpose: 1,
    use_in_report: "Yes",
    description: "another simple description",
    uploadDate: new Date(),
  },
];

export default function SearchConfigsSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { searchConfigs, getSearchConfigsByCase, setSearchConfigs } =
    useContext(SearchConfigContext);
  const { case_id } = useParams();
  const [loading, setLoading] = useState(false);

  const fetchCaseData = async () => {
    setLoading(true);
    try {
      await getSearchConfigsByCase(case_id);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId) {
        getCaseByID(case_id);
      }
      fetchCaseData();
    } else {
      setSearchConfigs(testSearchConfigs);
    }
  }, [sarCase.sarCaseId]);

  const navigate = useNavigate();

  const [newCritValue, setNewCritValue] = React.useState("select");
  const handleNewCritValueChange = (event) => {
    setNewCritValue(event.target.value);

    if (event.target.value != "select")
      if (event.target.value == "object-detect")
        navigate(`/cases/${case_id}/search-configs/detect-objects-palette`);
      else if (event.target.value == "anomaly-detect")
        navigate(`/cases/${case_id}/search-configs/detect-anomaly-palette`);
      else if (event.target.value == "radiometric-scratch")
        navigate(`/cases/${case_id}/search-configs/radiometric`);
      else if (event.target.value == "rgb-color-scratch")
        navigate(`/cases/${case_id}/search-configs/detect-color-palette-rgb`);
      else if (event.target.value == "hsl-color-scratch")
        navigate(`/cases/${case_id}/search-configs/detect-color-palette-hsl`);
      else navigate(`/cases/${case_id}/images`);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingX: 10,
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            component={Link}
            sx={{ visibility: "hidden", marginRight: "auto" }}
          >
            This is a hidden button
          </Button>

          <Button
            variant="contained"
            type="submit"
            component={Link}
            sx={{ visibility: "hidden", marginRight: "auto" }}
          >
            This is a hidden button
          </Button>

          <label sx={{ marginLeft: "auto" }}>
            Create New &nbsp;
            <select value={newCritValue} onChange={handleNewCritValueChange}>
              {/* <option value="select"> - Select - </option>
              <option value="rgb-color">Color Palette</option>
              <option value="thermal-color">Thermal Color</option>
              <option value="radiometric">Radiometric</option>
              <option value="facial-recog">Facial Recognition</option>
              <option value="object-detect">Object Detect</option>
              <option value="anomaly-detect">Anomaly Detect</option> */}
              <option value="select"> - Select - </option>
              <option value="rgb-color">
                Color Palette from Reference Image
              </option>
              <option value="radiometric">
                Radiometric from Reference Image
              </option>
              <option value="facial-recog">
                Facial Recognition from Reference Image
              </option>
              {/* <option value="thermal-color">Thermal Color from Reference Image</option> */}
              <option value="rgb-color-scratch">
                Color Palette (RGB) from scratch
              </option>
              <option value="hsl-color-scratch">
                Color Palette (HSL) from scratch
              </option>
              <option value="radiometric-scratch">
                Radiometric from scratch
              </option>
              <option value="object-detect">Object Detect</option>
              <option value="anomaly-detect">Anomaly Detect</option>
            </select>
          </label>
        </Box>
        {searchConfigs.length == 0 ? (
          <Box>No search configs found for case {case_id}</Box>
        ) : (
          <div>
            <SearchConfigsTable
              searchConfigs={searchConfigs}
              tableOptions={{
                toolbar: false,
                sorting: false,
                draggable: false,
                grouping: false,
                search: false,
                paging: false,
                selection: false,
              }}
              editable={true}
              deleteable={true}
            ></SearchConfigsTable>
          </div>
        )}

        <Box
          sx={{
            width: "100%",
            padding: "2rem", // Adjust padding for mobile
            // marginTop: "2rem", // Adjust padding for mobile
          }}
        >
          <FAQ faqs={FAQs.searchconfig_select_FAQ} />
        </Box>
      </Box>
    </>
  );
}
