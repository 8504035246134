import React, { createContext, useState, useContext } from "react";
import api, { API_URL } from "../API";
import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
import { CaseContext } from "./caseContext";
import { FlightContext } from "./flightContext";
import { toast } from "react-toastify";

// Create the initial context state
const initialContextState = {
  frameId: undefined,
  flightId: undefined,
  sarCaseId: undefined,
  filepath: "",
  video_timestamp: 0,
  latitude: 0,
  longitude: 0,
  altitude: 0,
  alt_agl: 0,
  heading: 0,
  pitch: 0,
  roll: 0,
  footageDate: "",
};

const testFootages = [
  {
    frameId: 1,
    flightId: 1,
    sarCaseId: 1,
    filepath: "867-5309",
    video_timestamp: 1,
    latitude: 2,
    longitude: 3,
    altitude: 4,
    alt_agl: 5,
    heading: 6,
    pitch: 7,
    roll: 8,
    footageDate: new Date(),
  },
  {
    frameId: 2,
    flightId: 1,
    sarCaseId: 1,
    filepath: "867-5309",
    video_timestamp: 1,
    latitude: 2,
    longitude: 3,
    altitude: 4,
    alt_agl: 5,
    heading: 6,
    pitch: 7,
    roll: 8,
    footageDate: new Date(),
  },
];

// Create the context
export const FootageContext = createContext(initialContextState);

// Create a context provider component
export const FootageProvider = ({ children }) => {
  const { authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const { caseCtx, sarCase } = useContext(CaseContext);
  const { flightCtx } = useContext(FlightContext);
  const [footageCtx, setFootageCtx] = useState(initialContextState);
  const [footage, setFootage] = React.useState([]);

  const updateFootageCtx = async (ctx) => {
    setFootageCtx(ctx);
  };

  const getFootageByID = async (footageID, start = 0, qty = 0) => {
    console.log("in footage context - getFootageByID");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-footage-by-id",
          ftid: footageID,
          start,
          qty
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data[0]["records"][0]);
          setFootageCtx(response.data[0]["records"][0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const footageByFlightQuery = (flight_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getFootageByFlight(
          flight_id,
          query.page * query.pageSize,
          query.pageSize
        );
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Flight Footages found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getFootageByFlight = async (flight_id, start = 0, qty = 0) => {
    console.log("in footage context - getFootageByFlight");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-image-footage-by-flight",
          cid: sarCase.sarCaseId,
          fid: flight_id,
          start,
          qty
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setFootage(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        setFootage([]);
        reject(error);
      }
    });
  };

  const syncFlightFootageExif = async (flight_id) => {
    console.log("in footage context - syncFlightFootageExif");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "sync-flight-footage-exif",
          gid: sarCase.sarCaseOrgId,
          cid: sarCase.sarCaseId,
          fid: flight_id,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.status));
        } else {
          resolve(response.status);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const resetFlightFootageExifStatus = async (flight_id) => {
    console.log("in footage context - resetFlightFootageExifStatus");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "reset-flight-footage-exif",
          fid: flight_id,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.status));
        } else {
          resolve(response.status);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteFlightFootage = async (flight_id, frame_id) => {
    console.log("in footage context - deleteFlightFootage");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "delete-flight-footage",
          fid: flight_id,
          frid: frame_id,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.status));
        } else {
          resolve(response.status);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const radioMetricConverters = [
    {
      objId: 1,
      dispName: "No Conversion",
    },
    {
      objId: 4,
      dispName: "Range [-25,135] = t *(highC - lowC) / 2^bits) + lowC",
    },
    {
      objId: 2,
      dispName: "Kelvin Base = 0.04*t - 273.15",
    },
    {
      objId: 3,
      dispName: "C Base = 0.01*t - 100",
    },
  ];

  // Create the context value
  const contextValue = {
    footage,
    footageByFlightQuery,
    getFootageByFlight,
    footageCtx,
    updateFootageCtx,
    getFootageByID,
    setFootage,
    syncFlightFootageExif,
    radioMetricConverters,
    resetFlightFootageExifStatus,
    deleteFlightFootage,
  };

  return (
    <FootageContext.Provider value={contextValue}>
      {children}
    </FootageContext.Provider>
  );
};
