import * as React from "react";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
  //   useTransformEffect,
  //   useTransformContext
} from "react-zoom-pan-pinch";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestoreIcon from "@mui/icons-material/Restore";

const style = {
  position: "absolute",
  transform: "translateX(6vw)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "32px",
};
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";

export default function ImageViewModal(props) {
  const {
    imageViewModalOpen,
    handleImageViewModalOpen,
    handleImageViewModalClose,
  } = useContext(UiContext);

  //   const [isLoading, setIsLoading] = useState(false);

  const { case_id } = useParams();

  const onClose = async (event, reason) => {
    // if ((reason === "backdropClick" || reason === "escapeKeyDown"))
    //   return;
    // setIsLoading(false);
    handleImageViewModalClose();
  };

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <>
        <Button startIcon={<ZoomInIcon />} onClick={() => zoomIn()}></Button>
        <Button startIcon={<ZoomOutIcon />} onClick={() => zoomOut()}></Button>
        <Button
          startIcon={<RestoreIcon />}
          onClick={() => resetTransform()}
        ></Button>
      </>
    );
  };

  //   Hook that uploads files when files are set
  React.useEffect(() => {}, []);
  // Simple access to the context instance
  // const context = useTransformContext();
  //   useTransformEffect(({ state, instance }) => {
  //     console.log(state); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }

  //     return () => {
  //       // unmount
  //     };
  //   });

  return (
    <Modal
      open={imageViewModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={{ ...style }}>
        <TransformWrapper minScale={0}>
          <React.Fragment>
            <Controls />
            <TransformComponent>
              <ImageComponent image={props?.image} />
            </TransformComponent>
          </React.Fragment>
        </TransformWrapper>
      </Box>
    </Modal>
  );
}

const ImageComponent = (props) => {
  //   const [x, setX] = useState(0);
  //   const [y, setY] = useState(0);
  //   const _onMouseMove = (e) => {
  //     setX(e.nativeEvent.offsetX);
  //     setY(e.nativeEvent.offsetY);
  //   };
  //   useTransformEffect(({ state, instance }) => {
  //     console.log(state); // { previousScale: 1, scale: 1, positionX: 0, positionY: 0 }

  //     // return () => {
  //     //   // unmount
  //     // };
  //   });

  return (
    <>
      <img
        // onMouseMove={(e) => {
        //   console.log("EEEEEEEE");
        //   e.preventDefault();
        //   _onMouseMove(e);
        // }}
        style={{
          maxHeight: "80%",
          maxWidth: "100%",
        }}
        src={props?.image}
        alt="test"
      />
      {/* <Typography>
        {`X: ${x}`}
        {`Y: ${y}`}
      </Typography> */}
    </>
  );
};
