import React, { createContext, useState, useContext } from "react";
import { AppContext } from "./appContext";

import api, { API_URL } from "../API";
import { toast } from "react-toastify";
import { UserContext } from "./userContext";
// Create the initial context state
const initialContextState = {
  imageId: undefined,
  filepath: "",
};

// Create the context
export const ImageContext = createContext(initialContextState);

// Create a context provider component
export const ImageProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [imageCtx, setImageCtx] = useState(initialContextState);
  const [images, setImages] = React.useState([]);
  const [radiometricCoverters, setRadiometricCoverters] = React.useState([]);
  const [fileContext, setFileContext] = React.useState({
    action: "get-s3-presigned-url",
    action_purpose: "images", // todo: evolve to "case" (generic)
    presigned_ttl: 3600,
    gid: undefined,
    wid: undefined,
    cid: undefined,
    img_purpose: 0, // images: 0 - source, 1 - evidence, 2 - source subimages (not used here)
    img_view_type: 0, // 0 - color rgb, 1 - thermal rgb, 2 - radiometric
    for_report: 0,
    filename: "",
    content_type: "",
  });

  const updateFileContext = async (ctx) => {
    setFileContext(ctx);
  };
  // Function to update the ID in the store
  const updateImageCtx = async (ctx) => {
    setImageCtx(ctx);
  };

  const getFieldName = (fieldName) => {
    if (fieldName === "imageId") return "id";
    if (fieldName === "uploadDate") return "reftime";
    else return fieldName;
  };

  const ImagesByCaseQuery = (case_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getImagesByCase(
          case_id,
          query.page * query.pageSize,
          query.pageSize,
          query?.orderBy ? getFieldName(query?.orderBy?.field) : "id",
          query.orderDirection
        );
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Images found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getImagesByCase = (
    case_id,
    start,
    qty,
    orderByField = "id",
    orderDirection = "ASC"
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-images-by-case",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          cid: case_id,
          start,
          qty,
          orderByField,
          orderDirection,
          ip: "0.0.0.0",
        });
        const { data: response } = await api.post("", requestBody);

        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setImages(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getImageData = async (iid, start = 0, qty = 0) => {
    const requestBody = JSON.stringify({
      action: "get-images-by-id",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
      iid: iid,
      start,
      qty,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response);
    } else {
      setImageCtx(response.data[0]["records"][0]);
    }
  };

  const deleteImageData = async (imageId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "delete-images-by-id",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          iid: imageId,
        });

        const { data: response } = await api.post("", requestBody);
        if (response.status != "failure") {
          resolve(true);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateImageData = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // find-faces-in-image
  const findFacesInImage = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          action: "find-faces-in-image",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          imageId: imageCtx.imageId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  //upload-image-for-color-palette
  const uploadImageForColorPalette = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,

          action: "upload-image-for-rgb-color-palette",
          // action: "upload-image-for-color-palette",
          iid: imageCtx.imageId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadImageForHSLColorPalette = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          action: "upload-image-for-hsl-color-palette",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          iid: imageCtx.imageId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // upload-image-for-radiometric
  const uploadImageForRadiometric = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          ...data,
          action: "upload-image-for-radiometric-palette",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          iid: imageCtx.imageId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          console.log("response: ", response.data);
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Add radiometric conversion selection list to image upload when radiometric checked (and pass value through)
  // get-radiometric-converters
  // radiometric_converter_id => in upload api call
  // radiometric_temperatur_converter_definitions => table

  const getRadiometricCoverters = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-radiometric-converters",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status !== "failure") {
          resolve(response.data);
        } else {
          reject(new Error(response.reason[0]));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    images,
    imageCtx,
    getImageData,
    deleteImageData,
    updateImageCtx,
    getImagesByCase,
    setImages,
    setImageCtx,
    fileContext,
    updateFileContext,
    updateImageData,
    findFacesInImage,
    uploadImageForColorPalette,
    uploadImageForHSLColorPalette,
    getRadiometricCoverters,
    uploadImageForRadiometric,
    radiometricCoverters,
    setRadiometricCoverters,
    ImagesByCaseQuery,
  };

  return (
    <ImageContext.Provider value={contextValue}>
      {children}
    </ImageContext.Provider>
  );
};
