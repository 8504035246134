import React, { useState, useEffect, useContext, useRef, useMemo, Suspense } from "react";
import Image from "mui-image";
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
  Modal,
  Tooltip,
  Chip,
  Checkbox,
  TextField,
  Grid,
} from "@mui/material";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CompareIcon from "@mui/icons-material/Compare";
import ClipboardIcon from "@mui/icons-material/AssignmentOutlined";
import ClipboardCheckIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import FlagIcon from "@mui/icons-material/OutlinedFlag";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { AnalysisContext } from "../Context/analysisContext";
import { UiContext } from "../Context/uiContext";
// import ImageComparisonModal from "./ImageComparisonModal";
const ImageComparisonModal = React.lazy(() => import("./ImageComparisonModal"));
import { CustomCropper } from "../Components/Cropper/CustomCropper";

export default function FramesSlider({ handleCloseFramesSlider }) {
  const [checked, setChecked] = useState(false);
  const {
    frames: frameData,
    currFrame,
    setCurrFrame,
    sliderCurrFrame,
    setSliderCurrFrame,
    updateAnalysisFrame,
  } = useContext(AnalysisContext);
  const {
    framesSliderOpen,
    frameComparisonModalOpen,
    handleFrameComparisonModalOpen,
    handleNotificationModalOpen,
  } = useContext(UiContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [useForReport, setUseForReport] = useState(false);
  const [flagImage, setFlagImage] = useState(false);
  const [showFrameData, setShowFrameData] = useState(false);
  const [selectedExtraMarkedup, setSelectedExtraMarkedup] = useState(null);
  const descriptionInput = useRef();
  const isMobile = useMediaQuery("(max-width:900px)");
  const [selectedId, setSelectedId] = useState(
    frameData[currentIndex]?.frameId || ""
  );

  useEffect(() => {
    if (sliderCurrFrame && frameData) {
      const index = frameData.findIndex(
        (frame) => frame.frameId === sliderCurrFrame.frameId
      );
      if (index !== -1) {
        setUseForReport(frameData[index]?.use_in_report);
        setFlagImage(frameData[index]?.is_flagged);
        setChecked(sliderCurrFrame?.click_original);
        setCurrentIndex(index);
        setSelectedId(frameData[index]?.frameId || "");
        setSelectedExtraMarkedup(null);
      }
    }
  }, [sliderCurrFrame, frameData]);

  useEffect(() => {
    setSelectedId(frameData[currentIndex]?.frameId || "");
    setSelectedExtraMarkedup(null);
  }, [currentIndex, frameData]);

  useEffect(() => {
    if (descriptionInput.current) {
      descriptionInput.current.value =
        frameData[currentIndex]?.description || "";
    }
  }, [currentIndex, frameData]);

  const imageUrl = useMemo(() => {
    if (checked) {
      return frameData[currentIndex]?.filepath.replace(/\.tiff?$/i, ".jpg");
    } else {
      if (selectedExtraMarkedup) {
        const extraPath =
          frameData[currentIndex]?.extra_markedup_filepath?.[
            selectedExtraMarkedup
          ]?.filepath;
        return extraPath
          ? `https://find911-data.s3.amazonaws.com/${extraPath}`
          : frameData[currentIndex]?.markedup_filepath;
      }
      return frameData[currentIndex]?.markedup_filepath;
    }
  }, [checked, selectedExtraMarkedup, currentIndex, frameData]);

  const handleNext = async () => {
    if (currentIndex < frameData.length - 1) {
      if (
        frameData[currentIndex]?.frameDataId &&
        (frameData[currentIndex]?.is_flagged != flagImage ||
          frameData[currentIndex]?.use_in_report != useForReport)
      ) {
        let description = "";
        if (useForReport) description = descriptionInput?.current.value;
        else description = frameData[currentIndex]?.description;

        await updateAnalysisFrame(frameData[currentIndex]?.frameDataId, {
          description: description,
          is_flagged: flagImage,
          use_in_report: useForReport,
        });
      }

      if (descriptionInput.current) {
        descriptionInput.current.value =
          frameData[currentIndex + 1]?.description;
      }
      setUseForReport(frameData[currentIndex + 1]?.use_in_report);
      setFlagImage(frameData[currentIndex + 1]?.is_flagged);
      setCurrentIndex(currentIndex + 1);
      setSelectedExtraMarkedup(null);
    }
  };

  const handlePrevious = async () => {
    if (currentIndex > 0) {
      if (
        frameData[currentIndex]?.frameDataId &&
        (frameData[currentIndex]?.is_flagged != flagImage ||
          frameData[currentIndex]?.use_in_report != useForReport)
      ) {
        let description = "";
        if (useForReport) description = descriptionInput?.current.value;
        else description = frameData[currentIndex]?.description;

        await updateAnalysisFrame(frameData[currentIndex]?.frameDataId, {
          description: description,
          is_flagged: flagImage,
          use_in_report: useForReport,
        });
      }

      if (descriptionInput.current) {
        descriptionInput.current.value =
          frameData[currentIndex - 1]?.description;
      }
      setUseForReport(frameData[currentIndex - 1]?.use_in_report);
      setFlagImage(frameData[currentIndex - 1]?.is_flagged);
      setCurrentIndex(currentIndex - 1);
      setSelectedExtraMarkedup(null);
    }
  };

  const handleClose = async () => {
    if (
      frameData[currentIndex]?.is_flagged != flagImage ||
      frameData[currentIndex]?.use_in_report != useForReport
    ) {
      let description = "";
      if (useForReport) {
        description = descriptionInput?.current.value;
      } else {
        description = frameData[currentIndex]?.description;
      }
      handleCloseFramesSlider(true);
      if (frameData[currentIndex]?.frameDataId) {
        await updateAnalysisFrame(frameData[currentIndex]?.frameDataId, {
          description: description,
          is_flagged: flagImage,
          use_in_report: useForReport,
        });
      }
    } else {
      handleCloseFramesSlider(false);
    }
  };

  const handleIdChange = (event) => {
    const selectedIndex = frameData.findIndex(
      (frame) => frame.frameId === event.target.value
    );
    if (selectedIndex !== -1) {
      setCurrentIndex(selectedIndex);
      setSelectedExtraMarkedup(null);
    }
  };

  const onSwitchChange = () => {
    setChecked(!checked);
    if (!checked) {
      setSelectedExtraMarkedup(null);
    }
  };

  const sendNotificationFrameDataImage = () => {
    setCurrFrame(frameData[currentIndex]);
    handleNotificationModalOpen();
  };

  const comparisonImages = useMemo(
    () => ({
      left: frameData[currentIndex]?.filepath.replace(/\.tiff?$/i, ".jpg"),
      right: imageUrl,
    }),
    [frameData, currentIndex, imageUrl]
  );

  if (framesSliderOpen && frameData) {
    return (
      <>
        <Suspense fallback={<div>Loading Comparison...</div>}>
          {frameComparisonModalOpen && (
            <ImageComparisonModal
              framesCompare={true}
              leftImageSrc={comparisonImages.left}
              rightImageSrc={comparisonImages.right}
            />
          )}
        </Suspense>
        <Modal
          open={framesSliderOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "95%" : "95%",
              height: isMobile ? "95%" : "95%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Close Button */}
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: 10, right: 10 }}
            >
              <CloseIcon />
            </IconButton>

            {/* Top Bar with Navigation and Controls */}
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  onClick={handlePrevious}
                  disabled={currentIndex === 0}
                >
                  <NavigateBeforeIcon />
                </IconButton>
                <Select
                  value={selectedId}
                  onChange={handleIdChange}
                  sx={{ minWidth: "150px" }}
                >
                  {frameData.map((frame) => (
                    <MenuItem key={frame.frameId} value={frame.frameId}>
                      {frame.frameId}
                    </MenuItem>
                  ))}
                </Select>
                <IconButton
                  onClick={handleNext}
                  disabled={currentIndex === frameData.length - 1}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {!checked &&
                  frameData[currentIndex]?.extra_markedup_filepath && (
                    <Select
                      value={selectedExtraMarkedup || "Default Marked"}
                      onChange={(e) => setSelectedExtraMarkedup(e.target.value)}
                      sx={{ minWidth: "150px" }}
                      disabled={checked}
                    >
                      <MenuItem value="Default Marked">Default Marked</MenuItem>
                      {Object.keys(
                        frameData[currentIndex]?.extra_markedup_filepath || {}
                      ).map((key) => (
                        <MenuItem key={key} value={key}>
                          {`Marked ${key}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}

                <Chip
                  label={checked ? "Show Marked Image" : "Show Original Image"}
                  onClick={onSwitchChange}
                />
                <Tooltip
                  title={
                    frameData[currentIndex]?.markedup_display_filepath.includes(
                      "NoData.jpg"
                    )
                      ? "No Marked Image"
                      : "Compare images"
                  }
                >
                  <IconButton
                    onClick={handleFrameComparisonModalOpen}
                    disabled={frameData[
                      currentIndex
                    ]?.markedup_display_filepath.includes("NoData.jpg")}
                  >
                    <CompareIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Send notification">
                  <IconButton onClick={sendNotificationFrameDataImage}>
                    <CircleNotificationsIcon />
                  </IconButton>
                </Tooltip>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Tooltip title={flagImage ? "" : "Flag image"}>
                    <Checkbox
                      onChange={() => setFlagImage(!flagImage)}
                      checked={flagImage}
                      icon={<FlagIcon />}
                      checkedIcon={<FlagIcon color="error" />}
                    />
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Tooltip title={useForReport ? "" : "Use in report"}>
                    <Checkbox
                      onChange={() => {
                        setUseForReport(!useForReport);
                        setShowFrameData(false);
                      }}
                      checked={useForReport}
                      icon={<ClipboardIcon />}
                      checkedIcon={<ClipboardCheckIcon />}
                    />
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Tooltip
                    title={
                      !showFrameData ? "Show Frame Data" : "Hide Frame Data"
                    }
                  >
                    <IconButton
                      onClick={() => {
                        setShowFrameData(!showFrameData);
                      }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            {/* Image and Frame Data Section */}
            <Grid
              container
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                height: "100%",
                width: "100%",
              }}
            >
              {/* Image Container */}
              <Grid
                item
                xs={12}
                md={useForReport ? 8 : showFrameData ? 8 : 12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  overflow: "hidden",
                  height: "100%",
                  width: "100%",
                }}
              >
                <CustomCropper imageUrl={imageUrl} noCrop={true} />
              </Grid>

              {showFrameData ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: "auto",
                      bgcolor: "background.default",
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Frame Data
                    </Typography>
                    <Box
                      component="table"
                      sx={{
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid",
                        borderColor: "divider",
                      }}
                    >
                      <Box
                        component="thead"
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <Box component="tr">
                          <Box
                            component="th"
                            sx={{
                              p: 1,
                              textAlign: "left",
                              borderRight: "1px solid",
                              borderColor: "divider",
                            }}
                          >
                            Field
                          </Box>
                          <Box component="th" sx={{ p: 1, textAlign: "left" }}>
                            Value
                          </Box>
                        </Box>
                      </Box>
                      <Box component="tbody">
                        {frameData[currentIndex] &&
                          Object.entries(frameData[currentIndex])
                            .filter(
                              ([field]) =>
                                ![
                                  "filepath",
                                  "display_filepath",
                                  "markedup_filepath",
                                  "markedup_display_filepath",
                                  "extra_markedup_filepath",
                                  "description",
                                  "is_processed",
                                  "is_archived",
                                  "is_representative",
                                ].includes(field)
                            )
                            .map(([field, value]) => (
                              <Box
                                component="tr"
                                key={field}
                                sx={{
                                  borderBottom: "1px solid",
                                  borderColor: "divider",
                                }}
                              >
                                <Box
                                  component="td"
                                  sx={{
                                    p: 1,
                                    borderRight: "1px solid",
                                    borderColor: "divider",
                                  }}
                                >
                                  <strong>{field}</strong>
                                </Box>
                                <Box component="td" sx={{ p: 1 }}>
                                  {typeof value === "object"
                                    ? JSON.stringify(value)
                                    : value}
                                </Box>
                              </Box>
                            ))}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}

              {useForReport ? (
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: "auto",
                      bgcolor: "background.default",
                      borderRadius: 2,
                      p: 2,
                    }}
                  >
                    <Tooltip
                      title={"This will automatically save the description"}
                    >
                      <TextField
                        label="Add notes"
                        inputRef={descriptionInput}
                        multiline
                        maxRows={5}
                        fullWidth
                        defaultValue={
                          frameData[currentIndex]?.description || ""
                        }
                      />
                    </Tooltip>
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Modal>
      </>
    );
  } else {
    return null;
  }
}
