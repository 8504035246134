import * as React from "react";
import { useContext, useState, useRef } from "react";
import { Box, Modal, Tooltip, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import HitsTable from "../Hits/HitsTable";
import { AnalysisContext } from "../Context/analysisContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "1200px", // Limit maximum width for larger screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh", // Limit height to 90% of the viewport height
  overflow: "auto", // Enable scrolling for the modal
};

const ClickableSVG = styled("svg")((props) => ({
  height: `${props?.imageDimensions.height}px`,
  width: `${props?.imageDimensions.width}px`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url("${props?.imagePath}")`,
  maxWidth: "100%", // Ensure the image doesn't overflow on smaller screens
}));

const Container = styled("div")({
  width: "100%",
  maxHeight: "500px",
  justifyContent: "center",
  alignItems: "center",
  overflow: "auto", // Enable scrolling for the container
});

export default function HitsViewModal(props) {
  const activeFrameDataImage = props.activeFrameDataImage;
  const { hitsViewModalOpen, handleHitsViewModalClose } = useContext(UiContext);
  const { getHitsByFrameDataImage, getHitsCountByFrameDataImage } =
    useContext(AnalysisContext);
  const { hits } = useContext(AnalysisContext);
  const [loading, setLoading] = useState(true);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [totalCount, setTotalCount] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile devices

  const onClose = async (event, reason) => {
    handleHitsViewModalClose();
  };

  React.useEffect(() => {
    if (hits.length !== 0) {
      setLoading(false);
    }
  }, [hits]);

  React.useEffect(() => {
    return () => {
      handleHitsViewModalClose();
    };
  }, []);

  const getClickCoords = (event) => {
    const e = event.target;
    const dim = e.getBoundingClientRect();
    const x = event.clientX - dim.left;
    const y = event.clientY - dim.top;
    setSelectedPoints([
      ...selectedPoints,
      {
        x: x,
        y: y,
      },
    ]);
  };

  const hasCombinationOrNearest = (targetX, targetY, threshold = 10) => {
    let nearestPoint = null;
    let nearestDistance = Infinity;

    for (const point of selectedPoints) {
      const distance = Math.sqrt(
        (point.x - targetX) ** 2 + (point.y - targetY) ** 2
      );

      if (distance < nearestDistance) {
        nearestDistance = distance;
        nearestPoint = point;
      }
    }

    if (nearestDistance <= threshold) {
      return true;
    }

    return selectedPoints.some(
      (point) => point.x === targetX && point.y === targetY
    );
  };

  React.useEffect(() => {
    const img = new Image();
    img.src = activeFrameDataImage?.filepath;

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      setImageDimensions({ width, height });
    };
  }, [activeFrameDataImage?.filepath]);

  return (
    <Modal
      open={hitsViewModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: isMobile ? "95%" : "90%" }}>
        <Container>
          <ClickableSVG
            imagePath={activeFrameDataImage?.filepath}
            onClick={getClickCoords}
            imageDimensions={imageDimensions}
          >
            {selectedPoints.map(({ x, y }) => (
              <Tooltip key={`${x}-${y}`} title={`X:${x}, Y:${y}`}>
                <circle
                  cx={x}
                  cy={y}
                  r="10"
                  stroke="black"
                  strokeWidth="1"
                  fill="blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedPoints([
                      ...selectedPoints.filter((el) => {
                        return (
                          el.x != e.target.getAttribute("cx") &&
                          el.y != e.target.getAttribute("cy")
                        );
                      }),
                    ]);
                  }}
                />
              </Tooltip>
            ))}
          </ClickableSVG>
        </Container>

        <Box sx={{ paddingTop: "2vh" }}>
          <HitsTable
            loading={loading}
            activeFrameDataImage={activeFrameDataImage}
            title="Hits info"
            options={{
              toolbar: true,
              sorting: false,
              draggable: false,
              grouping: false,
              search: true,
              paging: true,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              maxBodyHeight: isMobile ? "300px" : "500px", // Adjust height for mobile
              actionsCellStyle: { zIndex: "0", position: "relative" },
              headerStyle: { position: "sticky", top: 0, zIndex: 100 },
              selection: false,
              rowStyle: (rowData) => ({
                backgroundColor: hasCombinationOrNearest(rowData.x, rowData.y)
                  ? "#EEE"
                  : "#FFF",
              }),
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
}