import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Table from "../table/table";

import { toast } from "react-toastify";

import placeholderImage from "../Assets/image.png";
import { ReportsAssetsContext } from "../Context/ReportAssetsContext";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; //

export default function ReportAssetsTable(props) {
  const [imageStatus, setImageStatus] = useState({});

  const { sarCase } = useContext(CaseContext);

  const {
    reportAssetsByCaseQuery,
    reportAssets,
    setReportAssets,
    reportAssetsCtx,
    updateReportAssetsCtx,
  } = useContext(ReportsAssetsContext);

  const { deleteImageData, updateImageData } = useContext(ImageContext);

  const { openInNewTab } = useContext(UiContext);

  useEffect(() => {
    const preloadImages = async (data) => {
      const promises = data.map(
        (row) =>
          new Promise((resolve) => {
            if (!row.display_filepath.endsWith(".pdf")) {
              const img = new Image();
              img.src = row.display_filepath || placeholderImage;
              img.onload = () => resolve({ id: row.imageId, status: "loaded" });
              img.onerror = () => resolve({ id: row.imageId, status: "error" });
            } else {
              resolve({ id: row.imageId, status: "loaded" });
            }
          })
      );

      const results = await Promise.all(promises);
      const statusMap = results.reduce((acc, { id, status }) => {
        acc[id] = status;
        return acc;
      }, {});

      setImageStatus(statusMap);
    };

    preloadImages(reportAssets);
  }, [reportAssets]);

  const columns = useMemo(
    () => [
      {
        title: "Image name",
        field: "imageId",
        editable: "never",
        render: (rowData) =>
          rowData.filepath.substring(21 + rowData.filepath.lastIndexOf("/")),
      },
      {
        title: "Thumbnail",
        field: "display_filepath",
        editable: "never",
        render: (rowData) => (
          <>
            {!rowData.filepath.endsWith(".pdf") &&
            imageStatus[rowData.imageId] === "error" ? (
              <Tooltip title="Re-upload">
                <img
                  src={placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                  onClick={() =>
                    openInNewTab(
                      `Cases/${
                        rowData?.sarCaseId
                      }/upload/images?imageName=${rowData.filepath.substring(
                        21 + rowData.filepath.lastIndexOf("/")
                      )}&imageID=${rowData.imageId}&imagePurpose=${
                        rowData.img_purpose
                      }&imageViewType=${rowData.img_view_type}`
                    )
                  }
                />
              </Tooltip>
            ) : rowData.filepath.endsWith(".pdf") ? (
              <a
                href={rowData.filepath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PictureAsPdfIcon style={{ color: "red", cursor: "pointer" }} />
              </a>
            ) : (
              <a
                href={rowData.filepath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={rowData.display_filepath || placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                />
              </a>
            )}
          </>
        ),
      },
      {
        title: "Purpose",
        field: "img_purpose",
        editable: "never",
        render: (rowData) =>
          rowData.img_purpose == 0
            ? `Source`
            : rowData.img_purpose == 1
            ? `Evidence`
            : rowData.img_purpose == 5
            ? `Report Asset`
            : "",
      },

      {
        title: "For Report",
        field: "use_in_report",
        editable: "onUpdate",
        render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">report</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.use_in_report}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Report (Seq)",
        field: "img_sequence",
        editable: "onUpdate",
        render: (rowData) =>
          rowData.use_in_report ? String(rowData.img_sequence) : `-`,
      },
      {
        title: "Date",
        field: "uploadDate",
        editable: "never",
      },
      {
        title: "Description",
        field: "description",
        editable: "onUpdate",
        editComponent: (tableData) => (
          <TextField
            value={tableData.rowData.description}
            onChange={(e) => tableData.onChange(e.target.value)}
            multiline={true}
            maxRows={5}
          />
        ),
      },
    ],
    [imageStatus]
  );

  const navigate = useNavigate();

  const handleDeleteClick = async (oldData) => {
    return new Promise(async (resolve, reject) => {
      try {
        updateReportAssetsCtx({
          imageId: oldData.imageId,
          filepath: oldData.filepath,
        });
        let dataUpdate = reportAssets.filter(
          (el) => el.imageId !== oldData.imageId
        );
        setReportAssets([...dataUpdate]);
        await deleteImageData(oldData.imageId);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        resolve(error);
      }
    });
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      const dataUpdate = [...tableData];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setNewTableData([...dataUpdate]);
      await updateImageData({
        action: "update-image",
        iid: newData.imageId,
        // img_purpose: newData.img_purpose,
        // img_view_type: newData.img_view_type,
        // radiometric_converter_id: newData.radiometric_converter_id,
        use_in_report: newData.use_in_report,
        img_sequence: newData.img_sequence,
        description: newData.description,
      });

      updateImagesContext(oldData.imageId, newData);
      resolve();
    });

  const updateImagesContext = (imageId, newImageData) => {
    const imagesUpdate = [...reportAssets];
    const imagesIndex = reportAssets.findIndex(
      (img) => img.imageId === imageId
    );
    const { tableData, ...newResponse } = newImageData;
    imagesUpdate[imagesIndex] = newResponse;
    setReportAssets([...imagesUpdate]);
  };

  const tableRef = React.createRef();
  const { case_id } = useParams();

  return (
    <>
      <Table
        tableId={props?.tableId || "Table-Images"}
        title={""}
        dataQuery={true}
        data={(query) => reportAssetsByCaseQuery(case_id, query)}
        tableRef={tableRef}
        columns={columns}
        options={props?.options}
        loading={props?.loading}
        onRowDelete={props?.deleteable ? handleDeleteClick : null}
        deleteable={props?.deleteable}
        onRowUpdate={props?.editable ? onUpdate : null}
        editable={props?.editable}
        recordType="report asset"
        // onRowView={handleViewClick}
      />
    </>
  );
}
