import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import {
  systemContext,
  userContext,
  caseContext,
  analysisContext,
} from "../index";

import { Box, Button, Stack, Grid, Typography } from "@mui/material";
import AnalysisTable from "../Analysis/AnalysisTable";
import { AnalysisContext } from "../Context/analysisContext";
import { CaseContext } from "../Context/caseContext";
import { toast } from "react-toastify";
import { MainLayout } from "../Layouts/MainLayout";
import { UiContext } from "../Context/uiContext";

export default function AnalysisSelectPage() {
  const { statusModalOpen } = useContext(UiContext);
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { analyses, getAnalysesByCase } = useContext(AnalysisContext);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const { case_id } = useParams();
  // const [analyses, setAnalyses] = React.useState([]);

  const fetchData = async () => {
    try {
      setAnalysisLoading(true);
      await getCaseByID(case_id);
      await getAnalysesByCase(case_id);
      setAnalysisLoading(false);
    } catch (error) {
      setAnalysisLoading(false);
      toast.error(error.message);
    }
  };

  const fetchAnalysesByCase = async () => {
    try {
      setAnalysisLoading(true);
      await getAnalysesByCase(case_id);
      setAnalysisLoading(false);
    } catch (error) {
      setAnalysisLoading(false);
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      if (analyses?.[0]?.initial || !sarCase.sarCaseId) {
        fetchData();
      } else {
        fetchAnalysesByCase();
      }
    }
  }, []);

  return (
    <MainLayout title="Analysis">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={`/Cases/${case_id}/Flights`}
          sx={{ marginLeft: "auto" }}
        >
          Create New Analysis
        </Button>
      </Box>
      <AnalysisTable
        analyses={analyses?.[0]?.initial ? [] : analyses}
        loading={analysisLoading}
        editable={true}
      />
    </MainLayout>
  );
}
