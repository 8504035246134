// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-content {
    z-index: 2 !important;
    /* Add any other custom styles as needed */
  }`, "",{"version":3,"sources":["webpack://./src/CustomDropdownStyles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,0CAA0C;EAC5C","sourcesContent":[".dropdown-content {\n    z-index: 2 !important;\n    /* Add any other custom styles as needed */\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
