import React, {
  useState,
  useContext,
  createRef,
  useRef,
  useEffect,
} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { ImageContext } from "../Context/imageContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { AppContext } from "../Context/appContext";
import { API_URL } from "../API";
import useMinMax from "../Hooks/useMinMax";

import CustomColorModal from "../Components/CustomColorModal";
import CustomColorLite from "../Components/CustomColorLite";
import ColorRange from "../Components/ColorRange";
import ColorScale from "../../src/Components/ColorScale";
import ColorScaleLite from "../Components/ColorScaleLite";

// MUI Components
import {
  Box,
  Grid,
  Button,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Input,
  Container,
  useMediaQuery,
} from "@mui/material";

// MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// NPM Imports
import useEyeDropper from "use-eye-dropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";
const ACTION = "create-select-case";
const defaultValues = {
  gid: "1",
  wid: "1",
  ip: "0.0.0.0",
  action: ACTION,
};

import { CustomCropper } from "../Components/Cropper/CustomCropper";
import { PreviewResults } from "../Components/Cropper/components/PreviewResults";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/classic.css";
import FAQ from "../Components/FAQ";
import FAQS from "../Static/FAQs";

export default function BuildColorPaletteRGB() {
  const navigate = useNavigate();
  const { authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [sarCase, getCaseByID] = useState(CaseContext);
  const { imageCtx, getImageData, uploadImageForColorPalette } =
    useContext(ImageContext);
  const { createColorPaletteSearchConfigRGB } = useContext(SearchConfigContext);
  const { case_id, image_id } = useParams();
  console.log("image_id::::", image_id);
  const isMobile = useMediaQuery("(max-width:900px)"); // Detect mobile devices
  const showOnlyColorPaletteList = image_id ? false : true;

  const { open, close, isSupported } = useEyeDropper();
  const [error, setError] = useState();
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef();
  const paletteNameRef = useRef();
  const searchDescriptionInput = useRef();
  const [image, setImage] = useState(testImage);
  const [selectedRgbColor, setSelectedRgbColor] = useState(null);
  const [selectedHexColor, setSelectedHexColor] = useState(null);
  const [selectedHSLColor, setSelectedHSLColor] = useState(null);
  const [paletteArray, setPaletteArray] = useState([]);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [showCustomColor, setShowCustomColor] = useState(false);
  const [color, setColor] = useState("rgba(255,255,255,1)");
  const [colorIndex, setColorIndex] = useState(null);
  const [currentIndexRgb, setCurrentIndexRgb] = useState(null);
  const [currentIndexRgb2, setCurrentIndexRgb2] = useState(null);
  const [currentIndexHex, setCurrentIndexHex] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [colorRangeVisible, setColorRangeVisible] = useState(false);
  const [colorRange, setColorRange] = useState();
  // const [createdColorRange, setCreatedColorRange] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [preview, setPreview] = useState();

  // useEyeDropper will reject/cleanup the open() promise on unmount,
  // so setState never fires when the component is unmounted.
  const pickColor = () => {
    open()
      .then((color) => {
        let rgb = hexToRgb(color.sRGBHex);
        let hsl = rgbToHSL(rgb.r, rgb.g, rgb.b);
        setSelectedRgbColor(`rgb(${rgb.r},${rgb.g},${rgb.b})`);
        setSelectedHSLColor(hsl);
        setSelectedHexColor(color.sRGBHex);
      })
      .catch((e) => {
        console.log(e);
        // Ensures component is still mounted
        // before calling setState
        if (!e.canceled) toast.error(error.message);
      });
  };

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function rgbToHSL(r, g, b) {
    // Normalize r, g, b to the range [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Find min and max values of r, g, and b
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;

    // Calculate Luminance
    let l = (max + min) / 2;

    // Calculate Hue and Saturation
    let h, s;
    if (delta === 0) {
      h = s = 0; // It's a gray color
    } else {
      s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

      switch (max) {
        case r:
          h = (g - b) / delta + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / delta + 2;
          break;
        case b:
          h = (r - g) / delta + 4;
          break;
      }

      h = Math.round(h * 60); // Convert to degrees
    }

    // Convert to percentage
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  React.useEffect(() => {
    !showOnlyColorPaletteList ? setImage(imageCtx.filepath) : null;
  }, [imageCtx.filepath]);

  const extractRGBValues = (rgbString) => {
    const rgbArray = rgbString.match(/\d+/g);
    if (rgbArray && rgbArray.length === 3) {
      return rgbArray.map(Number);
    }
    throw new Error("Invalid RGB string format");
  };

  // Function to calculate base64 string size in bytes
  function validateBase64Size(base64String) {
    const padding = base64String.endsWith("==")
      ? 2
      : base64String.endsWith("=")
      ? 1
      : 0;
    const base64Length = base64String.length;
    const imageSizeInBytes = base64Length * 0.75 - padding;
    // Check if the image exceeds the API gateway's limit
    const apiGatewayLimit = 1800000; // in MB
    if (imageSizeInBytes > apiGatewayLimit) {
      // Handle the error - Image size exceeds the limit
      return false;
    } else {
      return true;
    }
  }

  const fetchData = async (fetchImageData) => {
    try {
      await getCaseByID(case_id);
      if (fetchImageData) {
        await getImageData(image_id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      if (showOnlyColorPaletteList && !imageCtx.imageId) {
        fetchData(false);
      } else if (
        !showOnlyColorPaletteList &&
        !sarCase.sarCaseId &&
        !imageCtx.imageId
      ) {
        fetchData(true);
      } else {
        fetchData(false);
      }
    }
  }, [sarCase.sarCaseId, imageCtx.imageId]);

  async function removePcnt(objects) {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      const newObj = { ...obj };
      delete newObj.pcnt;
      return newObj;
    });
  }

  const sendKeyColors = async (paramData) => {
    if (checkedElements.length === 0) {
      toast.warning("Select atleast one item");
      return;
    }

    if (!paletteNameRef.current.value) {
      toast.warning("Name is required.");
      return;
    }

    const paletteColorsEdit = await removePcnt(paramData);
    const extractedObjects = paletteColorsEdit.map((input) =>
      !input.isRange
        ? {
            rgb: extractRGBValues(input.rgb),
          }
        : {
            rgb: extractRGBValues(input.rgb),
            rgb2: extractRGBValues(input.rgb2),
          }
    );

    try {
      const response = await createColorPaletteSearchConfigRGB({
        paletteName: paletteNameRef.current.value,
        cid: parseInt(case_id),
        rgbList: extractedObjects,
        description: searchDescriptionInput.current.value,
      });
      console.log("createColorPaletteSearchConfigRGB response:", response);
      navigate(`/cases/${case_id}/search-configs`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addColorToPalette = (rgbValue, hexValue, isRange = false) => {
    if (!rgbValue) {
      return;
    }
    const removeAlpha = rgbValue.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    setPaletteArray([
      ...paletteArray,
      { rgb: removeAlpha, hex: hexValue, isRange },
    ]);
  };

  const removeColorFromPalette = (index) => {
    const updatedPaletteArray = [...paletteArray];
    updatedPaletteArray.splice(index, 1);
    setPaletteArray(updatedPaletteArray);
  };

  const editColorInPalette = (mapIndex, newRgb, newRgb2) => {
    const updatedPaletteArray = [...paletteArray];
    const removeAlpha = newRgb.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    updatedPaletteArray[mapIndex].rgb = removeAlpha;

    if (newRgb2) {
      const removeAlpha2 = newRgb2.replace(
        /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
        "rgb($1)"
      );
      updatedPaletteArray[mapIndex].rgb2 = removeAlpha2;
    }

    setPaletteArray(updatedPaletteArray);
  };

  const onClose = () => {
    setIsEditingColor(!isEditingColor);
  };

  const onClickEditColor = (index, rgb, rgb2, hex) => {
    setColorIndex(index);
    setIsEditingColor(!isEditingColor);
    setCurrentIndexRgb(rgb);
    setCurrentIndexRgb2(rgb2);
    setCurrentIndexHex(hex);
  };

  const addCustomColor = () => {
    setShowCustomColor(!showCustomColor);
  };

  const clearPalette = () => {
    setPaletteArray([]);
  };

  const handleCheckboxChange = (event, index, color) => {
    if (event.target.checked) {
      // Add the index to checkedItems
      setCheckedItems([...checkedItems, color, index]);
      // setPaletteArray([...paletteArray, { rgb: rgbValue, hex: hexValue }]
    } else {
      // Remove the index from checkedItems
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const checkedElements = paletteArray.filter((color, index) =>
    checkedItems.includes(index)
  );

  const checkedRangeColors = checkedElements;

  const toggleColorRange = () => {
    setColorRangeVisible(!colorRangeVisible);
  };

  const onChangeSetColorRange = (value) => {
    setColorRange(value);
  };
  function adjustRGB(startRGB, endRGB, buffer) {
    const adjustedStart = startRGB.map((value) => Math.max(value - buffer, 0));
    const adjustedEnd = endRGB.map((value) => Math.min(value + buffer, 255));

    return [adjustedStart, adjustedEnd];
  }

  const onClickCreateRange = (value, rangeBuffer = 10, minValue, maxValue) => {
    // let tempValues = value.map((el) => {
    //   return {
    //     ...el,
    //     rgb: el.rgb.replace(/rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/, "rgb($1)"),
    //   };
    // });
    // const getMinMaxFromRange = useMinMax(tempValues);
    // const [adjustedStart, adjustedEnd] = adjustRGB(
    //   getMinMaxFromRange[0],
    //   getMinMaxFromRange[1],
    //   rangeBuffer
    // );

    setPaletteArray([
      ...paletteArray,
      {
        // rgb: `rgb(${adjustedStart.join(",")})`,
        rgb: minValue,
        // rgb2: `rgb(${adjustedEnd.join(",")})`,
        rgb2: maxValue,
        hex: "",
        isRange: true,
      },
    ]);
    // setCreatedColorRange(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        // height: "93vh",
        overflow: "auto",
        paddingX: isMobile ? 2 : 10, // Adjust padding for mobile
        paddingTop: isMobile ? 2 : 10, // Adjust padding for mobile
      }}
    >
      {showCustomColor ? (
        <CustomColorLite
          value={color}
          paletteArray={paletteArray}
          onChange={setColor}
          onClose={addCustomColor}
          addColorToPalette={addColorToPalette}
        />
      ) : null}
      {colorRangeVisible ? (
        <ColorRange
          isVisible={colorRangeVisible}
          onClose={toggleColorRange}
          checkedElements={checkedRangeColors}
          addColorToPalette={addColorToPalette}
          onClickCreateRange={onClickCreateRange}
          onClickEditColor={onClickEditColor}
        />
      ) : null}
      <Grid container spacing={isMobile ? 2 : 4} id="color-palette-builder">
        {!showOnlyColorPaletteList && (
          <Grid item xs={12} md={6} id="color-picker">
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} id="cropper" sx={{maxHeight: "50vh"}}>
                <Box>
                  <CustomCropper
                    imageUrl={image}
                    setPreview={setPreview}
                    setPaletteArray={setPaletteArray}
                    paletteArray={paletteArray}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} id="color-picker">
                <Paper
                  elevation={16}
                  sx={{
                    padding: 2,
                    marginTop: "10px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
                    justifyContent: "left",
                    alignItems: isMobile ? "center" : "flex-start", // Center align on mobile
                  }}
                >
                  <Grid container gap={2} xs={12}>
                    <Grid item xs={10} md={4} id="color-picker-area">
                      <Box
                        sx={{
                          width: "100%",
                          height: "90%",
                          maxHeight: "300px", // Adjust height for mobile
                          outline: "2px solid black",
                        }}
                      >
                        {preview && <PreviewResults preview={preview} />}
                      </Box>
                    </Grid>

                    <Grid item xs={10} md={2} id="color-picker-buttons">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {isSupported() ? (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "none",
                              },
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={pickColor}
                          >
                            Pick color
                          </Button>
                        ) : (
                          <span>
                            EyeDropper API not supported in this browser
                          </span>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "none",
                            },
                            marginTop: 2,
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={() =>
                            addColorToPalette(
                              selectedRgbColor,
                              selectedHexColor
                            )
                          }
                        >
                          Add to Palette
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={10} md={4} id="color-picker-selected">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",

                          // marginLeft: isMobile ? 0 : 6, // Adjust margin for mobile
                          // marginTop: isMobile ? 2 : 0, // Adjust margin for mobile
                        }}
                      >
                        <Box
                          sx={{
                            width: 100,
                            height: 100,
                            outline: "2px solid black",
                            marginLeft: isMobile ? 0 : "10px",
                            margin: "0 auto",

                            backgroundColor: `${selectedRgbColor}`,
                          }}
                        ></Box>
                        <Box style={{ textAlign: "center" }}>
                          <Typography sx={{ margin: "5px" }}>
                            {selectedRgbColor}
                          </Typography>
                          <Typography sx={{ margin: "5px" }}>
                            {selectedHexColor}
                          </Typography>
                          <Typography sx={{ margin: "5px" }}>
                            {selectedHSLColor}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} md={image_id ? 6 : 12} id="color-palette-list">
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "100%",
              // height: isMobile ? "auto" : "82vh", // Adjust height for mobile
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isMobile ? "0px" : "1.5vw", // Adjust padding for mobile
              marginTop: isMobile ? "5rem" : "0rem", // Adjust padding for mobile
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                placeholder="Enter Palette Name"
                inputRef={paletteNameRef}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                placeholder="Optional Description"
                inputRef={searchDescriptionInput}
                fullWidth
                multiline
                sx={{
                  marginTop: "1vw",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: isMobile ? "40vh" : "58vh", // Adjust height for mobile
                  marginTop: "1vw",
                  overflow: "auto",
                  scrollbarWidth: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                {paletteArray.map((color, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      paddingLeft: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                      paddingRight: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                      marginTop: "2px",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <CustomColorModal
                      mapIndex={colorIndex}
                      isEditingColor={isEditingColor}
                      onClose={onClose}
                      onChangeColor={editColorInPalette}
                      currIndexRgb={currentIndexRgb}
                      currIndexRgb2={currentIndexRgb2}
                      currentIndexHex={currentIndexHex}
                    />
                    <Box sx={{ marginRight: 2 }}>
                      <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, index, color)
                        }
                        checked={checkedItems.includes(index)}
                      />
                    </Box>
                    {color?.isRange ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            &nbsp; &nbsp;
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `${color.rgb}`,
                                border: "2px solid #000",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `${color.rgb2}`,
                                border: "2px solid #000",
                              }}
                            ></div>
                          </div>
                        </div>
                        <Box sx={{ marginLeft: 3 }}>
                          <Typography>{color.rgb},</Typography>
                          <Typography>{color.rgb2}</Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: "40px",
                            height: "40px",
                            outline: "2px solid black",
                            marginBottom: "5px",
                            backgroundColor: `${color.rgb}`,
                          }}
                        ></Box>
                        <Box sx={{ marginLeft: 3 }}>
                          <Typography>{color.rgb}</Typography>
                        </Box>
                      </>
                    )}

                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginBottom: "2px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CreateIcon
                        onClick={() =>
                          onClickEditColor(
                            index,
                            color.rgb,
                            color.isRange ? color.rgb2 : null,
                            color.hex
                          )
                        }
                      />
                      <DeleteForeverIcon
                        sx={{ marginLeft: 3 }}
                        onClick={() => removeColorFromPalette(index)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
                onClick={() => addCustomColor()}
              >
                Add Custom Color
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
                onClick={() => toggleColorRange()}
              >
                Add Color Range
              </Button>
              <Button
                onClick={() => sendKeyColors(checkedElements)}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          padding: isMobile ? "5rem" : "1.5vw", // Adjust padding for mobile
          marginTop: isMobile ? "5rem" : "5rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQS.build_color_palette_RGB_FAQ} />
      </Box>
    </Box>
  );
}
