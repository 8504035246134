import { useEffect, useState, useContext } from "react";
import { ImageContext } from "../Context/imageContext";
export default () => {
  const { radiometricCoverters, setRadiometricCoverters, getRadiometricCoverters } = useContext(ImageContext);

  const getData = async () => {
    const response = await getRadiometricCoverters();
    setRadiometricCoverters(response);
  };

  useEffect(() => {
    if(radiometricCoverters.length === 0)
      getData();
  }, [radiometricCoverters]);

  return radiometricCoverters;
};
