export const API_URL = 'https://def97x4305.execute-api.us-east-1.amazonaws.com/production'
import { toast } from "react-toastify";

import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
  baseURL: API_URL,
});

// Function to refresh the token
const refreshToken = async () => {
  try {
    // Use the Cognito SDK to refresh the token
    const currentSession = await Auth.currentSession();

    // Extract the refreshed token from the session
    const refreshedToken = currentSession.accessToken.jwtToken;

    // Return the refreshed token
    return refreshedToken;
  } catch (error) {
    // Handle the error if token refresh fails
    toast.error(error.message);
    throw error;
  }
};


function trimObjectStrings(obj) {
  if (typeof obj !== 'object' || obj === null) {
    throw new Error('Input must be a valid object.');
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object') {
        trimObjectStrings(obj[key]);
      }
    }
  }

  return obj;
}

// Axios request interceptor
api.interceptors.request.use(
  async (config) => {
    // Use the Cognito SDK to refresh the token
    const currentSession = await Auth.currentSession();
    const token = currentSession.accessToken.jwtToken;

    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-type"] = "application/json";

    let reqData = JSON.parse(config.data);
    if (reqData && typeof reqData === 'object') {
      config.data = trimObjectStrings(reqData);
    } 
    return config;
  },
  (error) => {
    toast.error(error.message);
    return Promise.reject(error);
  }
);

// Axios response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Check if the error is a 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      try {
        // Refresh the token
        const refreshedToken = await refreshToken();

        // Get the original request config
        const originalRequestConfig = error.config;

        // Update the authorization header with the refreshed token
        originalRequestConfig.headers.Authorization = refreshedToken;

        // Retry the original request with the updated token
        return api(originalRequestConfig);
      } catch (refreshError) {
        toast.error(refreshError);
        // Handle the token refresh error
        console.error('Token refresh error:', refreshError);
        throw refreshError;
      }
    }

    // Handle other types of errors
    throw error;
  }
);

export default api;
