import React, { useState, useEffect, useContext, forwardRef } from "react";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { UiContext } from "../Context/uiContext";
import Table from "../table/table";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useNavigate, useParams } from "react-router-dom";
import SearchConfigModal from "../Modals/searchConfigModal";
import { AnalysisContext } from "../Context/analysisContext";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";

export default function HitsTable(props) {
  const activeFrameDataImage = props.activeFrameDataImage;
  const { getHitsByFrameDataImage, hits } = useContext(AnalysisContext);

  const [columns, setColumns] = useState([
    { title: "Analysis ID", field: "analysisId", editable: "never" },
    { title: "Frame ID", field: "frameId", editable: "never" },
    { title: "FrameData ID", field: "frameDataId", editable: "never" },
    { title: "FrameData Image ID", field: "frameDataImageId", editable: "never" },
    { title: "ID", field: "hitId" },
    { title: "Tag Number", field: "tagNumber", editable: "never" },
    {
      title: "x",
      field: "x",
      editable: "never",
    },
    {
      title: "y",
      field: "y",
      editable: "never",
    },
    { title: "Latitude", field: "latitude", editable: "never" },
    { title: "Longitude", field: "longitude", editable: "never" },
    { title: "Altitude", field: "alt_agl", editable: "never", width: 10 },
  ]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // Material Table Columns Rows
  const data = (query) =>
    new Promise(async (resolve, reject) => {
      // console.log("query.pageSize", query.pageSize);
      // console.log("query.page", query.page);
      // console.log("hits", hits);
      // console.log("hits lenght", hits.length);
      let result = [];
      try {
        result = await getHitsByFrameDataImage(
          activeFrameDataImage.frameDataImageId,
          query.pageSize,
          query.page * query.pageSize
        );
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["ofTotalCount"]["maxQty"],
        });
      } catch (error) {
        toast.error(error.message);
        resolve({
          data: result,
          page: query.page,
          totalCount: result.length,
        });
      }
    });

  const tableRef = React.createRef();

  return (
    <>
      <MaterialTable
        data={data}
        tableRef={tableRef}
        columns={columns}
        loading={props?.loading}
        options={{...props?.options, headerStyle: { position: "sticky", top: 0, zIndex: "1", backgroundColor: "white"  }}}
        title={props?.title}
        icons={tableIcons}
      />
    </>
  );
}
