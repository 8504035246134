import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";

import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";

import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Table from "../table/table";

import PaletteIcon from "@mui/icons-material/Palette";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SatelliteIcon from "@mui/icons-material/Satellite";
import Person2Icon from "@mui/icons-material/Person2";

import { toast } from "react-toastify";

import placeholderImage from "../Assets/image.png";
import { FootageContext } from "../Context/footageContext";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";

export default function ImagesTable(props) {
  // const images = props.images;
  const [imageStatus, setImageStatus] = useState({});

  const { sarCase, getCaseByID } = useContext(CaseContext);
  const {
    imageCtx,
    images,
    updateImageCtx,
    deleteImageData,
    getImagesByCase,
    setImages,
    // images,
    updateImageData,
    ImagesByCaseQuery,
  } = useContext(ImageContext);

  const radiometricCoverters = useRadiometericConverters();

  const { openInNewTab } = useContext(UiContext);

  // const filePathRef = useRef();
  // const [errors, setErrors] = useState({});

  // const onImageError = useCallback(
  //   (id) => {
  //     // e.target.src = placeholderImage;
  //     // errorsTemp[id] = true;
  //     setErrors({ ...errors, [id]: true });
  //   },
  //   [errors]
  // );

  // console.log("onImageError::::", errors);
  // const [columns, setColumns] = useState();

  useEffect(() => {
    const preloadImages = async (data) => {
      const promises = data.map(
        (row) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = row.display_filepath || placeholderImage;
            img.onload = () => resolve({ id: row.imageId, status: "loaded" });
            img.onerror = () => resolve({ id: row.imageId, status: "error" });
          })
      );

      const results = await Promise.all(promises);
      const statusMap = results.reduce((acc, { id, status }) => {
        acc[id] = status;
        return acc;
      }, {});

      setImageStatus(statusMap);
    };

    preloadImages(images);
  }, [images]);

  const columns = useMemo(
    () => [
      // {
      //   title: "View",
      //   field: "imageId",
      //   render: (rowData) => (
      //     <IconButton
      //       onClick={(e) => handleViewClick(rowData)}
      //       aria-label="view"
      //     >
      //       <VisibilityIcon />
      //     </IconButton>
      //   ),
      // },
      {
        title: "Configs",
        field: "imageId",
        editable: "never",
        render: (rowData) => (
          <>
            {/* <Button onClick={() => handleDeleteClick(rowData)}>Delete</Button> */}
            {/* <Button onClick={() => handleViewClick(rowData)}>View</Button> */}
            {rowData.img_purpose == 0 && rowData.img_view_type == 2 ? (
              <Tooltip title="Radiometric">
                <IconButton
                  onClick={(e) => handleRadiometricClick(rowData)}
                  aria-label="Radiometric"
                >
                  <SatelliteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
            {rowData.img_purpose == 0 && rowData.img_view_type != 2 ? (
              <div>
                <Tooltip title="Colors-RGB">
                  <IconButton
                    onClick={(e) => handleRGBColorsClick(rowData)}
                    aria-label="Colors-RGB"
                  >
                    <PaletteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Colors-HSL">
                  <IconButton
                    onClick={(e) => handleHSLColorsClick(rowData)}
                    aria-label="Colors-HSL"
                  >
                    <PaletteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Faces">
                  <IconButton
                    onClick={(e) => handleFacialClick(rowData)}
                    aria-label="Faces"
                  >
                    <Person2Icon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        title: "Image ID",
        field: "imageId",
        editable: "never",
        // // editable: "onUpdate",  "21" below accounts for a timestamp prefix on the name
        // render: (rowData) =>
        //   rowData.filepath.substring(21 + rowData.filepath.lastIndexOf("/")),
      },
      {
        title: "Image name",
        field: "imageId",
        editable: "never",
        // editable: "onUpdate",  "21" below accounts for a timestamp prefix on the name
        render: (rowData) =>
          rowData.filepath.substring(21 + rowData.filepath.lastIndexOf("/")),
      },
      {
        title: "Thumbnail",
        field: "display_filepath",
        editable: "never",
        render: (rowData) => (
          <>
            {imageStatus[rowData.imageId] === "error" ? (
              <Tooltip title="Re-upload">
                <img
                  src={placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                  onClick={() =>
                    openInNewTab(
                      `Cases/${
                        rowData?.sarCaseId
                      }/upload/images?imageName=${rowData.filepath.substring(
                        21 + rowData.filepath.lastIndexOf("/")
                      )}&imageID=${rowData.imageId}&imagePurpose=${
                        rowData.img_purpose
                      }&imageViewType=${rowData.img_view_type}`
                    )
                  }
                />
              </Tooltip>
            ) : (
              // </a>
              <a
                href={rowData.filepath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={rowData.display_filepath || placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                />
              </a>
            )}
          </>
        ),
      },
      {
        title: "Purpose",
        field: "img_purpose",
        editable: "onUpdate",
        render: (rowData) =>
          rowData.img_purpose == 0
            ? `Source`
            : rowData.img_purpose == 1
            ? `Evidence`
            : // : rowData.img_purpose == 2
              // ? `subimage`
              "",
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">purpose</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.img_purpose}
              label="purpose"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={0}>Source</MenuItem>
              <MenuItem value={1}>Evidence</MenuItem>
              {/* <MenuItem value={2}>subimage</MenuItem> */}
            </Select>
          </FormControl>
        ),
      },
      {
        title: "View Type",
        field: "img_view_type",
        editable: "onUpdate",
        render: (rowData) =>
          rowData.img_view_type == 0
            ? `Color`
            : rowData.img_view_type == 1
            ? `Thermal Grayscale`
            : rowData.img_view_type == 2
            ? `Radiometric`
            : `Other ${rowData.img_view_type}`,

        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">view</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.img_view_type}
              label="view"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={0}>Color</MenuItem>
              <MenuItem value={1}>Thermal Grayscale</MenuItem>
              <MenuItem value={2}>Radiometric</MenuItem>
              <MenuItem value={3}>{`Other`}</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Radiometric converter",
        field: "radiometric_converter_id",
        editable: "onUpdate",

        render: (rowData) => {
          const index = radiometricCoverters.findIndex(
            (el) => el.objId === rowData.radiometric_converter_id
          );
          return index !== -1
            ? radiometricCoverters[index]?.dispName
            : rowData.img_view_type === 2
            ? "No Conversion"
            : "N/A";
        },

        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Radiometric converter
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.radiometric_converter_id}
              label="radiometric_converter_id"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              {radiometricCoverters?.map((el) => {
                return (
                  <MenuItem
                    // control={<Radio />}
                    value={el.objId}
                    key={el.objId}
                    disabled={
                      tableData.rowData.img_view_type === 2 ? false : true
                    }
                  >
                    {el.dispName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ),
      },
      {
        title: "For Report",
        field: "use_in_report",
        editable: "onUpdate",
        render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">report</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.use_in_report}
              label="report"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={false}>No</MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        title: "Report (Seq)",
        field: "img_sequence",
        editable: "onUpdate",
        render: (rowData) =>
          rowData.use_in_report ? String(rowData.img_sequence) : `-`,
      },
      {
        title: "Date",
        field: "uploadDate",
        editable: "never",
      },
      {
        title: "Description",
        field: "description",
        editable: "onUpdate",
        editComponent: (tableData) => (
          <TextField
            value={tableData.rowData.description}
            onChange={(e) => tableData.onChange(e.target.value)}
            multiline={true}
            maxRows={5}
          />
        ),
      },
    ],
    [imageStatus, radiometricCoverters]
  );

  const navigate = useNavigate();

  // -------------------------------------------------

  const handleDeleteClick = async (oldData) => {
    return new Promise(async (resolve, reject) => {
      try {
        updateImageCtx({
          imageId: oldData.imageId,
          filepath: oldData.filepath,
        });
        let dataUpdate = images.filter((el) => el.imageId !== oldData.imageId);
        setImages([...dataUpdate]);
        await deleteImageData(oldData.imageId);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        resolve(error);
      }
    });
  };

  const handleViewClick = (rowData) => {
    updateImageCtx({
      ...rowData,
    });

    navigate(`/cases/${sarCase.sarCaseId}/images/${rowData.imageId}`);
  };

  const handleRadiometricClick = (rowData) => {
    updateImageCtx({
      imageId: rowData.imageId,
      filepath: rowData.filepath,
    });

    navigate(
      `/cases/${sarCase.sarCaseId}/images/${rowData.imageId}/detect-radiometric-palette`
    );
  };

  const handleRGBColorsClick = (rowData) => {
    updateImageCtx({
      imageId: rowData.imageId,
      filepath: rowData.filepath,
    });

    navigate(
      `/cases/${sarCase.sarCaseId}/images/${rowData.imageId}/detect-color-palette-rgb`
    );
  };

  const handleHSLColorsClick = (rowData) => {
    updateImageCtx({
      imageId: rowData.imageId,
      filepath: rowData.filepath,
    });

    navigate(
      `/cases/${sarCase.sarCaseId}/images/${rowData.imageId}/detect-color-palette-hsl`
    );
  };

  const handleFacialClick = (rowData) => {
    updateImageCtx({
      imageId: rowData.imageId,
      filepath: rowData.filepath,
    });

    navigate(
      `/cases/${sarCase.sarCaseId}/images/${rowData.imageId}/detect-faces-palette`
    );
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      const dataUpdate = [...tableData];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setNewTableData([...dataUpdate]);
      await updateImageData({
        action: "update-image",
        iid: newData.imageId,
        img_purpose: newData.img_purpose,
        img_view_type: newData.img_view_type,
        radiometric_converter_id: newData.radiometric_converter_id,
        use_in_report: newData.use_in_report,
        img_sequence: newData.img_sequence,
        description: newData.description,
      });

      updateImagesContext(oldData.imageId, newData);
      resolve();
    });

  const updateImagesContext = (imageId, newImageData) => {
    const imagesUpdate = [...images];
    const imagesIndex = images.findIndex((img) => img.imageId === imageId);
    const { tableData, ...newResponse } = newImageData;
    imagesUpdate[imagesIndex] = newResponse;
    setImages([...imagesUpdate]);
  };

  const tableRef = React.createRef();
  const { case_id } = useParams();

  return (
    <>
      <Table
        // data={images}
        tableId={props?.tableId || "Table-Images"}
        title={""}
        dataQuery={true}
        data={(query) => ImagesByCaseQuery(case_id, query)}
        tableRef={tableRef}
        columns={columns}
        onRowUpdate={props?.editable ? onUpdate : null}
        onRowDelete={props?.deleteable ? handleDeleteClick : null}
        options={props?.options}
        loading={props?.loading}
        editable={props?.editable}
        deleteable={props?.deleteable}
        onRowView={handleViewClick}
        recordType="image"
      />
    </>
  );
}
