import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { CriteriaContext } from "../Context/criteriaContext";

import { Box, Button, Stack, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CriteriaTable from "../Criteria/CriteriaTable";

export default function CriteriaSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { criteria, getCriteriaByCase, setCriteria, testCriteria } = useContext(CriteriaContext);
  const { case_id } = useParams();

  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId)
        getCaseByID(case_id);
      getCriteriaByCase(case_id);
    } else {
      setCriteria(testCriteria);
    }
  }, [sarCase.sarCaseId]);


  const navigate = useNavigate();

  const [newCritValue, setNewCritValue] = React.useState('radiometric');
  const handleNewCritValueChange = (event) => {
    setNewCritValue(event.target.value);

    if  (event.target.value != "select" )
      if  (event.target.value == "object-detect" )
        navigate(`/Cases/${case_id}/SearchConfigs/DetectObjectsPalette`);
      else if  (event.target.value == "anomaly-detect" )
        navigate(`/Cases/${case_id}/SearchConfigs/DetectAnomalyPalette`);
      else navigate(`/Cases/${case_id}/Images`);
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingX: 10,
          paddingTop: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            component={Link}
            sx={{ visibility: "hidden", marginRight: "auto" }}
          >
            This is a hidden button
          </Button>
          <Typography
            variant="h2"
            sx={{ display: "flex", marginX: 1, paddingRight: 7 }}
          >
            Criteria: (Case: {sarCase.sarCaseId}: {sarCase.sarCaseName})
          </Typography>

          <Button
            variant="contained"
            type="submit"
            component={Link}
            sx={{ visibility: "hidden", marginRight: "auto" }}
          >
            This is a hidden button
          </Button>

            <label sx={{ marginLeft: "auto" }} >
              Create New &nbsp;
              <select value={newCritValue} onChange={handleNewCritValueChange}>
                <option value="select"> - Select - </option>
                <option value="rgb-color">Color Palette</option>
                <option value="thermal-color">Thermal Color</option>
                <option value="radiometric">Radiometric</option>
                <option value="facial-recog">Facial Recognition</option>
                <option value="object-detect">Object Detect</option>
                <option value="anomaly-detect">Anomaly Detect</option>
              </select>
            </label>

        </Box>
        <CriteriaTable
          criteria={criteria}
        ></CriteriaTable>
      </Box>
      {errorMessage && <div className="error"> {errorMessage} </div>}
    </>
  );
}
