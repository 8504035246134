import React, { useState, useContext, useEffect } from "react";
import { systemContext, userContext, caseContext } from "../index";

export default function useFileUpload() {
    const { authHeaders } = useAppContext();
    const sysCtx = useContext(systemContext);
    const usrCtx = useContext(userContext);
    const caseCtx = useContext(caseContext);
    
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles(files)
    }, [files])

    const getPresignedURL = async (filename, filetype) => {
        console.log( "in get presigned" )
        return await fetch(sysCtx.apiUrl, {
            method: 'POST',
            headers: {
                ...authHeaders
            },
            body: JSON.stringify({
                "action": 'get-s3-presigned-url',
                "gid": '1',
                "wid": usrCtx.workerId,
                "cid": caseCtx.caseId,
                "flight_id": '1',
                "filename": filename,
                "content_type": filetype
            })
        })
        .then(resp => resp.json())
    };
    
    const uploadFile = async (file) => {
        console.log( "in uploadFile" )
        const data = {
            method: 'PUT',
            headers: {
                "Content-Type": file.type
            },
            body: file
        }
        await getPresignedURL(file.name, file.type)
        .then(resp => resp.data)
        .then(uploadURL => {
            console.log(uploadURL)
            fetch(uploadURL, data)
                .then(resp => console.log(resp))
        })
    };
    
    const uploadFiles = () => {
        console.log( "in uploadFiles" )
        console.log(files)
        files.map(async (file) => {
            uploadFile(file)
        })
    };

    return [files, setFiles, uploadFiles]
}