import React from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../table/table";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageViewModal from "../Modals/imageViewModal";
import { MainLayout } from "../Layouts/MainLayout";
import ReportTable from "./ReportTable";
import { ReportContext } from "../Context/reportContext";


export default function ReportsViewPage(props) {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { reports, getReportsByCase } = useContext(ReportContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { case_id, report_id } = useParams();

  React.useEffect(() => {
    // if (!props?.data) {
    //   navigate(`/Case/${case_id}/Reports`);
    // }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      await getReportsByCase(case_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <MainLayout title={`Report `}>
      <Box marginBottom={5} maxHeight={750}>
        
        
      </Box>
    </MainLayout>
  );
}
