import React, { cloneElement, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../Context/appContext";

function querystring(name, url = window.location.href) {
  const parsedName = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`, "i");
  const results = regex.exec(url);

  if (!results || !results[2]) {
    return false;
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function UnauthenticatedRoute(props) {
  const { isAuthenticated } = useContext(AppContext);
  const { children } = props;

  const location = useLocation();
  const redirect = querystring("redirect", location.href);

  // Preserve all current query parameters
  const currentParams = new URLSearchParams(location.search);
  currentParams.delete("redirect"); // Avoid appending "redirect" twice if it's already in the URL

  if (isAuthenticated) {
    const redirectUrl = redirect ? redirect : "/";
    // Reconstruct the URL to include both the redirect path and existing query parameters
    let redirectWithParams = `${redirectUrl}`;
    if (currentParams.toString()) {
      redirectWithParams = `${redirectUrl}&${currentParams.toString()}`;
    }
    return <Navigate to={redirectWithParams} />;
  }

  return cloneElement(children, props);
}
