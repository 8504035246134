import React, { useContext } from "react";
import { Amplify } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config";

import { BrowserRouter as Router } from "react-router-dom";
import { createRoot } from "react-dom/client";
import Routes from "./Routes";

import NavBar from "./Components/NavBar";

import { CaseProvider } from "./Context/caseContext";
import { AnalysisProvider } from "./Context/analysisContext";
import { ReportProvider } from "./Context/reportContext";
import { FlightProvider } from "./Context/flightContext";
import { FootageProvider } from "./Context/footageContext";
import { ImageProvider } from "./Context/imageContext";
import { CriteriaProvider } from "./Context/criteriaContext";
import { UserProvider } from "./Context/userContext";
import { AppProvider } from "./Context/appContext";
import { UiProvider } from "./Context/uiContext";
import { FlightVideoProvider } from "./Context/videoContext";
import { SearchConfigProvider } from "./Context/searchConfigContext";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// import { Breadcrumbs } from "@mui/material";
// import BasicBreadcrumbs from "./breadCrumbs";

export const systemContext = React.createContext({});
export const userContext = React.createContext({});
export const caseContext = React.createContext({});
export const analysisContext = React.createContext({});
export const reportContext = React.createContext({});
export const flightContext = React.createContext({});
export const imageContext = React.createContext({});
export const footageContext = React.createContext({});
export const criteriaContext = React.createContext({});
export const searchConfigContext = React.createContext({});

const MasterProvider = ({ children }) => (
  <AppProvider>
    <UserProvider>
      <CaseProvider>
        <AnalysisProvider>
          <FlightProvider>
            <FootageProvider>
              <ImageProvider>
                <CriteriaProvider>
                  <FlightVideoProvider>
                    <UiProvider>
                      <SearchConfigProvider>
                        <ReportProvider>{children}</ReportProvider>
                      </SearchConfigProvider>
                    </UiProvider>
                  </FlightVideoProvider>
                </CriteriaProvider>
              </ImageProvider>
            </FootageProvider>
          </FlightProvider>
        </AnalysisProvider>
      </CaseProvider>
    </UserProvider>
  </AppProvider>
);

const App = () => {
  // const { isAuthenticated, authHeaders, user, loadSession } =
  //   useContext(AppContext);
  // const { fetchWorkerDetails } = useContext(UserContext);

  // React.useEffect(() => {
  //   if (isAuthenticated && authHeaders) {
  //     fetchWorkerDetails();
  //   }
  // }, [isAuthenticated]);

  return (
    <>
      <NavBar />
      {/* <BasicBreadcrumbs/> */}
      <Routes />
      {/* </Breadcrumbs> */}
    </>
  );
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
const defaultTheme = createTheme({
  // palette: {
  //   mode: 'dark'
  // }
});
root.render(
  <Router>
    <MasterProvider>
      <ThemeProvider theme={defaultTheme}>
        <App sx={{
          backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}/>

        <ToastContainer position="top-right" />
      </ThemeProvider>
    </MasterProvider>
  </Router>
);
