import React, {
  useState,
  useContext,
  createRef,
  useRef,
  useEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { ImageContext } from "../Context/imageContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
// import Cropper from "react-easy-crop";
import { CustomCropper } from "../Components/Cropper/CustomCropper";
import { PreviewResults } from "../Components/Cropper/components/PreviewResults";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/classic.css";

import CustomTempModal from "../Components/CustomTempModal";
import CustomTempLite from "../Components/CustomTempLite";

//MUI Components
import {
  Box,
  Grid,
  Button,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Container,
  Slider,
  useMediaQuery,
} from "@mui/material";

//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";
const ACTION = "create-select-case";
const defaultValues = {
  gid: "1",
  wid: "1",
  ip: "0.0.0.0",
  action: ACTION,
};

export default function BuildRadiometricPalette() {
  const navigate = useNavigate();
  const { userCtx } = useContext(UserContext);
  const [sarCase, getCaseByID] = useState(CaseContext);
  const { imageCtx, getImageData, uploadImageForRadiometric } =
    useContext(ImageContext);
  const { createRadiometricSearchConfig } = useContext(SearchConfigContext);
  const { case_id, image_id } = useParams();

  const [cropData, setCropData] = useState("#");
  const paletteNameRef = useRef();
  const searchDescriptionInput = useRef();
  const [image, setImage] = useState(testImage);
  const [paletteArray, setPaletteArray] = useState([]);
  const [isEditingTemp, setIsEditingTemp] = useState(false);
  const [showCustomTemp, setShowCustomTemp] = useState(false);
  const [temp, setTemp] = useState("0");
  const [tempIndex, setTempIndex] = useState(null);
  const [currentIndexT1, setCurrentIndexT1] = useState(null);
  const [currentIndexT2, setCurrentIndexT2] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [coordinates, setCoordinates] = useState(null);
  const [preview, setPreview] = useState();
  const isMobile = useMediaQuery("(max-width:900px)"); // Detect mobile devices

  React.useEffect(() => {
    if (image_id) {
      setImage(imageCtx.filepath.replace(/\.(tiff|tif)$/i, ".jpg"));
    }
  }, [imageCtx.filepath]);

  // const convertToRGBFormat = (objects) => {
  //   if (!Array.isArray(objects)) {
  //     throw new Error("Input must be an array of objects");
  //   }

  //   return objects.map((obj) => {
  //     if (obj.rgb && typeof obj.rgb === "string") {
  //       const rgbArray = obj.rgb.match(/\d+(\.\d+)?/g);
  //       if (rgbArray && rgbArray.length === 3) {
  //         const roundedArray = rgbArray.map(Number).map(Math.round);
  //         const rgbString = `rgb(${roundedArray.join(",")})`;
  //         obj.rgb = rgbString;
  //       }
  //     }
  //     return obj;
  //   });
  // };

  // const extractRGBValues = (rgbString) => {
  //   const rgbArray = rgbString.match(/\d+/g);
  //   if (rgbArray && rgbArray.length === 3) {
  //     return rgbArray.map(Number);
  //   }
  //   throw new Error("Invalid RGB string format");
  // };

  useEffect(() => {
    console.log(paletteArray);
  }, [paletteArray]);

  const fetchData = async () => {
    try {
      await getCaseByID(case_id);
      if (image_id) {
        await getImageData(image_id);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      console.log("imageCtx: ", imageCtx);
      if (!sarCase.sarCaseId && !imageCtx.imageId) {
        fetchData();
      }
    } else {
      if (image_id) {
        setImage(imageCtx.filepath);
      }
    }
  }, [sarCase.sarCaseId, imageCtx.imageId]);

  const sendKeyTemps = async (paramData) => {
    if (paletteNameRef.current.value === "") {
      toast.warning("Palette name is required");
      return;
    }
    if (checkedElements.length === 0) {
      toast.warning("Select atleast one item");
      return;
    }

    if (!paletteNameRef.current.value) {
      toast.warning("Name is required.");
      return;
    }
    try {
      const response = await createRadiometricSearchConfig({
        paletteName: paletteNameRef.current.value,
        cid: parseInt(case_id),
        description: searchDescriptionInput.current.value,
        degCList: checkedElements.map((el) => {
          return {
            t: Number(el.t1),
            t2: Number(el.t2),
          };
        }),
      });
      console.log("createRadiometricSearchConfig response:", response);
      navigate(`/cases/${case_id}/search-configs`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const getCropData = async () => {
  //   console.log("doing getCropData");
  //   if (typeof cropperRef.current?.cropper !== "undefined") {
  //     console.log("doing crop");
  //     const croppedCanvas =
  //       await cropperRef.current?.cropper.getCroppedCanvas();
  //     setCropData(croppedCanvas.toDataURL());
  //     console.log("did crop - doing fetch");
  //     fetchKeyTemps(croppedCanvas.toDataURL());
  //     console.log("did fetch");
  //   }
  // };

  const addTempToPalette = ({ t1, t2 }) => {
    t1 = parseFloat(t1).toFixed(2);
    t2 = t2 ? parseFloat(t2).toFixed(2) : null;

    if (t2 && parseFloat(t1) > parseFloat(t2)) {
      toast.error(
        "Lower bound (t1) must be less than or equal to Upper bound (t2)"
      );
      return;
    }
    setPaletteArray([
      ...paletteArray,
      {
        t1: `${t1}`,
        t2: `${t2}`,
        isRange: t2 ? true : false,
      },
    ]);
  };

  const removeTempFromPalette = (index) => {
    const updatedPaletteArray = [...paletteArray];
    updatedPaletteArray.splice(index, 1);
    setPaletteArray(updatedPaletteArray);
  };

  const editTempInPalette = (mapIndex, t1, t2) => {
    t1 = parseFloat(t1).toFixed(2);
    t2 = t2 ? parseFloat(t2).toFixed(2) : null;

    if (t2 && parseFloat(t1) > parseFloat(t2)) {
      toast.error(
        "Lower bound (t1) must be less than or equal to Upper bound (t2)"
      );
      return;
    }

    const updatedPaletteArray = [...paletteArray];
    console.log("updatedPaletteArray[mapIndex]", updatedPaletteArray[mapIndex]);
    updatedPaletteArray[mapIndex].t1 = t1;
    updatedPaletteArray[mapIndex].t2 = t2;
    updatedPaletteArray[mapIndex].isRange = t2 ? true : false;

    setPaletteArray(updatedPaletteArray);
  };

  const onClose = () => {
    setIsEditingTemp(!isEditingTemp);
  };

  const onClickEditTemp = (index, temp, isRange) => {
    console.log("onClickEditTemp temp: ", temp);
    console.log("onClickEditTemp isRange: ", isRange);
    setTempIndex(index);
    setIsEditingTemp(!isEditingTemp);
    setCurrentIndexT1(temp.t1);
    setCurrentIndexT2(temp.t2);
  };

  const addCustomTemperature = () => {
    setShowCustomTemp(!showCustomTemp);
  };

  const clearPalette = () => {
    setPaletteArray([]);
  };

  const handleCheckboxChange = (event, index, color) => {
    if (event.target.checked) {
      // Add the index to checkedItems
      setCheckedItems([...checkedItems, color, index]);
      // setPaletteArray([...paletteArray, { rgb: rgbValue, hex: hexValue }]
    } else {
      // Remove the index from checkedItems
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const checkedElements = paletteArray.filter((color, index) =>
    checkedItems.includes(index)
  );

  const addCustomTemp = () => {
    setShowCustomTemp(!showCustomTemp);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        // height: "93vh",
        overflow: "auto",
        paddingX: isMobile ? 2 : 10, // Adjust padding for mobile
        paddingTop: isMobile ? 2 : 10, // Adjust padding for mobile
      }}
    >
      {showCustomTemp ? (
        <CustomTempLite
          value={temp}
          paletteArray={paletteArray}
          onChange={setTemp}
          onClose={addCustomTemp}
          addTempToPalette={addTempToPalette}
        />
      ) : null}
      <Grid container spacing={isMobile ? 2 : 4}>
        {image_id && (
          <Grid item xs={12} md={6}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} id="cropper" sx={{maxHeight: "50vh"}}>
                <Box>
                  <CustomCropper
                    imageUrl={image}
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                    setPreview={setPreview}
                    setPaletteArray={setPaletteArray}
                    paletteArray={paletteArray}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} id="preview">
                <Paper
                  elevation={16}
                  sx={{
                    padding: 2,
                    marginTop: "10px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
                    justifyContent: "left",
                    alignItems: isMobile ? "center" : "flex-start", // Center align on mobile
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "90%",
                      maxHeight: "300px", // Adjust height for mobile
                      outline: "2px solid black",
                    }}
                  >
                    {coordinates && preview && (
                      <PreviewResults
                        coordinates={coordinates}
                        preview={preview}
                      />
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} md={image_id ? 6 : 12}>
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "100%",
              // height: isMobile ? "auto" : "82vh", // Adjust height for mobile
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isMobile ? "0px" : "1.5vw", // Adjust padding for mobile
              marginTop: isMobile ? "5rem" : "0rem", // Adjust padding for mobile
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                placeholder="Enter Palette Name"
                inputRef={paletteNameRef}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                placeholder="Optional Description"
                inputRef={searchDescriptionInput}
                fullWidth
                multiline
                sx={{
                  marginTop: "1vw",
                }}
              />
              {paletteArray?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxHeight: isMobile ? "40vh" : "58vh", // Adjust height for mobile
                    marginTop: "1vw",
                    overflow: "auto",
                    scrollbarWidth: "auto",
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  }}
                >
                  {paletteArray.map((temp, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        paddingLeft: "2vw",
                        paddingRight: "2vw",
                        marginTop: "2px",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <CustomTempModal
                        mapIndex={tempIndex}
                        isEditingTemp={isEditingTemp}
                        onClose={onClose}
                        onChangeTemp={editTempInPalette}
                        currIndexT1={currentIndexT1}
                        currIndexT2={currentIndexT2}
                      />
                      <Box sx={{ marginRight: 2 }}>
                        <Checkbox
                          onChange={(event) =>
                            handleCheckboxChange(event, index, temp)
                          }
                          checked={checkedItems.includes(index)}
                        />
                      </Box>

                      {temp?.isRange ? (
                        <>
                          <Box sx={{ marginLeft: 3 }}>
                            <Typography>
                              {` (${parseFloat(temp.t1).toFixed(
                                2
                              )} - ${parseFloat(temp.t2).toFixed(2)}) °C`}
                            </Typography>
                            <Typography>
                              {`(${parseFloat((temp.t1 * 9) / 5 + 32).toFixed(
                                2
                              )} - ${parseFloat((temp.t2 * 9) / 5 + 32).toFixed(
                                2
                              )})  °F `}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box sx={{ marginLeft: 3 }}>
                            <Typography>{` ${parseFloat(temp.t1).toFixed(
                              2
                            )} °C `}</Typography>
                            <Typography>{` ${parseFloat(
                              (temp.t1 * 9) / 5 + 32
                            ).toFixed(2)} °F`}</Typography>
                          </Box>
                        </>
                      )}

                      <Box
                        sx={{
                          marginLeft: "auto",
                          marginBottom: "2px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <CreateIcon
                          onClick={() =>
                            onClickEditTemp(index, temp, temp.isRange)
                          }
                        />
                        <DeleteForeverIcon
                          sx={{ marginLeft: 3 }}
                          onClick={() => removeTempFromPalette(index)}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  // marginBottom: 5,
                }}
                onClick={() => addCustomTemperature()}
              >
                Add Custom Temperature
              </Button>

              <Button
                onClick={() => sendKeyTemps(checkedElements)}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                Submit
              </Button>
              {/* <DeleteForeverIcon
                fontSize={"large"}
                sx={{}}
                onClick={() => clearPalette()}
              /> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          padding: isMobile ? "5rem" : "1.5vw", // Adjust padding for mobile
          marginTop: isMobile ? "2rem" : "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.detect_radio_palette_FAQ} />
      </Box>
    </Box>
  );
}
