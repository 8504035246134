import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Menu,
  MenuItem,
  AppBar,
  Box,
  Toolbar,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import CustomDrawer from "./CustomDrawer";

import { AppContext } from "../Context/appContext";
import { UserContext } from "../Context/userContext";
import { toast } from "react-toastify";

export default function NavBar() {
  const { isAuthenticated, updateAuthHeaders, logout, loadSession, user } =
    useContext(AppContext);
    const { fetchWorkerDetails } = useContext(UserContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const nav = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  async function handleLogout() {
    logout();
    nav("/login");
  }
  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {    
    if(isAuthenticated){
      onLoad();
      fetchWorkerDetails();
    }
   
  }, [isAuthenticated]);

  async function onLoad() {
    try {
      updateAuthHeaders();
      loadSession();
    } catch (e) {
      if (e !== "No current user") {
        toast.warning(e);
      }
    }
    setIsAuthenticating(false);
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    nav("/profile");
  };

  React.useEffect(() => {}, [user]);
  return (
    !isAuthenticating && (
      <Box sx={{ flexGrow: 1, boxShadow: "none" }}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <CustomDrawer isClosed={open} closeDrawer={handleDrawerClose} />{" "}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Button color="inherit" component={Link} to="/">
                Find911
              </Button>
            </Typography>
            {isAuthenticated ? (
              <>
                {/* {Image(user)}
                <Button
                  color="inherit"
                  component={Link}
                  onClick={handleLogout}
                  startIcon={<LogoutIcon />}
                >
                  Logout
                </Button> */}
               <Typography
                  variant="h6"
                  sx={{ display: "flex", marginX: 1, paddingRight: 2}}
                >
                  {user?.cognitoWorkerName}
                </Typography>
                <img
                  style={{
                    borderRadius: "50%",
                    maxHeight:50,
                    maxWidth:50
                  }}
                  src={`http://gravatar.com/avatar/${user?.email}.jpg?s=50&d=identicon`}
                  alt="avatar"
                  onClick={handleClick}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/signup">
                  Signup
                </Button>
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    )
  );
}
