import React, { useState, useEffect, useContext, forwardRef } from "react";
import { flushSync } from "react-dom";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";

import { AnalysisContext } from "../../Context/analysisContext";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

import { UiContext } from "../../Context/uiContext";
import { toast } from "react-toastify";
export default function HitsTable(props) {
  const { handleImageViewModalOpen, handleHitsViewModalOpen, handleNotificationModalOpen } =
    useContext(UiContext);
  const {
    currFrame,
    currframeDataImage,
    framesDataImagesQuery,
    setCurrFrameDataImage,
    frameDataImagesCount,
    slidishRef,
    currImage,
    setCurrImage,
  } = useContext(AnalysisContext);

  function viewFrameDataImage(data) {
    handleImageViewModalOpen();
    setCurrFrameDataImage(data);
    setCurrImage(data);
  }

  function sendNotificationFrameDataImage(data) {
    setCurrFrameDataImage(data);
    handleNotificationModalOpen();
  }

  const openHitsViewModal = async (rowData) => {
    setCurrFrameDataImage(rowData);
    handleHitsViewModalOpen();
  };

  const [columns, setColumns] = useState([
    // {
    //   title: "View Image",
    //   field: "frameDataImageId",
    //   editable: "never",
    //   render: (rowData) => (
    //     <>
    //       <Button
    //         disabled={currFrame?.frameId === rowData.frameId}
    //         onClick={() => viewFrameDataImage(rowData)}
    //       >
    //         View
    //       </Button>
    //     </>
    //   ),
    //   editComponent: (tableData) => (
    //     <>
    //       <Button
    //         disabled={true}
    //         onClick={() => viewFrameDataImage(tableData.rowData)}
    //       >
    //         View
    //       </Button>
    //     </>
    //   ),
    // },
    {
      title: "FrameData Image ID",
      field: "frameDataImageId",
      editable: "never",
      // width: 50,
    },
    {
      title: "Image",
      field: "filepath",
      editable: "never",
      // width: 100,
      render: (rowData) => (
        <Button
          onClick={() => slidishRef.current.goto(rowData.tableData.index)}
        >
          <img
            style={{
              maxWidth: "120px",
              maxHeight: "120px",
            }}
            src={rowData.filepath.replace(/full/g, "thumb")}
          ></img>
        </Button>
      ),
    },
    {
      title: "Name",
      field: "name",
      editable: "onUpdate",
      // width: 50,
    },
    {
      title: "Hits",
      field: "number_of_hits",
      editable: "never",
      // width: 50,
    },
    {
      title: "Display Sequence",
      field: "display_sequence",
      editable: "never",
      // width: 50,
    },
    {
      title: "Report Sequence",
      field: "report_sequence",
      editable: "onUpdate",
      // width: 50,
    },

    {
      title: "Description",
      field: "description",
      editable: "onUpdate",
      width: 100,
      // render: (rowData) => (
      //   <TextField
      //     value={rowData.flightDescription}
      //     onChange={(e) => rowData.onChange(e.target.value)}
      //     multiline={true}
      //     maxRows={5}
      //     style={{
      //       width:"100%"
      //     }}
      //   />
      // ),
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.flightDescription}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
          style={{
            width: "100%",
          }}
        />
      ),
    },
  ]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableRef = React.createRef();

  return (
    <>
      <MaterialTable
        data={framesDataImagesQuery}
        tableRef={tableRef}
        columns={columns}
        loading={props?.loading}
        options={{...props?.options, headerStyle: { position: "sticky", top: 0, zIndex: "1", backgroundColor: "white"  }}}
        title={props?.title}
        icons={tableIcons}
        actions={[
          {
            icon: InfoIcon,
            tooltip: "Get Hits",
            onClick: (event, rowData) => {
              rowData?.number_of_hits > 0
                ? openHitsViewModal(rowData)
                : toast.warning("No hits on this image");
            },
          },
          {
            icon: VisibilityIcon,
            tooltip: "View Image",
            onClick: (event, rowData) => {
              viewFrameDataImage(rowData);
            },
          },
          {
            icon: CircleNotificationsIcon,
            tooltip: "Send notification",
            onClick: (event, rowData) => {
              sendNotificationFrameDataImage(rowData);
            },
          },
        ]}
      />
    </>
  );
}
