import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { MainLayout } from "../Layouts/MainLayout";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
import { AppContext } from "../Context/appContext";

export default function CaseCreatePage() {
  const navigate = useNavigate();
  const { createCase } = useContext(CaseContext);
  const { user } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    agencyName: "",
    caseName: "",
    leadName: "",
    leadPhone: "",
    leadEmail: "",
    searchAreaName: "",
    caseDescription: "",
  });

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (touched[name]) {
      validateField(name, value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = "";

    if (
      ["agencyName", "caseName", "leadName", "leadPhone", "leadEmail", "searchAreaName"].includes(
        name
      )
    ) {
      if (!value.trim()) {
        error = "This field is required";
      }
    }

    if (name === "leadEmail" && value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Enter a valid email";
      }
    }

    if (name === "leadPhone" && value) {
      const phoneRegex = /^\+?[0-9]{7,15}$/;
      if (!phoneRegex.test(value)) {
        error = "Enter a valid phone number";
      }
    }

    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isFormValid = () => {
    let newErrors = {};
    Object.keys(formData).forEach((field) => {
      validateField(field, formData[field]);
      if (errors[field]) {
        newErrors[field] = errors[field];
      }
    });

    const hasErrors = Object.values(newErrors).some((error) => error);
    setErrors(newErrors);
    return !hasErrors;
  };

  const onSubmit = useCallback(
    () => async (event) => {
      event.preventDefault();

      const touchedAll = {};
      Object.keys(formData).forEach((key) => (touchedAll[key] = true));
      setTouched(touchedAll);

      if (!isFormValid()) {
        toast.warn("Please fix errors before submitting");
        return;
      }

      setLoading(true);
      try {
        const data = {
          action: "create-case",
          gid: userCtx.workerOrgId,
          wid: user.cognitoId,
          ...formData,
        };

        await createCase(data);
        toast.success("Case created successfully!");
        navigate("/cases");
      } catch (error) {
        toast.error(error?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    },
    [formData, userCtx, createCase, navigate, errors]
  );

  return (
    <MainLayout title="Case create">
      <Box
        component="form"
        onSubmit={onSubmit()}
        sx={{
          display: "flex",
          justifyContent: "center",
          px: 2,
        }}
      >
        <Paper
          elevation={16}
          sx={{
            padding: 4,
            width: { xs: "100%", sm: "90%", md: "60%", lg: "50%" },
            maxWidth: 800,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4" textAlign="center">
              Create Case
            </Typography>

            {[
              { name: "agencyName", label: "Lead Agency" },
              { name: "caseName", label: "Case Name" },
              { name: "leadName", label: "Lead Name" },
              {
                name: "leadPhone",
                label: "Lead Phone",
                helperText: "Include country code",
              },
              {
                name: "leadEmail",
                label: "Lead Email",
                helperText: "We'll use this to contact the lead",
              },
              { name: "searchAreaName", label: "Search Area Name" },
            ].map((field) => (
              <TextField
                key={field.name}
                name={field.name}
                label={field.label}
                value={formData[field.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched[field.name] && errors[field.name])}
                helperText={
                  (touched[field.name] && errors[field.name]) ||
                  field.helperText ||
                  ""
                }
                inputProps={{ maxLength: 100 }}
                fullWidth
              />
            ))}

            <TextField
              name="caseDescription"
              label="Brief Description"
              multiline
              rows={4}
              value={formData.caseDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: 1000 }}
              fullWidth
              helperText="Optional - up to 1000 characters"
            />

            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              startIcon={loading && <CircularProgress size={18} />}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Stack>
        </Paper>
      </Box>

      <Box sx={{ width: "100%", px: 2, mt: 4 }}>
        <FAQ faqs={FAQs.case_create_FAQ} />
      </Box>
    </MainLayout>
  );
}