import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  useMediaQuery,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { HslColorPicker } from "react-colorful";
import ColorPicker from "react-best-gradient-color-picker";

const HslColorPickerWithInputs = ({ color, onChange }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <HslColorPicker
        color={color}
        onChange={onChange}
        style={{ width: "100%", minHeight: "200px" }}
      />
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          label="Hue"
          type="number"
          value={color.h}
          onChange={(e) =>
            onChange({
              ...color,
              h: Math.min(360, Math.max(0, parseInt(e.target.value))) || 0,
            })
          }
          inputProps={{ min: 0, max: 360 }}
          size="small"
          fullWidth
        />
        <TextField
          label="Saturation %"
          type="number"
          value={color.s}
          onChange={(e) =>
            onChange({
              ...color,
              s: Math.min(360, Math.max(0, parseInt(e.target.value))) || 0,
            })
          }
          inputProps={{ min: 0, max: 100 }}
          size="small"
          fullWidth
        />
        <TextField
          label="Lightness %"
          type="number"
          value={color.l}
          onChange={(e) =>
            onChange({
              ...color,
              l: Math.min(360, Math.max(0, parseInt(e.target.value))) || 0,
            })
          }
          inputProps={{ min: 0, max: 100 }}
          size="small"
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default function CustomColorModal({
  mapIndex,
  isEditingColor,
  onClose,
  onChangeColor,
  currIndexRgb,
  currIndexRgb2,
  HSL = false,
}) {
  const isMobile = useMediaQuery("(max-width:900px)");
  const isSmallHeight = useMediaQuery("(max-height:600px)");

  // Parse HSL string to object { h, s, l }
  const parseHslString = (hslStr) => {
    if (!hslStr?.startsWith("hsl(")) return { h: 0, s: 0, l: 0 };
    const matches = hslStr.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
    return {
      h: parseInt(matches?.[1] || 0),
      s: parseInt(matches?.[2] || 0),
      l: parseInt(matches?.[3] || 0),
    };
  };

  // Convert HSL object to string
  const hslToString = (hsl) => `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;

  // State management
  const [hsl1, setHsl1] = useState(() => parseHslString(currIndexRgb));
  const [hsl2, setHsl2] = useState(() => parseHslString(currIndexRgb2));
  const [rgb1, setRgb1] = useState(currIndexRgb);
  const [rgb2, setRgb2] = useState(currIndexRgb2);

  // Update states when props change
  useEffect(() => {
    if (HSL) {
      setHsl1(parseHslString(currIndexRgb));
      setHsl2(parseHslString(currIndexRgb2));
    } else {
      setRgb1(currIndexRgb);
      setRgb2(currIndexRgb2 || null);
    }
  }, [currIndexRgb, currIndexRgb2, HSL]);

  const handleSave = () => {
    if (HSL) {
      onChangeColor(
        mapIndex,
        hslToString(hsl1),
        hsl2 ? hslToString(hsl2) : null
      );
    } else {
      onChangeColor(mapIndex, rgb1, rgb2);
    }
    onClose();
  };

  if (!isEditingColor) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9998,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? "10px" : "10px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          borderRadius: "0.25rem",
          padding: isMobile ? "1rem" : "2rem",
          zIndex: 9999,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          gap: isMobile ? 2 : 4,
          width: isMobile ? "90vw" : "auto",
          maxWidth: isMobile ? "90vw" : "80vw",
          backgroundColor: "rgb(255,255,255, 0.9)",
          overflow: "auto",
          overflowY: isMobile ? "scroll" : "hidden",
          maxHeight: isSmallHeight ? "90vh" : "auto",
        }}
      >
        <Grid container gap={2} xs={12}>
          {HSL ? (
            <>
              <Paper elevation={16} sx={{ padding: 2, marginTop: "10px" }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Low
                </Typography>
                <HslColorPickerWithInputs color={hsl1} onChange={setHsl1} />
                <Typography sx={{ mt: 1 }}>
                  Current: {hslToString(hsl1)}
                </Typography>
              </Paper>

              {hsl2 && (
                <Paper elevation={16} sx={{ padding: 2, marginTop: "10px" }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    High
                  </Typography>
                  <HslColorPickerWithInputs color={hsl2} onChange={setHsl2} />
                  <Typography sx={{ mt: 1 }}>
                    Current: {hslToString(hsl2)}
                  </Typography>
                </Paper>
              )}
            </>
          ) : (
            <>
              <Paper
                elevation={16}
                sx={{
                  padding: 2,
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "left",
                  alignItems: isMobile ? "center" : "flex-start",
                  overflow: "auto",
                  maxHeight: isSmallHeight ? "60vh" : "auto",
                }}
              >
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Low
                  </Typography>
                  <ColorPicker
                    value={rgb1}
                    onChange={setRgb1}
                    disableDarkMode={true}
                  />
                </Grid>
              </Paper>

              {rgb2 && (
                <Paper
                  elevation={16}
                  sx={{
                    padding: 2,
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    justifyContent: "left",
                    alignItems: isMobile ? "center" : "flex-start",
                    overflow: "auto",
                    maxHeight: isSmallHeight ? "60vh" : "auto",
                  }}
                >
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      High
                    </Typography>
                    <ColorPicker
                      value={rgb2}
                      onChange={setRgb2}
                      disableDarkMode={true}
                    />
                  </Grid>
                </Paper>
              )}
            </>
          )}
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", lg: "column" },
            gap: 1,
            position: { xs: "fixed", lg: "static" },
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            backgroundColor: { xs: "background.paper", lg: "transparent" },
            p: 1,
            zIndex: 2,
          }}
        >
          <CloseIcon
            color="info"
            fontSize={isMobile ? "medium" : "large"}
            onClick={onClose}
            sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
          />
          <CheckIcon
            color="info"
            fontSize={isMobile ? "medium" : "large"}
            onClick={handleSave}
            sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
          />
        </Box>
      </Box>
    </Box>
  );
}
