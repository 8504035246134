import React, { createContext, useState } from "react";

// Create the initial context state
const initialContextState = {
  fileUploadModalopen: false,
};

// Create the context
export const UiContext = createContext(initialContextState);

// Create a context provider component
export const UiProvider = ({ children }) => {
  const [imageSizeCache, setImageSizeCache] = useState({});
  const [fileUploadModalopen, setFileUploadModalOpen] = useState(false);
  const [searchConfigModalOpen, setSearchConfigModalOpen] = useState(false);
  const [createSearchConfigModalOpen, setCreateSearchConfigModalOpen] =
    useState(false);
  const [createReportOpen, setCreateReportOpen] = useState(false);
  const [imageViewModalOpen, setImageViewModalOpen] = useState(false);
  const [framesSliderOpen, setFramesSliderOpen] = useState(false);
  const [analysisPreviewOpen, setAnalysisPreviewOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [hitsViewModalOpen, setHitsViewModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [imageComparisonModalOpen, setImageComparisonModalOpen] =
    useState(false);
  const [frameComparisonModalOpen, setFrameComparisonModalOpen] =
    useState(false);
  const [resetExifStatus, setResetExifStatus] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const handlefileUploadModalOpen = () => setFileUploadModalOpen(true);
  const handleSearchConfigModalOpen = () => setSearchConfigModalOpen(true);
  const handleCreateSearchConfigModalOpen = () =>
    setCreateSearchConfigModalOpen(true);
  const handleCreateReportModalOpen = () => setCreateReportOpen(true);
  const handleImageViewModalOpen = () => setImageViewModalOpen(true);
  const handleFramesSliderOpen = () => setFramesSliderOpen(true);
  const handleStatusModalOpen = (resetExif = false) => {
    setStatusModalOpen(true);
    console.log("resetExif", resetExif);
    setResetExifStatus(resetExif);
  };
  const handleHitsViewModalOpen = () => setHitsViewModalOpen(true);
  const handleNotificationModalOpen = () => setNotificationModalOpen(true);
  const handleAnalysisPreviewOpen = () => setAnalysisPreviewOpen(true);
  const handleImageComparisonModalOpen = () =>
    setImageComparisonModalOpen(true);
  const handleFrameComparisonModalOpen = () =>
    setFrameComparisonModalOpen(true);
  const handlefileUploadModalClose = () => setFileUploadModalOpen(false);
  const handleSearchConfigModalClose = () => setSearchConfigModalOpen(false);
  const handleCreateSearchConfigModalClose = () =>
    setCreateSearchConfigModalOpen(false);
  const handleCreateReportModalClose = () => setCreateReportOpen(false);
  const handleImageViewModalClose = () => setImageViewModalOpen(false);
  const handleFramesSliderClose = () => setFramesSliderOpen(false);
  const handleAnalysisPreviewClose = () => setAnalysisPreviewOpen(false);
  const handleHitsViewModalClose = () => setHitsViewModalOpen(false);
  const handleNotificationModalClose = () => setNotificationModalOpen(false);
  const handleStatusModalClose = () => setStatusModalOpen(false);
  const handleImageComparisonModalClose = () =>
    setImageComparisonModalOpen(false);
  const handleFrameComparisonModalClose = () =>
    setFrameComparisonModalOpen(false);
  const getBaseUrl = () => {
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      // Use localhost and the appropriate port for development
      return `http://localhost:3030`;
    } else {
      // Use the production URL
      return "https://find911.c303.io";
    }
  };

  const openInNewTab = (path) => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/${path}`;
    window.open(url, "_blank", "noreferrer");
  };

  // Create the context value
  const contextValue = {
    fileUploadModalopen,
    searchConfigModalOpen,
    createSearchConfigModalOpen,
    handleNotificationModalOpen,
    handleNotificationModalClose,
    handlefileUploadModalOpen,
    handlefileUploadModalClose,
    handleSearchConfigModalOpen,
    handleSearchConfigModalClose,
    handleCreateSearchConfigModalOpen,
    handleCreateSearchConfigModalClose,
    handleImageViewModalOpen,
    handleImageViewModalClose,
    notificationModalOpen,
    imageViewModalOpen,
    createReportOpen,
    handleCreateReportModalOpen,
    handleCreateReportModalClose,
    statusModalOpen,
    handleStatusModalOpen,
    handleStatusModalClose,
    hitsViewModalOpen,
    handleHitsViewModalOpen,
    handleHitsViewModalClose,
    progress,
    setProgress,
    openInNewTab,
    resetExifStatus,
    imageComparisonModalOpen,
    handleImageComparisonModalOpen,
    handleImageComparisonModalClose,
    framesSliderOpen,
    setFramesSliderOpen,
    handleFramesSliderClose,
    handleFramesSliderOpen,
    frameComparisonModalOpen,
    setFrameComparisonModalOpen,
    handleFrameComparisonModalClose,
    handleFrameComparisonModalOpen,
    analysisPreviewOpen,
    handleAnalysisPreviewClose,
    handleAnalysisPreviewOpen,
    imageSizeCache, setImageSizeCache
  };

  return (
    <UiContext.Provider value={contextValue}>{children}</UiContext.Provider>
  );
};
