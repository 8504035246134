import React from "react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import AuthenticatedRoute from "./Components/AuthenticatedRoute";
import UnauthenticatedRoute from "./Components/UnauthenticatedRoute";

// import Login from "./Login";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import Dashboard from "../src/Components/Dashboard/Dashboard";

import BuildRadiometricPalette from "./radiometricPalette/BuildRadioMetricPalette";
import BuildColorPalette from "./ColorPalette/BuildColorPalette";
import DetectObjectsPalette from "./ObjectDetect/DetectObjectsPalette";
import DetectFacesPalette from "./FaceDetect/DetectFacesPalette";
import DetectAnomalyPalette from "./AnomalyDetect/DetectAnomalyPalette";

// import CaseResultsPage from "./Cases/CaseResults";
import CreateAnalysisPage from "./Analysis/AnalysisCreate";
import ExecuteAnalysisPage from "./Analysis/AnalysisExecute";
import AnalysisSelectPage from "./Analysis/AnalysisSelect";
import AnalysisResultsPage from "./Analysis/AnalysisResults";

import MarkupsExamine from "./Markups/MarkupsExamine";

import CaseCreatePage from "./Cases/CaseCreate";
import CaseSelectPage from "./Cases/CaseSelect";
import CaseSummaryPage from "./Cases/CaseView";

import FlightCreatePage from "./Flights/FlightCreate";
import FlightSelectPage from "./Flights/FlightSelect";
import FlightSummaryPage from "./Flights/FlightView";

import CriteriaCreatePage from "./Criteria/CriteriaCreate";
import CriteriaSelectPage from "./Criteria/CriteriaSelect";
import CriteriaSummaryPage from "./Criteria/CriteriaView";

import SearchConfigsSelectPage from "./SearchConfigs/SearchConfigsSelect";

import TableOfContents from "./TableOfContents";

import ImagesSelectPage from "./Images/ImagesSelect";

import FootageUpload from "./Footage/FootageUpload";
import FootageSelectPage from "./Footage/FootageSelect";

import ImageViewPage from "./Images/ImageView";
import FlightVideoViewPage from "./Flights/FlightVideoView";
import FlightFootageViewPage from "./Footage/FlightFootageView";

import ProfileViewPage from "./Profile/profile";

// import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { useLocation, Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Typography, Link, Box } from "@mui/material";
import FlightVideoSelectPage from "./Footage/FlightVideoSelect";
import ReportsSelectPage from "./Report/ReportsSelect";
import ReportsViewPage from "./Report/ReportView";
import Upload from "./Upload/Upload";
import MessagingOpt from "./Static/MessagingOpt";

function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
  });
}
function AppBreadcrumbs() {
  let location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Box
      style={{
        transform: "translateY(20px)",
        height: "0px",
        marginLeft: "10vh",
        zIndex: "-100",
        // paddingBottom: "10vh"
        // marginX: 10,
        // flexGrow: 1,
        // overflow: "auto",
      }}
    >
      <Breadcrumbs aria-label="Breadcrumb">
        <Link color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          let to = `/${pathnames.slice(0, index + 1).join("/")}`;
          if (to.includes("upload")) {
            to = "#";
          }
          return last ? (
            <Typography color="textPrimary" key={to}>
              {toTitleCase(value)}
            </Typography>
          ) : (
            <Link color="inherit" component={RouterLink} to={to} key={to}>
              {toTitleCase(value)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}

export default function Links() {
  return (
    <>
      <AppBreadcrumbs />
      <Routes>
        <Route
          path="/login"
          element={
            <UnauthenticatedRoute>
              <Login />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <UnauthenticatedRoute>
              <Signup />
            </UnauthenticatedRoute>
          }
        />

        <Route
          path="/messaging-opt-in"
          element={
            // <UnauthenticatedRoute>
              <MessagingOpt />
            // </UnauthenticatedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <AuthenticatedRoute>
              <ProfileViewPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <Dashboard />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Case select</title>
              </Helmet>
              <CaseSelectPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/Create"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Case create</title>
              </Helmet>
              <CaseCreatePage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Case summary</title>
              </Helmet>
              <CaseSummaryPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/upload/:type"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Images upload</title>
              </Helmet>
              <Upload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/images/upload/:type"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Images upload</title>
              </Helmet>
              <Upload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Reports"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Reports Select</title>
              </Helmet>
              <ReportsSelectPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Reports/:report_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Report View</title>
              </Helmet>
              <ReportsViewPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Analysis/Create"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Analysis Create</title>
              </Helmet>
              <CreateAnalysisPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Analysis/Execute"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Analysis Execute</title>
              </Helmet>
              <ExecuteAnalysisPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Analysis"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Analysis Select</title>
              </Helmet>
              <AnalysisSelectPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Analysis/:analysis_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Analysis Results</title>
              </Helmet>
              <AnalysisResultsPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flights Select</title>
              </Helmet>
              <FlightSelectPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Flights/Create"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flight Create</title>
              </Helmet>
              <FlightCreatePage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Flights/:flight_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flights Summary</title>
              </Helmet>
              <FlightSummaryPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/upload/:type"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Upload</title>
              </Helmet>
              <Upload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/Videos/upload/:type"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Surveillance video upload</title>
              </Helmet>
              <Upload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/Footage/upload/:type"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Surveillance footage upload</title>
              </Helmet>
              <Upload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/Videos"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flight Video Select</title>
              </Helmet>
              <FlightVideoSelectPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Flights/:flight_id/Videos/:video_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flight Video View</title>
              </Helmet>
              <FlightVideoViewPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/Footage"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Footage Select</title>
              </Helmet>
              <FootageSelectPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Flights/:flight_id/Footage/:footage_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Flight Footage View</title>
              </Helmet>
              <FlightFootageViewPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Criteria"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Criteria Select</title>
              </Helmet>
              <CriteriaSelectPage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Criteria/Create"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Criteria Create</title>
              </Helmet>
              <CriteriaCreatePage />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Criteria/:criteria_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Criteria Summary</title>
              </Helmet>
              <CriteriaSummaryPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Images/"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Images Select</title>
              </Helmet>
              <ImagesSelectPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Images/:image_id"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Image View</title>
              </Helmet>
              <ImageViewPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Footage/Upload"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Footage Upload</title>
              </Helmet>
              <FootageUpload />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Markups/Examine"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Examine Markups</title>
              </Helmet>
              <MarkupsExamine />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Search Configs</title>
              </Helmet>
              <SearchConfigsSelectPage />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Images/:image_id/DetectColorPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Colors Palette</title>
              </Helmet>
              <BuildColorPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs/DetectColorPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Colors Palette</title>
              </Helmet>
              <BuildColorPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs/DetectObjectsPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Objects Palette</title>
              </Helmet>
              <DetectObjectsPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs/DetectAnomalyPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Anomaly Palette</title>
              </Helmet>
              <DetectAnomalyPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs/DetectFacesPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Faces Palette</title>
              </Helmet>
              <DetectFacesPalette />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="Cases/:case_id/Images/:image_id/DetectFacesPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Faces Palette</title>
              </Helmet>
              <DetectFacesPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/Images/:image_id/DetectRadiometricPalette"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Radiometric Palette</title>
              </Helmet>
              <BuildRadiometricPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="Cases/:case_id/SearchConfigs/radiometric"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>Detect Radiometric Palette</title>
              </Helmet>
              <BuildRadiometricPalette />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="*"
          element={
            <AuthenticatedRoute>
              <Helmet>
                <title>TableOfContents</title>
              </Helmet>
              <TableOfContents />
            </AuthenticatedRoute>
          }
        />
      </Routes>
    </>
  );
}
