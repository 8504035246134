import * as React from "react";
import { useContext, useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  Select,
  MenuItem,
  Grid,
  Paper,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "95%",
  // maxWidth: "1200px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "32px",
  overflow: "auto",
  maxHeight: "90vh",
};

import { UiContext } from "../Context/uiContext";
import { AnalysisContext } from "../Context/analysisContext";

export default function ImageComparisonModal(props) {
  const {
    imageComparisonModalOpen,
    handleImageComparisonModalClose,
    frameComparisonModalOpen,
    handleFrameComparisonModalClose,
  } = useContext(UiContext);

  const { origFrameDataImage, setOrigFrameDataImage } =
    useContext(AnalysisContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onClose = async (event, reason) => {
    if (imageComparisonModalOpen) {
      handleImageComparisonModalClose();
    }
    if (frameComparisonModalOpen) {
      handleFrameComparisonModalClose();
    }
  };

  React.useEffect(() => {
    if (origFrameDataImage[0]?.name !== "orig" && !props.framesCompare) {
      const imgOrig = props?.frameDataImages.filter(
        (obj) => obj.name === "orig"
      );
      setOrigFrameDataImage(imgOrig);
    }
  }, [origFrameDataImage]);

  const ZoomableDraggableImages = ({ leftImageSrc, rightImageSrc, props }) => {
    const leftImageRef = useRef(null);
    const rightImageRef = useRef(null);
    const [imgScale, setImgScale] = useState(1);

    const handleLeftImageChange = () => {
      const {
        clientWidth,
        clientHeight,
        scrollWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
      } = leftImageRef.current;

      const zoomX = scrollWidth / clientWidth;
      const zoomY = scrollHeight / clientHeight;
      const offsetX = scrollLeft / zoomX;
      const offsetY = scrollTop / zoomY;

      rightImageRef.current.scrollLeft = offsetX * zoomX;
      rightImageRef.current.scrollTop = offsetY * zoomY;
    };

    const handleLeftImageScroll = () => {
      handleLeftImageChange();
    };

    const handleZoom = (newScale) => {
      setImgScale(newScale);
      leftImageRef.current.scrollLeft = 0;
      leftImageRef.current.scrollTop = 0;
      rightImageRef.current.scrollLeft = 0;
      rightImageRef.current.scrollTop = 0;
    };

    const UnstyledSelectBasic = ({ options }) => {
      return (
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={selectedImage[0]?.name}
          label="Image"
          onChange={handleChange}
          size="small"
          sx={{ minWidth: "150px" }}
        >
          {options?.frameDataImages.map((el) => (
            <MenuItem key={el.name} value={el.name}>
              {el.name}
            </MenuItem>
          ))}
        </Select>
      );
    };

    return (
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography>Original Image:</Typography>
              <Button
                variant="contained"
                onClick={() => handleZoom(imgScale * 2)}
              >
                +
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  handleZoom(imgScale / 2 < 0.05 ? 0.05 : imgScale / 2)
                }
              >
                -
              </Button>
            </Box>
            <Box
              ref={leftImageRef}
              onScroll={handleLeftImageScroll}
              sx={{
                overflow: "auto",
                border: "1px solid #ccc",
                width: "100%",
                height: isMobile ? "40vh" : "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                imageRendering: "pixelated"
              }}
            >
              <img
                src={leftImageSrc}
                style={{
                  transform: `scale(${imgScale})`,
                  transformOrigin: "0 0",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                alt="Left Image"
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography>Compare with:</Typography>
              {imageComparisonModalOpen && (
                <UnstyledSelectBasic options={props} />
              )}
            </Box>
            <Box
              ref={rightImageRef}
              sx={{
                overflow: "auto",
                border: "1px solid #ccc",
                width: "100%",
                height: isMobile ? "40vh" : "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                imageRendering: "pixelated"
              }}
            >
              <img
                src={rightImageSrc}
                style={{
                  transform: `scale(${imgScale})`,
                  transformOrigin: "0 0",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                alt="Right Image"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const [selectedImage, setSelectedImage] = React.useState(
    !props.framesCompare &&
      props?.frameDataImages.filter((obj) => obj.name === "circle_tag_img")
  );

  const handleChange = (event) => {
    setSelectedImage(
      props?.frameDataImages.filter((obj) => obj.name === event.target.value)
    );
  };

  return (
    <Modal
      open={imageComparisonModalOpen || frameComparisonModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* {frameComparisonModalOpen && (
          <FramesCompare
            leftImageSrc={props.leftImageSrc}
            rightImageSrc={props.rightImageSrc}
            props={props}
          />
        )} */}
        {(imageComparisonModalOpen || frameComparisonModalOpen) && (
          <ZoomableDraggableImages
            leftImageSrc={
              imageComparisonModalOpen
                ? origFrameDataImage[0]?.filepath
                : props.leftImageSrc
            }
            rightImageSrc={
              imageComparisonModalOpen
                ? selectedImage[0]?.filepath
                : props.rightImageSrc
            }
            props={props}
          />
        )}
      </Box>
    </Modal>
  );
}
