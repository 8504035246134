import * as React from "react";
import { useContext, useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  Select,
  MenuItem,
  Grid,
  Paper,
  Container,
} from "@mui/material";

const style = {
  position: "absolute",
  transform: "translateX(6vw)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  padding: "32px",
};

import { UiContext } from "../Context/uiContext";
import { AnalysisContext } from "../Context/analysisContext";

export default function ImageComparisonModal(props) {
  const {
    imageComparisonModalOpen,
    handleImageComparisonModalOpen,
    handleImageComparisonModalClose,
  } = useContext(UiContext);

  const { origFrameDataImage, setOrigFrameDataImage } =
    useContext(AnalysisContext);

  const onClose = async (event, reason) => {
    handleImageComparisonModalClose();
  };
  // Filter objects for imgOrig and circleTagImage
  // const imgOrig = props?.frameDataImages.filter((obj) => obj.name === "orig");
  const circleTagImage = props?.frameDataImages.filter(
    (obj) => obj.name === "circle_tag_img"
  );

  React.useEffect(() => {
    if(origFrameDataImage[0]?.name !== "orig"){
      const imgOrig = props?.frameDataImages.filter((obj) => obj.name === "orig");
      setOrigFrameDataImage(imgOrig);
    }
  }, [origFrameDataImage]);



  const ZoomableDraggableImages = ({ leftImageSrc, rightImageSrc, props }) => {
    const leftImageRef = useRef(null);
    const rightImageRef = useRef(null);
    const [imgScale, setImgScale] = useState(1);

    const handleLeftImageChange = () => {
      const {
        clientWidth,
        clientHeight,
        scrollWidth,
        scrollHeight,
        scrollLeft,
        scrollTop,
      } = leftImageRef.current;

      // Calculate the ratio of zoom/pan on the left image
      const zoomX = scrollWidth / clientWidth;
      const zoomY = scrollHeight / clientHeight;
      const offsetX = scrollLeft / zoomX;
      const offsetY = scrollTop / zoomY;

      // Apply the same zoom/pan values to the right image
      rightImageRef.current.scrollLeft = offsetX * zoomX;
      rightImageRef.current.scrollTop = offsetY * zoomY;
    };

    const handleLeftImageScroll = () => {
      handleLeftImageChange();
    };
    const UnstyledSelectBasic = ({ options }) => {
      return (
        <div>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            value={selectedImage[0]?.name}
            label="Image"
            onChange={handleChange}
          >
            {options?.frameDataImages.map((el) => (
              <MenuItem value={el.name}>{el.name}</MenuItem>
            ))}
          </Select>
        </div>
      );
    };

    return (
      <Grid container spacing={4} sx={{ height: "85vh" }}>
        <Grid item xs={6} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "85vh !Imporant",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                marginBottom: "2vh",
                alignItems: "baseline",
              }}
            >
              <Typography
                sx={{
                  marginBottom: "2vh",
                }}
              >
                Original Image:{" "}
              </Typography>
              <button
                style={{
                  width: "50px",
                  height: "50px",
                  fontSize: "xx-large",
                }}
                onClick={() => setImgScale(imgScale * 2)}
              >
                +
              </button>
              <button
                style={{
                  width: "50px",
                  height: "50px",
                  fontSize: "xx-large",
                }}
                onClick={() =>
                  setImgScale(imgScale / 2 < 0.05 ? 0.05 : imgScale / 2)
                }
              >
                -
              </button>
            </Box>

            <div
              ref={leftImageRef}
              onScroll={handleLeftImageScroll}
              style={{
                overflow: "scroll",
                // maxWidth: "750px", // Adjust the width as needed
                maxHeight: "75vh", // Set a fixed height or adjust accordingly
                borderStyle: "solid",
                // marginLeft: "5vh",
              }}
            >
              <img
                src={leftImageSrc}
                maxWidth={750}
                maxHeight={750}
                style={{ transform: `scale(${imgScale})` }}
                alt="Left Image"
              />
            </div>
          </Box>
        </Grid>

        <Grid item xs={6} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // height: "82vh !Imporant",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                marginBottom: "2vh",
                alignItems: "baseline",
              }}
            >
              <Typography>Comapre with: </Typography>
              <UnstyledSelectBasic options={props} />
            </Box>

            <div
              ref={rightImageRef}
              style={{
                overflow: "hidden",
                // maxWidth: "750px", // Adjust the width as needed
                maxHeight: "75vh", // Set a fixed height or adjust accordingly
                // marginLeft: "10vh",
                borderStyle: "solid",
              }}
            >
              <img
                src={rightImageSrc}
                maxWidth={750}
                maxHeight={750}
                style={{ transform: `scale(${imgScale})` }}
                alt="Right Image"
              />
            </div>
          </Box>
        </Grid>
      </Grid>

      // <div style={{ display: "flex" }}>

      // </div>
    );
  };

  const [selectedImage, setSelectedImage] = React.useState(
    props?.frameDataImages.filter((obj) => obj.name === "circle_tag_img")
  );

  const handleChange = (event) => {
    setSelectedImage(
      props?.frameDataImages.filter((obj) => obj.name === event.target.value)
    );
  };

  return (
    <Modal
      open={imageComparisonModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={{ ...style }}>
        <div>
          <ZoomableDraggableImages
            leftImageSrc={origFrameDataImage[0]?.filepath}
            rightImageSrc={selectedImage[0]?.filepath}
            props={props}
          />
        </div>
      </Box>
    </Modal>
  );
}
