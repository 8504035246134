import React from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../table/table";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageViewModal from "../Modals/imageViewModal";
import { MainLayout } from "../Layouts/MainLayout";
import ReportTable from "./ReportTable";
import { ReportContext } from "../Context/reportContext";
import { UiContext } from "../Context/uiContext";
import CreateReportModal from "../Modals/createReportModal";

export default function ReportsSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { reports, getReportsByCase } = useContext(ReportContext);
  const {
    createReportOpen,
    handleCreateReportModalClose,
    handleCreateReportModalOpen,
  } = React.useContext(UiContext);

  const [loading, setLoading] = useState(false);

  const { case_id, image_id } = useParams();

  React.useEffect(() => {
    if (!reports) fetchData();
  }, [reports]);

  const fetchData = async () => {
    try {
      setLoading(true);
      await getReportsByCase(case_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <MainLayout title={`Reports ${sarCase.sarCaseName}`}>
      {createReportOpen && <CreateReportModal />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          onClick={() => handleCreateReportModalOpen()}
          sx={{ marginLeft: "auto" }}
        >
          Create Report
        </Button>
      </Box>
      <Box marginBottom={5} maxHeight={750}>
        <ReportTable reports={reports} loading={loading} />
      </Box>
    </MainLayout>
  );
}
