import React from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";

import { Box, Button, Typography } from "@mui/material";
import ImagesTable from "../Images/ImagesTable";
import FileUploadModal from "../Modals/FileUploadModal";
import { MainLayout } from "../Layouts/MainLayout";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";

const testImages = [
  {
    imageId: 1,
    imageName: "Santa Cruz Sweatshirt",
    filepath:
      "https://img1.wsimg.com/isteam/ip/bcef1094-bcc3-4cf6-a34f-e052ad88e450/IMG-6681%20(1).JPG/:/rs=w:133,h:75,cg:true,m/cr=w:133,h:75/qt=q:95",
    sequence: 1,
    img_purpose: 0,
    use_in_report: "Yes",
    description: "simple description",
    uploadDate: new Date(),
  },
  {
    imageId: 2,
    imageName: "Bullet Casing",
    filepath:
      "https://img1.wsimg.com/isteam/ip/bcef1094-bcc3-4cf6-a34f-e052ad88e450/IMG-6881.PNG/:/cr=t:29.6%25,l:17.24%25,w:31.65%25,h:14.62%25/rs=w:365,h:365,cg:true,m",
    sequence: 1,
    img_purpose: 1,
    use_in_report: "Yes",
    description: "another simple description",
    uploadDate: new Date(),
  },
];

export default function ImagesSelectPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { handlefileUploadModalOpen, fileUploadModalopen, openInNewTab } =
    useContext(UiContext);
  const { userCtx } = useContext(UserContext);
  const { images, getImagesByCase, setImages } = useContext(ImageContext);
  const { case_id } = useParams();

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase?.sarCaseId) {
        getCaseByID(case_id);
        // getImagesByCase(case_id);
      } else {
        // getImagesByCase(case_id);
      }
    } else {
      setImages(testImages);
    }
  }, [sarCase?.sarCaseId]);

  return (
    <MainLayout title={`Images`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Button
          variant="contained"
          type="submit"
          component={Link}
          to={"/cases/create"}
          sx={{ visibility: "hidden", marginRight: "auto" }}
        >
          This is a hidden button
        </Button>

        <Button
          variant="contained"
          type="submit"
          onClick={() => openInNewTab(`Cases/${case_id}/images/upload/images`)}
          sx={{ marginLeft: "auto" }}
        >
          Upload Files
        </Button>
      </Box>
      <FileUploadModal
        isImageSelect={true}
        title="Purpose and Kind of Files:"
      />
      <ImagesTable
        // images={images}
        tableId={"ImagesTable-select"}
        editable={false}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: true,
          selection: false,
          maxBodyHeight: "500px",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
      />

      <Box
        sx={{
          width: "100%",
          padding: "2rem", // Adjust padding for mobile
          marginTop: "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.images_select_FAQ} />
      </Box>
    </MainLayout>
  );
}
