import React from "react";
import Vimeo from "@u-wave/react-vimeo";

export default function BackgroundVideo() {
  return (
    <>
      <div
        style={{
          bottom: 0,
          left: 0,
          minHeight: "90%",
          minWidth: "100%",
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 0,
        }}
      >
        <div
          style={{
            height: "56.25vw", // for a 16:9 aspect ratio, 9/16*100 = 56.25
            minHeight: "90vh",
            minWidth: "177.77vh", // for a 16:9 aspect ratio, 16/9*100 = 177.77
            position: "absolute",
            transform: "translate(" - (50 % ", ") - (50 % ")"),
            width: "100vw",
          }}
        >
          <Vimeo
            background={true}
            height={1080}
            loop={true}
            responsive
            video="https://player.vimeo.com/video/666375507?h=14c6670bfa&autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=0"
            width={1920}
            autoplay={true}
            muted={true}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.66)",
          bottom: 0,
          boxShadow: "inset 0 0 5rem rgba(0, 0, 0, 0.5)",
          left: 0,
          minHeight: "100%",
          minWidth: "100%",
          objectFit: "cover",
          objectPosition: "center",
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: -1,
        }}
      />
    </>
  );
}
