import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { CropperRef, Cropper } from "react-advanced-cropper";

import { CaseContext } from "../../Context/caseContext";
import { UserContext } from "../../Context/userContext";
import { ImageContext } from "../../Context/imageContext";

import { ZoomInIcon } from "./icons/ZoomInIcon";
import { ZoomOutIcon } from "./icons/ZoomOutIcon";
import { ArrowTopIcon } from "./icons/ArrowTopIcon";
import { ArrowLeftIcon } from "./icons/ArrowLeftIcon";
import { ArrowRightIcon } from "./icons/ArrowRightIcon";
import { ArrowBottomIcon } from "./icons/ArrowBottomIcon";

import { VerticalButtons } from "./components/VerticalButtons";
import { SquareButton } from "./components/SquareButton";
import "./CustomCropper.scss";
import { toast } from "react-toastify";
import { UiContext } from "../../Context/uiContext";

export const CustomCropper = ({
  imageUrl,
  coordinates,
  setPreview,
  setCoordinates,
  setPaletteArray,
  paletteArray,
  noCrop = false,
  HSL = false,
}) => {
  const { userCtx } = useContext(UserContext);
  const [sarCase] = useState(CaseContext);
  const { imageSizeCache, setImageSizeCache } = useContext(UiContext);

  const {
    uploadImageForRadiometric,
    uploadImageForColorPalette,
    uploadImageForHSLColorPalette,
  } = useContext(ImageContext);
  const cropperRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  // State to store dynamic cropper size
  const [cropSize, setCropSize] = useState(200); // Default to 200x200

  const [activeDirection, setActiveDirection] = useState(null);
  const [activeZoom, setActiveZoom] = useState(null);
  const moveInterval = useRef(null);
  const zoomInterval = useRef(null);

  useEffect(() => {
    if (activeZoom) {
      // Trigger the first zoom immediately
      zoom(activeZoom)();

      // Then set up interval for continuous zooming
      zoomInterval.current = setInterval(() => {
        zoom(activeZoom)();
      }, 100);
    } else {
      // Clear interval when button is released
      if (zoomInterval.current) {
        clearInterval(zoomInterval.current);
        zoomInterval.current = null;
      }
    }

    // Clean up on unmount
    return () => {
      if (zoomInterval.current) {
        clearInterval(zoomInterval.current);
      }
    };
  }, [activeZoom]);

  useEffect(() => {
    if (activeDirection) {
      // Trigger the first move immediately
      move(activeDirection)();

      // Then set up interval for continuous movement
      moveInterval.current = setInterval(() => {
        move(activeDirection)();
      }, 100); // Adjust timing as needed (100ms = 10 moves per second)
    } else {
      // Clear interval when button is released
      if (moveInterval.current) {
        clearInterval(moveInterval.current);
        moveInterval.current = null;
      }
    }

    // Clean up on unmount
    return () => {
      if (moveInterval.current) {
        clearInterval(moveInterval.current);
      }
    };
  }, [activeDirection]);

  // Calculate optimal cropper size based on image dimensions
  // const imageSizeCache = {};

  useEffect(() => {
    if (imageUrl) {
      if (imageSizeCache[imageUrl]) {
        const { width, height } = imageSizeCache[imageUrl];
        calculateCropSize(width, height);
      } else {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const { width, height } = img;
          setImageSizeCache({
            ...imageSizeCache,
            [imageUrl]: { width, height },
          });
          calculateCropSize(width, height);
        };
      }
    }
  }, [imageUrl]);

  const calculateCropSize = (width, height) => {
    const aspectRatio = width / height;
    let dynamicSize = Math.min(
      500,
      Math.max(100, 0.2 * Math.min(width, height))
    );
    if (aspectRatio < 0.3 || aspectRatio > 3) {
      dynamicSize = Math.min(dynamicSize, 150);
    }
    setCropSize(dynamicSize);
  };

  const zoom = (factor) => () => {
    const cropper = cropperRef.current;
    if (cropper) {
      cropper.zoomImage(factor);
    }
  };

  const move = (direction) => () => {
    const cropper = cropperRef.current;
    if (cropper) {
      const coordinates = cropper.getCoordinates({ naturalSize: true });
      if (coordinates) {
        const { width, height } = coordinates;
        const moveAmount = {
          left: [-width / 8, 0],
          right: [width / 8, 0],
          top: [0, -height / 8],
          bottom: [0, height / 8],
        }[direction];

        if (moveAmount) {
          cropper.moveImage(...moveAmount);
        }
      }
    }
  };

  const renderMoveButton = (direction, title, icon) => (
    <SquareButton
      title={title}
      onMouseDown={() => setActiveDirection(direction)}
      onMouseUp={() => setActiveDirection(null)}
      onMouseLeave={() => setActiveDirection(null)} // Stop if mouse leaves button
      onClick={move(direction)} // Still support single click
    >
      {icon}
    </SquareButton>
  );

  const renderZoomButton = (factor, title, icon) => (
    <SquareButton
      title={title}
      onMouseDown={() => setActiveZoom(factor)}
      onMouseUp={() => setActiveZoom(null)}
      onMouseLeave={() => setActiveZoom(null)}
      onClick={zoom(factor)}
    >
      {icon}
    </SquareButton>
  );

  const onCrop = useCallback(async () => {
    if (cropperRef.current) {
      if (setCoordinates) {
        setCoordinates(
          cropperRef.current.getCoordinates({ naturalSize: true })
        );
        setPreview(
          cropperRef.current.getCanvas({ naturalSize: true })?.toDataURL()
        );
        submitCoordinates(
          cropperRef.current.getCoordinates({ naturalSize: true })
        );
      } else {
        setPreview(
          cropperRef.current.getCanvas({ naturalSize: true })?.toDataURL()
        );
        fetchKeyColors(cropperRef.current.getCanvas()?.toDataURL());
      }
    }
  }, [cropperRef.current, setCoordinates, setPreview]);

  const submitCoordinates = async (coordinates) => {
    const { top: y, left: x, width, height } = coordinates;
    const originalTop = y; // Y
    const originalLeft = x; // X
    const originalBottom = y + height;
    const originalRight = x + width;
    await fetchKeyTemps({
      top: originalTop,
      left: originalLeft,
      bottom: originalBottom,
      right: originalRight,
    });
  };

  const fetchKeyTemps = async (coordinates) => {
    try {
      setIsLoading(true);
      const response = await uploadImageForRadiometric({
        top: coordinates.top,
        left: coordinates.left,
        bottom: coordinates.bottom,
        right: coordinates.right,
      });
      setPaletteArray([
        ...paletteArray,
        ...response.map((el) => ({
          t1: `${parseFloat(el.low).toFixed(2)}`,
          t2: `${parseFloat(el.high).toFixed(2)}`,
          isRange: el.high ? true : false,
        })),
      ]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const fetchKeyColors = async (paramData) => {
    try {
      setIsLoading(true);
      let response = null;
      if (!HSL) {
        response = await uploadImageForColorPalette({
          image: paramData,
          topNumColors: 5,
          cid: sarCase.sarCaseId,
        });
      } else {
        response = await uploadImageForHSLColorPalette({
          image: paramData,
          topNumColors: 5,
          cid: sarCase.sarCaseId,
        });
      }
      if (!HSL) {
        setPaletteArray(paletteArray.concat(convertToRGBFormat(response)));
      } else {
        setPaletteArray(paletteArray.concat(convertToHSLFormat(response)));
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  const convertToRGBFormat = (objects) => {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      if (obj.low && typeof obj.low === "string") {
        const rgbArray = obj.low.match(/\d+(\.\d+)?/g);
        if (rgbArray && rgbArray.length === 3) {
          const roundedArray = rgbArray.map(Number).map(Math.round);
          const rgbString = `rgb(${roundedArray.join(",")})`;
          obj.rgb = rgbString;
        }
      }

      if (obj.high && typeof obj.high === "string") {
        const rgbArray = obj.high.match(/\d+(\.\d+)?/g);
        if (rgbArray && rgbArray.length === 3) {
          const roundedArray = rgbArray.map(Number).map(Math.round);
          const rgbString = `rgb(${roundedArray.join(",")})`;
          obj.rgb2 = rgbString;
        }
      }
      obj.isRange = true;

      return obj;
    });
  };

  const convertToHSLFormat = (objects) => {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      if (obj.low && typeof obj.low === "string") {
        const hslArray = obj.low.match(/\d+(\.\d+)?/g);
        if (hslArray && hslArray.length === 3) {
          const hslString = `hsl(${hslArray[0]}, ${Math.round(
            hslArray[1]
          )}%, ${Math.round(hslArray[2])}%)`;
          obj.hsl = hslString;
        }
      }

      if (obj.high && typeof obj.high === "string") {
        const hslArray = obj.high.match(/\d+(\.\d+)?/g);
        if (hslArray && hslArray.length === 3) {
          const hslString = `hsl(${hslArray[0]}, ${Math.round(
            hslArray[1]
          )}%, ${Math.round(hslArray[2])}%)`;

          obj.hsl2 = hslString;
        }
      }
      obj.isRange = true;

      return obj;
    });
  };
  return (
    <>
      <div
        className="getting-result-manual-example"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Cropper
          ref={cropperRef}
          className="getting-result-manual-example__cropper"
          src={imageUrl}
          imageRestriction="fitArea"
          resizeImage={false}
          maxWidth={noCrop ? "" : cropSize}
          maxHeight={noCrop ? "" : cropSize}
          
          // initialAspectRatio={noCrop ? 1 : undefined} // Make sure it covers the entire area
          // viewMode={noCrop ? 3 : 1} // Allows unrestricted cropping if noCrop is true
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain", // Ensure the image fits within the container
          }}
        />
        {setPreview && setPaletteArray && paletteArray && (
          <div
            className="getting-result-manual-example__crop-button"
            onClick={onCrop}
          >
            {!isLoading ? "Crop Image" : "Loading..."}
          </div>
        )}
        <VerticalButtons>
          {renderZoomButton(1.05, "Zoom In", <ZoomInIcon />)}
          {renderZoomButton(0.95, "Zoom Out", <ZoomOutIcon />)}
          {renderMoveButton("top", "Move Top", <ArrowTopIcon />)}
          {renderMoveButton("left", "Move Left", <ArrowLeftIcon />)}
          {renderMoveButton("right", "Move Right", <ArrowRightIcon />)}
          {renderMoveButton("bottom", "Move Bottom", <ArrowBottomIcon />)}
        </VerticalButtons>
      </div>
    </>
  );
};
