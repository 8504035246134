import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import CssBaseline from "@mui/material/CssBaseline";
import DashButton from "../Components/Dashboard/DashButton";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Stack,
  Button,
  ListItemText,
  ListSubheader,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";

import ImagesTable from "../Images/ImagesTable";
import FileUploadModal from "../Modals/FileUploadModal";
import Image from "mui-image";
import Carousel from "react-material-ui-carousel";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import FlagIcon from "@mui/icons-material/Flag";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import MaterialTable from "material-table";

import { CaseContext } from "../Context/caseContext";
import { AnalysisContext } from "../Context/analysisContext";
import { ReportContext } from "../Context/reportContext";
import { FlightContext } from "../Context/flightContext";
import { ImageContext } from "../Context/imageContext";
import { UiContext } from "../Context/uiContext";
import { SearchConfigContext } from "../Context/searchConfigContext";

import AnalysisTable from "../Analysis/AnalysisTable";
import ReportTable from "../Report/ReportTable";
import FlightTable from "../Flights/FlightTable";
import SearchConfigsTable from "../SearchConfigs/SearchConfigsTable";
import Table from "../table/table";
import styled from "@emotion/styled";
import CreateSearchConfigModal from "../Modals/createSearchConfigModal";
import CreateReportModal from "../Modals/createReportModal";
import CustumAccordianSummaryLayout from "../Layouts/CustumAccordianSummaryLayout";
import { MainLayout } from "../Layouts/MainLayout";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
import ReportAssetsTable from "../ReportAssets/ReportAssetsTable";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #dddddd;
  }
  &:nth-of-type(even) {
    background-color: #999999;
  }
  & > td {
    color: black;
  }
`;

export default function CaseSummaryPage() {
  const { sarCase, getCaseByID, updateCase } = useContext(CaseContext);
  const { analyses, getAnalysesByCase } = useContext(AnalysisContext);
  const { reports, getReportsByCase } = useContext(ReportContext);
  const {} = useContext(FlightContext);
  const { images, getImagesByCase } = useContext(ImageContext);
  const { searchConfigs, getSearchConfigsByCase, setSearchConfigs } =
    useContext(SearchConfigContext);
  const {
    handlefileUploadModalOpen,
    fileUploadModalopen,
    handleSearchConfigModalOpen,
    handleCreateSearchConfigModalOpen,
    createReportOpen,
    handleCreateReportModalClose,
    handleCreateReportModalOpen,
    openInNewTab,
  } = useContext(UiContext);
  const [columns, setColumns] = useState([
    { title: "Org Id", field: "sarCaseOrgId", editable: "never" },
    { title: "Case Id", field: "sarCaseId", editable: "never" },
    { title: "Date", field: "sarCaseDate", editable: "never" },
    { title: "Lead Agency", field: "sarCaseAgencyName", editable: "onUpdate" },
    { title: "Case Name/#", field: "sarCaseName", editable: "onUpdate" },
    { title: "Lead Name", field: "leadName", editable: "onUpdate" },
    { title: "Lead Phone", field: "leadPhone", editable: "onUpdate" },
    { title: "Lead Email", field: "leadEmail", editable: "onUpdate" },
    {
      title: "Search Area Name",
      field: "searchAreaName",
      editable: "onUpdate",
    },
    {
      title: "Description",
      field: "sarCaseDescription",
      editable: "onUpdate",
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.sarCaseDescription}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
  ]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [assetsLoading, setAssetsLoading] = useState(false);
  const [flightLoading, setFlightLoading] = useState(false);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [reportsLoading, setReportsLoading] = useState(false);
  const [searchConfigsloading, setSearchConfigsLoading] = useState(false);
  const navigate = useNavigate();

  const { case_id } = useParams();

  const fetchCaseData = async () => {
    setLoading(true);
    try {
      await getCaseByID(case_id);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!sarCase?.sarCaseId) {
      console.log("CASE VIEW fetchCaseData ");
      fetchCaseData();
    } else {
      console.log("CASE VIEW SETTING DATA: ", sarCase);
      setData([sarCase]);
    }
  }, [sarCase]);

  React.useEffect(() => {
    console.log("DATA: ", data);
  }, [data]);

  const onAnalysesExpand = async (event, expanded) => {
    if (expanded) {
      // try {
      setAnalysisLoading(true);
      // await getAnalysesByCase(case_id);
      // setAnalysisLoading(false);
      // } catch (error) {
      //   setAnalysisLoading(false);
      //   toast.error(error.message);
      // }
    } else {
      setAnalysisLoading(false);
    }
  };

  const onMarkupExpand = (event, expanded) => {
    if (expanded) getMarkupsByCase(case_id);
  };

  const onReportsExpand = async (event, expanded) => {
    if (expanded) {
      try {
        setReportsLoading(true);
        let data = await getReportsByCase(case_id);
        console.log("onReportsExpand", data);
        setReportsLoading(false);
      } catch (error) {
        setReportsLoading(false);
        toast.error(error.message);
      }
    }
  };

  const onFlightsExpand = async (event, expanded) => {
    if (expanded) {
      setFlightLoading(true);
    } else {
      setFlightLoading(false);
    }
  };

  const onImagesExpand = async (event, expanded) => {
    if (expanded) {
      try {
        setImageLoading(true);
        // await getImagesByCase(case_id);
        // setImageLoading(false);
      } catch (error) {
        setImageLoading(false);
        toast.error(error.message);
      }
    } else {
      setImageLoading(false);
    }
  };

  const onAssetsExpand = async (event, expanded) => {
    if (expanded) {
      try {
        setAssetsLoading(true);
        // await getImagesByCase(case_id);
        // setImageLoading(false);
      } catch (error) {
        setAssetsLoading(false);
        toast.error(error.message);
      }
    } else {
      setAssetsLoading(false);
    }
  };

  const onSearchConfigsExpand = async (event, expanded) => {
    if (expanded) {
      setSearchConfigsLoading(true);
      try {
        await getSearchConfigsByCase(case_id);
      } catch (error) {
        toast.error(error.message);
      }
      setSearchConfigsLoading(false);
    }
  };

  const onSummaryUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.sarCaseId === oldData.sarCaseId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);

        await updateCase({
          action: "update-case",
          cid: oldData.sarCaseId,
          case_name: newData.sarCaseName,
          agency_name: newData.sarCaseAgencyName,
          lead_name: newData.leadName,
          lead_phone: newData.leadPhone,
          lead_email: newData.leadEmail,
          search_area_name: newData.searchAreaName,
          description: newData.sarCaseDescription,
        });

        resolve();
      } catch (error) {
        toast.error(error.message);
        resolve();
      }

      // setTimeout(() => {

      // }, 1000);
    });

  return (
    <MainLayout title={`Case ${sarCase?.sarCaseName}`}>
      {createReportOpen && (
        <CreateReportModal
          createReportModalClose={() => {
            onReportsExpand("", true);
            handleCreateReportModalClose();
          }}
        />
      )}
      <Box
        sx={{
          width: "100%",
          marginBottom: "5vh",
        }}
      >
        <br />
        <br />
        {data && data.length > 0 && (
          <Table
            data={data}
            columns={columns}
            onRowUpdate={onSummaryUpdate}
            loading={loading}
            editable={true}
            recordType="case"
          />
        )}
      </Box>
      <Box>
        <Accordion onChange={onImagesExpand}>
          <CustumAccordianSummaryLayout
            title="1. Reference Images / Evidence"
            description="Photos for color samples or evidence"
            button1Text="go to page"
            button1HandleClick={() => navigate(`/cases/${case_id}/images`)}
            button2HandleClick={() => {
              openInNewTab(`Cases/${case_id}/upload/images`);
              // handlefileUploadModalOpen();
            }}
            button2Text="UPLOAD FILES"
          />
          <AccordionDetails>
            {/* <FileUploadModal
              isImageSelect={true}
              title="Purpose and Kind of Files:"
            /> */}

            {imageLoading && (
              <ImagesTable
                tableId={"ImagesTable-CaseView"}
                editable={true}
                deleteable={true}
                options={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: false,
                  paging: true,
                  selection: false,
                  maxBodyHeight: "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
              ></ImagesTable>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onSearchConfigsExpand}>
          <CustumAccordianSummaryLayout
            title="2. Search Configs"
            description="View / create search criteria"
            button1Text="go to page"
            button1HandleClick={() =>
              navigate(`/cases/${case_id}/search-configs`)
            }
            button2HandleClick={() => handleCreateSearchConfigModalOpen()}
            button2Text="CREATE CONFIG"
          />
          <CreateSearchConfigModal />
          <AccordionDetails>
            {searchConfigs && (
              <SearchConfigsTable
                searchConfigs={searchConfigs}
                tableOptions={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: false,
                  paging: false,
                  selection: false,
                  maxBodyHeight: "500px",
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
                loading={searchConfigsloading}
                editable={true}
                deleteable={true}
                key={case_id}
              ></SearchConfigsTable>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onFlightsExpand}>
          <CustumAccordianSummaryLayout
            title="3. Flight Images"
            description="Load images or START analysis"
            button1Text="go to page"
            button1HandleClick={() => navigate(`/cases/${case_id}/flights`)}
            button2HandleClick={() =>
              navigate(`/cases/${case_id}/flights/create`)
            }
            button2Text="Create Flight"
          />

          <AccordionDetails>
            {flightLoading && (
              <FlightTable
                analyse={true}
                editable={true}
                tableId={"FlightTable-CaseView"}
              />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onAnalysesExpand}>
          <CustumAccordianSummaryLayout
            title="4. Analysis Results"
            description="Check status or review results"
            button1Text="go to page"
            button1HandleClick={() => navigate(`/cases/${case_id}/analysis`)}
          />

          <AccordionDetails>
            {analysisLoading && (
              <AnalysisTable
                tableId={"AnalysisTable-CaseView"}
                // analyses={analyses?.[0]?.initial ? [] : analyses}
                // loading={analysisLoading}
              />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onAssetsExpand}>
          <CustumAccordianSummaryLayout
            title="5. Report Assets"
            description="Extra images or pdfs for report"
            button1Text="go to page"
            button1HandleClick={() => navigate(`/cases/${case_id}/assets`)}
            button2HandleClick={() => {
              openInNewTab(`Cases/${case_id}/upload/assets`);
            }}
            button2Text="UPLOAD ASSETS"
          />
          <AccordionDetails>
            {assetsLoading && (
              <ReportAssetsTable
                tableId={"AssetsTable-CaseView"}
                editable={true}
                deleteable={true}
                options={{
                  toolbar: false,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: false,
                  paging: true,
                  selection: false,
                  maxBodyHeight: "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
              ></ReportAssetsTable>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onReportsExpand}>
          <CustumAccordianSummaryLayout
            title="6. Reports"
            description="View / create case reports"
            button1Text="go to page"
            button1HandleClick={() => navigate(`/cases/${case_id}/reports`)}
            button2HandleClick={() => handleCreateReportModalOpen()}
            button2Text="CREATE REPORT"
          />

          <AccordionDetails>
            <ReportTable reports={reports} loading={reportsLoading} editable={true} deleteable={true}/>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "2rem", // Adjust padding for mobile
          marginTop: "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.case_view_FAQ} />
      </Box>
    </MainLayout>
  );
}

{
  /*<Accordion onChange={onReportsExpand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textDecoration: "underline" }}>
              <strong>6. Reports</strong>
            </Typography>
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                navigate(`/Cases/${case_id}/Reports/Select`);
              }}
              sx={{ marginLeft: "auto", marginRight: "2vh" }}
            >
              go to page
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                navigate(`/Cases/${case_id}/Analysis/Create`);
              }}
              sx={{ marginLeft: "auto", marginRight: "2vh" }}
            >
              Create Report
            </Button>
          </AccordionSummary>
          <AccordionDetails>
            <ReportTable reports={reports} />
          </AccordionDetails>
        </Accordion>

        <Accordion onChange={onMarkupExpand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ textDecoration: "underline" }}>
              <strong>5. Flagged Images</strong>
            </Typography>
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                navigate(`/Cases/${case_id}/Markups/Select`);
              }}
              sx={{ marginLeft: "auto", marginRight: "2vh" }}
            >
              go to page
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginLeft: "auto",
                marginRight: "2vh",
                visibility: "hidden",
              }}
            >
              {/*Examine Image Markups*/
}
{
  /*</Button>
          </AccordionSummary>
          <AccordionDetails>
            {/*<AnalysisTable markups={markups} />*/
}
{
  /*<Typography>
              TODO: RTB Markup list of images - needs reuse & sortable Ehti
            </Typography>
          </AccordionDetails>
        </Accordion>*/
}
