import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { flushSync } from "react-dom";
import { useParams } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { AnalysisContext } from "../Context/analysisContext";
import { UiContext } from "../Context/uiContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import ImageViewModal from "../Modals/imageViewModal";
import ZoomImageModal from "../Components/ZoomImageModal";
import Slidish from "slidish";
import "slidish/style.css";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
  Button,
  useMediaQuery,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchConfigModal from "../Modals/searchConfigModal";
import ImageComparisonModal from "../Modals/ImageComparisonModal";
import { MainLayout } from "../Layouts/MainLayout";
import TaskStatusModal from "../Modals/TaskStatusModal";
import HitsViewModal from "../Modals/HitsViewModal";
import FramesTable from "./Frames/FramesTable";
import FrameDataTable from "./Frames/FrameDataTable";
import FrameDataImagesTable from "./Frames/FrameDataImagesTable";
import NotificationModal from "../Modals/notificationModal";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
import FramesSlider from "../Modals/FramesSliders";

export default function AnalysisResultsPage() {
  const {
    handleSearchConfigModalOpen,
    searchConfigModalOpen,
    statusModalOpen,
    imageViewModalOpen,
    hitsViewModalOpen,
    notificationModalOpen,
    handleStatusModalOpen,
    imageComparisonModalOpen,
    handleImageComparisonModalOpen,
    handleImageComparisonModalClose,
    framesSliderOpen,
    setFramesSliderOpen,
    handleFramesSliderClose,
    handleFramesSliderOpen,
  } = useContext(UiContext);
  const { setSearchConfigs } = useContext(SearchConfigContext);
  const { userCtx } = useContext(UserContext);
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const {
    getAnalysisByID,
    analysisCtx,
    setAnalysisResults,
    setAnalysisCtx,
    getAnalysesByCase,
    currFrameData,
    currFrame,
    setCurrFrame,
    searchConfig,
    framesLoading,
    frameDataImages,
    setFrameDataImages,
    currframeDataImage,
    frameDataCount,
    frameDataImagesCount,
    framesDataLoading,
    framesDataImagesLoading,
    slidishRef,
    setFrameDataCount,
    setFrameDataImagesCount,
    updateAnalysisCtx,
    setCurrFrameData,
    setCurrFrameDataImage,
    setAnalyses,
    currImage,
    setCurrImage,
    frames,
  } = useContext(AnalysisContext);

  const [loading, setLoading] = useState(false);
  const { case_id, analysis_id } = useParams();
  const [framesExpanded, setFramesExpanded] = React.useState(true);
  const [isZoomed, setIsZoomed] = React.useState(false);
  const [zoomImageFilePath, setZoomImageFilePth] = React.useState();
  const [selectedRow, setSelectedRow] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)"); // Detect mobile devices

  const handleFramesExpandedChange = (panel) => (event, isExpanded) => {
    setFramesExpanded(!framesExpanded);
  };

  const fetchCaseData = async () => {
    return getCaseByID(case_id);
  };

  const fetchAnalysis = async () => {
    return getAnalysisByID(analysis_id);
  };

  const reloadData = async () => {
    flushSync(() => {
      updateAnalysisCtx({});
      setFrameDataCount(false);
      setFrameDataImagesCount(false);
      setFrameDataImages([]);
      setCurrFrame({});
    });
    await fetchAnalysis();
  };

  const fetchContextOnLoad = async () => {
    setLoading(true);
    if (!sarCase.sarCaseId) await fetchCaseData();
    await fetchAnalysis();
    setLoading(false);
  };

  React.useEffect(() => {
    setSearchConfigs(searchConfig);
    return () => {
      setSearchConfigs([]);
    };
  }, [searchConfig]);

  React.useEffect(() => {
    fetchContextOnLoad();
    return () => {
      flushSync(() => {
        updateAnalysisCtx({});
        setFrameDataCount(false);
        setFrameDataImagesCount(false);
        setFrameDataImages([]);
        setCurrFrame({});
        setCurrFrameData({});
        setCurrFrameDataImage({});
      });
      setAnalysisResults([]);
    };
  }, []);

  const onClickZoom = (currImagePath) => {
    setIsZoomed(!isZoomed);
    setZoomImageFilePth(currImagePath);
  };

  const handleInfoClick = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        flushSync(() => {
          setAnalyses([]);
        });
        await getAnalysisByID(analysis_id);
        showStatusModal();
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });
  };

  const showStatusModal = () => {
    handleStatusModalOpen();
  };

  const showImageComparisonModal = () => {
    handleImageComparisonModalOpen();
  };

  const handleCloseFramesSlider = (reload) => {
    if (reload) reloadData();
    handleFramesSliderClose();
  };

  return (
    <MainLayout title={`Analysis results ${analysisCtx.analysisName}`}>
      {statusModalOpen && (
        <TaskStatusModal
          todo_count={analysisCtx?.analysis_todo_count}
          done_count={analysisCtx?.analysis_done_count}
          percent={analysisCtx?.analysis_percent}
          clock_ms_used={analysisCtx?.clock_ms_used}
          estRemaining={analysisCtx?.estRemaining}
          attempted_count={analysisCtx?.analysis_attempted_count}
          errors={analysisCtx?.analysis_errors}
          reftime={analysisCtx?.analysisDate}
          starttime={analysisCtx?.analysis_start_time}
          endtime={analysisCtx?.analysis_end_time}
          id={analysisCtx?.analysisId}
          recordType="Analysis"
        />
      )}

      <ZoomImageModal
        zoomed={isZoomed}
        filePath={zoomImageFilePath}
        onClose={() => onClickZoom()}
      />

      {framesSliderOpen && (
        <FramesSlider handleCloseFramesSlider={handleCloseFramesSlider} />
      )}

      {searchConfigModalOpen && <SearchConfigModal readOnly={true} />}
      {imageComparisonModalOpen && frameDataImages.length > 0 && (
        <ImageComparisonModal frameDataImages={frameDataImages} />
      )}
      {hitsViewModalOpen && (
        <HitsViewModal activeFrameDataImage={currframeDataImage} />
      )}
      {imageViewModalOpen && (
        <ImageViewModal
          image={currImage?.filepath.replace(/\.(tiff|tif)$/i, ".jpg")}
        />
      )}

      {notificationModalOpen && (
        <NotificationModal
          activeRecord={{ ...currframeDataImage, ...currFrame }}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ marginBottom: isMobile ? "2vh" : "5vh" }}>
            {analysisCtx?.analysisName && (
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="h4">
                  Info Analysis #{analysis_id}
                </Typography>
                <Typography>
                  <strong>Name:</strong> {analysisCtx?.analysisName}
                </Typography>
                <Typography>
                  <strong>Description:</strong> {analysisCtx?.description}
                </Typography>
                <Typography>
                  <strong>Tags:</strong> {analysisCtx?.matchTag}
                </Typography>
              </Box>
            )}

            <Accordion
              expanded={framesExpanded}
              onChange={handleFramesExpandedChange()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ textDecoration: "underline" }}>
                  <strong> Frames </strong>
                </Typography>
                <Box sx={{ textAlign: "right", paddingRight: "5vh" }}>
                  <Tooltip title="Search config context">
                    <InfoIcon
                      onClick={(e) => {
                        e?.stopPropagation();
                        handleSearchConfigModalOpen();
                      }}
                    />
                  </Tooltip>
                </Box>
              </AccordionSummary>

              <AccordionDetails>
                {analysisCtx.analysisId && (
                  <FramesTable
                    reloadData={reloadData}
                    handleInfoClick={handleInfoClick}
                    loading={framesLoading}
                    editable={true}
                    options={{
                      toolbar: true,
                      sorting: false,
                      draggable: false,
                      grouping: false,
                      search: false,
                      paging: true,
                      selection: false,
                      maxBodyHeight: isMobile ? "300px" : "500px",
                      pageSize: 10,
                      pageSizeOptions: [10, 25, 50, 100],
                      actionsCellStyle: { zIndex: "0", position: "relative" },
                      headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                    }}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box sx={{ marginBottom: isMobile ? "2vh" : "5vh" }}>
            {frameDataCount > 0 && (
              <FrameDataTable
                title="Frame Data"
                loading={framesDataLoading}
                editable={true}
                options={{
                  toolbar: true,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: true,
                  paging: true,
                  selection: false,
                  maxBodyHeight: isMobile ? "300px" : "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid>
        {frameDataImages?.length > 0 && (
          <Paper
            elevation={18}
            sx={{
              height: isMobile ? "400px" : "660px",
            }}
          >
            <Slidish
              options={{
                height: isMobile ? "380px" : "640px",
              }}
              layout={{
                slides: true,
                thumbs: true,
                playButton: false,
                indexIndicator: false,
              }}
              slideshow={{ autoplay: false }}
              handlers={{
                onSlideChange: (prevIndex, currIndex) =>
                  setSelectedRow(frameDataImages[currIndex]["frameDataImageId"]),
              }}
              ref={slidishRef}
            >
              {frameDataImages
                .map((frameData, i) => (
                  <img
                    key={i}
                    src={frameData["filepath"]}
                    alt={frameData["description"]}
                  />
                ))
                .filter((el) => el !== undefined)}
            </Slidish>
          </Paper>
        )}

        <Box sx={{ marginBottom: isMobile ? "2vh" : "5vh", marginTop: "5vh" }}>
          {frameDataImagesCount > 0 && (
            <>
              <Button
                onClick={(e) => {
                  e?.stopPropagation();
                  showImageComparisonModal();
                }}
              >
                Compare Images
              </Button>
              <FrameDataImagesTable
                loading={framesDataImagesLoading}
                title="Frame Data Images Info"
                editable={true}
                options={{
                  toolbar: true,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: true,
                  paging: true,
                  selection: false,
                  maxBodyHeight: isMobile ? "300px" : "700px",
                  pageSize: 25,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                  tableLayout: "auto",
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow === rowData.frameDataImageId
                        ? "#EEE"
                        : "#FFF",
                  }),
                }}
              />
            </>
          )}
        </Box>
      </Grid>

      <Box
        sx={{
          width: "100%",
          padding: isMobile ? "1rem" : "2rem",
          marginTop: isMobile ? "1rem" : "2rem",
        }}
      >
        <FAQ faqs={FAQs.analysis_results_FAQ} />
      </Box>
    </MainLayout>
  );
}