import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import FootageTable from "../Footage/FootageTable";
import ColorPaletteTable from "../ColorPalette/ColorPaletteTable";

import Paper from "@mui/material/Paper";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { AnalysisContext } from "../Context/analysisContext";


// const testFootage = [
//   {
//     id: 1,
//     flight: "flight1",
//     access: "All",
//     footageType: "Color",
//     dateCreated: new Date(),
//   },
//   {
//     id: 2,
//     flight: "flight2",
//     access: "Bill",
//     footageType: "Thermal",
//     dateCreated: new Date(),
//   },
//   {
//     id: 3,
//     flight: "LONG FLIGHT NAME TEST",
//     access: "All",
//     footageType: "Color",
//     dateCreated: new Date(),
//   },
// ];

// const testCPs = [
//   {
//     id: 1,
//     name: "Dog Hair Palette",
//     access: "Case",
//     owner: "Bill",
//     dateCreated: new Date(),
//   },
//   {
//     id: 2,
//     name: "Bone",
//     access: "All",
//     owner: "Find911",
//     dateCreated: new Date(),
//   },
// ];

// const ACTION = "create-select-case";
// const textValues = {
//   analysisName: "",
// };

export default function CreateAnalysisPage() {
  const navigate = useNavigate();
  const { userCtx, fetchWorkerDetails } = useContext(UserContext);
  const { sarCase } = useContext(CaseContext);
  const { analysisCtx, createAnalysis } = useContext(AnalysisContext);
  const [formValues, setFormValues] = useState({});

  //form submit fields
  const analysisNameInput = useRef();
  const analysisDescriptionInput = useRef();
  const matchTagInput = useRef();

  const onSubmit = useCallback(
    () => async (event) => {
      event.preventDefault();
      alert( "in on submit - CAPage" );
      const data = {
        action: "create-analysis",
        // jsx: 1,
        gid: sarCase.sarCaseOrgId,
        wid: userCtx.workerId,
        cid: sarCase.sarCaseId,
        analysisName: analysisNameInput.current.value,
        analysisDescription: analysisDescriptionInput.current.value,
        matchTagInput: matchTagInput.current.value,
        isFullCase: false
      };
      await createAnalysis(data);
      navigate(`/Cases/${case_id}/Analysis`);
    },
    [sarCase.sarCaseOrgId, userCtx.workerId, sarCase.sarCaseId, userCtx, sarCase]
  );

  // const [footage, setFootage] = React.useState([]);
  // const [CPs, setCPs] = React.useState([]);

  React.useEffect(() => {
  // if (1 == 1) {
    //   fetchCases();
    // } else {
    //   // MANUALLY SET FOR NOW
    //   setCases(testCases);
    // }
  }, [userCtx.cognitoId, userCtx.workerId, userCtx, sarCase]);


  const [selectedFootage, setSelectedFootage] = React.useState([]);
  function handleFootageChange(footage) {
    console.log("In CreateAnalysisPage: ");
    setSelectedFootage(footage);
    console.log(footage);
    // console.log('Selected Footage IDs:', selectedFootage)
  }

  const [selectedCPs, setSelectedCPs] = React.useState([]);
  function handleCPChange(IDs) {
    // console.log('In CreateAnalysisPage: ')
    setSelectedCPs(IDs);
    // console.log(IDs)
    // console.log('Selected CP IDs:', selectedCPs)
  }

  return (
    <>
      <form
        id="enroll"
        onSubmit={onSubmit()}
        style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
      >
        <Paper elevation={16} sx={{ padding: 5 }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h2">Create Analysis</Typography>

        <Box>
          <FootageTable
            onChange={(ids) => handleFootageChange(ids)}
            footage={footage}
          ></FootageTable>
        </Box>
        <br></br>
        <br></br>
        <Box>
          <ColorPaletteTable
            onChange={(ids) => handleCPChange(ids)}
            rows={CPs}
          />
        </Box>
        <br></br>
        <br></br>

            <TextField
              id="analysisName"
              label="Enter Analysis Name"
              placeholder="Analysis Name"
              value={formValues.analysisName}
              onChange={handleInputChange}
            ></TextField>

            <TextField
              multiline={true}
              rows={4}
              id="analysisDescription"
              name="analysisDescription"
              label="Brief Description"
              placeholder="Brief Description"
              inputRef={analysisDescriptionInput}
            ></TextField>

            <TextField
              id="matchTag"
              name="matchTag"
              label="Tag on Match"
              placeholder="Tag on Match"
              inputRef={matchTagInput}
            ></TextField>

            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
          </Paper>
      </form>
    </>
  );
}
