import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";

// Generate Order Data
function createData(id, title, secondTitle, testing) {
  return { id, title, secondTitle, testing };
}

const rows = [
  createData(
    0,
    "Upload Image for Color Palette",
    "Upload Images",
    "Invoke N8N test"
  ),
  createData(
    1,
    "Select Image for Color Palette",
    "Upload Videos",
    "Get Models"
  ),
  createData(
    2,
    "Configure Color Palette for Analysis",
    "Perform Analysis",
    "Get RGB/Thermal Images"
  ),
  createData(3, "Create Analysis Cnfiguration "),
];

export default function ToDo() {
  return (
    <React.Fragment>
      <Title>In Development</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Create/Select Color Palette & Configuration</TableCell>
            <TableCell>Analyze</TableCell>
            <TableCell>Testing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.secondTitle}</TableCell>
              <TableCell>{row.testing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
