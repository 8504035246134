import React, { createContext, useState, useContext, useRef } from "react";
import api, { API_URL } from "../API";
import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
import { CaseContext } from "./caseContext";
import { toast } from "react-toastify";

// Create the initial context state
const initialContextState = {
  analysisId: undefined,
  sarCaseId: undefined,
  analysisName: "",
  analysisDescription: "",
  matchTag: "",
  analysisDate: "",
};

// Create the context
export const AnalysisContext = createContext(initialContextState);

// Create a context provider component
export const AnalysisProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const { sarCase } = useContext(CaseContext);
  const [analysisCtx, setAnalysisCtx] = useState(initialContextState);
  const [analyses, setAnalyses] = React.useState([{ initial: true }]);
  const [analysisResults, setAnalysisResults] = React.useState([]);
  const [hits, setHits] = React.useState([]);
  const [currImage, setCurrImage] = React.useState([]); // analysis images for frame
  const [origFrameDataImage, setOrigFrameDataImage] = React.useState([]);

  const updateAnalysisCtx = async (ctx) => {
    setAnalysisCtx(ctx);
  };

  const getAnalysisByID = async (analysisID, setContext = true) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-analyses-by-id",
          aid: analysisID,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setContext ? setAnalysisCtx(response.data[0]) : false;
          resolve(response.data[0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getFieldName = (fieldName) => {
    if (fieldName === "analysisId") return "id";
    if (fieldName === "analysisName") return "name";
    if (fieldName === "analysisDate") return "reftime";
    if (fieldName === "frameDataHitCount") return "total_hits";
    if (fieldName === "frameId") return "frame_id";
    if (fieldName === "frameDataId") return "frame_data_id";
    if (fieldName === "frameDataImageId") return "frame_data_image_id";
    if (fieldName === "number_of_hits") return "fdHits";
    if (fieldName === "rgb") return "adjustments";
    if (fieldName === "reftime") return "frame_data_id";
    else return fieldName;
  };

  const analysesByCaseQuery = (case_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getAnalysesByCase(
          case_id,
          query.page * query.pageSize,
          query.pageSize,
          query?.orderBy ? getFieldName(query?.orderBy?.field) : "id",
          query.orderDirection
        );

        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Analysis found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getAnalysesByCase = async (
    case_id,
    start = 0,
    qty = 0,
    orderByField = "id",
    orderDirection = "ASC"
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-analyses-by-case",
          cid: case_id,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          start,
          qty,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        console.log("getAnalysesByCase: ", response);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setAnalyses(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getAnalysesResults = async (analysis_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-analysis-results",
          aid: analysis_id,
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
        });
        const { data: response } = await api.post("", requestBody);

        if (response.status != "success") {
          reject(new Error(response.reason[0]));
          // alert(
          //   "analysis results Failure: " +
          //     sarCase.caseId +
          //     "for analysis: " +
          //     analysis_id
          // );
        } else {
          setAnalysisResults(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const createAnalysis = async (
    orgId,
    caseId,
    flightId,
    scidList,
    name,
    description,
    matchTag,
    fridList = [],
    vidList = [],
    filters = [],
    isFullCase = false
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-analysis",
          // js: 2,
          gid: orgId,
          cid: caseId,
          fid: flightId,
          wid: user.cognitoId,
          scidList,
          fridList,
          vidList,
          name,
          description,
          matchTag,
          filters,
          isFullCase,
        });
        // const requestBody = JSON.stringify(data);
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          console.log("createAnalysis: ", JSON.stringify(response.data));
          analysisCtx.analysisId = response.data[0]["id"];
          resolve(response.data[0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const [runAnalysisPreview, setRunAnalysisPreview] = React.useState([]);

  const runAnalysis = async (
    orgId,
    caseId,
    flightId,
    analysisIdList,
    isPreview
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "run-analyses",
          gid: orgId,
          cid: caseId,
          fid: flightId,
          analysisIdList: analysisIdList,
          wid: user.cognitoId,
          isPreview,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          analysisCtx.analysisId = response.data["id"];
          setRunAnalysisPreview(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getFrameDataByFrameId = async (fidList) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-frame-data-by-frame-ids",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fidList,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          analysisCtx.analysisId = response.data["id"];
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteAnalysis = async (orgId, caseId, flightId, analysisIdList) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "delete-analyses",
          gid: orgId,
          cid: caseId,
          fid: flightId,
          analysisIdList: analysisIdList,
          wid: user.cognitoId,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          // analysisCtx.analysisId = response.data["id"];
          let tempAnalyses = [...analyses];

          tempAnalyses = tempAnalyses.filter(
            (el) => el.analysisId !== analysisIdList[0]
          );
          setAnalyses([...tempAnalyses]);
          resolve(response.data);
          // return response.data;
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  //get-hits-by-frame-data-image
  const getHitsByFrameDataImage = async (
    fdiid,
    qty,
    start,
    orderByField = undefined,
    orderDirection = undefined
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-hits-by-frame-data-image",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdiid,
          qty,
          start,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          console.log("getHitsByFrameDataImage: ", response.data);
          setHits([...hits, ...response.data]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  //get-hits-by-frame-data-image
  const getHitsCountByFrameDataImage = async (fdiid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-hitcount-by-frame-data-image",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdiid,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          console.log("getHitsByFrameDataImage: ", response.data);
          // setHits(response.data);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const flagFrame = async (frameId) => {
    const requestBody = JSON.stringify({
      action: "flag-frame",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
      // gid: orgId,
      // cid: caseId,
      // fid: flightId,
      // analysisIdList: analysisIdList,
      frid: frameId,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      // analysisCtx.analysisId = response.data["id"];
      return response.data;
    }
  };

  const flagFrameDataImage = async (frameDataImageId) => {
    const requestBody = JSON.stringify({
      action: "flag-frame-data-image",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
      // gid: orgId,
      // cid: caseId,
      // fid: flightId,
      // analysisIdList: analysisIdList,
      // frid: frameId,
      fdiid: frameDataImageId,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      // analysisCtx.analysisId = response.data["id"];
      return response.data;
    }
  };

  const archiveFrame = async (frameId) => {
    const requestBody = JSON.stringify({
      action: "archive-frame",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId,
      // gid: orgId,
      // cid: caseId,
      // fid: flightId,
      // analysisIdList: analysisIdList,
      frid: frameId,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      // analysisCtx.analysisId = response.data["id"];
      return response.data;
    }
  };

  // const [framesTotalCount, setFramesTotalCount] = React.useState();
  const [frames, setCurrFrames] = React.useState([]);
  const [currFrame, setCurrFrame] = React.useState(null);
  const [framesLoading, setFramesLoading] = useState(false);
  const [searchConfig, setSearchConfig] = React.useState();
  // get-analysis-frames

  // Material Table Columns Rows
  const framesQuery = (query) =>
    new Promise(async (resolve, reject) => {
      console.log("framesQuery query::: ", query);
      setFramesLoading(true);
      let result = [];
      try {
        result = await getAnalysisFrames(
          analysisCtx.analysisId,
          query.pageSize,
          query.page * query.pageSize,
          query?.orderBy ? getFieldName(query?.orderBy?.field) : undefined,
          query.orderDirection
        );
        setFramesLoading(false);
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"][0]["flights"][0]["frames"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No frames found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        setFramesLoading(false);
        resolve({
          data: result,
          page: query.page,
          totalCount: result.length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getAnalysisFrames = async (
    analysisID,
    qty,
    start,
    orderByField = undefined,
    orderDirection = undefined
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-analysis-frames",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          aid: analysisID,
          start,
          qty,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          if (response["data"][0]["records"].length > 0) {
            setSearchConfig(
              response["data"][0]["records"][0]["runConfig"][0]["cfgData"]
            );
            setCurrFrame(
              response["data"][0]["records"][0]["flights"][0]["frames"][0]
            );
            setCurrFrames(
              response["data"][0]["records"][0]["flights"][0]["frames"]
            );
            resolve(response.data);
          } else {
            resolve(response.data);
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const [frameData, setFrameData] = React.useState([]);
  const [currFrameData, setCurrFrameData] = React.useState();
  const [frameDataCount, setFrameDataCount] = React.useState(false);
  const [framesDataLoading, setFramesDataLoading] = React.useState(false);

  // Material Table Columns Rows
  const framesDataQuery = (query) =>
    new Promise(async (resolve, reject) => {
      setFramesDataLoading(true);
      let result = await getFrameDataByFrameID(
        analysisCtx.analysisId,
        currFrame.frameId,
        query.pageSize,
        query.page * query.pageSize,
        query?.orderBy ? getFieldName(query?.orderBy?.field) : undefined,
        query.orderDirection
      );
      setFramesDataLoading(false);
      // console.log("result[0][", result[0]["records"]);
      resolve({
        data: result[0]["records"],
        page: query.page,
        totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
      });
    });

  // get-frame-data-by-frame-id
  const getFrameDataByFrameID = async (
    analysisId,
    frameID,
    qty,
    start,
    orderByField = undefined,
    orderDirection = undefined
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-frame-data-by-frame-id",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          frid: frameID,
          aid: analysisId,
          start,
          qty,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setCurrFrameData(response["data"][0]["records"][0]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const [frameDataImages, setFrameDataImages] = React.useState([]); // analysis images for frame
  const [currframeDataImage, setCurrFrameDataImage] = React.useState([]); // analysis images for frame
  const [frameDataImagesCount, setFrameDataImagesCount] = React.useState(false);
  const [framesDataImagesLoading, setFramesDataImagesLoading] =
    React.useState(false);
  const slidishRef = useRef(null);

  // Material Table Columns Rows
  const framesDataImagesQuery = (query) =>
    new Promise(async (resolve, reject) => {
      setFramesDataImagesLoading(true);
      let result = await getFrameDataImages(
        currFrameData.frameDataId,
        query.pageSize,
        query.page * query.pageSize,
        query?.orderBy
          ? getFieldName(
              query?.orderBy?.field === "number_of_hits"
                ? "fdiHits"
                : query?.orderBy?.field
            )
          : undefined,
        query.orderDirection
      );
      setFrameDataImages(result[0]["records"]);
      setFramesDataImagesLoading(false);
      resolve({
        data: result[0]["records"],
        page: query.page,
        totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
      });
    });

  // get-frame-data-images
  const getFrameDataImages = async (
    frameDataID,
    qty,
    start,
    orderByField = undefined,
    orderDirection = undefined
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-frame-data-images",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdid: frameDataID,
          start,
          qty,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setCurrFrameDataImage(response["data"][0]["records"][0]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateAnalysis = async (analysisID, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-analysis",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          aid: analysisID,
          ...updatedData,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // update-analysis-frame
  const updateAnalysisFrame = async (fdid, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-analysis-frame",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdid,
          ...updatedData,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateFrame = async (fid, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-frame",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fid,
          ...updatedData,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateFrameData = async (fdid, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-frame-data",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdid,
          ...updatedData,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const updateFrameDataImage = async (fdiid, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-frame-data-image",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fdiid,
          ...updatedData,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // create-send-frame-data-image
  const createSendFrameDataImage = async (
    frameID,
    frameDataId,
    frameDataImageID,
    phoneNums,
    emails,
    usrMsg,
    refTime
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "create-send-frame-data-image",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fid: frameID,
          fdid: frameDataId,
          fdiid: frameDataImageID,
          phoneNums: phoneNums,
          emails: emails,
          usrMsg: usrMsg,
          refTime: refTime,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to update the caseID in the store
  // const updateAnalysisId = async (id) => {
  //   // Make your API call here to update the id
  //   // For demo purposes, we will simply update the state directly
  //   setAnalysisId(id);
  // };

  const [sliderCurrFrame, setSliderCurrFrame] = React.useState(null);

  // Create the context value
  const contextValue = {
    currFrame,
    setCurrFrame,
    searchConfig,
    analyses,
    analysisResults,
    analysesByCaseQuery,
    getAnalysesByCase,
    getAnalysesResults,
    analysisCtx,
    updateAnalysisCtx,
    getAnalysisByID,
    flagFrame,
    flagFrameDataImage,
    archiveFrame,
    createAnalysis,
    setAnalyses,
    setAnalysisResults,
    runAnalysis,
    deleteAnalysis,
    getFrameDataByFrameId,
    getHitsByFrameDataImage,
    hits,
    getHitsCountByFrameDataImage,
    getAnalysisFrames,
    getFrameDataByFrameID,
    getFrameDataImages,
    framesQuery,
    framesLoading,
    setFramesLoading,
    frames,
    frameData,
    setFrameData,
    currFrameData,
    setCurrFrameData,
    frameDataImages,
    setFrameDataImages,
    currframeDataImage,
    setCurrFrameDataImage,
    framesDataQuery,
    framesDataImagesQuery,
    setFrameDataCount,
    frameDataCount,
    frameDataImagesCount,
    setFrameDataImagesCount,
    framesDataImagesLoading,
    framesDataLoading,
    slidishRef,
    setAnalysisCtx,
    updateAnalysis,
    currImage,
    setCurrImage,
    origFrameDataImage,
    setOrigFrameDataImage,
    createSendFrameDataImage,
    updateFrame,
    updateFrameData,
    updateFrameDataImage,
    updateAnalysisFrame,
    runAnalysisPreview,
    sliderCurrFrame,
    setSliderCurrFrame,
  };

  return (
    <AnalysisContext.Provider value={contextValue}>
      {children}
    </AnalysisContext.Provider>
  );
};
