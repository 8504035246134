import React, { createContext, useState, useContext } from "react";
import { toast } from "react-toastify";
import { AppContext } from "./appContext";
import api, { API_URL } from "../API";
// Create the initial context state
const initialContextState = {
  cognitoId: undefined,
  cognitoWorkerName: "",
  cognitoWorkerOrgName: "",

  workerId: undefined, // Most important piece of info
  workerName: undefined,

  workerOrgId: undefined, // Useful to create a case
  workerOrgName: "TBD", // Possibly irrelevant and may skip
  contact: "",
};

// Create the context
export const UserContext = createContext(initialContextState);

// Create a context provider component
export const UserProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const [userCtx, setUserCtx] = useState(initialContextState);
  // Function to update the caseID in the store
  const fetchWorkerDetails = async () => {
    const requestBody = JSON.stringify({
      action: "get-worker-details",
      uid: user.cognitoId, // let uid (user id) be the cognito id
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      toast.error(response);
    } else {
      console.log("wid", response.data[0]);
      toast.info(
        `wid: ${response.data[0]["wid"]}, gid: ${response.data[0]["gid"]}, name: ${response.data[0]["name"]}`
      );
      setUserCtx({
        cognitoId: user.cognitoId,
        cognitoWorkerName: response.data[0]["name"],
        cognitoWorkerOrgName: response.data[0]["orgName"],

        workerId: response.data[0]["wid"],
        workerName: response.data[0]["name"],

        workerOrgId: response.data[0]["gid"],
        workerOrgName: response.data[0]["orgName"],

        contact: response.data[0]["contact"],
      });
    }
  };

  // update-worker-profile
  // uid : cognitoID
  const updateWorkerProfile = async (name, contact) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "update-worker-profile",
          uid: user.cognitoId,
          contact: contact,
          name: name,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          console.log("updateWorkerProfile", response.data[0]);
          fetchWorkerDetails();
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // send-worker-notification
  const sendWorkerNotification = async (
    cid,
    wid,
    log_level_code,
    subject,
    message,
    useText,
    useEmail
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "send-worker-notification",
          cid,
          wid,
          log_level_code,
          subject,
          message,
          useText,
          useEmail,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  // Create the context value
  const contextValue = {
    userCtx,
    fetchWorkerDetails,
    updateWorkerProfile,
    sendWorkerNotification,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
