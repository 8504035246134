import React, { useEffect, useState } from "react";
import CustomColorLite from "./CustomColorLite";
import ColorScale from "./ColorScale";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
// MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import ColorScaleHSL from "./ColorScaleHSL";
import ColorScaleRGB from "./ColorScale";

export default function ColorRange({
  onClose,
  isVisible,
  checkedElements,
  onClickCreateRange,
  onClickEditColor,
  HSL = false,
}) {
  const [rawColors, setRawColors] = useState(checkedElements);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [rangeBuffer, setRangeBuffer] = useState(10);
  const [rangeSat, setRangeSat] = useState(10);
  const [rangeL, setRangeL] = useState(10);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallHeight = useMediaQuery("(max-height:600px)"); // Check for small vertical screens

  const onClickCustomColor = () => {
    setIsEditingColor(!isEditingColor);
  };

  const removeColorFromPalette = (index) => {
    const updatedPaletteArray = [...rawColors];
    updatedPaletteArray.splice(index, 1);
    setRawColors(updatedPaletteArray);
  };

  const addColorToPalette = (value, hexValue) => {
    if (HSL) {
      setRawColors([
        ...rawColors,
        { hsl: value, hex: hexValue, isRange: true },
      ]);
      return;
    } else {
      const removeAlpha = value.replace(
        /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
        "rgb($1)"
      );
      setRawColors([
        ...rawColors,
        { rgb: removeAlpha, hex: hexValue, isRange: true },
      ]);
    }
  };

  const addNewColorAndClose = (colorRange) => {
    console.log("addNewColorAndClose:", colorRange, rangeBuffer);
    if (
      /^\d*$/.test(rangeBuffer) &&
      rangeBuffer >= 0 &&
      rangeBuffer <= 255 &&
      colorRange.length >= 1
    ) {
      onClickCreateRange(colorRange, rangeBuffer, minValue, maxValue);
      onClose();
    } else {
      toast.warning("Invalid input");
    }
  };

  const rangeBufferUpdate = (event, MAX) => {
    let value = Number(event.target.value);
    if (value >= 0 && value <= MAX) {
      setRangeBuffer(value);
      setError(false);
    } else {
      setRangeBuffer(value);
      setError(true);
    }
  };

  const rangeSatUpdate = (event) => {
    let value = Number(event.target.value);
    if (value >= 0 && value <= 100) {
      setRangeSat(value);
      setError(false);
    } else {
      setRangeSat(value);
      setError(true);
    }
  };

  const rangeLUpdate = (event) => {
    let value = Number(event.target.value);
    if (value >= 0 && value <= 100) {
      setRangeL(value);
      setError(false);
    } else {
      setRangeL(value);
      setError(true);
    }
  };

  if (isVisible) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: isMobile ? 1 : 2,
        }}
      >
        {isEditingColor ? (
          <CustomColorLite
            rawColors={rawColors}
            onClose={onClickCustomColor}
            customColorIsVisible={isEditingColor}
            addColorToPalette={addColorToPalette}
            HSL={HSL}
          />
        ) : null}
        <Box
          sx={{
            position: "relative",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            p: isMobile ? 1 : 2,
            zIndex: 1,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 2,
            width: isMobile ? "100%" : "auto",
            maxWidth: isMobile ? "100%" : "90vw",
            maxHeight: isSmallHeight ? "90vh" : "auto", // Adjust max height for small screens
            overflow: "auto",
          }}
        >
          <Paper
            elevation={16}
            sx={{
              height: isMobile ? "auto" : "70vh", // Adjust height for mobile and small screens
              width: isMobile ? "100%" : 500,
              overflow: "auto",
              p: isMobile ? 1 : 2,
            }}
          >
            <Typography variant="h6">Current Colors</Typography>
            <Box
              sx={{
                zIndex: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={onClickCustomColor}
              >
                Add Custom Color
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              {rawColors.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    placeholder="Enter range buffer"
                    label={HSL ? "Range Hue" : "Range Buffer"}
                    fullWidth
                    type="number"
                    onChange={(event) =>
                      rangeBufferUpdate(event, HSL ? 360 : 255)
                    }
                    error={error}
                    defaultValue={rangeBuffer}
                    inputProps={{
                      min: 0,
                      max: HSL ? 360 : 255,
                    }}
                  />

                  {HSL ? (
                    <>
                      <TextField
                        placeholder="Enter range buffer"
                        label="Range Saturation"
                        fullWidth
                        type="number"
                        onChange={rangeSatUpdate}
                        error={error}
                        defaultValue={rangeSat}
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                        sx={{ mt: 2 }}
                      />

                      <TextField
                        placeholder="Enter range buffer"
                        label="Range Lightness"
                        fullWidth
                        type="number"
                        onChange={rangeLUpdate}
                        error={error}
                        defaultValue={rangeL}
                        inputProps={{
                          min: 0,
                          max: 100,
                        }}
                        sx={{ mt: 2 }}
                      />
                    </>
                  ) : null}
                </Box>
              )}
              {rawColors.map((color, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    pb: 2,
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: HSL ? color.hsl : color.rgb,
                      border: "1px solid #000",
                      mr: 2,
                    }}
                  />
                  <Typography sx={{ flexGrow: 1 }}>
                    {HSL ? color.hsl : color.rgb}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <DeleteForeverIcon
                      onClick={() => removeColorFromPalette(index)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
          <Paper
            elevation={16}
            sx={{
              height: isMobile ? "auto" : "70vh", // Adjust height for mobile and small screens
              width: isMobile ? "100%" : 500,
              p: isMobile ? 1 : 2,
              overflow: "auto",
            }}
          >
            <Typography variant="h6">Current Palette</Typography>
            <Box sx={{ mt: 2 }}>
              {HSL ? (
                <ColorScaleHSL
                  addColorToPalette={addColorToPalette}
                  checkedElements={rawColors}
                  rangeBufferH={rangeBuffer}
                  rangeBufferS={rangeSat}
                  rangeBufferL={rangeL}
                  setMinValue={setMinValue}
                  minValue={minValue}
                  setMaxValue={setMaxValue}
                  maxValue={maxValue}
                />
              ) : (
                <ColorScaleRGB
                  addColorToPalette={addColorToPalette}
                  checkedElements={rawColors}
                  rangeBuffer={rangeBuffer}
                  setMinValue={setMinValue}
                  minValue={minValue}
                  setMaxValue={setMaxValue}
                  maxValue={maxValue}
                />
              )}
            </Box>
          </Paper>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              gap: 1,
              position: isMobile ? "fixed" : "static",
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: "center",
              backgroundColor: isMobile ? "background.paper" : "transparent",
              p: 1,
              zIndex: 2,
            }}
          >
            <CloseIcon
              color="info"
              fontSize={isMobile ? "medium" : "large"}
              onClick={onClose}
              sx={{ cursor: "pointer" }}
            />
            <CheckIcon
              color="info"
              fontSize={isMobile ? "medium" : "large"}
              onClick={() => addNewColorAndClose(rawColors)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}