import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        textAlign: "center",
        mt: 10,
      }}
    >
      <Typography variant="h2" color="error">
        404
      </Typography>
      <Typography variant="h5" sx={{ mt: 2 }}>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Button variant="contained" sx={{ mt: 3 }} onClick={() => navigate("/")}>
        Go Home
      </Button>
    </Box>
  );
}