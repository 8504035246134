import React, { useState, useContext, createRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { ImageContext } from "../Context/imageContext";
import { AppContext } from "../Context/appContext";
import { API_URL } from "../API";

import CustomColorModal from "../Components/CustomColorModal";
import CustomColorLite from "../Components/CustomColorLite";
import ColorRange from "../Components/ColorRange";
import ColorScale from "../Components/ColorScale";

// import { useAppContext } from "../lib/contextLib";
// import {
//   systemContext,
//   userContext,
//   caseContext,
//   analysisContext,
// } from "../index";
// const sysCtx = useContext(systemContext);

//MUI Components
import {
  Box,
  Grid,
  Button,
  Typography,
  Checkbox,
  Paper,
  TextField,
} from "@mui/material";
// import TextField from "@mui/material/TextField";
// import Select from "@mui/material/Select";
// import Carousel from "react-material-ui-carousel";
// import Radio from "@mui/material/Radio";

//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

//NPM Imports
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Image from "mui-image";
import { EyeDropper } from "react-eyedrop";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ColorPicker from "react-best-gradient-color-picker";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";
const ACTION = "create-select-case";
const defaultValues = {
  gid: "1",
  wid: "1",
  ip: "0.0.0.0",
  action: ACTION,
};

export default function MarkupsExamine() {
  const { authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [sarCase, getCaseByID] = useState(CaseContext);
  const { imageCtx } = useContext(ImageContext);
  const { case_id } = useParams();

  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef();
  const [caseList, setCaseList] = useState({ data: [] });
  const [image, setImage] = useState(testImage);
  const [selectedRgbColor, setSelectedRgbColor] = useState(null);
  const [selectedHexColor, setSelectedHexColor] = useState(null);
  const [paletteArray, setPaletteArray] = useState([]);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [showCustomColor, setShowCustomColor] = useState(false);
  const [color, setColor] = useState("rgba(255,255,255,1)");
  const [colorIndex, setColorIndex] = useState(null);
  const [currentIndexRgb, setCurrentIndexRgb] = useState(null);
  const [currentIndexHex, setCurrentIndexHex] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [colorRangeVisible, setColorRangeVisible] = useState(false);
  const [colorRange, setColorRange] = useState();

  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId) getCaseByID(case_id);
    }
  }, [sarCase.sarCaseId]);

  React.useEffect(() => {
    setImage(imageCtx.filepath);
  }, [imageCtx]);

  const convertToRGBFormat = (objects) => {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      if (obj.rgb && typeof obj.rgb === "string") {
        const rgbArray = obj.rgb.match(/\d+(\.\d+)?/g);
        if (rgbArray && rgbArray.length === 3) {
          const roundedArray = rgbArray.map(Number).map(Math.round);
          const rgbString = `rgb(${roundedArray.join(",")})`;
          obj.rgb = rgbString;
        }
      }
      return obj;
    });
  };

  const extractRGBValues = (rgbString) => {
    const rgbArray = rgbString.match(/\d+/g);
    if (rgbArray && rgbArray.length === 3) {
      return { rgb: rgbArray.map(Number) };
    }
    throw new Error("Invalid RGB string format");
  };

  const fetchKeyColors = async (paramData) => {
    console.log("in fetchKeyColors");
    console.log("image:", paramData);

    fetch(API_URL, {
      method: "POST",
      body: JSON.stringify({
        action: "upload-image-for-color-palette",
        image: paramData,
        topNumColors: 5,
        wid: userCtx.workerId,
        gid: sarCase.sarCaseOrgId,
        cid: sarCase.sarCaseId,
        iid: imageCtx.imageId,
      }),
      headers: {
        ...authHeaders,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((respData) => {
        console.log("status", respData.status);
        console.log("data", respData.data);
        setPaletteArray(convertToRGBFormat(respData.data));
      })
      .catch((error) => {
        console.log("catch error", error);
        if (error.message === "xyz") {
        }
      });
  };

  async function removePcnt(objects) {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      const newObj = { ...obj };
      delete newObj.pcnt;
      return newObj;
    });
  }

  const addElementToEnd = (array, newElement) => {
    if (newElement === ["rgb(255, 255, 255)", "rgb(0, 0, 0)"]) {
      newElement = [];
    }

    const newArray = [...array];
    newArray.push({ scale: newElement });
    return newArray;
  };

  // const sendKeyColors = async (paramData) => {
  //   const paletteColorsEdit = await removePcnt(paramData);
  //   const extractedObjects = paletteColorsEdit.map((input) =>
  //     extractRGBValues(input.rgb)
  //   );
  //   const palleteWithScale = addElementToEnd(extractedObjects, colorRange);

  //   fetch(API_URL, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       action: "create-color-palette-search-config",
  //       paletteName: paletteNameRef.current.value,
  //       // wid: userCtx.workerId,
  //       // gid: sarCase.sarCaseOrgId,
  //       cid: parseInt(case_id),
  //       // iid: imageCtx.imageId,
  //       rgbList: palleteWithScale,
  //     }),
  //     headers: {
  //       ...authHeaders,
  //     },
  //   }).catch((error) => {
  //     console.log("catch error", error);
  //     if (error.message === "xyz") {
  //     }
  //   });
  // };

  const getCropData = async () => {
    console.log("doing getCropData");
    if (typeof cropperRef.current?.cropper !== "undefined") {
      console.log("doing crop");
      const croppedCanvas =
        await cropperRef.current?.cropper.getCroppedCanvas();
      setCropData(croppedCanvas.toDataURL());
    }
  };

  function onChange({ rgb, hex }) {
    setSelectedRgbColor(rgb);
    setSelectedHexColor(hex);
  }

  const addColorToPalette = (rgbValue, hexValue) => {
    const removeAlpha = rgbValue.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    setPaletteArray([...paletteArray, { rgb: removeAlpha, hex: hexValue }]);
  };

  const removeColorFromPalette = (index) => {
    const updatedPaletteArray = [...paletteArray];
    updatedPaletteArray.splice(index, 1);
    setPaletteArray(updatedPaletteArray);
  };

  const editColorInPalette = (mapIndex, newRgb, newHex) => {
    const updatedPaletteArray = [...paletteArray];
    const removeAlpha = newRgb.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    updatedPaletteArray[mapIndex].rgb = removeAlpha;
    setPaletteArray(updatedPaletteArray);
  };

  const onClose = () => {
    setIsEditingColor(!isEditingColor);
  };

  const onClickEditColor = (index, rgb, hex) => {
    setColorIndex(index);
    setIsEditingColor(!isEditingColor);
    setCurrentIndexRgb(rgb);
    setCurrentIndexHex(hex);
  };

  const addCustomColor = () => {
    setShowCustomColor(!showCustomColor);
  };

  const clearPalette = () => {
    setPaletteArray([]);
  };

  const handleCheckboxChange = (event, index, color) => {
    if (event.target.checked) {
      // Add the index to checkedItems
      setCheckedItems([...checkedItems, color, index]);
      // setPaletteArray([...paletteArray, { rgb: rgbValue, hex: hexValue }]
    } else {
      // Remove the index from checkedItems
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const checkedElements = paletteArray.filter((color, index) =>
    checkedItems.includes(index)
  );

  const checkedRangeColors = checkedElements;

  const toggleColorRange = () => {
    setColorRangeVisible(!colorRangeVisible);
  };

  const onChangeSetColorRange = (value) => {
    setColorRange(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        paddingX: 10,
        paddingTop: 5,
      }}
    >
      {showCustomColor ? (
        <CustomColorLite
          value={color}
          paletteArray={paletteArray}
          onChange={setColor}
          onClose={addCustomColor}
          addColorToPalette={addColorToPalette}
        />
      ) : null}
      {/* {colorRangeVisible ? (
        <ColorRange
          isVisible={colorRangeVisible}
          onClose={toggleColorRange}
          checkedElements={checkedRangeColors}
          addColorToPalette={addColorToPalette}
        />
      ) : null} */}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box sx={{ width: 3 / 4, display: "flex", flexDirection: "column" }}>
            {/* <Carousel
                sx={{ height: "100%" }}
                autoPlay={false}
                duration="1"
                swipe={false}
                indicators={false}
                navButtonsAlwaysVisible={false}
                fullHeightHover={false}
                onChange={(now, prev) => updateCurrImgIndex(now)}
              > */}

            {/* </Carousel> */}
            <Box>
              {/* upload file code */}
              {/* <input type="file" onChange={onChange} /> */}
              <Cropper
                ref={cropperRef}
                style={{ height: 600, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={false}
              />
            </Box>

            <Box sx={{ display: "flex" }}>
              <Button
                onClick={getCropData}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginTop: 5,
                }}
              >
                Crop
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginTop: 5,
                  marginLeft: 5,
                }}
              >
                &lt; Prev
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginTop: 5,
                  marginLeft: 5,
                }}
              >
                Next &gt;
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginTop: 5,
                  marginLeft: 5,
                }}
                onClick={() =>
                  addHitForMarkup(selectedRgbColor, selectedHexColor)
                }
              >
                Mark this Hit
              </Button>

            </Box>
          </Box>
          <Paper
            elevation={16}
            sx={{
              padding: 2,
              marginY: 5,
              width: "20%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 400,
                height: 400,
                marginTop: 5,
                marginBottom: 5,
                outline: "2px solid black",
              }}
            >
              {/* <TransformWrapper
                defaultScale={1}
                defaultPositionX={100}
                defaultPositionY={200}
              >
                <TransformComponent> */}
              <Image src={cropData} alt="cropped" />
              {/* </TransformComponent>
              </TransformWrapper> */}
            </Box>
          </Paper>

          <Paper
            elevation={16}
            sx={{
              height: 800,
              width: 500,
              overflow: "auto",
              scrollbarWidth: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Box
              sx={{
                width: 400,
                height: 780,
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              Hit List
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={4} maxHeight={1000}>
            <Paper
              elevation={16}
            sx={{
              height: 800,
              width: 500,
              overflow: "auto",
              scrollbarWidth: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
                marginTop: 5,
              }}
            >
                Markup List
              {paletteArray.map((color, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    marginRight: 10,
                    marginBottom: 5,
                    borderBottom: "2px solid black",
                  }}
                >
                  <CustomColorModal
                    mapIndex={colorIndex}
                    isEditingColor={isEditingColor}
                    onClose={onClose}
                    onChangeColor={editColorInPalette}
                    currIndexRgb={currentIndexRgb}
                    currentIndexHex={currentIndexHex}
                  />
                  <Box sx={{ marginRight: 2 }}>
                    <Checkbox
                      onChange={(event) =>
                        handleCheckboxChange(event, index, color)
                      }
                      checked={checkedItems.includes(index)}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      outline: "2px solid black",
                      marginBottom: 5,
                      backgroundColor: `${color.rgb}`,
                    }}
                  ></Box>
                  <Box sx={{ marginLeft: 3 }}>
                    <Typography>{color.rgb}</Typography>
                    {/* <Typography sx={{ marginBottom: 5 }}>
                      {color.hex}
                    </Typography> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
