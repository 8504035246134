import React, { useRef, useState } from "react";
import CustomColorLite from "./CustomColorLite";
import ColorScale from "./ColorScale";

//MUI components
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Slider,
} from "@mui/material";

//MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";

export default function ColorRange({
  onClose,
  isVisible,
  checkedElements,
  onClickCreateRange,
  onClickEditColor,
}) {
  const [rawColors, setRawColors] = useState(checkedElements);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [rangeBuffer, setRangeBuffer] = useState(10);
  const [error, setError] = useState(false);

  const onClickCustomColor = () => {
    setIsEditingColor(!isEditingColor);
  };

  const removeColorFromPalette = (index) => {
    const updatedPaletteArray = [...rawColors];
    updatedPaletteArray.splice(index, 1);
    setRawColors(updatedPaletteArray);
  };

  const addColorToPalette = (rgbValue, hexValue) => {
    const removeAlpha = rgbValue.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    setRawColors([
      ...rawColors,
      { rgb: removeAlpha, hex: hexValue, isRange: true },
    ]);
  };

  const addNewColorAndClose = (colorRange) => {
    if (
      /^\d*$/.test(rangeBuffer) &&
      rangeBuffer >= 0 &&
      rangeBuffer <= 255 &&
      colorRange.length >= 1
    ) {
      onClickCreateRange(colorRange, rangeBuffer);
      onClose();
    } else {
      toast.warning("Invalid input");
    }
  };
  const rangeBufferUpdate = (event) => {
    let value = Number(event.target.value);
    if (value >= 0 && value <= 255) {
      setRangeBuffer(value);
      setError(false);
    } else {
      setRangeBuffer(value);
      setError(true);
    }
  };

  if (isVisible) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isEditingColor ? (
          <CustomColorLite
            rawColors={rawColors}
            onClose={onClickCustomColor}
            customColorIsVisible={isEditingColor}
            addColorToPalette={addColorToPalette}
          />
        ) : null}
        <Box
          sx={{
            position: "relative",
            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "0.25rem",
            paddingBottom: "0.5rem",
            paddingTop: "0.75rem",
            zIndex: 1,
            display: "flex",
          }}
        >
          <Box>
            <Paper
              elevation={16}
              sx={{
                height: 800,
                width: 500,
                marginRight: 5,
              }}
            >
              <Typography>Current Palette</Typography>

              <Box sx={{ margin: "auto" }}>
                {" "}
                <ColorScale
                  addColorToPalette={addColorToPalette}
                  checkedElements={rawColors}
                />
              </Box>
            </Paper>
          </Box>
          <Box>
            <Paper
              elevation={16}
              sx={{
                height: 800,
                width: 500,
                overflow: "auto",
                scrollbarWidth: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <Typography variant="h3">Current Colors</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 5,
                  marginTop: 5,
                }}
              >
                {rawColors.length > 0 && (
                  <div style={{ marginBottom: "5vh", marginRight: "8vh" }}>
                    <TextField
                      placeholder="Enter range buffer"
                      label="Range buffer"
                      // inputRef={rangeBufferRef}
                      fullWidth
                      type="number"
                      onChange={rangeBufferUpdate}
                      error={error}
                      defaultValue={rangeBuffer}
                      inputProps={{
                        type: "number",
                        pattern: "[0-9]*",
                        inputMode: "numeric",
                        min: 0,
                        max: 255,
                      }}
                      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                  </div>
                )}
                {rawColors.map((color, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      marginRight: 10,
                      marginBottom: 5,
                      borderBottom: "2px solid black",
                    }}
                  >
                    <Box sx={{ marginRight: 2 }}>
                      {/* <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, index, color)
                        }
                        checked={checkedItems.includes(index)}
                      /> */}
                    </Box>
                    {/* <Checkbox
                      onChange={(event) =>
                        handleCheckboxChange(event, index, color)
                      }
                      checked={checkedItems.includes(index)}
                    /> */}
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        outline: "2px solid black",
                        marginBottom: 5,
                        backgroundColor: `${color.rgb}`,
                      }}
                    ></Box>
                    <Box sx={{ marginLeft: 3 }}>
                      <Typography>{color.rgb}</Typography>
                      {/* <Typography sx={{ marginBottom: 5 }}>
                      {color.hex}
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginBottom: 2,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CreateIcon
                        onClick={() =>
                          onClickEditColor(index, color.rgb, color.hex)
                        }
                      />
                      <DeleteForeverIcon
                        sx={{ marginLeft: 3 }}
                        onClick={() =>
                          removeColorFromPalette(index, checkedElements)
                        }
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Paper>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
                onClick={() => onClickCustomColor()}
              >
                Add Custom Color
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CloseIcon
              color={"info"}
              fontSize={"large"}
              sx={{ marginLeft: 2 }}
              onClick={onClose}
            />
            <CheckIcon
              color={"info"}
              fontSize={"large"}
              sx={{ marginLeft: 2, marginTop: 3 }}
              onClick={() => addNewColorAndClose(rawColors)}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
}
