import React from "react";
import { Helmet } from "react-helmet";

import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { FootageContext } from "../Context/footageContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../table/table";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";

export default function FlightFootageViewPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);

  const { flightCtx, getFlightByID} =
    useContext(FlightContext);
  const {
    footageCtx,
    getFootageByID,
    radioMetricConverters,
  } = useContext(FootageContext);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(testImage);

  const { case_id, flight_id, footage_id } = useParams();


  const fetchData = async () => {
    try {
      if (case_id && flight_id && footage_id) {
        setLoading(true);
        await getCaseByID(case_id);
        await getFlightByID(flight_id);
        await getFootageByID(footage_id);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    setImage(footageCtx?.filepath );
  }, [footageCtx]);

  React.useEffect(() => {
    if (1 == 1) {
      setColumns([
        { title: "Frame Id", field: "frameId", editable: "never" },
        { title: "Flight ID", field: "flightId", editable: "never" },
        { title: "Case ID", field: "sar_case_id", editable: "never" },
        { title: "alt_agl", field: "alt_agl", editable: "onUpdate" },
        { title: "altitude", field: "altitude", editable: "onUpdate" },
        { title: "Path Name", field: "filepath", editable: "never" },
        { title: "heading", field: "heading", editable: "onUpdate" },
        {
          title: "img_view_type",
          field: "img_view_type",
          editable: "never",
          render: (rowData) =>
            rowData.img_view_type == 0
              ? `Color`
              : rowData.img_view_type == 1
              ? `Thermal RGB`
              : rowData.img_view_type == 2
              ? `Radiometric`
              : `Other {rowData.img_view_type}`,
        },
        { title: "latitude", field: "latitude", editable: "onUpdate" },
        { title: "longitude", field: "longitude", editable: "onUpdate" },
        { title: "Altitude", field: "alt_agl", editable: "never", width: 10 },
        {
          title: "match_tag_list",
          field: "match_tag_list",
          editable: "never",
          render: (rowData) =>
            rowData.match_tag_list !== null ? rowData.match_tag_list : "N/A",
        },
        { title: "pitch", field: "pitch", editable: "onUpdate" },
        {
          title: "Radiometric converter",
          field: "radiometric_converter_id",
          editable: "never",

          render: (rowData) => {
            const index = radioMetricConverters.findIndex(
              (el) => el.objId === rowData.radiometric_converter_id
            );
            return index !== -1
              ? radioMetricConverters[index]?.dispName
              : rowData.img_view_type === 2
              ? "No Conversion"
              : "N/A";
          },
        },
        {
          title: "reftime",
          field: "reftime",
          editable: "never",
          render: (rowData) =>
            (rowData.reftime && rowData.reftime.toLocaleString()) || "N/A",
        },
        { title: "roll", field: "roll", editable: "onUpdate" },
        { title: "video_id", field: "video_id", editable: "onUpdate" },
        {
          title: "video_timestamp",
          field: "video_timestamp",
          editable: "onUpdate",
        },
      ]);
      if (!sarCase.sarCaseId && !flightCtx.flightId && !footageCtx.footageId) {
        fetchData();
      } else {
        if(footageCtx)
          setData([footageCtx]);
        else 
          setData([])
      }
    } else {
      setData(testFootage);
    }
  }, [sarCase, footageCtx]);

  const onSummaryUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);

        resolve();
      }, 1000);
    });

  return (
    <>
      <Helmet>
        <title>{`Footage ${sarCase.sarCaseName}`}</title>
      </Helmet>
      <Box>
        <CssBaseline />


        <Box margin={5} maxHeight={750} width={"95vw"}>
        <br />
          <br />
          <Table
            data={data}
            columns={columns}
            onRowUpdate={onSummaryUpdate}
            loading={loading}
            editable={true}
          />
        </Box>
        <Box margin={5}></Box>
      </Box>

      <Box
        margin={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Cropper
          style={{ height: 800, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={false}
        />
      </Box>
    </>
  );
}
