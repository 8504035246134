import * as React from "react";
import { useContext, useState, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { FootageContext } from "../Context/footageContext";
import { FlightContext } from "../Context/flightContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "800px", // Limit maximum width for larger screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh", // Limit height to 90% of the viewport height
  overflowY: "auto", // Enable vertical scrolling for the entire modal
};

export default function TaskStatusModal(props) {
  const { statusModalOpen, handleStatusModalClose, resetExifStatus } =
    useContext(UiContext);
  const { resetFlightFootageExifStatus } = useContext(FootageContext);
  const { getFlightByID } = useContext(FlightContext);

  const { flight_id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onClose = async (event, reason) => {
    handleStatusModalClose();
    props.showStatus(false);
  };

  const getStatus = (
    done_count,
    todo_count,
    percentage,
    startTime,
    endTime,
    attempted_count
  ) => {
    try {
      const now = new Date();
      const startedAt = startTime ? new Date(startTime) : null;
  
      // Ignore the default endTime value "2020-01-01 00:00:00"
      console.log("endTime", endTime);
      const isValidEndTime = endTime && endTime !== "2020-01-01 00:00:00";
      console.log("isValidEndTime", isValidEndTime);
      const endedAt = isValidEndTime ? new Date(endTime) : null;
      console.log("endedAt", endedAt);

      const expectedMinutes = todo_count * 1; // 1 minute per task
      console.log("startedAt", startedAt);
      console.log("now", now);
      const elapsedMinutes = startedAt ? (now - startedAt) / (1000 * 60) : 0;
      console.log("elapsedMinutes", elapsedMinutes);
      
      // Invalid inputs
      if (
        done_count == null ||
        todo_count == null ||
        percentage == null ||
        isNaN(done_count) ||
        isNaN(todo_count) ||
        isNaN(percentage)
      ) {
        return "Failed or needs to be executed again";
      }
  
      // ✅ All tasks completed
      if (todo_count > 0 && done_count === todo_count) return "Completed";
  
      // ❌ Started, took too long
      if (
        startedAt &&
        !endedAt &&
        done_count < todo_count &&
        elapsedMinutes > expectedMinutes
      ) {
        return "Failed (Took too long)";
      }
  
      // ⚠️ Over-attempted (attempted > todo + 10% buffer)
      if (attempted_count && attempted_count > todo_count * 1.1) {
        return "Failed (Too many attempts)";
      }
  
      // ❌ Ended but nothing was done
      if (endedAt && done_count === 0) {
        return "Failed (No progress)";
      }
  
      // 🔄 Actively running
      if (done_count < todo_count && percentage > 0) {
        return "In Progress";
      }
  
      // ⏳ Queued and not started
      if (!startedAt && done_count === 0 && percentage === 0) {
        return "In Queue";
      }
  
      // ❌ Unexpected state
      if (done_count > todo_count && percentage > 100) {
        return "Failed or needs to be executed again";
      }
  
      return "Unknown";
    } catch (error) {
      toast.error(`Status error: ${error.message}`);
      return "Error";
    }
  };

  const handleClick = async () => {
    try {
      await resetFlightFootageExifStatus(flight_id);
      await getFlightByID(flight_id);
      handleStatusModalClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    return () => {
      handleStatusModalClose();
    };
  }, []);

  return (
    <Modal
      open={statusModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <Box sx={{ ...style }}>
        {resetExifStatus && (
          <Grid container spacing={4}>
            <Grid item xs={4} textAlign="left"></Grid>
            <Grid item xs={4} justifyContent="center"></Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                variant="contained"
                type="submit"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleClick();
                }}
                sx={{ marginLeft: "auto", marginRight: "2vh", width: "25vh" }}
              >
                Reset Exif status
              </Button>
            </Grid>
          </Grid>
        )}
        <h1>
          Info {props.recordType} # {props?.id}
        </h1>
        <p>Task todo count: {props?.todo_count}</p>
        {props?.attempted_count >= 0 ? (
          <p>Attempted count: {props?.attempted_count}</p>
        ) : (
          ""
        )}
        <p>Task done count: {props?.done_count}</p>
        <p>Percentage: {Number(props?.percent).toFixed(2)}%</p>
        {props?.clock_ms_used && (
          <p>
            {`Time spent: ${(Number(props?.clock_ms_used) / 1000).toFixed(
              2
            )} Seconds`}
          </p>
        )}
        {props?.reftime && <p>Queuing time: {props?.reftime}</p>}
        {props?.starttime && <p>Start time: {props?.starttime}</p>}
        {props?.endtime && <p>End time: {props?.endtime}</p>}
        {props?.estRemaining &&
          props.estRemaining !== 0 &&
          getStatus(
            props?.done_count,
            props?.todo_count,
            props?.percent,
            props?.starttime,
            props?.endtime,
            props?.attempted_count
          ) !== "Completed" && (
            <p>
              {`Estimated time remaining: ${(
                Number(props?.estRemaining) / 1000
              ).toFixed(2)}  Seconds`}
            </p>
          )}
        <p>
          Status:
          {getStatus(
            props?.done_count,
            props?.todo_count,
            props?.percent,
            props?.starttime,
            props?.endtime,
            props?.attempted_count
          )}
        </p>

        {props?.errors ? (
          <>
            <hr />
            <h3>Error(s):</h3>
            <Box
              sx={{
                maxHeight: "200px", // Set a fixed height for the error section
                overflowY: "auto", // Enable vertical scrolling
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: props?.errors }}></div>
            </Box>
          </>
        ) : (
          ""
        )}
      </Box>
    </Modal>
  );
}
