import * as React from "react";
import { useContext, useState, useRef } from "react";
import { Box, Button, Grid, Modal } from "@mui/material";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "48%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { FootageContext } from "../Context/footageContext";
import { FlightContext } from "../Context/flightContext";

export default function TaskStatusModal(props) {
  const { statusModalOpen, handleStatusModalClose, resetExifStatus } =
    useContext(UiContext);
  const { resetFlightFootageExifStatus } = useContext(FootageContext);
  const { getFlightByID } = useContext(FlightContext);

  const { flight_id } = useParams();

  const onClose = async (event, reason) => {
    handleStatusModalClose();
  };

  const getStatus = (done_count, todo_count, percentage) => {
    try {
      if (todo_count > 0 && done_count === todo_count) {
        return "Completed";
      }

      if (done_count < todo_count && percentage > 0) {
        return "In Progress";
      }

      if ((done_count && todo_count && percentage) === 0) {
        return "In Queue";
      }

      if ((done_count && todo_count && percentage) === null) {
        return "Failed or needs to be executed again";
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleClick = async () => {
    try {
      await resetFlightFootageExifStatus(flight_id);
      await getFlightByID(flight_id);
      handleStatusModalClose();
    } catch (error) {
      toast.error(error.message);
    }
  };
  //   Hook that uploads files when files are set
  React.useEffect(() => {
    return () => {
      handleStatusModalClose();
    };
  }, []);

  return (
    <Modal
      open={statusModalOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "scroll" }}
    >
      <div>
        <Box sx={{ ...style }}>
          {resetExifStatus && (
            <Grid container spacing={4}>
              <Grid item xs={4} textAlign="left"></Grid>
              <Grid item xs={4} justifyContent="center"></Grid>
              <Grid item xs={4} textAlign="right">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleClick();
                  }}
                  sx={{ marginLeft: "auto", marginRight: "2vh", width: "25vh" }}
                >
                  Reset Exif status
                </Button>
              </Grid>
            </Grid>
          )}
          <h1>Info</h1>
          <p>Task todo count: {props?.todo_count}</p>
          {props?.analysis_attempted_count >= 0 ? (
            <p>Attempted count: {props?.analysis_attempted_count}</p>
          ) : (
            ""
          )}
          <p>Task done count: {props?.done_count}</p>
          <p>Percentage: {Number(props?.percent).toFixed(2)}%</p>
          {props?.clock_ms_used && (
            <p>
              {`Time spent: ${(Number(props?.clock_ms_used) / 1000).toFixed(
                2
              )} Seconds`}
            </p>
          )}
          {props.estRemaining !== 0 &&
            getStatus(props?.done_count, props?.todo_count, props?.percent) !==
              "Completed" && (
              <p>
                {`Estimated time remaining: ${(
                  Number(props?.estRemaining) / 1000
                ).toFixed(2)}  Seconds`}
              </p>
            )}
          <p>
            Status:
            {getStatus(props?.done_count, props?.todo_count, props?.percent)}
          </p>

          {props?.analysis_errors ? (
            <>
              <hr />
              <h3>Error(s):</h3>
              <div
                dangerouslySetInnerHTML={{ __html: props?.analysis_errors }}
              ></div>
            </>
          ) : (
            ""
          )}
        </Box>
      </div>
    </Modal>
  );
}
