import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState, useContext, createRef, useRef } from "react";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { ImageContext } from "../Context/imageContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { toast } from "react-toastify";
import { API_URL } from "../API";
import { useParams } from "react-router-dom";

//MUI Components
import {
  Box,
  Grid,
  IconButton,
  Button,
  ListItemText,
  ListSubheader,
  Typography,
  Checkbox,
  Container,
  Paper,
  Icon,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// import Select from "@mui/material/Select";
// import Carousel from "react-material-ui-carousel";
// import Radio from "@mui/material/Radio";

//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

//NPM Imports
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Image from "mui-image";
import { EyeDropper } from "react-eyedrop";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ColorPicker from "react-best-gradient-color-picker";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
// const testImage =
//   "https://dynaimage.cdn.cnn.com/cnn/c_fill,g_auto,w_1200,h_675,ar_16:9/https%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F220818142713-dogs-tears-emotions-wellness-stock.jpg";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";
const ACTION = "create-select-case";
const defaultValues = {
  gid: "1",
  wid: "1",
  ip: "0.0.0.0",
  action: ACTION,
};

export default function DetectObjectsPalette() {
  const isMobile = useMediaQuery("(max-width:900px)"); // Detect mobile devices

  const { userCtx } = useContext(UserContext);
  const [sarCase, getCaseByID] = useState(CaseContext);
  const { case_id, image_id } = useParams();

  const { createFaceRecognitionSearchConfig } = useContext(SearchConfigContext);
  const { imageCtx, getImageData, findFacesInImage } = useContext(ImageContext);
  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef();

  const [formValues, setFormValues] = useState(defaultValues);

  const [faceArray, setFaceArray] = useState([]);

  const [image, setImage] = useState(testImage);
  const [cwidth, setCwidth] = useState(0);
  const [cheight, setCheight] = useState(0);
  const [checkedItems, setCheckedItems] = useState([]);
  const searchNameInput = useRef();
  const searchDescriptionInput = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const fetchFaces = async (paramData, width, height) => {
    console.log("in fetchFaces");
    console.log("image:", paramData);
    console.log("image userCtx:", userCtx);
    try {
      setIsLoading(true);
      const response = await findFacesInImage({
        imageBytesBase64: paramData,
        width: width,
        height: height,
        gid: userCtx.workerOrgId,
        cid: case_id,
      });
      setIsLoading(false);
      if (response?.length === 0) {
        toast.warn("No faces found in your selection.");
      }
      setFaceArray([...faceArray, ...response]);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }

    // fetch(API_URL, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     action: "find-faces-in-image",
    //     // action: "find-faces-by-id",
    //     imageId: imageCtx.imageId,
    //     imageBytesBase64: paramData,
    //     width: width,
    //     height: height,
    //   }),
    //   headers: {
    //     ...authHeaders,
    //   },
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((respData) => {
    //     console.log("status", respData.status);
    //     console.log("data", respData.data);

    //     // setFaceArray(respData.data);
    //   })
    //   .catch((error) => {
    //     console.log("catch error", error);
    //     if (error.message === "xyz") {
    //     }
    //   });
  };

  const findFaces = async () => {
    console.log("doing getCropData");
    if (typeof cropperRef.current?.cropper !== "undefined") {
      console.log("doing crop");
      const croppedCanvas =
        await cropperRef.current?.cropper.getCroppedCanvas();
      const { width, height } = cropperRef.current?.cropper.getCroppedCanvas();
      console.log("did crop - doing fetch", width, height);
      fetchFaces(croppedCanvas.toDataURL(), width, height);
      console.log("did fetch");
    }
  };

  const fetchData = async () => {
    try {
      await getCaseByID(case_id);
      await getImageData(image_id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      console.log("imageCtx: ", imageCtx);
      if (!sarCase.sarCaseId && !imageCtx.imageId) {
        fetchData();
      }
    } else {
      setImage(imageCtx.filepath);
    }
  }, [sarCase.sarCaseId, imageCtx.imageId, sarCase]);

  const navigate = useNavigate();

  const extractFsiidsFromArray = async (objectsArray) => {
    const fsiidArray = [];

    for (let i = 0; i < objectsArray.length; i++) {
      const object = objectsArray[i];
      if (object.hasOwnProperty("fsiid")) {
        fsiidArray.push(object.fsiid);
      }
    }

    return fsiidArray;
  };

  const submitSelectedData = async () => {
    if (faceArray.length === 0) {
      toast.warning("No faces found to process");
      return;
    }
    if (!searchNameInput.current.value) {
      toast.warning("Name is required.");
      return;
    }
    if (checkedFaces.length > 0) {
      try {
        const finalFsiidArray = await extractFsiidsFromArray(checkedFaces);
        console.log("in submitSelectedData", finalFsiidArray);
        console.log("final gid", sarCase.sarCaseOrgId);
        console.log("final cid", sarCase.sarCaseId, case_id);
        const response = await createFaceRecognitionSearchConfig({
          gid: sarCase.sarCaseOrgId,
          cid: case_id,
          searchName: searchNameInput.current.value,
          fsiidList: finalFsiidArray,
          description: searchDescriptionInput.current.value,
        });
        console.log("createFaceRecognitionSearchConfig response:", response);
        // setOptions(response);
        navigate(`/cases/${case_id}/search-configs`);
      } catch (error) {
        toast.error(error.message);
      }

      // await fetch(API_URL, {
      //   method: "POST",
      //   headers: {
      //     ...authHeaders,
      //   },
      //   body: JSON.stringify({
      //     action: "create-face-recognition-search-config",
      //   }),
      // })
      //   .then((response) => {
      //     return response.json();
      //   })
      //   .then((respData) => {
      //     console.log("respData", respData);
      //     if (respData.status != "success") {
      //       alert("detect face submit Failure saving face list");
      //     } else {
      //       setOptions(respData.data);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //   });
    } else {
      toast.warning("Select atleast one face.");
    }
  };

  // const addColorToPalette = (rgbValue, hexValue) => {
  //   setFaceArray([...faceArray, { rgb: rgbValue, hex: hexValue }]);
  // };

  const removeColorFromPalette = (index) => {
    const updatedfaceArray = [...faceArray];
    updatedfaceArray.splice(index, 1);
    setFaceArray(updatedfaceArray);
  };

  // const editColorInPalette = (mapIndex, newRgb, newHex) => {
  //   const updatedfaceArray = [...faceArray];
  //   updatedfaceArray[mapIndex].rgb = newRgb;
  //   setFaceArray(updatedfaceArray);
  // };

  // const onClose = () => {
  //   setIsEditingColor(!isEditingColor);
  // };

  // const onClickEditColor = (index, rgb, hex) => {
  //   setColorIndex(index);
  //   setIsEditingColor(!isEditingColor);
  //   setCurrentIndexRgb(rgb);
  //   setCurrentIndexHex(hex);
  // };

  const handleCheckboxChange = (event, index, face) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, face, index]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const checkedFaces = faceArray.filter((face, index) =>
    checkedItems.includes(index)
  );

  const clearPalette = () => {
    setFaceArray([]);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        // height: "93vh",
        overflow: "auto",
        paddingX: isMobile ? 2 : 10, // Adjust padding for mobile
        paddingTop: isMobile ? 2 : 10, // Adjust padding for mobile
      }}
    >
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "82vh !Imporant",
            }}
          >
            <Box>
              <Cropper
                ref={cropperRef}
                style={{ height: 350, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={imageCtx.filepath}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={false}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={findFaces}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginTop: 5,
                }}
                disabled={isLoading}
              >
                Find Faces
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "100%",
              // height: isMobile ? "auto" : "82vh", // Adjust height for mobile
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isMobile ? "0px" : "1.5vw", // Adjust padding for mobile
              marginTop: isMobile ? "5rem" : "0rem", // Adjust padding for mobile
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                id="searchName"
                name="searchName"
                placeholder="Search Config Name"
                inputRef={searchNameInput}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                placeholder="Optional Description"
                inputRef={searchDescriptionInput}
                fullWidth
                multiline
                sx={{ marginTop: "1vw" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: isMobile ? "40vh" : "58vh", // Adjust height for mobile
                  marginTop: "1vw",
                  overflow: "auto",
                  scrollbarWidth: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                {faceArray.map((face, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      paddingLeft: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                      paddingRight: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                      marginTop: "2px",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <Box sx={{ marginRight: 2 }}>
                      <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, index, face)
                        }
                        checked={checkedItems.includes(index)}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxWidth: "50px",
                        maxHeight: "100px",
                        outline: "2px solid black",
                        marginBottom: 5,
                      }}
                    >
                      <Image
                        style={{
                          maxWidth: "50px",
                          maxHeight: "100px",
                        }}
                        // sx={{ display:'none'}}
                        src={face.filepath}
                      ></Image>
                    </Box>
                    <Box sx={{ marginLeft: 3 }}>
                      {/* <Typography>{color.rgb}</Typography> */}
                      {/* <Typography sx={{ marginBottom: 5 }}>
                      {color.hex}
                    </Typography> */}
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginBottom: 2,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {/*
                    <CreateIcon
                      onClick={() =>
                        onClickEditColor(index, color.rgb, color.hex)
                      }
                    /> */}
                      <DeleteForeverIcon
                        sx={{ marginLeft: 3 }}
                        onClick={() => removeColorFromPalette(index)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <Button
                onClick={submitSelectedData}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
              >
                Submit
              </Button>
              {/* <DeleteForeverIcon
                fontSize={"large"}
                sx={{}}
                onClick={() => clearPalette()}
              /> */}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          padding: "2rem", // Adjust padding for mobile
          marginTop: "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.detect_faces_palette_FAQ} />
      </Box>
    </Box>
  );
}
