import React from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { ImageContext } from "../Context/imageContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../table/table";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageViewModal from "../Modals/imageViewModal";
import { MainLayout } from "../Layouts/MainLayout";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
import { FootageContext } from "../Context/footageContext";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";

const testImages = [
  {
    caseId: 1,
    imageId: 1,
    frames: [],
  },
];

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";

export default function ReportAssetsView() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const {
    imageCtx,
    getImageData,
    getImagesByCase,
    images,
    setImages,
    updateImageData,
  } = useContext(ImageContext);

  const radiometricCoverters = useRadiometericConverters();
  const [image, setImage] = useState(testImage);

  const [columns, setColumns] = useState([
    { title: "Image Id", field: "imageId", editable: "never" },
    {
      title: "Name",
      field: "filepath",
      editable: "never",
      render: (rowData) =>
        rowData.filepath.substring(21 + rowData.filepath.lastIndexOf("/")),
    },
    {
      title: "Purpose",
      field: "img_purpose",
      editable: "onUpdate",
      render: (rowData) =>
        rowData.img_purpose == 0
          ? `Source`
          : rowData.img_purpose == 1
          ? `Evidence`
          : // : rowData.img_purpose == 2
            // ? `subimage`
            "",
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">purpose</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.img_purpose}
            label="purpose"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            <MenuItem value={0}>Source</MenuItem>
            <MenuItem value={1}>Evidence</MenuItem>
            {/* <MenuItem value={2}>subimage</MenuItem> */}
          </Select>
        </FormControl>
      ),
    },
    // {
    //   title: "View Type",
    //   field: "img_view_type",
    //   editable: "onUpdate",
    //   render: (rowData) =>
    //     rowData.img_view_type == 0
    //       ? `Color`
    //       : rowData.img_view_type == 1
    //       ? `Thermal RGB`
    //       : rowData.img_view_type == 2
    //       ? `Radiometric`
    //       : `Other ${rowData.img_view_type}`,

    //   editComponent: (tableData) => (
    //     <FormControl fullWidth>
    //       <InputLabel id="demo-simple-select-label">view</InputLabel>
    //       <Select
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={tableData.rowData.img_view_type}
    //         label="view"
    //         onChange={(e) => tableData.onChange(e.target.value)}
    //       >
    //         <MenuItem value={0}>Color</MenuItem>
    //         <MenuItem value={1}>Thermal RGB</MenuItem>
    //         <MenuItem value={2}>Radiometric</MenuItem>
    //         <MenuItem value={3}>{`Other`}</MenuItem>
    //       </Select>
    //     </FormControl>
    //   ),
    // },
    // {
    //   title: "Radiometric converter",
    //   field: "radiometric_converter_id",
    //   editable: "onUpdate",

    //   render: (rowData) => {
    //     const index = radiometricCoverters.findIndex(
    //       (el) => el.objId === rowData.radiometric_converter_id
    //     );
    //     return index !== -1
    //       ? radiometricCoverters[index]?.dispName
    //       : rowData.img_view_type === 2
    //       ? "No Conversion"
    //       : "N/A";
    //   },

    //   editComponent: (tableData) => (
    //     <FormControl fullWidth>
    //       <InputLabel id="demo-simple-select-label">
    //         Radiometric converter
    //       </InputLabel>
    //       <Select
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={tableData.rowData.radiometric_converter_id}
    //         label="radiometric_converter_id"
    //         onChange={(e) => tableData.onChange(e.target.value)}
    //       >
    //         {radiometricCoverters?.map((el) => {
    //           return (
    //             <MenuItem
    //               // control={<Radio />}
    //               value={el.objId}
    //               key={el.objId}
    //               disabled={
    //                 tableData.rowData.img_view_type === 2 ? false : true
    //               }
    //             >
    //               {el.dispName}
    //             </MenuItem>
    //           );
    //         })}
    //       </Select>
    //     </FormControl>
    //   ),
    // },
    // {
    //   title: "For Report",
    //   field: "use_in_report",
    //   editable: "onUpdate",
    //   render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
    //   editComponent: (tableData) => (
    //     <FormControl fullWidth>
    //       <InputLabel id="demo-simple-select-label">report</InputLabel>
    //       <Select
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={tableData.rowData.use_in_report}
    //         label="report"
    //         onChange={(e) => tableData.onChange(e.target.value)}
    //       >
    //         <MenuItem value={false}>No</MenuItem>
    //         <MenuItem value={true}>Yes</MenuItem>
    //       </Select>
    //     </FormControl>
    //   ),
    // },
    // {
    //   title: "Sequence",
    //   field: "img_sequence",
    //   editable: "onUpdate",
    //   render: (rowData) =>
    //     rowData.use_in_report ? String(rowData.img_sequence) : `-`,
    // },
    { title: "Date", field: "uploadDate", editable: "never" },
    {
      title: "Description",
      field: "description",
      editable: "onUpdate",
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.description}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
  ]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { case_id, asset_id } = useParams();

  React.useEffect(() => {
    setImage(imageCtx.display_filepath);
    setColumns([
      { title: "Image Id", field: "imageId", editable: "never" },
      {
        title: "Name",
        field: "filepath",
        editable: "never",
        render: (rowData) =>
          rowData.filepath.substring(21 + rowData.filepath.lastIndexOf("/")),
      },
      {
        title: "Purpose",
        field: "img_purpose",
        editable: "onUpdate",
        render: (rowData) =>
          rowData.img_purpose == 0
            ? `Source`
            : rowData.img_purpose == 1
            ? `Evidence`
            : // : rowData.img_purpose == 2
              // ? `subimage`
              "",
        editComponent: (tableData) => (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">purpose</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tableData.rowData.img_purpose}
              label="purpose"
              onChange={(e) => tableData.onChange(e.target.value)}
            >
              <MenuItem value={0}>Source</MenuItem>
              <MenuItem value={1}>Evidence</MenuItem>
              {/* <MenuItem value={2}>subimage</MenuItem> */}
            </Select>
          </FormControl>
        ),
      },
      // {
      //   title: "View Type",
      //   field: "img_view_type",
      //   editable: "onUpdate",
      //   render: (rowData) =>
      //     rowData.img_view_type == 0
      //       ? `Color`
      //       : rowData.img_view_type == 1
      //       ? `Thermal RGB`
      //       : rowData.img_view_type == 2
      //       ? `Radiometric`
      //       : `Other ${rowData.img_view_type}`,

      //   editComponent: (tableData) => (
      //     <FormControl fullWidth>
      //       <InputLabel id="demo-simple-select-label">view</InputLabel>
      //       <Select
      //         labelId="demo-simple-select-label"
      //         id="demo-simple-select"
      //         value={tableData.rowData.img_view_type}
      //         label="view"
      //         onChange={(e) => tableData.onChange(e.target.value)}
      //       >
      //         <MenuItem value={0}>Color</MenuItem>
      //         <MenuItem value={1}>Thermal RGB</MenuItem>
      //         <MenuItem value={2}>Radiometric</MenuItem>
      //         <MenuItem value={3}>{`Other`}</MenuItem>
      //       </Select>
      //     </FormControl>
      //   ),
      // },
      // {
      //   title: "Radiometric converter",
      //   field: "radiometric_converter_id",
      //   editable: "onUpdate",

      //   render: (rowData) => {
      //     const index = radiometricCoverters.findIndex(
      //       (el) => el.objId === rowData.radiometric_converter_id
      //     );
      //     return index !== -1
      //       ? radiometricCoverters[index]?.dispName
      //       : rowData.img_view_type === 2
      //       ? "No Conversion"
      //       : "N/A";
      //   },

      //   editComponent: (tableData) => (
      //     <FormControl fullWidth>
      //       <InputLabel id="demo-simple-select-label">
      //         Radiometric converter
      //       </InputLabel>
      //       <Select
      //         labelId="demo-simple-select-label"
      //         id="demo-simple-select"
      //         value={tableData.rowData.radiometric_converter_id}
      //         label="radiometric_converter_id"
      //         onChange={(e) => tableData.onChange(e.target.value)}
      //       >
      //         {radiometricCoverters?.map((el) => {
      //           return (
      //             <MenuItem
      //               // control={<Radio />}
      //               value={el.objId}
      //               key={el.objId}
      //               disabled={
      //                 tableData.rowData.img_view_type === 2 ? false : true
      //               }
      //             >
      //               {el.dispName}
      //             </MenuItem>
      //           );
      //         })}
      //       </Select>
      //     </FormControl>
      //   ),
      // },
      // {
      //   title: "For Report",
      //   field: "use_in_report",
      //   editable: "onUpdate",
      //   render: (rowData) => (rowData.use_in_report ? `Yes` : `No`),
      //   editComponent: (tableData) => (
      //     <FormControl fullWidth>
      //       <InputLabel id="demo-simple-select-label">report</InputLabel>
      //       <Select
      //         labelId="demo-simple-select-label"
      //         id="demo-simple-select"
      //         value={tableData.rowData.use_in_report}
      //         label="report"
      //         onChange={(e) => tableData.onChange(e.target.value)}
      //       >
      //         <MenuItem value={false}>No</MenuItem>
      //         <MenuItem value={true}>Yes</MenuItem>
      //       </Select>
      //     </FormControl>
      //   ),
      // },
      // {
      //   title: "Sequence",
      //   field: "img_sequence",
      //   editable: "onUpdate",
      //   render: (rowData) =>
      //     rowData.use_in_report ? String(rowData.img_sequence) : `-`,
      // },
      { title: "Date", field: "uploadDate", editable: "never" },
      {
        title: "Description",
        field: "description",
        editable: "onUpdate",
        editComponent: (tableData) => (
          <TextField
            value={tableData.rowData.description}
            onChange={(e) => tableData.onChange(e.target.value)}
            multiline={true}
            maxRows={5}
          />
        ),
      },
    ]);
  }, [imageCtx, radiometricCoverters]);

  const fetchData = async () => {
    setLoading(true);
    await getCaseByID(case_id);
    await getImageData(asset_id);
    setLoading(false);
    setData([imageCtx]);
  };
  React.useEffect(() => {
    if (1 == 1) {
      if (!sarCase.sarCaseId && !imageCtx.imageId) {
        fetchData();
      } else {
        setData([imageCtx]);
        // setImages(imageCtx.filepath);
      }
    } else {
      // setImage(testImage);
      setData([
        {
          imageId: 94,
          sarCaseId: 33,
          filepath:
            "https://find911-data.s3.amazonaws.com/Org_0/Case_33/Images/Source/2023_07_05-20_44_30-Screenshot 2023-05-31 at 4.02.09 AM.png",
          description: "",
          img_purpose: 0,
          img_view_type: 0,
          use_in_report: true,
          img_sequence: 0,
          uploadDate: "2023-07-05 20:44:30",
          tableData: {
            id: 0,
          },
        },
      ]);
    }
    return () => {
      setData([]);
    };
  }, [sarCase.sarCaseId, imageCtx.imageId]);

  const updateImagesContext = (imageId, newImageData) => {
    const imagesUpdate = [...images];
    const imagesIndex = images.findIndex((img) => img.imageId === imageId);
    const { tableData, ...newResponse } = newImageData;
    imagesUpdate[imagesIndex] = newResponse;
    setImages([...imagesUpdate]);
  };

  const onSummaryUpdate = (newData, oldData, tableData, setNewTableData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.imageId === oldData.imageId
        );

        dataUpdate[index] = newData;
        setNewTableData([...dataUpdate]);
        await updateImageData({
          action: "update-image",
          iid: oldData.imageId,
          use_in_report: newData.use_in_report,
          img_sequence: newData.img_sequence,
          description: newData.description,
        });

        updateImagesContext(oldData.imageId, newData);

        resolve();
      } catch (error) {
        toast.error(error.message);
        resolve();
      }

      // setTimeout(() => {

      //   resolve();
      // }, 1000);
    });
  };

  return (
    <MainLayout title={`Image ${sarCase.sarCaseName}`}>
      <Box marginBottom={5} maxHeight={750}>
        <Table
          data={data}
          columns={columns}
          onRowUpdate={onSummaryUpdate}
          loading={loading}
          editable={true}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Cropper
          style={{
            height: 500,
            width: "100%",
            imageRendering: "pixelated",
            imageRendering: "crisp-edges",
            msInterpolationMode: "nearest-neighbor",
          }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={false}
        />
      </Box>

      <Box
        sx={{
          width: "100%",
          padding: "1rem", // Adjust padding for mobile
          marginTop: "1rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.report_assets_view_FAQ} />
      </Box>
    </MainLayout>
  );
}
