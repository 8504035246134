import React, { createContext, useState, useContext } from "react";
import api, { API_URL } from "../API";
import { AppContext } from "./appContext";
import { UserContext } from "./userContext";
import { CaseContext } from "./caseContext";
import { FlightContext } from "./flightContext";
import { toast } from "react-toastify";

// Create the initial context state
const initialContextState = {
  videoId: undefined,
  flightId: undefined,
  sarCaseId: undefined,
  filepath: "",
  video_length: "",
  videoDescription: "",
  latitude_bottom: "",
  latitude_top: "",
  longitude_left: "",
  longitude_right: "",
  is_split: false,
  uploadDate: "",
};

const testVideos = [
  {
    videoId: 1,
    flightId: 1,
    sarCaseId: 1,
    filepath: "867-5309",
    video_length: "Lost Boys",
    videoDescription: "desc 1",
    latitude_bottom: 1.1,
    latitude_top: 2.2,
    longitude_left: 3.3,
    longitude_right: 4.4,
    is_split: false,
    uploadDate: new Date(),
  },
  {
    videoId: 2,
    flightId: 1,
    sarCaseId: 1,
    filepath: "867-5309",
    video_length: "Lost Boys",
    videoDescription: "desc 1",
    latitude_bottom: 1.1,
    latitude_top: 2.2,
    longitude_left: 3.3,
    longitude_right: 4.4,
    is_split: false,
    uploadDate: new Date(),
  },
];

// Create the context
export const VideoContext = createContext(initialContextState);

// Create a context provider component
export const FlightVideoProvider = ({ children }) => {
  const { user, authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const { caseCtx } = useContext(CaseContext);
  const { flightCtx } = useContext(FlightContext);
  const [videoCtx, setVideoCtx] = useState(initialContextState);
  const [videos, setVideos] = React.useState([]);

  const updateVideoCtx = async (ctx) => {
    setVideoCtx(ctx);
  };

  const getVideoByID = async (videoID, start = 0, qty = 0) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("in video context - getVideoByID");
        const requestBody = JSON.stringify({
          action: "get-videos-by-id",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          vid: videoID,
          start,
          qty,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setVideoCtx(response.data[0]["records"][0]);
          resolve(response.data[0]["records"][0]);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getFieldName = (fieldName) => {
    if (fieldName === "videoId") return "id";
    if (fieldName === "videoDescription") return "description";
    if (fieldName === "uploadDate") return "reftime";
    else
      return fieldName;
  };

  const videosByFlightQuery = (flight_id, query) =>
    new Promise(async (resolve, reject) => {
      let result = [];
      try {
        result = await getVideosByFlight(
          flight_id,
          query.page * query.pageSize,
          query.pageSize,
          query?.orderBy ? getFieldName(query?.orderBy?.field) : "id",
          query.orderDirection
        );
        if (result[0]["records"].length > 0) {
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["ofTotalCount"]["maxQty"], // needs to be updated
          });
        } else {
          toast.warn("No Flight Videos found.");
          resolve({
            data: result[0]["records"],
            page: query.page,
            totalCount: result[0]["records"].length, // needs to be updated
          });
        }
      } catch (error) {
        resolve({
          data: result[0]["records"],
          page: query.page,
          totalCount: result[0]["records"].length, // needs to be updated
        });
        toast.error(error.message);
      }
    });

  const getVideosByFlight = async (
    flight_id,
    start = 0,
    qty = 0,
    orderByField = "id",
    orderDirection = "ASC"
  ) => {
    console.log("in video context - getVideosByFlight");
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify({
          action: "get-videos-by-flight",
          wid: user.cognitoId,
          gid: userCtx.workerOrgId,
          fid: flight_id,
          start,
          qty,
          orderByField,
          orderDirection,
        });
        const { data: response } = await api.post("", requestBody);
        if (response.status != "success") {
          reject(new Error(response.reason[0]));
        } else {
          setVideos(response.data[0]["records"]);
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const splitFlightVideo = async (flight_id) => {
    console.log("in video context - splitFlightVideo");
    const requestBody = JSON.stringify({
      action: "split-flight-video",
      wid: user.cognitoId,
      gid: userCtx.workerOrgId || sarCase.sarCaseOrgId,
      cid: sarCase.sarCaseId,
      fid: flight_id,
    });
    const { data: response } = await api.post("", requestBody);
    if (response.status != "success") {
      alert(response.reason[0]);
    } else {
      setVideos(response.data);
    }
  };

  // Create the context value
  const contextValue = {
    videos,
    getVideosByFlight,
    videosByFlightQuery,
    videoCtx,
    updateVideoCtx,
    getVideoByID,
    setVideos,
    splitFlightVideo,
  };

  return (
    <VideoContext.Provider value={contextValue}>
      {children}
    </VideoContext.Provider>
  );
};
