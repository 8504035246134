import React, { useState, useEffect } from "react";
import { Box, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';


const headCells = ['Name', 'Access', 'Owner', 'Date']

function ColorPaletteTableRow(props ) {

    const {id, name, access, owner, dateCreated} = props;
    const [isSelected, setIsSelected] = useState(false);

    const handleClick = (event) => {
        setIsSelected(!isSelected);
        // console.log('In ColorPaletteTableRow')
        // console.log('\tFlight:', name, 'ID:', id)
        // console.log('\tSelected:', isSelected)
        props.onClick(id)
    }

    return (
        <TableRow hover selected={isSelected} onClick={() => handleClick()} key={name}>
            <TableCell>
                <Checkbox checked={isSelected}></Checkbox>
            </TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{access}</TableCell>
            <TableCell>{owner}</TableCell>
            <TableCell>{dateCreated.toLocaleString()}</TableCell>
        </TableRow>
    )
}


export default function FootageTable(props) {

    const rows = props.rows;

    const [selectedIDs, setSelectedIDs] = useState([]);

    const handleClick = (id) => {
        
        const selectedIndex = selectedIDs.indexOf(id);
        let newSelected = [];
        
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIDs, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedIDs.slice(1));
        } else if (selectedIndex === selectedIDs.length - 1) {
          newSelected = newSelected.concat(selectedIDs.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedIDs.slice(0, selectedIndex),
            selectedIDs.slice(selectedIndex + 1),
            );
        }
        
        setSelectedIDs(newSelected);
        // console.log('In ColorPaletteTable')
        // console.log('\tSelected Rows:', selectedIDs)
        // props.onChange(selectedIDs)
    }

    useEffect(() => {
        props.onChange(selectedIDs)
    }, [selectedIDs])   

    return <>
        <Typography variant="h4">Color Palette Configurations</Typography>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox></Checkbox>
                        </TableCell>
                        {headCells.map((headCell) => <TableCell key={headCell}>{headCell}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => 
                        <ColorPaletteTableRow 
                            onClick={(id) => handleClick(id)}
                            id={row.id}
                            name={row.name} 
                            access={row.access} 
                            owner={row.owner} 
                            dateCreated={row.dateCreated}
                            key={row.name}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}