import React, { useState, useEffect } from "react";
import Table from "../table/table";

export default function FootageTable(props) {
  const footage = props.footage;

  const [selectedIDs, setSelectedIDs] = useState([]);
  const [columns, setColumns] = useState([
    { title: "Flight", field: "flight", editable: "onUpdate" },
    { title: "Access", field: "access", editable: "onUpdate" },
    { title: "Type", field: "footageType", editable: "onUpdate" },
    
    {
      title: "Date",
      field: "dateCreated",
      editable: "onUpdate",
    },
    
  ]);


  return (
    <>
      <Table
        data={footage}
        columns={columns}
        // loading={loading}
        onRowUpdate={props?.editable ? onUpdate : null}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: false,
          selection: false,
          maxBodyHeight: "500px",
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
      />
    </>
  );
}
