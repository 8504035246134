import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import { systemContext, userContext, caseContext, analysisContext } from "../index";

import { useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Stack,
  Button,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import ImagesTable from "../Images/ImagesTable";



import Image from "mui-image";
import Carousel from "react-material-ui-carousel";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import FlagIcon from "@mui/icons-material/Flag";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

const exampleObj = {
  caseId: 1,
  srcImgs: [],
  srcSubImgs: [],
  srcEvidence: [],
  flights: [],
  criteria: [],
  analyses: [],
  hitImgs: []
};

export default function CaseSummaryPage() {
  const { authHeaders } = useAppContext();
  const sysCtx = useContext(systemContext);
  const usrCtx = useContext(userContext);
  const caseCtx = useContext(caseContext);

  const [sarCase, setSarCase] = React.useState([]);

  const fetchData = async () => {
    fetch(sysCtx.apiUrl, {
      method: "POST",
      headers: {
        ...authHeaders
      },
      body: JSON.stringify({
        action: "get-cases-by-id",
        cid: caseCtx.caseId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((respData) => {
        if  ( respData.status != "success" )
          alert( "case view Failure getting case: " + caseCtx.caseId + "::" + respData.status + " for: get-cases-by-id with issue: " + respData.data )
        else setSarCase(respData.data);
      })
      .catch(() => {
        alert("catch error");
      });
  };

  React.useEffect(() => {
    if (1 == 1) {
      fetchData();
    } else {
      setSarCase(exampleObj);
    }
  }, [usrCtx.cognitoId, usrCtx.workerId, usrCtx]);


  return (
    <>
      <Box margin={5} maxHeight={750}>
        <Typography variant="h3" align="left" sx={{ marginBottom: 5 }}>
          Case Summary - {caseCtx.caseName}

          <br/>
          <br/>

          <table>
            <tr><th>Case Id</th><td>{caseCtx.CaseId}<br/>
                {caseCtx.caseDate}<br/>{caseCtx.caseName}</td></tr>
            <tr><th>Lead</th><td>{caseCtx.leadName}<br/>
                {caseCtx.leadPhone}<br/>{caseCtx.leadEmail}</td></tr>
            <tr><th>Beneficiary</th><td>{caseCtx.beneficiaryName}<br/>
                {caseCtx.beneficiaryPhone}<br/>{caseCtx.beneficiaryEmail}</td></tr>
            <tr><th>Description</th><td>{caseCtx.caseDescription}</td></tr>
          </table>

          {/*}
          Source Images:<br/>
          <ImagesTable
            images={sarCase.srcImgs}
          ></ImagesTable>

          SubImages:<br/>
          <ImagesTable
            images={sarCase.srcSubImgs}
          ></ImagesTable>

          Evidence Images:<br/>
          <ImagesTable
            images={sarCase.srcEvidence}
          ></ImagesTable>
          */}

          <br/>
          <Link to="/Images/Select">Images</Link>
          <br/>
          <Link to="/Criteria/Select">Criteria</Link>

        </Typography>
      </Box>
    </>
  );
}

