import React, { useState, useEffect } from "react";
import { Box, Paper, useMediaQuery, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { HslColorPicker } from "react-colorful";
import ColorPicker from "react-best-gradient-color-picker";

const HslColorPickerWithInputs = ({ color, onChange }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
    >
      <HslColorPicker
        color={color}
        onChange={onChange}
        style={{ width: "100%", minHeight: "200px" }}
      />
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          label="Hue"
          type="number"
          value={color.h}
          onChange={(e) =>
            onChange({
              ...color,
              h: Math.min(360, Math.max(0, parseInt(e.target.value) || 0)),
            })
          }
          inputProps={{ min: 0, max: 360 }}
          size="small"
          fullWidth
        />
        <TextField
          label="Saturation %"
          type="number"
          value={color.s}
          onChange={(e) =>
            onChange({
              ...color,
              s: Math.min(100, Math.max(0, parseInt(e.target.value) || 0)),
            })
          }
          inputProps={{ min: 0, max: 100 }}
          size="small"
          fullWidth
        />
        <TextField
          label="Lightness %"
          type="number"
          value={color.l}
          onChange={(e) =>
            onChange({
              ...color,
              l: Math.min(100, Math.max(0, parseInt(e.target.value) || 0)),
            })
          }
          inputProps={{ min: 0, max: 100 }}
          size="small"
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default function CustomColorLite({
  onClose,
  addColorToPalette,
  HSL = false,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isSmallHeight = useMediaQuery("(max-height:600px)");

  // Parse HSL string to object { h, s, l }
  const parseHslString = (hslStr) => {
    if (!hslStr?.startsWith("hsl(")) return { h: 0, s: 0, l: 0 };
    const matches = hslStr.match(/hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/);
    return {
      h: parseInt(matches?.[1] || 0),
      s: parseInt(matches?.[2] || 0),
      l: parseInt(matches?.[3] || 0),
    };
  };

  // Convert HSL object to string
  const hslToString = (hsl) => `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;

  // State management
  const [hsl, setHsl] = useState(() =>
    parseHslString(HSL ? "hsl(1,20%,20%)" : "hsl(0,0%,100%)")
  );
  const [rgb, setRgb] = useState(
    HSL ? "hsl(1,20%,20%)" : "rgba(255,255,255,1)"
  );

  const addNewColorAndClose = () => {
    if (HSL) {
      addColorToPalette(hslToString(hsl), null); // Pass null for hex if not needed
    } else {
      const { valueToHex } = useColorPicker(rgb, () => {});
      addColorToPalette(rgb, valueToHex());
    }
    onClose();
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isMobile ? "10px" : "0",
      }}
    >
      <Box
        sx={{
          position: "relative",
          boxShadow: 3,
          borderRadius: "0.25rem",
          padding: isMobile ? "1rem" : "2rem",
          width: isMobile ? "90vw" : "40vw",
          maxHeight: isSmallHeight ? "90vh" : "auto",
          backgroundColor: "rgb(255,255,255, 0.9)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "auto",
        }}
      >
        <Paper
          elevation={16}
          sx={{
            padding: isMobile ? 1 : 2,
            width: "100%",
            height: isMobile ? "60vh" : "auto",
            maxHeight: isSmallHeight ? "70vh" : "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
          }}
        >
          {HSL ? (
            <HslColorPickerWithInputs color={hsl} onChange={setHsl} />
          ) : (
            <ColorPicker value={rgb} onChange={setRgb} disableDarkMode={true} />
          )}
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: isMobile ? "1rem" : "2rem",
            width: "100%",
            padding: isMobile ? "0 1rem" : "0",
          }}
        >
          <CloseIcon
            color="error"
            fontSize={isMobile ? "medium" : "large"}
            sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
            onClick={onClose}
          />
          <CheckIcon
            color="success"
            fontSize={isMobile ? "medium" : "large"}
            sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
            onClick={addNewColorAndClose}
          />
        </Box>
      </Box>
    </Box>
  );
}
