import React, { useState, useRef } from "react";

// MUI components
import { Box, Paper, Stack, Typography, TextField } from "@mui/material";

// MUI Icons
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { toast } from "react-toastify";

export default function CustomTempLite({ onClose, addTempToPalette }) {
  const t1Input = useRef();
  const t2Input = useRef();

  // State to track validation errors
  const [errors, setErrors] = useState({
    t1: false,
    t2: false,
  });
  const [helperTexts, setHelperTexts] = useState({
    t1: "",
    t2: "",
  });

  // Validate temperature input
  const validateTemperature = (value) => {
    return /^-?[0-9]*(\.[0-9]+)?$/.test(value); // Allows integers and floats (positive/negative)
  };

  // Handle form submission
  const addNewTemperatureAndClose = () => {
    let t1 = t1Input.current.value.trim();
    let t2 = t2Input.current.value.trim();

    let newErrors = { t1: false, t2: false };
    let helperText = { t1: "", t2: "" };

    // Validate T1
    if (!validateTemperature(t1)) {
      toast.warning(
        "Please enter a valid number for T1 (e.g., 25.00 or -3.14)"
      );
      newErrors.t1 = true;
      helperText.t1 = "Invalid format. Use numbers like 25.00 or -3.14";
    } else {
      t1 = parseFloat(t1).toFixed(2);
    }

    // Validate T2
    if (t2 !== "" && !validateTemperature(t2)) {
      toast.warning(
        "Please enter a valid number for T2 (e.g., 25.00 or -3.14)"
      );
      newErrors.t2 = true;
      helperText.t2 = "Invalid format. Use numbers like 25.00 or -3.14";
    } else if (t2 !== "") {
      t2 = parseFloat(t2).toFixed(2);
    }

    // Ensure T1 is lower than or equal to T2
    if (t2 !== "" && parseFloat(t1) > parseFloat(t2)) {
      newErrors.t1 = true;
      newErrors.t2 = true;
      helperText.t1 = "Lower bound (T1) must be ≤ Upper bound (T2)";
      helperText.t2 = "Upper bound (T2) must be ≥ Lower bound (T1)";
      toast.error("T1 must be less than or equal to T2.");
    }

    // If there are errors, update state and stop execution
    if (newErrors.t1 || newErrors.t2) {
      setErrors(newErrors);
      setHelperTexts(helperText);
      return;
    }

    // If valid, save temperatures and close modal
    addTempToPalette({ t1, t2: t2 !== "" ? t2 : null });
    onClose();
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          borderRadius: "0.25rem",
          paddingBottom: "0.5rem",
          paddingTop: "0.75rem",
          zIndex: 9999,
          display: "flex",
        }}
      >
        <Box>
          <Paper
            elevation={16}
            sx={{ padding: 5, bgcolor: "rgb(255,255,255, 0.7)" }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="h2">Temperatures</Typography>

              <TextField
                id="t1"
                name="Temperature °C"
                label="Temperature °C"
                placeholder=""
                inputRef={t1Input}
                error={errors.t1} // Show error state if validation fails
                helperText={helperTexts.t1}
                inputProps={{
                  inputMode: "numeric", // Opens numeric keyboard on mobile devices
                  pattern: "-?[0-9]*(.[0-9]+)?", // Allows integers and floats (positive/negative)
                  title: "Please enter a valid number (e.g., 25 or -3.14)", // Error message for invalid input
                }}
              />

              <TextField
                id="t2"
                name="Temperature °C"
                label="Temperature °C"
                placeholder=""
                inputRef={t2Input}
                error={errors.t2} // Show error state if validation fails
                helperText={helperTexts.t2}
                inputProps={{
                  inputMode: "numeric", // Opens numeric keyboard on mobile devices
                  pattern: "-?[0-9]*(.[0-9]+)?", // Allows integers and floats (positive/negative)
                  title: "Please enter a valid number (e.g., 25 or -3.14)", // Error message for invalid input
                }}
              />
            </Stack>

            <Box
              sx={{
                marginLeft: 3,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <CloseIcon
                color={"info"}
                fontSize={"large"}
                sx={{ marginTop: 5 }}
                onClick={() => onClose()}
              />
              <CheckIcon
                color={"info"}
                fontSize={"large"}
                sx={{ marginLeft: 3, marginTop: 5 }}
                onClick={addNewTemperatureAndClose}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
