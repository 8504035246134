import React, { useState, useContext, createRef, useRef } from "react";

import { API_URL } from "../API";
import { AppContext } from "../Context/appContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { useParams } from "react-router-dom";

//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
//MUI Components
import {
  Box,
  Grid,
  Button,
  Paper,
  TextField,
  Typography,
  Checkbox,
  Container,
  useMediaQuery,
} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";
import Table from "../table/table";
import FAQ from "../Components/FAQ";
import FAQs from "../Static/FAQs";
const options = [
  { label: "Grapes 🍇", value: "grapes", id: 1 },
  { label: "Mango 🥭", value: "mango", id: 2 },
  { label: "Strawberry 🍓", value: "strawberry", id: 3, disabled: true },
];

const replaceTestOptions = [
  { label: "Grapes 🍇", value: "grapes", id: 1 },
  { label: "Mango 🥭", value: "mango", id: 2 },
  { label: "Strawberry 🍓", value: "strawberry", id: 3 },
];

export default function DetectAnomalyPalette() {
  const { createDetectAnomalySearchConfig, getAnomalyDetectors } =
    useContext(SearchConfigContext);

  const { case_id } = useParams();

  const isMobile = useMediaQuery("(max-width:900px)"); // Detect mobile devices

  const [selected, setSelected] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [paramsData, setParamsData] = useState({});
  const [options, setOptions] = useState([]);
  const tableRef = useRef();

  const removeObjectFromList = (index) => {
    const updatedselected = [...selected];
    updatedselected.splice(index, 1);
    setSelected(updatedselected);
  };


  const addObjectToList = (item) => {
    if(item.length > 1){
      toast.warning("Select only one item.");
      return;
    }
    setSelected(item);
  };


  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...tableData];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setNewTableData([...dataUpdate]);
        setParamsData(newData);
        resolve();
      }, 1000);
    });

  const getTable = (data) => {
    let arr = [];
    Object.keys(data).map((el, i) => {
      arr.push({ title: el, field: el, editable: "onUpdate" });
    });
    return (
      <Table
        data={[isObjectEmpty(paramsData) ? data : paramsData]}
        columns={arr}
        onRowUpdate={onUpdate}
        editable={true}
        tableRef={tableRef}
      />
    );
  };
  const searchNameInput = useRef();
  const searchDescriptionInput = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAnomalyDetectors();
      setLoading(false);

      setOptions(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      fetchData();
    } else {
      setOptions(replaceTestOptions);
    }
  }, []);

  React.useEffect(() => {
    setParamsData({});
  }, [selected]);

  const navigate = useNavigate();
  const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };
  const submitSelectedData = async () => {
    // console.log(
    //   "tableRef.current.state.lastEditingRow",
    //   tableRef.current.state.lastEditingRow
    // );
    // console.log("selected:", selected[0]["parameters"] !== "");
    if (
      selected[0]["parameters"] !== "" &&
      tableRef.current.state.lastEditingRow
    ) {
      toast.warning("Save your changes...");
      return;
    }
    if (!searchNameInput.current.value) {
      toast.warning("Search config name is required");
      return;
    }
    if (selected.length > 0) {
      try {
        let objList = [...selected];
        if (!isObjectEmpty(paramsData)) {
          const { tableData, ...rest } = paramsData;
          objList[0]["params"] = rest;
        } else {
          objList[0]["params"] =
            objList[0]["parameters"] === ""
              ? ""
              : JSON.parse(objList[0]["parameters"]);
        }
        const response = await createDetectAnomalySearchConfig({
          cid: case_id,
          searchName: searchNameInput.current.value,
          description: searchDescriptionInput.current.value,
          objList,
        });
        // console.log("getAnomalyDetectors response", response);
        setOptions(response);
        navigate(`/cases/${case_id}/search-configs`);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.warning("Select atleast one item.");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        // height: "93vh",
        overflow: "auto",
        paddingX: isMobile ? 2 : 10, // Adjust padding for mobile
        paddingTop: isMobile ? 2 : 10, // Adjust padding for mobile
      }}
    >
      <Grid container spacing={isMobile ? 2 : 4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "82vh !Imporant",
            }}
          >
            <h1>Select Anomaly Detection Algorithm</h1>
            <MultiSelect
              options={options.map((el) => {
                return {
                  ...el,
                  label: el.detector_name,
                  value: el.detector_name,
                };
              })}
              hasSelectAll={false}
              value={selected}
              onChange={addObjectToList}
              labelledBy="Select"
              isLoading={isLoading}
              // disabled={selected.length > 0}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "100%",
              // height: isMobile ? "auto" : "82vh", // Adjust height for mobile
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isMobile ? "0px" : "1.5vw", // Adjust padding for mobile
              marginTop: isMobile ? "5rem" : "0rem", // Adjust padding for mobile
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                id="searchName"
                name="searchName"
                label="Search Config Name"
                placeholder="Search Config Name"
                inputRef={searchNameInput}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                label="Optional Description"
                placeholder="Optional Search Config Description"
                inputRef={searchDescriptionInput}
                sx={{ marginTop: "1vw" }}
                fullWidth
                multiline
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: isMobile ? "40vh" : "58vh", // Adjust height for mobile
                  marginTop: "1vw",
                  overflow: "auto",
                  scrollbarWidth: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <pre>
                  {selected.map((object, index) => (
                    <>
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          paddingLeft: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                          paddingRight: isMobile ? "1rem" : "2vw", // Adjust padding for mobile
                          marginTop: "2px",
                          borderBottom: "2px solid black",
                        }}
                      >
                         <Box sx={{ marginRight: 2 }}>
                          <Typography>{object.value}</Typography>
                        </Box>
                        <Box
                          sx={{
                            marginLeft: "auto",
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{ marginLeft: 3 }}
                            onClick={() => removeObjectFromList(index)}
                          />
                        </Box>
                      </Box>
                      {object.parameters && (
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          {getTable(JSON.parse(object.parameters))}
                        </Box>
                      )}
                    </>
                  ))}
                </pre>
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginRight: "2vw",
                marginBottom: "1vw",
              }}
            >
              <Button
                onClick={submitSelectedData}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  maxHeight: 55,
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          padding: "2rem", // Adjust padding for mobile
          marginTop: "2rem", // Adjust padding for mobile
        }}
      >
        <FAQ faqs={FAQs.anomaly_palette_FAQ} />
      </Box>
    </Box>
  );
}
