import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import { useContext } from "react";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Typography,
  Input,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { MainLayout } from "../Layouts/MainLayout";
import { toast } from "react-toastify";

export default function FlightCreatePage() {
  const navigate = useNavigate();
  const { userCtx, fetchWorkerDetails } = useContext(UserContext);
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { flightCtx, createFlight } = useContext(FlightContext);
  const [loading, setLoading] = useState(false);

  const { case_id } = useParams();

  const fetchCaseData = async () => {
    await getCaseByID(case_id);
  };

  //form submit fields
  const flightNameInput = useRef();
  const pilotNameInput = useRef();
  const flightDescriptionInput = useRef();

  const onSubmit = useCallback(
    () => async (event) => {
      event.preventDefault();
      if (flightNameInput.current.value === "") {
        toast.warn("Flight name can not be empty");
        return;
      }
      if (pilotNameInput.current.value === "") {
        toast.warn("Pilot name can not be empty");
        return;
      }
      setLoading(true);
     
      const data = {
        action: "create-flight",
        gid: sarCase.sarCaseOrgId,
        wid: userCtx.workerId,
        cid: sarCase.sarCaseId,
        flightName: flightNameInput.current.value,
        pilotName: pilotNameInput.current.value,
        flightDescription: flightDescriptionInput.current.value,
      };
      try {
        await createFlight(data);
        setLoading(false);
        navigate(`/Cases/${case_id}/Flights`);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
        navigate(`/Cases/${case_id}/Flights`);
      }
    },
    [userCtx, sarCase]
  );

  React.useEffect(() => {
    if (!sarCase.sarCaseId) {
      fetchCaseData();
    }
    // if (1 == 1) {
    //   fetchCases();
    // } else {
    //   // MANUALLY SET FOR NOW
    //   setCases(testCases);
    // }
  }, [userCtx.cognitoId, userCtx.workerId, userCtx, sarCase]);

  return (
    <MainLayout title={`Create Flight`}>
      <form
        id="enroll"
        onSubmit={onSubmit()}
        style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
      >
        <Paper elevation={16} sx={{ padding: 5 }}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h2">Create Flight</Typography>
            <TextField
              id="flightName"
              name="flightName"
              label="Flight Name"
              placeholder="Flight Name"
              inputRef={flightNameInput}
            ></TextField>

            <TextField
              id="pilotName"
              name="pilotName"
              label="Pilot Name"
              placeholder="Pilot Name"
              inputRef={pilotNameInput}
            ></TextField>

            <TextField
              multiline={true}
              rows={4}
              id="flightDescription"
              name="flightDescription"
              label="Brief Description"
              placeholder="Brief Description"
              inputRef={flightDescriptionInput}
            ></TextField>

            <Button disabled={loading} variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Paper>
      </form>
    </MainLayout>
  );
}
