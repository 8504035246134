import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { analysisContext } from "../index";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { CaseContext } from "../Context/caseContext";
import { AnalysisContext } from "../Context/analysisContext";
import Table from "../table/table";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import StartIcon from "@mui/icons-material/Start";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import TaskStatusModal from "../Modals/TaskStatusModal";
import ReplayIcon from "@mui/icons-material/Replay";
import { flushSync } from "react-dom";
import ConfirmationDialog from "../Modals/ConfirmationDialog";
import AnalysisPreview from "../Modals/analysisPreviewModal";

export default function AnalysisTable(props) {
  // const analyses = props.analyses;
  const { handleStatusModalOpen, statusModalOpen, handleAnalysisPreviewOpen } = useContext(UiContext);
  const { sarCase, getCaseByID } = useContext(CaseContext);

  const {
    analysisCtx,
    runAnalysis,
    deleteAnalysis,
    analysesByCaseQuery,
    getAnalysisByID,
    updateAnalysis,
    getAnalysesByCase,
    setAnalyses,
  } = useContext(AnalysisContext);

  const getStatus = (
     done_count,
     todo_count,
     percentage,
     startTime,
     endTime,
     attempted_count
   ) => {
     try {
       const now = new Date();
       const startedAt = startTime ? new Date(startTime) : null;
       const endedAt =
         endTime && !endTime.startsWith("2020") ? new Date(endTime) : null;
 
       const expectedMinutes = todo_count * 1; // 1 minute per task
       const elapsedMinutes = startedAt ? (now - startedAt) / (1000 * 60) : 0;
 
       // Invalid inputs
       if (
         done_count == null ||
         todo_count == null ||
         percentage == null ||
         isNaN(done_count) ||
         isNaN(todo_count) ||
         isNaN(percentage)
       ) {
         return "Failed or needs to be executed again";
       }
 
       // ✅ All tasks completed
       if (todo_count > 0 && done_count === todo_count) return "Completed";
 
       // ❌ Started, took too long
       if (
         startedAt &&
         !endedAt &&
         done_count < todo_count &&
         elapsedMinutes > expectedMinutes
       ) {
         return "Failed (Took too long)";
       }
 
       // ⚠️ Over-attempted (attempted > todo + 10% buffer)
       if (attempted_count && attempted_count > todo_count * 1.1) {
         return "Failed (Too many attempts)";
       }
 
       // ❌ Ended but nothing was done
       if (endedAt && done_count === 0) {
         return "Failed (No progress)";
       }
 
       // 🔄 Actively running
       if (done_count < todo_count && percentage > 0) {
         return "In Progress";
       }
 
       // ⏳ Queued and not started
       if (!startedAt && done_count === 0 && percentage === 0) {
         return "In Queue";
       }
 
       // ❌ Unexpected state
       if (done_count > todo_count && percentage > 100) {
         return "Failed or needs to be executed again";
       }
 
       return "Unknown";
     } catch (error) {
       toast.error(`Status error: ${error.message}`);
       return "Error";
     }
   };

  const [columns, setColumns] = useState([
    { title: "ID", field: "analysisId", editable: "never" },
    { title: "Name", field: "analysisName", editable: "onUpdate" },
    {
      title: "Analysis Status",
      field: "analysisId",
      editable: "never",
      // editable: "onUpdate",  "21" below accounts for a timestamp prefix on the name
      render: (rowData) => (
        <Tooltip title="Refresh to see the latest status">
          <>
            {getStatus(
              rowData?.analysis_done_count,
              rowData?.analysis_todo_count,
              rowData?.analysis_percent,
              rowData?.analysis_start_time,
              rowData?.analysis_end_time,
              rowData?.analysis_attempted_count,
            )}
          </>
        </Tooltip>
      ),
    },
    { title: "Match Tag", field: "matchTag", editable: "onUpdate" },
    {
      title: "Previous Match Tags",
      field: "previous_matchTag",
      editable: "never",
    },

    { title: "Description", field: "description", editable: "onUpdate" },
    {
      title: "Filters",
      field: "filters",
      editable: "never",
      // render: (rowData) => rowData?.filters.join(", "),
    },
    {
      title: "Frames Selected",
      field: "hasFramesSelected",
      editable: "never",
    },
    { title: "Is Full Case", field: "isFullCase", editable: "never" },
    { title: "Date", field: "analysisDate", editable: "never" },
    // { title: "Processed", field: "numProcessed", editable: "never" },
    // { title: "Flagged", field: "numFlagged", editable: "never" },
    // { title: "Archived", field: "numArchived", editable: "never" },
    // { title: "Date", field: "analysisDate", editable: "never" },
  ]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [executeLoading, setExecuteLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { case_id } = useParams();
  const navigate = useNavigate();

  const [showAnalysisStatus, setShowAnalysisStatus] = useState(false);

  const showStatusModal = (rowData) => {
    // console.log("rowData:", rowData.analysisId);
    // let record = analyses.filter((el) => el.analysisId === rowData.analysisId);
    // console.log("rowData:", rowData);
    setSelectedRecord(rowData);
    handleStatusModalOpen();
    setShowAnalysisStatus(true);
  };

  // const handleExecuteClick = async (rowData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       setExecuteLoading(true);
  //       // analysisCtx.analysisId = rowData.analysisId;
  //       // analysisCtx.analysisName = "To get from button - TBD";
  //       await runAnalysis(sarCase.sarCaseOrgId, sarCase.sarCaseId, -1, [
  //         rowData.analysisId,
  //       ]);
  //       setExecuteLoading(false);
  //       navigate(`/cases/${sarCase.sarCaseId}/analysis/${rowData.analysisId}`);
  //       toast.info(
  //         `Execution Request Sent for Analysis: ${rowData.analysisId}`
  //       );
  //       resolve(true);
  //     } catch (error) {
  //       setExecuteLoading(false);
  //       toast.error(error.message);
  //       reject(error);
  //     }
  //   });
  // };

  const handleExecuteClick = async (rowData) => {
    console.log("rowData?.analysis_todo_count", rowData?.analysis_todo_count);
    console.log("rowData?.analysis_done_count", rowData?.analysis_done_count);
    console.log("rowData?.analysis_errors", rowData?.analysis_errors);
    if (
      rowData?.analysis_todo_count > 0 &&
      rowData?.analysis_done_count < rowData?.analysis_todo_count
      // &&
      //   rowData?.analysis_errors === ""
    ) {
      // Show confirmation dialog
      setSelectedRowData(rowData);
      setConfirmationDialogOpen(true);
    } else {
      await executeAnalysis(rowData);
    }
  };

  const executeAnalysis = async (rowData) => {
    try {
      setExecuteLoading(true);
      await runAnalysis(sarCase.sarCaseOrgId, rowData.sarCaseId, -1, [
        rowData.analysisId,
      ], true);
      setExecuteLoading(false);
      handleAnalysisPreviewOpen();
      // navigate(`/cases/${rowData.sarCaseId}/analysis/${rowData.analysisId}`);
      // toast.info(`Execution Request Sent for Analysis: ${rowData.analysisId}`);
    } catch (error) {
      setExecuteLoading(false);
      toast.error(error.message);
    }
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    setSelectedRowData(null);
  };

  const handleConfirmationDialogConfirm = async () => {
    setConfirmationDialogOpen(false);
    if (selectedRowData) {
      await executeAnalysis(selectedRowData);
    }
  };

  const handleInfoClick = async (rowData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // await getAnalysesByCase(case_id);
        const record = await getAnalysisByID(rowData.analysisId, false);
        showStatusModal(record);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });
  };

  const handleViewClick = (rowData) => {
    analysisCtx.analysisId = rowData.analysisId;
    // analysisCtx.analysisName = "To get from button - TBD";
    navigate(`/cases/${rowData.sarCaseId}/analysis/${rowData.analysisId}`);
  };

  const handleDeleteClick = async (rowData) => {
    // console.log("rowData", rowData);
    // analysisCtx.analysisId = analysisId;
    // analysisCtx.analysisName = "To get from button - TBD";
    setDeleteLoading(true);
    await deleteAnalysis(sarCase.sarCaseOrgId, rowData.sarCaseId, -1, [
      rowData.analysisId,
    ]);
    setDeleteLoading(false);
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.analysisId === oldData.analysisId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);
        await updateAnalysis(oldData.analysisId, {
          name: newData?.analysisName,
          matchtag:
            oldData.matchTag === newData.matchTag
              ? undefined
              : newData.matchTag,
          description: newData?.description,
        });
        resolve();
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });

  // const reloadData = async () => {
  //   flushSync(() => {
  //     setAnalyses([]);
  //   });
  //   await getAnalysesByCase();
  // };

  // React.useEffect(() => {
  //   setColumns();
  // }, [analyses]);

  return (
    <>
      {statusModalOpen && showAnalysisStatus && (
        <TaskStatusModal
          todo_count={selectedRecord?.analysis_todo_count}
          done_count={selectedRecord?.analysis_done_count}
          percent={selectedRecord?.analysis_percent}
          clock_ms_used={selectedRecord?.clock_ms_used}
          estRemaining={selectedRecord?.estRemaining}
          attempted_count={selectedRecord?.analysis_attempted_count}
          errors={selectedRecord?.analysis_errors}
          reftime={selectedRecord?.analysisDate}
          starttime={selectedRecord?.analysis_start_time}
          endtime={selectedRecord?.analysis_end_time}
          id={selectedRecord?.analysisId}
          showStatus={setShowAnalysisStatus}
          recordType="Analysis"
        />
      )}

     <AnalysisPreview/>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Re-execution"
        message="This analysis is already in progress. Are you sure you want to re-execute it?"
      />

      <Table
        tableId={props?.tableId || "Table-Analysis"}
        dataQuery={true}
        data={(query) => analysesByCaseQuery(case_id, query)}
        // data={analyses}
        columns={columns}
        options={{
          toolbar: false,
          sorting: true,
          draggable: false,
          grouping: false,
          search: false,
          paging: true,
          selection: false,
          maxBodyHeight: "500px",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
        loading={props?.loading}
        onRowView={handleViewClick}
        deleteable={true}
        onRowDelete={handleDeleteClick}
        onRowUpdate={props?.editable ? onUpdate : null}
        editable={props?.editable}
        actions={[
          // {
          //   icon: StartIcon,
          //   tooltip: "Execute",
          //   onClick: (event, rowData) => {
          //     if (executeLoading) {
          //       toast.warn("Execution Request in progress");
          //     }
          //     // if (
          //     //   rowData?.analysis_todo_count > 0 &&
          //     //   rowData?.analysis_done_count < rowData?.analysis_todo_count &&
          //     //   rowData?.analysis_errors === ""
          //     // ) {
          //     // toast.warn("Execution in progress");
          //     // } else {
          //     toast.info("Sending Execution Request");
          //     handleExecuteClick(rowData);
          //     // }
          //   },
          // },
          {
            icon: StartIcon,
            tooltip: "Execute",
            onClick: (event, rowData) => {
              if (executeLoading) {
                toast.warn("Execution Request in progress");
              } else {
                handleExecuteClick(rowData);
              }
            },
          },
          {
            icon: InfoIcon,
            tooltip: "Info",
            onClick: (event, rowData) => handleInfoClick(rowData),
          },
          // {
          //   icon: ReplayIcon,
          //   tooltip: "Reload data",
          //   isFreeAction: true,
          //   onClick: (event) => {
          //     event?.stopPropagation();
          //     reloadData();
          //   },
          // },
        ]}
        recordType="analysis"
      />
    </>
  );
}
