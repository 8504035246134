import React, { useState, useContext } from "react";
import { Grid, Button, Typography, AccordionSummary, ListItemText } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default ({
  title,
  description,
  button1Text,
  button1HandleClick,
  button2Text,
  button2HandleClick,
  button3Text,
  button3HandleClick,
  button2Disabled = false,
}) => {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Grid container spacing={4}>
        <Grid item xs={4} textAlign="left">
          {title && (
            // <Typography sx={{ textDecoration: "underline" }}>
            //   <strong>{title}</strong>
            // </Typography>
            <ListItemText
              primary={title}
              secondary={
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              }
            />
          )}
        </Grid>
        <Grid item xs={4} justifyContent="center">
          {button1Text && button1HandleClick && (
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                button1HandleClick();
              }}
              sx={{ marginLeft: "auto", marginRight: "2vh", width: "25vh" }}
            >
              {button1Text}
            </Button>
          )}

          {button3Text && button3HandleClick && (
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                button3HandleClick();
              }}
              sx={{
                marginLeft: "auto",
                marginRight: "2vh",
                marginTop: "2vh",
                width: "25vh",
              }}
            >
              {button3Text}
            </Button>
          )}
        </Grid>
        <Grid item xs={4} textAlign="right">
          {button2Text && button2HandleClick && (
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => {
                e?.stopPropagation();
                button2HandleClick();
              }}
              sx={{ marginLeft: "auto", marginRight: "2vh", width: "20vh" }}
              disabled={button2Disabled}
            >
              {button2Text}
            </Button>
          )}
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};
