import React from "react";
import { Helmet } from "react-helmet";

import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { VideoContext } from "../Context/videoContext";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "../table/table";
import { toast } from "react-toastify";
// import Cropper, { ReactCropperElement } from "react-cropper";
// import "cropperjs/dist/cropper.css";

const testVideos = [
  {
    caseId: 1,
    videoId: 1,
    frames: [],
  },
];

const testFlightVideo = "https://find911-data.s3.amazonaws.com/Org_10/Case_36/Flight_15/Video/2023_07_18-18_47_59-S1001782.mp4";


export default function FlightVideoViewPage() {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { userCtx } = useContext(UserContext);
  const { flightCtx, getFlightByID, flights, setFlights } = useContext(FlightContext);
  const { videoCtx, getVideoByID, videos, setVideos } = useContext(VideoContext);

  const [flightVideo, setFlightVideo] = useState(testFlightVideo);

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const { case_id, flight_id, video_id } = useParams();

  React.useEffect(() => {
    setFlightVideo(videoCtx.filepath);
  }, [videoCtx]);

  const fetchData = async () => {
    try {
      setLoading(true);
      await getCaseByID(case_id);
      await getVideoByID(video_id);
      setLoading(false);
      setData([videoCtx]);
    }
    catch(error){
      setLoading(false);
      toast.error(error.message);
    }
  
  };
  React.useEffect(() => {
    if (1 == 1) {
      setColumns([
        { title: "Video Id", field: "videoId", editable: "never" },
        { title: "Path Name", field: "filepath", editable: "never" },
        { title: "Length", field: "video_length", editable: "onUpdate" },
        { title: "Purpose", field: "img_purpose", editable: "onUpdate" },
        {
          title: "View type",
          field: "img_view_type",
          editable: "never",
          render: (rowData) =>
            rowData.img_view_type == 0
              ? `Color`
              : rowData.img_view_type == 1
              ? `Thermal RGB`
              : rowData.img_view_type == 2
              ? `Radiometric`
              : `Other {rowData.img_view_type}`,
        },
        { title: "latitude_bottom", field: "latitude_bottom", editable: "onUpdate" },
        { title: "latitude_top", field: "latitude_top", editable: "onUpdate" },
        { title: "longitude_left", field: "longitude_left", editable: "onUpdate" },
        { title: "longitude_right", field: "longitude_right", editable: "onUpdate" },
        { title: "For Report", field: "use_in_report", editable: "onUpdate" },
        { title: "Sequence", field: "img_sequence", editable: "onUpdate" },
        { title: "Date", field: "uploadDate", editable: "never" },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.sarCaseDescription}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ]);
      if (!sarCase.sarCaseId && !flightCtx.flightId && !videoCtx.videoId) {
        fetchData();
      } else {
        setData([videoCtx]);
        setVideos(videoCtx.filepath);
      }
    } else {
      setFlightVideo(testFlightVideo);
    }
  }, [sarCase.sarCaseId, videoCtx.videoId]);

  const onSummaryUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);

        resolve();
      }, 1000);
    });

  return (
    <>
      <Helmet>
        <title>{`Video ${sarCase.sarCaseName}`}</title>
      </Helmet>
      <Box>
        <CssBaseline />

        <Box
          margin={5}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* <Typography variant="h3" align="left" sx={{ marginBottom: 5 }}>
            Video Summary - {videoCtx.videoName}
          </Typography> */}
        </Box>

        <Box margin={5} maxHeight={750}>
          <br />
          <Table
            data={data}
            columns={columns}
            onRowUpdate={onSummaryUpdate}
            loading={loading}
          />
        </Box>
        <Box margin={5}>

        </Box>
      </Box>

      <Box
        margin={5}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <video width="100%" controls >
          <source src={flightVideo} type="video/mp4"/>
        </video>
      </Box>
    </>
  );
}
