import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../Context/appContext";
import api, { API_URL } from "../API";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import { UserContext } from "../Context/userContext";
import { useParams } from "react-router-dom";

const PRESIGN_BATCH_SIZE = 50;
const UPLOAD_BATCH_SIZE = 5; // already used
const URL_TTL_SECONDS = 3600; // 1 hour TTL

const MAX_RETRIES = 3; // Maximum number of retries for failed uploads
const BATCH_SIZE = 5; // Number of files to upload simultaneously

export default function useFileUpload2() {
  const { userCtx, sendWorkerNotification } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({});
  const [progress, setProgress] = useState(0); // Track upload progress
  const { case_id } = useParams();

  useEffect(() => {
    setFiles(files);
  }, [files]);

  // Convert the array into an object for efficient lookups
  const fileNameToUrlMap = (array) => {
    return array.reduce((acc, item) => {
      acc[item.filename] = item.presignedUrl;
      return acc;
    }, {});
  };

  const getBulkPresignedURL = async (uploadContext) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(uploadContext);
        const { data: response } = await api.post("", requestBody);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadWithRetry = async (file, uploadUrl, retries = MAX_RETRIES) => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        });

        if (response.ok) {
          return { filename: file.name, status: "Upload Successful" };
        } else {
          throw new Error(`Upload failed with status: ${response.status}`);
        }
      } catch (error) {
        if (i === retries - 1) throw error; // Throw error on last retry
      }
    }
  };

  const uploadBulkFiles = async (uploadContext) => {
    try {
      const updatedFiles = [];
      const totalFiles = files.length;
      let uploadedCount = 0;

      // Split files into presign batches
      for (let i = 0; i < totalFiles; i += PRESIGN_BATCH_SIZE) {
        const presignBatch = files.slice(i, i + PRESIGN_BATCH_SIZE);

        // Update context for this batch
        const batchUploadContext = {
          ...uploadContext,
          filenameList: presignBatch.map((file) => ({
            filename: file.name,
            content_type: file.type,
          })),
          presigned_ttl: URL_TTL_SECONDS, // ← 1 hour TTL
        };

        // Get presigned URLs for this batch
        let uploadUrls = await getBulkPresignedURL(batchUploadContext);
        uploadUrls = fileNameToUrlMap(uploadUrls); // filename → URL

        // Split presign batch into smaller upload batches
        for (let j = 0; j < presignBatch.length; j += UPLOAD_BATCH_SIZE) {
          const uploadBatch = presignBatch.slice(j, j + UPLOAD_BATCH_SIZE);

          const promises = uploadBatch.map(async (file) => {
            updatedFiles.push(file);
            setUploadStatus((prevStatus) => ({
              ...prevStatus,
              [file.name]: "Uploading...",
            }));

            try {
              const result = await uploadWithRetry(file, uploadUrls[file.name]);
              setUploadStatus((prevStatus) => ({
                ...prevStatus,
                [file.name]: result.status,
              }));
            } catch (error) {
              setUploadStatus((prevStatus) => ({
                ...prevStatus,
                [file.name]: "Upload Failed: " + error.message,
              }));
            } finally {
              uploadedCount++;
              setProgress((uploadedCount / totalFiles) * 100);
            }
          });

          await Promise.all(promises); // wait for current upload batch
        }
      }

      setFiles(updatedFiles);
      return "Upload completed successfully!";
    } catch (error) {
      throw new Error("Upload failed. Please try again.");
    }
  };

  const getPresignedURL = async (uploadContext) => {
    return new Promise(async (resolve, reject) => {
      try {
        const requestBody = JSON.stringify(uploadContext);
        const { data: response } = await api.post("", requestBody);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadFile = async (uploadContext, file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        };
        uploadContext["filename"] = file.name;
        uploadContext["content_type"] = file.type;
        let uploadURL = await getPresignedURL(uploadContext);
        let response = await fetch(uploadURL, data);

        resolve({
          filename: file.name,
          status: response.status,
        });
      } catch (error) {
        try {
          await sendWorkerNotification(
            case_id,
            0,
            error.message,
            `Upload failed for ${file.name}, CASE ID: ${case_id}`,
            true,
            true
          );
        } catch (error) {
          toast.error(error.message);
        }
        resolve({
          filename: file.name,
          status: error.message,
        });
      }
    });
  };

  const uploadFiles = async (uploadContext) => {
    return new Promise(async (resolve, reject) => {
      try {
        let promises = files.map(async (file) =>
          uploadFile(uploadContext, file)
        );
        let response = await Promise.all(promises);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  return [
    files,
    setFiles,
    uploadBulkFiles,
    uploadFiles,
    uploadStatus,
    setUploadStatus,
    progress,
    setProgress,
    uploadWithRetry,
    getPresignedURL,
  ];
}
