import React, { useState, useEffect, useContext } from "react";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { VideoContext } from "../Context/videoContext";

import {
  Box,
  Button,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../table/table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useRadiometericConverters from "../Hooks/useRadiometericConverters";

export default function FlightVideoTable(props) {
  // const videosData = props.videos;

  const { case_id, flight_id } = useParams();
 
  const radiometricCoverters = useRadiometericConverters();

  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { flightCtx, updateFlightCtx } = useContext(FlightContext);
  const {
    videosByFlightQuery,
    videoCtx,
    updateVideoCtx,
    videos,
    setVideos,
    splitFlightVideo,
  } = useContext(VideoContext);
  const [columns, setColumns] = useState([
    {
      title: "View",
      field: "flightId",
      render: (rowData) => (
        <>
          <Tooltip title="View">
            <IconButton
              onClick={(e) => handleEditClick(rowData)}
              aria-label="View"
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    { title: "Video", field: "videoId", editable: "onUpdate" },
    { title: "Path Name", field: "filepath", editable: "onUpdate" },
    { title: "Length", field: "video_length", editable: "onUpdate" },
    {
      title: "Lat/Lng Ranges",
      field: "latitude_bottom",
      editable: "onUpdate",
      render: (rowData) =>
        `Lat:${rowData.latitude_bottom}-${rowData.latitude_top} x Lng:${rowData.longitude_left}-${rowData.longitude_right}`,
    },
    {
      title: "Is Split",
      field: "is_split",
      editable: "onUpdate",
      render: (rowData) =>
        (rowData.is_split && "True") || (
          <Button
            onClick={() => handleSplitClick(rowData.flightId)}
            key={rowData.videoId}
          >
            Split
          </Button>
        ),
    },
    {
      title: "img_view_type",
      field: "img_view_type",
      editable: "never",
      render: (rowData) =>
        rowData.img_view_type == 0
          ? `Color`
          : rowData.img_view_type == 1
          ? `Thermal Grayscale`
          : rowData.img_view_type == 2
          ? `Radiometric`
          : `Other {rowData.img_view_type}`,
    },

    {
      title: "Radiometric converter",
      field: "radiometric_converter_id",
      editable: "onUpdate",

      render: (rowData) => {

        const index = radiometricCoverters.findIndex(
          (el) => el.objId === rowData.radiometric_converter_id
        );
        return index !== -1
          ? radiometricCoverters[index]?.dispName
          : rowData.img_view_type === 2
          ? "No Conversion"
          : "N/A";
      },
      editComponent: (tableData) => (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Radiometric converter
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tableData.rowData.radiometric_converter_id}
            label="radiometric_converter_id"
            onChange={(e) => tableData.onChange(e.target.value)}
          >
            {radiometricCoverters?.map((el) => {
              return (
                <MenuItem
                  // control={<Radio />}
                  value={el.objId}
                  key={el.objId}
                  // disabled={imageID && imageViewType ? true : false}
                >
                  {el.dispName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ),
    },

    {
      title: "Description",
      field: "videoDescription",
      editable: "onUpdate",
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.flightDescription}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
    {
      title: "Date",
      field: "uploadDate",
      editable: "onUpdate",
    },
  ]);

  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEditClick = (rowData) => {
    updateVideoCtx({
      ...rowData,
    });

    navigate(
      `/cases/${sarCase.sarCaseId}/flights/${rowData.flightId}/videos/${rowData.videoId}`
    );
  };

  const handleSplitClick = async (flight_id) => {
    console.log("The split link was clicked for flight", flight_id);
    // setLoading(true);
    await splitFlightVideo(flight_id);
    // setLoading(false);
  };

  const onUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);

        resolve();
      }, 1000);
    });

  const onSelectionChange = (rows, rowData) => {
    console.log("onSelectionChange, rowData:", rowData);
    console.log("onSelectionChange, rows:", rows);
    if (rows.length > 0) {
      let duplicateState = [...props?.videoIds];
      duplicateState = rows.map((el) => el.videoId);
      props?.setVideoIds(duplicateState);
    } else {
      if (rowData) {
        let duplicateState = [...props?.videoIds];
        duplicateState = duplicateState.filter((el) => el !== rowData.videoId);
        props?.setVideoIds(duplicateState);
      } else {
        props?.setVideoIds([]);
      }
    }
  };

  return (
    <>
      <Table
        title={""}
        dataQuery={true}
        data={(query) => videosByFlightQuery(flight_id, query)}
        columns={columns}
        loading={props?.loading}
        onRowUpdate={props?.editable ? onUpdate : null}
        options={props?.options}
        editable={props?.editable}
        onSelectionChange={props?.options?.selection ? onSelectionChange : null}
        // onRowView={!props?.options?.selection ? handleEditClick : null }
      />
    </>
  );
}
