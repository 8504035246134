import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import {
  systemContext,
  userContext,
  caseContext,
  reportContext,
} from "../index";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "../table/table";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon
import InfoIcon from "@mui/icons-material/Info";
import { ReportContext } from "../Context/reportContext";
import { toast } from "react-toastify";
import { UiContext } from "../Context/uiContext";
import TaskStatusModal from "../Modals/TaskStatusModal";

export default function ReportTable(props) {
  // const reports = props.reports;
  const { case_id } = useParams();
  const { handleStatusModalOpen, statusModalOpen } = useContext(UiContext);
  const { updateReportData, deleteReport, reports, setReports, getReportByID } =
    useContext(ReportContext);

  const getStatus = (done_count, todo_count, percentage) => {
    try {
      if (todo_count > 0 && done_count === todo_count) {
        return "Completed";
      }

      if (done_count < todo_count && percentage > 0) {
        return "In Progress";
      }

      if ((done_count && todo_count && percentage) === 0) {
        return "In Queue";
      }

      if ((done_count && todo_count && percentage) === null) {
        return "Failed or needs to be executed again";
      }

      if (done_count > todo_count && percentage > 100) {
        return "Failed or needs to be executed again";
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const [columns, setColumns] = useState([
    { title: "Report ID", field: "reportId", editable: "never" },
    {
      title: "Report Status",
      field: "reportId",
      editable: "never",
      // editable: "onUpdate",  "21" below accounts for a timestamp prefix on the name
      render: (rowData) => (
        <Tooltip title="Refresh to see the latest status">
          <>
            {getStatus(
              rowData?.report_done_count,
              rowData?.report_todo_count,
              rowData?.report_percent
            )}
          </>
        </Tooltip>
      ),
    },
    // { title: "Case ID", field: "sarCaseId", editable: "never" },
    {
      title: "Report",
      field: "filepath",
      editable: "never",
      render: (rowData) => {
        if (rowData.filepath && rowData.filepath.endsWith(".pdf")) {
          return (
            <a
              href={rowData.filepath}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PictureAsPdfIcon style={{ color: "red", cursor: "pointer" }} />
            </a>
          );
        } else {
          return rowData.filepath || "N/A";
        }
      },
    },
    { title: "Name", field: "reportName", editable: "onUpdate" },
    {
      title: "Description",
      field: "description",
      editable: "onUpdate",
    },

    {
      title: "Report Date",
      field: "reportDate",
      editable: "never",
    },
  ]);

  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    // navigate(`Case/${case_id}/Reports/${rowData.reportId}`);
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      const dataUpdate = [...tableData];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      setNewTableData([...dataUpdate]);
      await updateReportData({
        rid: newData.reportId,
        name: newData.reportName,
        description: newData.description,
      });
      resolve();
    });

  const handleDeleteClick = async (oldData) => {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteReport(oldData.reportId);
        let dataUpdate = reports.filter(
          (el) => el.reportId !== oldData.reportId
        );
        setReports([...dataUpdate]);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        resolve(error);
      }
    });
  };

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showReportStatus, setShowReportStatus] = useState(false);

  const showStatusModal = (rowData) => {
    // console.log("rowData:", rowData.analysisId);
    // let record = analyses.filter((el) => el.analysisId === rowData.analysisId);
    // console.log("rowData:", rowData);
    setSelectedRecord(rowData);
    handleStatusModalOpen();
    setShowReportStatus(true);
  };

  const handleInfoClick = async (rowData) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log("rowData:", rowData);
        // const record = await getReportByID(rowData.reportId);
        showStatusModal(rowData);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });
  };

  return (
    <>
      {statusModalOpen && showReportStatus && (
        <TaskStatusModal
          todo_count={selectedRecord?.report_todo_count}
          done_count={selectedRecord?.report_done_count}
          percent={selectedRecord?.report_percent}
          clock_ms_used={selectedRecord?.clock_ms_used}
          estRemaining={selectedRecord?.estRemaining}
          attempted_count={selectedRecord?.report_attempted_count}
          errors={selectedRecord?.report_errors}
          reftime={selectedRecord?.reportDate}
          starttime={selectedRecord?.report_start_time}
          endtime={selectedRecord?.report_end_time}
          id={selectedRecord?.reportId}
          recordType="Report"
          showStatus={setShowReportStatus}
        />
      )}
      <Table
        data={reports ? reports : []}
        columns={columns}
        loading={props?.loading}
        // onRowUpdate={props?.editable ? onUpdate : null}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: false,
          selection: false,
          maxBodyHeight: "500px",
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
        onRowUpdate={props?.editable ? onUpdate : null}
        editable={props?.editable}
        onRowDelete={props?.deleteable ? handleDeleteClick : null}
        deleteable={props?.deleteable}
        actions={[
          {
            icon: InfoIcon,
            tooltip: "Info",
            onClick: (event, rowData) => handleInfoClick(rowData),
          },
        ]}
        // onRowView={handleViewClick}
      />
    </>
  );
}
